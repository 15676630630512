(function (app) {
    app.controller('MatterDetailsCtrl', [
        '$scope', '$mdDialog', 'matterId',
        'DocumentGroupsResourceFactory', 'utils', 'doc',
        function (
            $, $mdDialog, matterId,
            DocumentGroupsResourceFactory, utils, doc
        ) {
            $.documentGroup = null;
            $.doc = doc;

            DocumentGroupsResourceFactory.create()
                .then(function (resource) {
                    return resource.get({ id: matterId }).$promise;
                })
                .then(function (res) {
                    $.documentGroup = res;
                });

            $.close = function () {
                $mdDialog.hide();
            };
        }
    ]);
})(angular.module('onfileApp'));
