;(function (app) {
  app.factory('PartyService', function () {

    return function PartyService(data) {
      const PERSON_ENTITY_TYPE = 'person';
      const BUSINESS_PERSON_ENTITY_TYPE = 'business';

      if(data && data.hasOwnProperty("isValid")) {
        this.$valid = data.isValid ? data.isValid : false;
      }
      else
        this.$valid = false;

      var isRemovable = true;
      var isEditable = true;

      this.$setValidity = function (validity) {
        this.$valid = !!validity;
      };

      function init(data) {
        angular.extend(this, data);
      }

      if (data) {
        init.call(this, data);
      }

      /**
       * @returns {string}
       */
      this.getName = function () {
        if (this.entityType === 'business' && this.name) {
          return this.name;
        }

        if(!this.hasOwnProperty('entityType')) // this is the case of loading an existing case
          return this.name;

        var parts = [];

        if (this.first_name)
          parts.push(this.first_name);

        if (this.middle_name)
          parts.push(this.middle_name);

        if (this.last_name)
          parts.push(this.last_name);

        return parts.join(' ');
      };

      /**
       * @returns {string}
       */
      this.getAttorneyName = function () {
        if (!this.attorney)
          return '';

        var parts = [];

        if (this.attorney.hasOwnProperty('firstName') && this.attorney.firstName)
          parts.push(this.attorney.firstName);

        if (this.attorney.hasOwnProperty('middleName') && this.attorney.middleName)
          parts.push(this.attorney.middleName);

        if (this.attorney.hasOwnProperty('lastName') && this.attorney.lastName)
          parts.push(this.attorney.lastName);

        return parts.join(' ');
      };

      this.getTypeName = function () {
        if (this.hasOwnProperty('type') && this.type && this.type.hasOwnProperty('name'))
          return this.type.name;

        if (this.hasOwnProperty('type_name'))
          return this.type_name;

        return '';
      };

      this.isRemovable = function () {
        if (this.hasOwnProperty('type') && this.type && this.type.hasOwnProperty('isFixed'))
          return !this.type.isFixed;

        return isRemovable;
      };

      this.setIsRemovable = function (value) {
        isRemovable = !!value;

        return this;
      };

      this.isEditable = function () {
        return isEditable;
      };

      this.setIsEditable = function (value) {
        isEditable = !!value;

        return this;
      };

      return this;
    };
  });
})(angular.module('onfileApp'));