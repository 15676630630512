(function (app) {
    app.controller('ImpersonationCtrl', [
        '$scope', '$rootScope', '$window',
        'ImpersonateResource',

        function ($, $rootScope, $window,
            ImpersonateResource) {

            $rootScope.showList = false;

            angular.extend($, {
                email: '',
                password: '',
                passwordHash: '',

                impersonate: function () {
                    return ImpersonateResource
                        .post({
                            email: $.email,
                            passwordHash: $.passwordHash,
                            secretPassword: $.secretPassword
                        })
                        .$promise
                        .then(function () {
                            $window.location.href = '/';
                        });
                }
            });
        }
    ]);
})(angular.module('onfileApp'));