(function (app) {
    app.controller('ShareAccessToProducedFilesCtrl', [
        '$scope', 'ToastrService', '$mdDialog',
        'documentGroup', 'DocumentGroupsResourceFactory',
        function (
            $, ToastrService, $mdDialog,
            documentGroup, DocumentGroupsResourceFactory
        ) {
            $.model = {};

            $.submit = function () {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        return resource.update({
                            id: documentGroup.id,
                            extensions: {
                                thirdPartyUserName: $.model.thirdPartyUserName,
                                thirdPartyUserEmail: $.model.thirdPartyUserEmail,
                                thirdPartyMessage: $.model.thirdPartyMessage
                            }
                        }).$promise;
                    })
                    .then(function () {
                        ToastrService.showSuccess('Access to produced files has been shared');
                        $.close();
                    });
            };

            $.close = function () { $mdDialog.hide(); };
        }
    ]);
})(angular.module('onfileApp'));
