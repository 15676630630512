(function (app) {
    app.factory('HearingDateModelFactory', [
        'utils',
        function (utils) {
            function HearingDateModel() {
                this.duration = 1;
                this.endDate = null;
                this.startDate = null;
                this.validationErrorMessages = {
                    duration: 'Please enter Duration',
                    startDate: 'Please enter Start Date',
                    endDate: 'Please enter End Date'
                };
                this.errors = [];

                this.isValid = function () {
                    var isValid = true;
                    this.errors = [];

                    Object.keys(this.validationErrorMessages)
                        .map(function (prop) {
                            if (!this[prop]) {
                                isValid = false;
                                this.errors.push(this.validationErrorMessages[prop]);
                            }
                        }.bind(this));

                    return isValid;
                };

                this.toDataObject = function () {
                    return {
                        startDate: +this.startDate,
                        endDate: +this.endDate,
                        duration: +this.duration
                    };
                };
            }

            return {
                create: function () { return new HearingDateModel(); },
                formatDate: function (start) {
                    var startDate = moment(start);
                    var format = '{{startWeekDayName}}, {{startMonthName}} {{startDate}}, {{startYear}} - ' +
                            '{{startTime}}';
                    var attributes = {
                        startWeekDayName: startDate.format('dddd'),
                        startMonthName: startDate.format('MMMM'),
                        startDate: startDate.format('D'),
                        startYear: startDate.format('YYYY'),
                        startTime: startDate.format('h:mma')
                    };

                    return utils.replacePlaceholders(format, attributes);
                }
            };
        }
    ]);
})(angular.module('onfileApp'));
