(function (app) {
    app.directive('pagination', [
        function () {
            return {
                strict: 'A',
                scope: {
                    quantity: '=?',
                    limit: '=?',
                    begin: '=?'
                },
                link: function ($) {
                    angular.extend($, {
                        limit: $.limit || 5,
                        begin: $.begin || 0,
                        quantity: $.quantity || 0,
                        currentPage: 1,

                        countPages: function () {
                            return Math.ceil($.quantity / $.limit);
                        },
                        getPages: function () {
                            return $.countPages() ? new Array($.countPages()) : [];
                        },
                        showPage: function (index) {
                            if (index === 'last' || index > $.countPages()) {
                                $.currentPage = $.countPages();
                            } else if (index === 'first' || !index || index <= 0) {
                                $.currentPage = 1;
                            } else {
                                $.currentPage = parseInt(index);
                            }

                            if (isNaN($.currentPage) || $.currentPage <= 0) {
                                $.currentPage = 1;
                            }

                            $.begin = ($.currentPage - 1) * $.limit;
                            $.begin = $.begin < 0 ? 0 : $.begin;
                        }
                    });
                },
                templateUrl: '/assets/app/shared/directives/pagination/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));