;(function (app) {
    app.factory('PrivilegeLogService', [
        'FirebaseService', '$q',

        function (FirebaseService, $q) {
            const self = this;
            var envParams;

            self.files = [];

            self.init = function (options) {
                envParams = options;

                return FirebaseService
                    .getNodeAsArray(composeFilesPath(options))
                    .$loaded()
                    .then(function (files) {
                        self.files = files;

                        return files;
                    });
            };

            self.addFiles = function (files) {
                var promises = [];
                var promise;

                if (Array.isArray(files) === false) {
                    files = [files];
                }

                for (var i = 0, file; i < files.length; i++) {
                    file = files[i];

                    promise = self.files
                        .$add({
                            client: file.client,
                            container: file.container,
                            display_name: file.display_name || file.filename,
                            filename: file.filename,
                            key: file.key,
                            mimetype: file.mimetype,
                            size: file.size,
                            url: file.url,
                            time: (new Date()).getTime()
                        })
                        .then(function (i) {
                            var newRecord = self.files.$getRecord(i.key);

                            newRecord.indexKey = newRecord.$id;

                            return self.files.$save(newRecord);
                        });

                    promises.push(promise);
                }

                return $q.all(promises);
            };

            self.getFiles = function (filter) {
                if (filter) {
                    var result = [];
                    for (var i = 0; i < self.files.length; i++) {
                        var file = self.files[i];
                        if (file.display_name.toLowerCase().indexOf(filter.toLowerCase()) != -1)
                            result.push(self.files[i]);
                    }

                    return result;
                }
                else
                    return self.files;
            };

            self.update = function (file) {
                return self.files.$save(file);
            };

            self.delete = function (file) {
                return self.files.$remove(file);
            };

            self.moveToEProduction = function (privilegeLog) {
                const eProdPath = composeEProductionPath(privilegeLog);

                return FirebaseService.saveNode(
                    eProdPath,
                    FirebaseService.purifyObject(privilegeLog)
                ).then(function () {
                    return self.delete(privilegeLog);
                });
            };

            const composeEProductionPath = function (privilegeLog) {
                return '/eproduction/packages/' + envParams.firmId + '/' + envParams.packageId +
                    '/batch_body/files/' + privilegeLog.$id;
            };

            const composeFilesPath = function (options) {
                if (!options.firmId || !options.caseId || !options.packageId) {
                    throw new Error('There is no required param for initialisation of Priv log');
                }

                return '/privilege_logs/' + options.firmId + '/' + options.caseId + '/' + options.packageId + '/files';
            };

            return self;
        }
    ]);
})(angular.module('onfileApp'));