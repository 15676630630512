(function (app) {
    app.controller('ReviewCaseCtrl', [
        'FeeResource', 'CaseService', '$rootScope',
        '$scope', '$timeout', 'TextNotificationService',
        'FeesService', 'AttorneyService', 'utils',

        function (
            FeeResource, CaseService, $rootScope,
            $, $timeout, TextNotificationService,
            FeesService, AttorneyService, utils
        ) {
            angular.extend($, {
                composeCaseName: CaseService.composeCaseName,
                minDate: new Date()
            });

            TextNotificationService.getNode()
                .then(function (settings) {
                    $.globalTextNotificationEnabled = settings.enabled;
                    processAlertPreference();
                });

            var timer;
            var processAlertPreference = function () {
                TextNotificationService.getNode()
                    .then(function (settings) {
                        $.globalTextNotificationEnabled = settings.enabled;
                    })
                    .then(function () {
                        // Set text notification enabled by default
                        $.case.text_alert_on_change = $.globalTextNotificationEnabled;

                        if (!$.globalTextNotificationEnabled) {
                            if (!timer) timer = $timeout(processAlertPreference, 500);
                        }
                        else {
                            if (timer)
                                $timeout.cancel(timer);
                        }
                    });
            };

            $.calculateFees = function () {
                return FeesService.calculate($.case);
            };

            $.formattedDate = utils.formatDate();

            $.getAttorneyName = function (eCase) { return AttorneyService.getFullName(eCase); };
        }
    ]);
})(angular.module('onfileApp'));
