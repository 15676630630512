(function (app) {
    app.controller('EServiceDraftCtrl', [
        'FirebaseService', 'MarketRulesService',
        '$scope', '$rootScope', '$stateParams',
        '$state', 'ToastrService', 'CaseResource',

        function (
            FirebaseService, MarketRulesService,
            $, $rootScope, $stateParams,
            $state, ToastrService, CaseResource
        ) {
            angular.extend($, {
                eFilingDraft: null
            });

            this.uiOnParamsChanged = function (changedParams) {
                $rootScope.$watch('eServicesDrafts', function (eServicesDrafts) {
                    if (!eServicesDrafts) {
                        return false;
                    }
                    eServicesDrafts.map(function(e){
                        if (e.$id == changedParams.id) { $.eServiceDraft = e; return; }
                    });
                });
            };

            $.deleteDraft = function () {
                $rootScope.eServicesDrafts
                    .$remove($.eServiceDraft)
                    .then(function () {
                        ToastrService.showMessageByKey('draft_deleted');
                        $state.go('draftServices');
                    });
            };

            $.resumeDraft = function (data) {
                if (MarketRulesService.isVisible('isCaseAvailableCheck')) {
                    CaseResource.check({
                        id: data.caseId,
                        court_code: data.courtId
                    })
                        .$promise
                        .then(function (status) {
                            if (status.error_code === 10) {
                                $.caseUnavailable = true;
                            } else if (status.error_code === 130) {
                                $.caseReportsUnavailable = true;
                            } else {
                                resumeDraft();
                            }
                        });
                } else {
                    resumeDraft();
                }
            };

            function resumeDraft() {
                $state.go('servicesCreate', {
                    caseId: $.eServiceDraft.item.id,
                    courtId: $.eServiceDraft.item.court_code,
                    currentTab: 0,
                    draftId: $.eServiceDraft.$id
                });
            }

            this.uiOnParamsChanged({id: $stateParams.id});
        }
    ]);
})(angular.module('onfileApp'));
