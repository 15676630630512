(function (app) {
    app.controller('EProductionSubmittedSidebarCtrl', [
        '$scope', '$rootScope', 'eProductionService', 'EnvironmentService',

        function ($, $rootScope, eProductionService, EnvironmentService) {
            $rootScope.showList = true;
            $rootScope.eProductionsSubmitted = null;

            eProductionService
                .loadSubmitted(EnvironmentService.firm_id())
                .then(function (items) {
                    $rootScope.eProductionsSubmitted = items;
                });
        }
    ]);
})(angular.module('onfileApp'));
