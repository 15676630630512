(function (app) {
    app.controller('ServiceInboxMessageCtrl', [
        '$scope', '$rootScope', '$stateParams',
        '$timeout', 'FilingCodeResource',

        function ($, $rootScope, $stateParams,
            $timeout, FilingCodeResource) {
            var timeToRead = 2 * 1000;

            var getCaseCourt = function () {
                return $.message.data.Envelope.Body.ServiceCallbackMessage
                    .CivilCase.CaseAugmentation[0].CaseCourt.OrganizationIdentification.IdentificationID;
            };

            var getCaseTrackingId = function () {
                return $.message.data.Envelope.Body.ServiceCallbackMessage
                    .CivilCase.CaseAugmentation[0].CaseLineageCase.CaseTrackingID;
            };

            angular.extend($, {
                message: null,
                setPopupVisible: function () {
                    $.isCreateReminderPopupVisible = true;
                },
                case: {}
            });

            this.uiOnParamsChanged = function (changedParams) {

                $rootScope.$watch('inboxMessages', function (inboxMessages) {
                    if (!inboxMessages)
                        return false;

                    $rootScope.inboxMessages
                        .$loaded()
                        .then(function (messages) {
                            for (var i = 0; i < messages.length; i++) {
                                if (messages[i].$id === changedParams.id) {
                                    if (!messages[i].hasOwnProperty('filing_type')) {
                                        $.message = messages[i];
                                        messages[i].filing_type = ConvertRegisterActionDescriptionText();
                                        $.message.filing_type = messages[i].filing_type;
                                    }
                                    else
                                        $.message = messages[i];

                                    $.court = getCaseCourt();
                                    $.caseId = getCaseTrackingId();
                                    $.case = {
                                        id: $.caseId,
                                        court_code: $.court,
                                        title: '',
                                        docket_id: $.message.data.Envelope.Body.ServiceCallbackMessage
                                            .CivilCase.CaseDocketID,
                                        serviceId: $.message
                                            .data
                                            .Envelope
                                            .Body
                                            .ServiceCallbackMessage
                                            .DocumentIdentification
                                            .IdentificationID
                                    };
                                    break;

                                }
                            }

                            if (!$.message.read) {
                                $timeout(function () {
                                    $.message.read = true;
                                    $rootScope.inboxMessages.$save($.message);
                                }, timeToRead);
                            }

                        });
                });
            };

            this.uiOnParamsChanged({id: $stateParams.id});

            $.recipients = function () {

                if ($.message === undefined || $.message === null)
                    return null;

                var node = $.message.data.Envelope.Body.ServiceCallbackMessage.ReviewedLeadDocument
                    .DocumentRendition.DocumentRenditionMetadata;

                return Array.isArray(node) ? node : [node];
            };

            $.submissions = function () {
                if ($.message === undefined || $.message === null)
                    return null;

                var node = $.message.data.Envelope.Body.ServiceCallbackMessage.ReviewedLeadDocument
                    .DocumentRendition.DocumentRenditionMetadata;

                if (Array.isArray(node.DocumentAttachment)) {
                    return node.DocumentAttachment;
                } else {
                    return [node.DocumentAttachment];
                }
            };


            var getRegisterActionDescriptionText = function () {
                return $.message.data.Envelope.Body.ServiceCallbackMessage
                    .ReviewedLeadDocument.DocumentMetadata.RegisterActionDescriptionText;
            };

            var ConvertRegisterActionDescriptionText = function () {
                var filingCodeId = getRegisterActionDescriptionText();

                FilingCodeResource.get({
                    court_code: getCaseCourt(),
                    filing_code_id: filingCodeId
                })
                    .$promise
                    .then(function (res) {
                        $.message.filing_type = (filingCodeId === 'SERVICE ONLY') ? filingCodeId : res.name;
                    });
            };
        }
    ]);
})(angular.module('onfileApp'));
