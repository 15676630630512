(function (app) {
    app.controller('ShareMatterFilesCtrl', [
        '$scope', 'ToastrService', '$mdDialog',
        'documents',
        function (
            $, ToastrService, $mdDialog,
            documents
        ) {
            $.documents = documents;

            $.submit = function () {
                ToastrService.showSuccess('Access to matter documents has been shared');
                $.close();
            };

            $.close = function () { $mdDialog.hide(); };
        }
    ]);
})(angular.module('onfileApp'));
