(function (app) {
    app.factory('AttorneyResource', ['$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/attorneys', {}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: false
                },
                save: urlEncodedResource({
                    url: '/attorneys/create',
                    method: 'POST',
                    isArray: false
                })
            });
        }]);
})(angular.module('onfileApp'));