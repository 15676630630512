(function (app) {
    app.factory('ReportResource', [
        '$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            var params = urlEncodedResource({
                method: 'POST',
                cache: false,
                isArray: true,
                cancellable: true
            });

            return $resource('/filing-reports', {}, {
                filings: params,
                payment: params
            });
        }
    ]);
})(angular.module('onfileApp'));