(function (app) {
    app.factory('ImpersonateResource', [
        '$resource', 'urlEncodedResource',

        function ($resource, urlEncodedResource) {
            return $resource('/impersonation/index', {}, {
                post: urlEncodedResource({
                    method: 'POST',
                    isArray: false,
                    cache: false
                })
            });
        }
    ]);
})(angular.module('onfileApp'));