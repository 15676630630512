(function (app) {
    app.controller('sendMobileIdCaptureLinkCtrl', [
        '$scope', 'ToastrService', '$mdDialog',
        'session', 'NotarySessionsResourceFactory',
        'NotaryService', 'EnvironmentService',
        function (
            $, ToastrService, $mdDialog,
            session, NotarySessionsResourceFactory,
            NotaryService, EnvironmentService
        ) {
            var notaryLink = EnvironmentService.environment() === 'development' ? 'https://demo.onlinenotary.us/' : 'https://app.onlinenotary.us/';
            $.model = {};
            // $.phoneMask = '+999 999-999-9999';
            $.sent = false;
            $.userLink
            $.createLink = function () {
                console.log(EnvironmentService.environment())
                if (session.security) {
                    $.userLink = NotaryService.createUserLink(
                        notaryLink,
                        session.security
                    ) + '/mv/c';
                    return true
                }
                return false
            }
            $.linkCopied = function () {
                ToastrService.showSuccess(
                    "LINK COPIED TO CLIPBOARD"
                );
                NotaryService.changeSessionStage(session.id, 'mobileVerificationIdCapture')
                    .then(function () {
                        session.stage = stage;
                    });
                $.close();
            }
            $.submit = function () {
              const options = {
                officialId: session.officialUid,
                firmId: session.firmId
              }
                return NotarySessionsResourceFactory.create(options)
                    .then(function (resource) {
                        return resource.sendMobileVerificationLink({
                            sessionId: session.id,
                            email: $.model.userEmail,
                            phone: $.model.phone,
                        }).$promise;
                    })
                    .then(function () {
                        NotaryService.changeSessionStage(session.id, 'mobileVerificationIdCapture')
                            .then(function () {
                               session.stage = stage;
                            });
                        ToastrService.showSuccess(
                            "Mobile ID verification link sent"
                        );
                        $.close();
                    });
            };

            $.close = function () { $mdDialog.hide(); };
        }
    ]);
})(angular.module('onfileApp'));
