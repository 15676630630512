(function (app) {
    app.constant('isHavingErrors', function (item, iterableProp) {
        if (!iterableProp) {
            return item && Object.keys(item.$error).length;
        }

        var curr;
        var hasErrors;
        var targetProperties = Object.keys(item)
            .filter(function (i) {
                return i.indexOf(iterableProp) >= 0;
            });

        for (var i in targetProperties) {
            curr = item[targetProperties[i]];
            hasErrors = curr && curr.$error && Object.keys(curr.$error).length;

            if (hasErrors) {
                return true;
            }
        }

        return false;
    });
})(angular.module('onfileApp'));