(function (app) {
    app.service('RequestsService', [
        '$rootScope',
        function ($rootScope) {
            this.setMessageForRequest = function (request, message) {
                $rootScope.setLoadingMessage(message);

                return request
                    .finally(function () {
                        $rootScope.setLoadingMessage('');
                    });
            };
        }
    ]);
})(angular.module('onfileApp'));
