(function (app) {
    app.value(
        'TransformerDecorator',
        function TransformerDecorator(transformer, raw, propertiesMap) {
            transformer.getValueByKey = function (path) {
                return path.split('.')
                    .reduce(
                        function (prev, curr) {
                            return prev ? prev[curr] : undefined;
                        },
                        transformer
                    );
            };
            transformer.construct = (function construct(props) {
                var property;
                for (property in props) {
                    transformer[property] = props[property];
                }

                for (property in propertiesMap) {
                    createMethod(property);
                }

                return transformer;
            });

            var createMethod = function createMethod(property) {
                return Object.defineProperty(
                    transformer,
                    property,
                    {
                        get: (function (property) {
                            return function () {
                                return function (defaultValue) {
                                    return transformer.getValueByKey(
                                        propertiesMap[property],
                                        defaultValue
                                    );
                                };
                            };
                        })(property)
                    }
                );
            };

            return transformer.construct(raw);
        }
    );
})(angular.module('onfileApp'));