;(function (app) {
  app.service('AccessLogItemService', function () {
    this.ip = null;
    this.type = null;
    this.filename = null;
    this.emailHash = null;

    this.TYPE_FILE = 'file';
    this.TYPE_LOGIN = 'login';
    this.TYPE_DOWNLOAD_ALL_STARTS = 'download_all_starts';
    this.TYPE_DOWNLOAD_ALL_ENDS = 'download_all_ends';

    this.init = function (data) {
      angular.extend(this, data);

      return this;
    };

    var has = Object.prototype.hasOwnProperty;

    var getNeededPropsSet = function (type) {
      switch (type) {
        case this.TYPE_FILE:
          return ['ip', 'type', 'filename', 'email'];
        case this.TYPE_LOGIN:
          return ['ip', 'type', 'email'];
        case this.TYPE_DOWNLOAD_ALL_STARTS:
          return ['ip', 'type', 'email'];
        case this.TYPE_DOWNLOAD_ALL_ENDS:
          return ['ip', 'type', 'email'];
        default:
          throw new Error('Unknown AccessLogItem type');
      }
    };

    // const getZeroGtmTimestamp = function () {
    //   return Date.parse(
    //       new Date((new Date()).getTime() + (new Date()).getTimezoneOffset() * 60000));
    // };

    // const getZeroGtmTimestamp = function () {
    //   const startOfWatch = new Date();
    //   return (+(startOfWatch) + startOfWatch.getTimezoneOffset() * 1000);
    // };

    const getZeroGtmTimestamp = function (){
      var d = new Date();
      var n = d.getTime();

      return n;
    };

    this.toPlainObject = function () {
      var result = {
        timestamp: getZeroGtmTimestamp()
      };
      var neededPropsSet = getNeededPropsSet.call(this, this.type);

      for (var prop in this) {
        if (has.call(this, prop) === false) {
          continue;
        }

        if (neededPropsSet.indexOf(prop) >= 0) {
          result[prop] = this[prop] ? this[prop].toString() : '';
        }
      }

      return result;
    };

    this.insertToLog = function (log) {
      if (has.call(log, 'access_log') === false) {
        log.access_log = {};
      }

      if (!log.access_log || has.call(log.access_log, this.emailHash) === false) {
        log.access_log[this.emailHash] = [];
      }

      log.access_log[this.emailHash]
        .push(this.toPlainObject());

      return log;
    };
  });
})(angular.module('onfileApp'));