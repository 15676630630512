(function (app) {
    app.directive('courtCoverSheet', [
        '$document', '$mdDialog', 'PdfConversionResource',
        '$timeout', 'EnvironmentService', 'CourtCoverSheetService',
        '$http', 'marketCoverSheetSettings', '$mdMenu', 'GAService',

        function (
            $document, $mdDialog, PdfConversionResource,
            $timeout, EnvironmentService, CourtCoverSheetService,
            $http, marketCoverSheetSettings, $mdMenu, GAService
        ) {
            return {
                scope: {
                    case: '=',
                    convertedCallback: '&?'
                },
                link: function ($) {
                    $.sheetItemCourtCode = '';
                    $.templateItems = [];

                    CourtCoverSheetService.getStyles($http)
                        .then(function (styles) {
                            $.styles = styles;
                        });

                    $.changeTemplate = function (sheetItem) {
                        if (!sheetItem) {
                            return false;
                        }

                        $mdDialog.show({
                            controller: getPopupController(
                                $, sheetItem, PdfConversionResource, $.hideFn, $timeout,
                                EnvironmentService, CourtCoverSheetService, GAService
                            ),
                            templateUrl: '/assets/app/shared/directives/courtCoverSheet/_popup.html?' +
                                '{{onfileApplicationVersion}}',
                            parent: angular.element($document.body),
                            clickOutsideToClose: true
                        });
                    };

                    $.hideFn = function () {
                        $.sheetItemCourtCode = '';
                        $mdDialog.hide();
                    };

                    $.$watch('case.court_code', function (val) {
                        if (!val) {
                            $.templateItems = [];
                            return;
                        }

                        $.templateItems = CourtCoverSheetService.getSheetsList({
                            marketCoverSheetSettings: marketCoverSheetSettings,
                            courtCode: val,
                            market: EnvironmentService.market()
                        });
                    });

                    $.$watch('case.type', function (val) {
                        if (!val) {
                            $.templateItems = [];
                            return;
                        }

                        var caseTypeCode = null;
                        if ($.case.type.hasOwnProperty('code')){
                            caseTypeCode = $.case.type.code;
                        } else {
                            caseTypeCode = $.case.type_code_id;
                        }

                        $.templateItems = CourtCoverSheetService.getSheetsList({
                            marketCoverSheetSettings: marketCoverSheetSettings,
                            courtCode: $.case.court_code,
                            caseTypeCode: caseTypeCode,
                            market: EnvironmentService.market()
                        });
                    });
                },
                templateUrl: '/assets/app/shared/directives/courtCoverSheet/template.html?' +
                    '{{onfileApplicationVersion}}'
            };
        }
    ]);

    var getPopupController = function (
        $, sheetItem, PdfConversionResource, hideFn, $timeout,
        EnvironmentService, CourtCoverSheetService, GAService
    ) {
        return [
            '$scope', 'Upload', 'SignatureResource',
            'ToastrService',
            function (
                $localScope, Upload, SignatureResource,
                ToastrService
            ) {
                if (!sheetItem) {
                    return;
                }

                var firmInfo = EnvironmentService.getFirmInfo();
                var uploadSignatureInputSelector = '#uploadSignature';

                $localScope.isSignatureNeeded = false;
                $localScope.selectedSignature = null;
                $localScope.signatures = [];

                $localScope.courtCode = $.case.court_code;
                $localScope.caseTypeCode = $.case.type.code;
                $localScope.templateUrl = sheetItem.file;
                $localScope.district = CourtCoverSheetService.getDistrict($.case.court_code);
                $localScope.county = CourtCoverSheetService.getCounty($.case.court_code);
                $localScope.caseNumber = CourtCoverSheetService.getCaseNumber($.case);
                $localScope.caseTitle = $.case.title;

                $localScope.plaintiff = CourtCoverSheetService.getPartyName($.case, 'Plaintiff');
                $localScope.defendant = CourtCoverSheetService.getPartyName($.case, 'Defendant');
                $localScope.secondDefendant = CourtCoverSheetService.getPartyName($.case, 'Defendant', 1);
                $localScope.defendantAddress = CourtCoverSheetService.getPartyAddress($.case, 'Defendant');
                $localScope.secondDefendantAddress = CourtCoverSheetService.getPartyAddress($.case, 'Defendant', 1);
                $localScope.estateName = CourtCoverSheetService.getPartyName($.case, 'Estate Name');
                $localScope.petitioner = CourtCoverSheetService.getPartyName($.case, 'Petitioner');
                $localScope.petitionerAddress = CourtCoverSheetService.getPartyAddress($.case, 'Petitioner');
                $localScope.respondent = CourtCoverSheetService.getPartyName($.case, 'Respondent');
                $localScope.responderAddress = CourtCoverSheetService.getPartyAddress($.case, 'Respondent');

                $localScope.filingPartyName = CourtCoverSheetService.getFilingPartyName($.case);
                $localScope.filingPartyType = CourtCoverSheetService.getFilingPartyType($.case);
                $localScope.filingPartyCity = CourtCoverSheetService.getFilingPartyCity($.case);
                $localScope.filingPartyState = CourtCoverSheetService.getFilingPartyState($.case);
                $localScope.filingPartyZip = CourtCoverSheetService.getFilingPartyZip($.case);
                $localScope.filingPartyPhone = CourtCoverSheetService.getFilingPartyPhone($.case);
                $localScope.filingPartyAddress = CourtCoverSheetService.getFilingPartyAddress($.case);

                $localScope.firstListedParty = CourtCoverSheetService.getPartyName($.case, 0);
                $localScope.secondListedParty = CourtCoverSheetService.getPartyName($.case, 1);

                $localScope.attorneyNumber = CourtCoverSheetService.getAttorneyNumber($.case);
                $localScope.attorneyName = CourtCoverSheetService.getAttorneyName($.case);
                $localScope.attorneyFor = CourtCoverSheetService.getAttorneyFor($.case);
                $localScope.address = CourtCoverSheetService.getAddress(firmInfo);
                $localScope.phone = CourtCoverSheetService.getPhone(firmInfo);
                $localScope.city = CourtCoverSheetService.getCity(firmInfo);
                $localScope.state = CourtCoverSheetService.getState(firmInfo);
                $localScope.zip = CourtCoverSheetService.getZip(firmInfo);
                $localScope.email = EnvironmentService.isAdmin() ? EnvironmentService.email() : '';
                $localScope.firmName = CourtCoverSheetService.getFirmName(firmInfo);
                $localScope.fullAddress = CourtCoverSheetService.getFullAddress(firmInfo);
                $localScope.styles = $.styles;

                $localScope.isCourtCodeSelected = function (courtCodes) {
                    var selectedCourtCode = this.courtCode;

                    return courtCodes.some(function (courtCode) {
                        return courtCode === selectedCourtCode;
                    });
                };

                $localScope.isCaseTypeCodeSelected = function (caseTypeCodes) {
                    var selectedCaseTypeCode = this.caseTypeCode;

                    return caseTypeCodes.some(function (caseTypeCode) {
                        return caseTypeCode === selectedCaseTypeCode;
                    });
                };

                console.debug($.case);

                $localScope.convertToPdf = function () {
                    var html = CourtCoverSheetService.prepareHtml(document.getElementById('cover-sheet').innerHTML);
                    var templateUsed = document.getElementById('formTemplateUrl').value;
                    var file = $.templateItems.find(function (o) { return o.file === templateUsed; });

                    console.log('ConverSheets -> ' + firmInfo.firmName + ' -> ' + sheetItem.name);
                    GAService.addEvent('CoverSheets', firmInfo.firmName, sheetItem.name);

                    return PdfConversionResource.convert({ html: html, title: file.name }).$promise
                        .then(function (data) {
                            if ($.convertedCallback) {
                                $.convertedCallback({ data: data });
                            }

                            hideFn();
                        });
                };

                $localScope.close = function () {
                    hideFn();
                };

                $localScope.init = function (options) {
                    $localScope.isSignatureNeeded = options && options.signature;

                    if ($localScope.isSignatureNeeded) {
                        SignatureResource.query().$promise
                            .then(function (signatures) {
                                $localScope.signatures = signatures;
                            });
                    }

                    attachCheckboxHandler($timeout, jQuery);
                    attachRadioHandler($timeout, jQuery, function (index) {
                        selectCaseType($timeout, $.case, jQuery, index);
                    });
                    selectCaseType($timeout, $.case, jQuery);

                    if ($localScope.attorneyNumber) {
                        selectAttorneyNumber(jQuery);
                    }

                    bindChangeFileSignatureHandler(uploadSignatureInputSelector, $localScope.onSignatureSelected);
                };

                $localScope.triggerUploadSignature = function () {
                    $timeout(function () {
                        angular.element(uploadSignatureInputSelector).trigger('click');
                    }, 0);
                };

                $localScope.onSignatureSelected = function (event) {
                    return uploadNewSignature(Upload, event, $localScope)
                        .then(function (newSignature) {
                            $localScope.selectedSignature = newSignature;
                        });
                };

                $localScope.selectSignature = function (signature) {
                    $localScope.printedSignature = null;
                    $localScope.selectedSignature = signature;
                };

                $localScope.insertDigitalSignature = function () {
                    if (!$localScope.attorneyName) {
                        ToastrService.showMessageByKey('select_filing_attorney');

                        return;
                    }

                    $localScope.printedSignature = null;
                    $localScope.selectedSignature = null;
                    $localScope.printedSignature = '/s/ ' + $localScope.attorneyName;
                };
            }
        ];
    };

    var attachCheckboxHandler = function ($timeout, $) {
        var strikethroughClass = 'strikethrough';

        $timeout(function () {
            // Setting checked attribute directly in HTML for correct PDF generation
            $('[type=checkbox]').change(function () {
                var el = $(this);

                if (el.is(':checked')) {
                    return el.attr('checked', 'checked');
                }

                el.attr('checked', false);
            });

            // Strikes through the this checkbox label
            $('[type=checkbox][data-strikethrough-trigger]').change(function () {
                if (this.checked) {
                    $(this).siblings('label').addClass(strikethroughClass);
                } else {
                    $(this).siblings('label').removeClass(strikethroughClass);
                }
            });
        }, 100);
    };

    var attachRadioHandler = function ($timeout, $, selectCaseTypeHandler) {
        var strikethroughClass = 'strikethrough';

        $timeout(function () {
            // Setting checked attribute directly in HTML for correct PDF generation
            $('[type=radio]').change(function () {
                var el = $(this);
                var name = el.attr('name');

                if (name) {
                    $('[name=' + name + ']').attr('checked', false);
                }

                if (el.is(':checked')) {
                    return el.attr('checked', 'checked');
                }
            });

            $('[name=juryDemand]').change(function () {
                var value = +$(this).val();

                $('[data-case-types]').attr('checked', false);

                selectCaseTypeHandler(value);
            });

            // Strikes through the other radio buttons related text
            $('[type=radio][data-strikethrough-trigger]').change(function () {
                var el = $(this);
                var name = el.attr('name');

                $('[name=' + name + ']').each(function () {
                    $(this).siblings('text').addClass(strikethroughClass);
                });

                $(this).siblings('text').removeClass(strikethroughClass);
            });
        }, 100);
    };

    var selectCaseType = function ($timeout, eCase, $, indexToSelect) {
        $timeout(function () {
            var caseType;
            var checkbox;
            var checkboxes = [];
            var dataAttr = 'data-case-types';
            var inputs = $('[' + dataAttr + ']');

            indexToSelect = indexToSelect || 0;

            if (eCase.type_code_id) {
                caseType = eCase.type_code_id.toString();
            }

            if (eCase.type && eCase.type.code) {
                caseType = eCase.type.code.toString();
            }

            if (!caseType) {
                console.warn('No case Type was found for cover sheet. Case:', eCase);
                return false;
            }

            for (var i = 0, el, attr, codes; i < inputs.length; i++) {
                el = jQuery(inputs[i]);
                attr = el.attr(dataAttr);

                if (!attr) {
                    continue;
                }

                codes = attr.split(',');

                if (codes.indexOf(caseType) >= 0) {
                    checkboxes.push(el);
                }

                if (checkboxes.length === indexToSelect + 1) {
                    break;
                }
            }

            checkbox = checkboxes[indexToSelect] || checkboxes[0];

            if (!checkbox) {
                console.debug('No appropriate checkbox for case type ' + caseType + ' were found');
                return false;
            }

            checkbox.attr('checked', 'checked');
        }, 100);
    };

    var selectAttorneyNumber = function ($) {
        $('[name=attorney][data-yes]').attr('checked', true);
    };

    var bindChangeFileSignatureHandler = function (uploadSignatureInputSelector, handler) {
        angular.element(uploadSignatureInputSelector).bind('change', function (event) {
            handler(event);
        });
    };

    var uploadNewSignature = function (Upload, event, $localScope) {
        var uploadData = {
            url: '/signatures/create',
            data: {
                file: event.target.files[0]
            },
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        };

        return Upload.upload(uploadData)
            .then(function (response) {
                var uploadedSignature = response.data;
                var existingSignature = $localScope.signatures
                    .find(function (signature) { return signature.id === uploadedSignature.id; });

                if (!existingSignature) {
                    $localScope.signatures.push(uploadedSignature);

                    return uploadedSignature;
                }

                // Update existing signature with server data
                Object.keys(existingSignature).map(function (prop) {
                    existingSignature[prop] = uploadedSignature[prop];
                });

                return existingSignature;
            });
    };
})(angular.module('onfileApp'));
