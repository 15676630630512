(function (app) {
    app.factory('NotarySharedDocumentsResourceFactory', [
        '$resource', '$q', 'notaryTokenUtil',
        function ($resource, $q, notaryTokenUtil) {
            return {
                create: function (options) {
                    var headers = {
                        'X-FIRM-ID': options.firmId,
                        'X-SESSION-ID': options.sessionId
                    };
                    var resource = $resource(notaryTokenUtil.notaryApiHost + '/shared-documents', { id: '@id' }, {
                        query: {
                            url: notaryTokenUtil.notaryApiHost + '/shared-documents?url=1',
                            method: 'GET',
                            isArray: true,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        delete: {
                            url: notaryTokenUtil.notaryApiHost + '/shared-documents/:id',
                            method: 'DELETE',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        }
                    });

                    return notaryTokenUtil.checkToken(resource, $q)
                        .then(function () {
                            return resource;
                        });
                }
            };
        }]
    );
})(angular.module('onfileApp'));
