(function (app) {
    app.run([
        '$rootScope', '$location', '$window',
        '$state', 'MarketRulesService', 'PermissionsService',
        'EnvironmentService', 'utils', '$q',
        function (
            $rootScope, $location, $window,
            $state, MarketRulesService, PermissionsService,
            EnvironmentService, utils, $q
        ) {
            $rootScope.batesAvailable = null;
            $rootScope.eProductionAvailable = null;

            var rules = [
                ['eproduction', 'eProductionAvailable'],
                ['notary', 'isNotaryAvailable'],
                ['bates', 'batesAvailable'],
                ['support', 'supportAvailable']
            ];
            var checkRoute = function () {
                var currentLocation = window.location.href.toLowerCase();

                for (var i = 0; i < rules.length; i++) {
                    var urlPart = rules[i][0];
                    var prop = rules[i][1];

                    if ($rootScope[prop] === false && currentLocation.indexOf(urlPart) >= 0) {
                        return $state.go('dashboard');
                    }
                }
            };
            var getPermissionsPromise = PermissionsService.getPermissions();
            var getMarketRulesPromise = MarketRulesService.loaded;

            $q.all([getPermissionsPromise, getMarketRulesPromise])
                .then(function (resp) {
                    var permissions = resp && resp[0];

                    if (permissions) {
                        var userId = EnvironmentService.userId();
                        var params = { userId: userId };

                        $rootScope.batesAvailable = utils.isFeatureAvailable(permissions.bates);
                        $rootScope.isNotaryAvailable = utils.isFeatureAvailable(permissions.notary, params);
                        $rootScope.isRequestAvailable = utils.isFeatureAvailable(permissions.request, params);
                        $rootScope.supportAvailable = utils.isFeatureAvailable(permissions.support, params);
                    }

                    $rootScope.eProductionAvailable = MarketRulesService.enabled('eproduction');
                    checkRoute();
                });

            $rootScope.$on('$locationChangeStart', function () {
                checkRoute();

                if (!$window.ga) {
                    return;
                }

                $window.ga('send', 'pageview', { page: $location.path() });
            });

            $rootScope.$on('$locationChangeSuccess', function () {
                if (!window.setActiveFlag) {
                    return;
                }

                window.setActiveFlag();
            });

            $rootScope.onMdAutocompleteTextChangedSetValidity = function (model, query) {
                if (query && query.length === 0) {
                    model.$setValidity('md-require-match', null);
                }
            };

            if (window.guestMode) {
                $state.go('auth');
            }
        }
    ]);
})(angular.module('onfileApp'));
