(function (app) {
    app.directive('successPopup', [
        '$rootScope', '$state', '$cacheFactory',
        '$window',

        function (
            $rootScope, $state, $cacheFactory,
            $window
        ) {
            return {
                strict: 'A',
                scope: {
                    status: '=',
                    filingId: '=',
                    filingEnvelopeId: '=',
                    resourceType: '@',
                    isHearingAllowed: '='
                },
                link: function ($) {
                    Object.assign($, {
                        /**
                         * Redirects to filing detail page and refreshes cached filings list
                         * @param id
                         * @param params
                         */
                        goToFiling: function (id, params, forceRefresh) {
                            var transitionParams;
                            var state = $.resourceType === 'service' ? 'servicesView' : 'recentFilings';
                            var pathParams = Object.assign({ id: id }, params || {});

                            // Removes Services/Filings list from Cache in order to upload new list with created Filing
                            $cacheFactory.get('$http').remove('/services');

                            if (forceRefresh) {
                                transitionParams = {
                                    reload: true,
                                    inherit: false,
                                    notify: true
                                };
                            }

                            $state.transitionTo(state, pathParams, transitionParams);
                        },
                        scheduleHearingWithRefresh: function (id) {
                            $window.location.href = '/services/#/filings/recent?id=' + id + '&action=scheduleHearing';
                            $window.location.reload();
                        },
                        hide: function () {
                            $.status = false;
                        },
                        goTo: function (route) {
                            return $state.transitionTo(route);
                        }
                    });
                },
                templateUrl: '/assets/app/shared/directives/successPopup/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
