(function (app) {
    app.service('ScheduleHearingService', [
        'utils', 'CourtResource',
        function ScheduleHearingService(utils, CourtResource) {
            var statusesMap = {
                pending: {
                    name: 'pending',
                    desc: 'Hearing request pending',
                    color: '#e49224',
                    allowsResubmit: false
                },
                scheduled: {
                    name: 'scheduled',
                    desc: 'Court event scheduled',
                    color: '#47b460',
                    allowsResubmit: false
                }
            };

            this.getFromFiling = function (filing) {
                var caseType;
                var completeFiling = utils.getOr(function () {
                    return filing.complete;
                });

                if (!completeFiling) {
                    return null;
                }

                caseType = this.getCaseType(completeFiling);

                if (!caseType) {
                    return null;
                }

                return utils.getOr(function () {
                    var hearingSchedule = completeFiling[caseType].CaseAugmentation[1].HearingSchedule;

                    if (!hearingSchedule) {
                        return null;
                    }

                    return {
                        address: utils.getOr(function () { return hearingSchedule.CourtCityStateZip; }),
                        street: utils.getOr(function () { return hearingSchedule.CourtStreet; }),
                        room: utils.getOr(function () { return hearingSchedule.CourtRoom; }),
                        startTime: utils.getOr(function () { return hearingSchedule.ScheduleDayStartTime.DateTime; }),
                        endTime: utils.getOr(function () { return hearingSchedule.ScheduleDayEndTime.DateTime; })
                    };
                });
            };

            this.getCaseType = function (object) {
                var caseTypeRegExp = /^.+Case$/;

                return Object.keys(object).find(function (key) {
                    return key.match(caseTypeRegExp);
                });
            };

            this.getStatus = function (status) {
                return statusesMap[status];
            };

            this.formatHearingFromDetails = function (hearing) {
                return {
                    style: this.getStatus(hearing.status || 'scheduled'),
                    data: {
                        start_date: hearing.startTime,
                        end_date: hearing.endTime,
                        address: hearing.address,
                        street: hearing.street,
                        room: hearing.room
                    },
                    raw: hearing
                };
            };

            this.format = function (hearing) {
                return {
                    style: this.getStatus(hearing.status),
                    data: {
                        start_date: hearing.start_date,
                        end_date: hearing.end_date,
                        address: hearing.address,
                        street: hearing.street,
                        room: hearing.room
                    }
                };
            };

            this.patchEnvelopeInformation = function (envelope, scheduledHearing) {
                var data = Object.assign({
                    envelopeNumber: envelope,
                    status: 'scheduled'
                }, scheduledHearing);

                return CourtResource.patchEnvelopeInformation(data).$promise;
            };

            this.populateHearingDataToFilingList = function (items) {
                var envelopeNumbers;
                var envelopeNumbersMap = {};

                items.map(function (item) {
                    var envelopeNumber = item && item.envelope_number;

                    if (!envelopeNumber) {
                        return;
                    }

                    if (envelopeNumbersMap[envelopeNumber] === undefined) {
                        envelopeNumbersMap[envelopeNumber] = [];
                    }

                    envelopeNumbersMap[envelopeNumber].push(item);
                });

                envelopeNumbers = Object.keys(envelopeNumbersMap).join(',');

                return CourtResource.getHearingStatuses({ envelopeNumbers: envelopeNumbers }).$promise
                    .then(function (hearings) {
                        hearings.map(function (h) {
                            var items = envelopeNumbersMap[h.envelope_number];

                            if (!items) {
                                return null;
                            }

                            items.map(function (item) {
                                item.hearingScheduled = this.format(h);
                            }.bind(this));
                        }.bind(this));

                        return items;
                    }.bind(this));
            };
        }
    ]);
})(angular.module('onfileApp'));
