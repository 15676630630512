(function (app) {
    app.factory('NotaryResourceFactory', [
        '$resource', '$q', 'NotaryFilesResourceFactory',
        'NotarySessionsResourceFactory', 'NotaryNotificationsResourceFactory', 'NotaryRecordsResourceFactory',
        'NotarySharedDocumentsResourceFactory',
        function (
            $resource, $q, NotaryFilesResourceFactory,
            NotarySessionsResourceFactory, NotaryNotificationsResourceFactory, NotaryRecordsResourceFactory,
            NotarySharedDocumentsResourceFactory
        ) {
            return {
                createFilesResource: function (options) {
                    return NotaryFilesResourceFactory.create(options);
                },
                createSessionsResource: function (options) {
                    return NotarySessionsResourceFactory.create(options);
                },
                createNotificationsResource: function (options) {
                    return NotaryNotificationsResourceFactory.create(options);
                },
                createRecordsResource: function (options) {
                    return NotaryRecordsResourceFactory.create(options);
                },
                createSharedDocumentsResource: function (options) {
                    return NotarySharedDocumentsResourceFactory.create(options);
                }
            };
        }]
    );
})(angular.module('onfileApp'));
