(function (app) {
    app.controller('CreateNotaryRequestCtrl', [
        '$scope', '$mdDialog', 'DocumentGroupsResourceFactory',
        'S3FileUploader', '$q', '$state',
        '$rootScope', 'ToastrService', 'matter',
        'documentIds', 'PermissionsService', 'OfficialsResourceFactory',
        'utils',
        function (
            $, $mdDialog, DocumentGroupsResourceFactory,
            S3FileUploader, $q, $state,
            $rootScope, ToastrService, matter,
            documentIds, PermissionsService, OfficialsResourceFactory,
            utils
        ) {
            $.isAssignmentAvailable = false;
            $.officials = [];
            $.additionalData = {
                official: null,
                date: null,
                time: null
            };
            $.model = {
                extensions: {
                    userName: matter.userName,
                    userEmail: matter.userEmail,
                    requestedDatetime: null
                }
            };
            $.timeSlots = utils.generateTimeSlots('00:00', '23:00', 30);
            $.minDate = new Date();

            $.close = function () { $mdDialog.hide(); };

            $.submit = function () {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        var requestData = Object.assign({}, $.model, {
                            type: 'notaryRequest',
                            name: matter.name,
                            documentIds: documentIds,
                            extensions: Object.assign({}, $.model.extensions, {
                                requestedDatetime: utils.toTimestamp($.additionalData.date, $.additionalData.time)
                            })
                        });

                        if ($.additionalData.official) {
                            requestData.assignedToUserId = $.additionalData.official.uid;
                            requestData.assignedToFirmId = $.additionalData.official.firmId;
                        }

                        return resource.create(requestData).$promise;
                    })
                    .then(function () {
                        $rootScope.$emit('updateAllMatters', { reloadMatterId: matter.id });
                        ToastrService.showSuccess('Notary request created');
                        $.close();
                    });
            };

            PermissionsService.isFeatureAvailable('notaryScheduling')
                .then(function (isAvailable) {
                    $.isAssignmentAvailable = isAvailable;

                    if (!$.isAssignmentAvailable) {
                        return;
                    }

                    return OfficialsResourceFactory.create()
                        .then(function (resource) {
                            return resource.query().$promise;
                        })
                        .then(function (officials) {
                            $.officials = officials;

                            return $.officials;
                        });
                });
        }
    ]);
})(angular.module('onfileApp'));
