(function (app) {
    app.directive('uploadDocuments', [
        'UploadDocumentService', 'EnvironmentService', '$q',
        'ToastrService', '$timeout',

        function (
            UploadDocumentService, EnvironmentService, $q,
            ToastrService, $timeout
        ) {
            return {
                strict: 'A',
                scope: {
                    onSuccessCallback: '&',
                    onSelectFilesCallback: '&',
                    allowMultiples: '=',
                    case: '=',
                    viewMode: '=',
                    dropzoneClass: '=',
                    buttonText: '=',
                    allowedFormats: '=',
                    foldersButtonText: '=',
                    filesButtonText: '=',
                    folderUploaderInput: '=',
                    dragText: '='
                },
                link: function ($) {
                    $.uploadProgress = 0;
                    $.fileIsLoading = false;

                    var uploadPromises = [];
                    var uploadProgresses = [];

                    $.invalidFiles = [];
                    $.uniqueId = 'drop-zone-area';

                    $.triggerFilePicker = function () {
                        angular.element('#' + $.uniqueId).click();

                        return true;
                    };

                    $.$watch('invalidFiles', function (invalidFiles) {
                        if (!invalidFiles || (Array.isArray(invalidFiles) && invalidFiles.length === 0)) {
                            return;
                        }

                        ToastrService.showMessageByKey('invalid_file_uploaded');
                    });

                    $.$watch('uploadFile', function (files) {
                        if (!files || !(files.length || files.name !== undefined)) {
                            return false;
                        }

                        if (!$.allowMultiples) {
                            files = new Array(files);
                        }

                        if (
                            $.onSelectFilesCallback && typeof $.onSelectFilesCallback === 'function' &&
                            $.onSelectFilesCallback({ files: files })
                        ) {
                            return true;
                        }

                        $.uploadProgress = 0;

                        var promise;
                        for (var i = 0; i < files.length; i++) {
                            var file = files[i];
                            uploadProgresses[i] = 0;

                            // Creating an Upload promise
                            promise = (new UploadDocumentService())
                                .upload(file, i, { container: EnvironmentService.s3BucketLocation($.case.draftId) })
                                .then(function (res) {
                                    if (res.data) {
                                        var doc = res.data;
                                        $.onSuccessCallback({document: doc});
                                    }
                                }, {}, countProgress)
                                .catch(function (res) {
                                    // @TODO catch upload errors.
                                });

                            uploadPromises.push(promise);
                        }
                        // Put all file upload in async queue.
                        $.fileIsLoading = true;
                        $q.all(uploadPromises).finally(function () {
                            $.fileIsLoading = false;
                        });
                    });

                    $timeout(function () {
                        angular.element(getFolderUploaderId()).on('change', function () {
                            var filesArray = [];
                            for (var i = 0; i < this.files.length; i++) {
                                filesArray.push(this.files[i]);
                            }

                            $.uploadFile = filesArray;
                        });
                    });

                    $.triggerFolderPicker = function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        angular.element(getFolderUploaderId()).click();
                    };

                    function countProgress(evt) {
                        uploadProgresses[evt.config.data.index] = parseInt(100.0 * evt.loaded / evt.total);

                        var totalProgress = 0;
                        for (var index in uploadProgresses) {
                            totalProgress += uploadProgresses[index];
                        }

                        $.uploadProgress = parseInt(totalProgress / uploadPromises.length);
                    }

                    function getFolderUploaderId() {
                        return '#folder-uploader-' + $.$id;
                    }
                },
                templateUrl: '/assets/app/shared/directives/uploadDocuments/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
