;(function (app) {
    app.service('SecurityService', [
        '$rootScope', '$q', '$http', 'EnvironmentService', 'filepickerSecurityEnabled',

        function ($rootScope, q, http, EnvironmentService, filepickerSecurityEnabled) {
            var secHandlerAddr = $rootScope.filestackSecurityUrl;
            const defaultSecExpiry = 2160599891;

            this.generateSecurity = function (secOpts, returnQueryString) {
                var defer = q.defer();
                if (!filepickerSecurityEnabled) {
                    defer.resolve({})
                }
                secOpts = secOpts || {};
                var addr = secHandlerAddr;
                if (returnQueryString) {
                    addr += "/qs"
                }
                http({
                    method: "POST",
                    url: secHandlerAddr,
                    data: angular.toJson(secOpts),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(function (resp) {
                    defer.resolve(resp.data);
                }).catch(function (err) {
                    console.error(err);
                    defer.resolve({})
                });

                return defer.promise;
            };

            this.getSecurityParams = function (secOpts) {
                return this.generateSecurity(secOpts, false)
            };

            this.appendSecurityQs = function (secOpts, url) {
                var defer = q.defer();
                this.generateSecurity(secOpts, true).then(function (data) {
                    var result = url;
                    if (data) {
                        result += "?signature=" + data.signature;
                        result += "&policy=" + data.policy;
                        defer.resolve(result);
                    } else {
                        defer.resolve(result)
                    }
                });
                return defer.promise;
            };

            this.getDialogSecParams = function () {
                return this.generateSecurity({
                    "expiry": defaultSecExpiry,
                    "call": ["pick", "store"]
                }, false);
            };

            this.getMetadataSecParams = function () {
                return this.generateSecurity({
                    "expiry": defaultSecExpiry,
                    "call": "read"
                }, false);
            };

            this.addParamsToLink = function (link) {
                var addParam = function (link, secParams) {
                    var uri = new URI(link);

                    Object.keys(secParams).map(function (param) {
                        uri.addQuery(param, secParams[param]);
                    });

                    return uri.toString();
                };

                return this.getMetadataSecParams()
                    .then(function (secParams) {
                        if (secParams) {
                            if (Array.isArray(link)) {
                                return link.map(function (link) {
                                    return addParam(link, secParams);
                                });
                            }

                            return addParam(link, secParams);
                        }

                        return null;
                    });
            };

            this.getRemoveSecParams = function () {
                return this.generateSecurity({
                    "expiry": defaultSecExpiry,
                    "call": ["remove"]
                }, false);
            };

            this.getConvertSecParams = function () {
                return this.generateSecurity({
                    "expiry": defaultSecExpiry,
                    "call": ["convert", "store"]
                }, false);
            };

            this.getRewriteSecParams = function () {
                return this.generateSecurity({
                    expiry: defaultSecExpiry,
                    call: ['writeUrl']
                }, false);
            };

            this.getPreviewSecParams = function () {
                return this.generateSecurity({
                    "expiry": defaultSecExpiry
                }, false);
            };

            this.getCustomSecParams = function (permissions) {
                return this.generateSecurity({
                    expiry: defaultSecExpiry,
                    call: permissions
                }, false);
            };
        }
    ]);

})(angular.module('onfileApp'));
