(function (app) {
    var ACTIVE_SESSION_STATUS = 'active';
    var COMPLETED_SESSION_STATUS = 'completed';
    var SCHEDULED_SESSION_STAGE = 'scheduled';
    var defaultSessionStatus = ACTIVE_SESSION_STATUS;

    app.controller('NotaryFilesSidebarCtrl', [
        '$scope', '$rootScope', '$timeout',
        '$q', 'utils', 'EnvironmentService',
        'NotaryFilesService', 'NotaryService', 'NotarySessionCancelledEvent',
        '$state', 'OfficialsResourceFactory', 'amMoment',

        function (
            $, $rootScope, $timeout,
            $q, utils, EnvironmentService,
            NotaryFilesService, NotaryService, NotarySessionCancelledEvent,
            $state, OfficialsResourceFactory, amMoment
        ) {
            var timeoutId;
            var nextPageCursorByStatus = {
                active: null,
                completed: null
            };
            var isLoadMoreVisibleByStatus = {
                active: true,
                completed: true
            };

            $rootScope.showList = true;
            $.notaryFilesService = NotaryFilesService;

            $timeout(function () { $rootScope.customListHeader = true; }, 100);

            $.setFavourite = function (item, e) {
                e.stopPropagation();
                item.isFavourite = !item.isFavourite;
            };

            $.setSelected = function (item, e) {
                e.stopPropagation();
                item.isSelected = !item.isSelected;
            };

            $.getCurrentStatus = function () {
                var sessionStatus = defaultSessionStatus;
                var status = NotaryFilesService.getStatus();

                if (status === SCHEDULED_SESSION_STAGE) {
                    sessionStatus = ACTIVE_SESSION_STATUS;
                } else if (status === COMPLETED_SESSION_STATUS) {
                    sessionStatus = status;
                }

                return sessionStatus;
            };

            $.isLoadMoreVisible = function () {
                return isLoadMoreVisibleByStatus[$.getCurrentStatus()];
            };

            $.loadMore = function () {
                var sessionStatus = $.getCurrentStatus();

                return loadSessions({
                    cursor: nextPageCursorByStatus[sessionStatus],
                    status: sessionStatus
                }).then(function () {
                    return NotaryFilesService.triggerItemsSet();
                });
            };

            $rootScope.scheduleNotary = function () {
                NotaryFilesService.map(function (item) {
                    if (item.isSelected !== true) {
                        return;
                    }

                    item.scheduled = new Date();
                });
            };

            $rootScope.$on(NotarySessionCancelledEvent, function (event, eventData) {
                NotaryFilesService.deleteById(eventData.id);

                $state.go('notarySessions');
            });

            $rootScope.$on('searchSessions', function (event, eventData) {
                console.log('event', event);
                console.log('eventData', eventData);

                NotaryFilesService.emptyItems();

                $q.all([
                    loadSessions(Object.assign({ status: defaultSessionStatus }, eventData)),
                    loadSessions(Object.assign({ status: COMPLETED_SESSION_STATUS }, eventData))
                    // updateStatus({ status: defaultSessionStatus })
                ]).then(function () {
                    return NotaryFilesService.triggerItemsSet();
                });
            });

            var loadSessions = function (options) {
                return NotaryService.getSessions(options)
                    .then(function (items) {
                        nextPageCursorByStatus[options.status] = undefined;
                        isLoadMoreVisibleByStatus[options.status] = false;

                        items = items.map(calculateItem);

                        if (items.length > 0) {
                            nextPageCursorByStatus[options.status] = items[items.length - 1].nextPageCursor;
                            isLoadMoreVisibleByStatus[options.status] = true;
                        }

                        items.forEach(function (item) {
                            NotaryFilesService.unshift(item, { doNotSortAfter: true });
                        });

                        NotaryFilesService.sortItems();
                    });
            };

            NotaryFilesService.emptyItems();

            $q.all([
                loadSessions({ status: defaultSessionStatus }),
                loadSessions({ status: COMPLETED_SESSION_STATUS })
            ]).then(function () {
                var timeoutMs = 10e3;
                var batchSize = 50;

                var fn = function () {
                    var items = NotaryFilesService.getItems();
                    var sessionIds = items.map(function (item) {
                        return item.id;
                    });
                    var batches = [];
                    var currentBatch = [];

                    for (var i = 0; i < sessionIds.length; i++) {
                        currentBatch.push(sessionIds[i]);

                        if (currentBatch.length === batchSize || i === sessionIds.length - 1) {
                            batches.push(currentBatch);
                            currentBatch = [];
                        }
                    }

                    var promises = batches.map(function (sessionIds) {
                        return NotaryService.getSessionsBatch({ sessionIds: sessionIds });
                    });

                    $q.all(promises).then(function (batchResponses) {
                        var resultById = {};

                        batchResponses.forEach(function (batchResponse) {
                            batchResponse.forEach(function (item) {
                                resultById[item.id] = item;
                            });
                        });

                        items.forEach(function (item) {
                            if (!resultById[item.id]) {
                                return;
                            }

                            var newItem = calculateItem(resultById[item.id]);

                            Object.keys(newItem)
                                .forEach(function (key) {
                                    if (item[key] !== newItem[key]) {
                                        item[key] = newItem[key];
                                    }
                                });
                        });

                        timeoutId = $timeout(fn, timeoutMs);
                    });
                };

                fn();

                return NotaryFilesService.triggerItemsSet();
            });

            OfficialsResourceFactory.create()
                .then(function (resource) {
                    return resource.get({ id: 'me' }).$promise;
                })
                .then(function (official) {
                    $rootScope.currentTimezone = official && official.timezone ?
                        official.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;

                    amMoment.changeTimezone($rootScope.currentTimezone);
                });

            $rootScope.$on('$locationChangeStart', function () {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                    timeoutId = undefined;
                }
            });

            var calculateItem = function (item) {
                var currTimestamp = +new Date();
                var stagesBeforeCameraCheck = [
                    'start',
                    'personalDetails',
                    'signatureInitial',
                    'signatureStart',
                    'signatureCaptured',
                    'idVerificationStart',
                    'idVerificationFront',
                    'idVerificationBack',
                    'mobileVerificationIdCapture',
                    'mobileVerificationIdCaptureBack',
                    'mobileVerificationPersonalDetails',
                    'mobileVerificationSignatureInitial',
                    'mobileVerificationSignatureCreation',
                    'sessionEnded',
                    'cameraCheckStart',
                    'KBAStart',
                    'KBAInProgress',
                    'KBAFails'
                ];

                if (item.scheduledAt && item.scheduledAt._seconds) {
                    item.isExpired = (item.scheduledAt._seconds * 1000 - currTimestamp) < 0;
                }

                if (!item.isCompleted) {
                    item.isOfficialOnline = utils.isUserOnline(item);
                    item.isSignerOnline = utils.isUserOnline(item, 'signerLastOnlineAt');
                    item.isSignerWebRTCOk = false;
                    item.isSignerWebRTCError = false;
                    item.networkError = false;
                    item.idCaptureFailure = false;
                    if (!item.userIO || !item.userIO.audioDevices || !item.userIO.videoDevices || !(item.userIO.audioDevices.length && item.userIO.videoDevices.length)) {
                        if (!stagesBeforeCameraCheck.includes(item.stage) && item.isSignerOnline) {
                            item.networkError = true;
                        }
                    }
                    else {
                        item.isSignerWebRTCOk = true
                    }

                    if (
                        [
                            'idVerificationBack',
                            'idVerificationFailed',
                            'mobileVerificationIdCaptureBack'
                        ].indexOf(item.stage) >= 0
                    ) {
                        item.idCaptureFailure = true;
                    }
                }

                return item;
            };
        }
    ]);
})(angular.module('onfileApp'));
