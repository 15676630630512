(function (app) {
    app.service('FeesService', [
        'FeeResource', 'CaseService', 'OptionalServiceService',

        function (
            FeeResource, CaseService, OptionalServiceService
        ) {
            this.calculate = function (eCase) {
                var promise;

                if (eCase.filings && eCase.filings.hasOwnProperty('length') && eCase.filings.length) {
                    promise = FeeResource.forFiling(CaseService.prepareBeforeSend(eCase)).$promise;
                } else if (eCase.filing) {
                    promise = FeeResource.forService(CaseService.prepareBeforeSend(eCase)).$promise;
                } else {
                    return false;
                }

                return promise.then(function (res) {
                    eCase.fees = res;

                    if (eCase.fees.items) {
                        eCase.fees.items.map(function (i) {
                            i.amount = OptionalServiceService.formatDecimal(i.amount);

                            return i;
                        });
                        eCase.fees.calculation.amount = OptionalServiceService
                            .formatDecimal(eCase.fees.calculation.amount);
                    }
                });
            };
        }
    ]);
})(angular.module('onfileApp'));