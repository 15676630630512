(function (app) {
    app.controller('OnboardingCtrl', [
        '$rootScope', '$scope', 'UsersResource',
        'FirmResource', 'OfficialsResourceFactory', '$timeout',
        '$http', '$q',
        function (
            $rootScope, $, UsersResource,
            FirmResource, OfficialsResourceFactory, $timeout,
            $http, $q
        ) {
            $rootScope.showList = false;

            $.users = [];
            $.firms = [];
            $.model = {};
            $.files = {
                seal: null,
                signature: null,
                cert: null
            };

            $.create = function () {
                var cachedResource = null;

                return OfficialsResourceFactory.create()
                    .then(function (resource) {
                        cachedResource = resource;

                        return resource.getUploadUrls({ officialId: $.model.user.userId }).$promise;
                    })
                    .then(function (data) {
                        return $q.all(
                            data.map(function (entry) {
                                return $http.put(
                                    entry.url,
                                    $.files[entry.type],
                                    {
                                        headers: { 'Content-Type': entry.contentType }
                                    }
                                );
                            })
                        );
                    })
                    .then(function () {
                        return cachedResource.create({
                            email: $.model.user.email,
                            firmId: $.model.firm.id,
                            name: $.model.user.firstName + ' ' + $.model.user.lastName,
                            digitalNumber: $.model.number,
                            uid: $.model.user.userId,
                            county: $.model.county,
                            expirationDate: +new Date($.model.expirationDate),
                            state: $.model.state
                        }).$promise;
                    });
            };

            $.allFilesSelected = function () {
                return !Object.keys($.files).find(function (key) {
                    return $.files[key] === null;
                });
            };

            $.triggerUploadFile = function (id) {
                $timeout(function () {
                    var el = angular.element('#' + id);

                    el.off('change')
                        .on('change', function (event) {
                            $.files[id] = event.target.files[0];
                        })
                        .trigger('click');
                }, 0);
            };

            UsersResource.systemList().$promise
                .then(function (users) {
                    $.users = users.sort(function (a, b) {
                        var aEmail = a.email || '';
                        var bEmail = b.email || '';

                        return aEmail.localeCompare(bEmail);
                    });
                });

            FirmResource.systemList().$promise
                .then(function (firms) {
                    $.firms = firms.sort(function (a, b) {
                        var aFirmName = a && a.firm && a.firm.firmName || '';
                        var bFirmName = b && b.firm && b.firm.firmName || '';

                        return aFirmName.localeCompare(bFirmName);
                    });
                });
        }
    ]);
})(angular.module('onfileApp'));
