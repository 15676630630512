(function (app) {
    app.controller('CreateNotarySessionRequestCtrl', [
        '$scope', '$mdDialog', '$state',
        '$rootScope',
        function (
            $scope, $mdDialog, $state,
            $rootScope
        ) {
            $scope.session = $rootScope.notarySessionRequest || {};
            $scope.files = $rootScope.notarySessionRequestFiles || [];
            $scope.activeStep = 0;

            $scope.nextStep = function () {
                $scope.activeStep++;
            };

            $scope.close = function () { $mdDialog.hide(); };
            $scope.submit = function () {
                $scope.created = true;
            };

            $scope.filesSelected = function (files) {
                var newFiles = files.map(function (file) {
                    file.displayedName = file.name;

                    return file;
                });

                $scope.files = $scope.files.concat(newFiles);
            };

            $scope.viewCreated = function () {
                $scope.close();
                $state.transitionTo('oneNotaryRequest', { id: '222' });
            };
        }
    ]);
})(angular.module('onfileApp'));
