// /**
//  * Created by murtaza on 5/13/17.
//  */
// ga('send', {
//     hitType: 'event',
//     eventCategory: 'Videos',
//     eventAction: 'play',
//     eventLabel: 'Fall Campaign'
// });


;(function (app) {
    app.service('GAService', ['$window', 'EnvironmentService',
        function ($window, EnvironmentService) {
            const addEvent = function (category, action, label) {
                if($window.ga)
                $window.ga('send',
                    {
                        hitType: 'event',
                        eventCategory: category,
                        eventAction: action,
                        eventLabel: label
                    });
            };

            this.addNewCase = function () {
                addEvent("Filings","NewCase", EnvironmentService.firm_id());
            };

            this.addNewEFiling = function () {
                addEvent("Filings","NewEFiling", EnvironmentService.firm_id());
            };

            this.addNewEService = function () {
                addEvent("Filings","NewEService", EnvironmentService.firm_id());
            };

            this.addNewEProduction = function () {
                addEvent("Filings","NewEProduction", EnvironmentService.firm_id());
            };

            this.addEvent = function(category,action,label){
                addEvent(category,action,label);
            }
        }
    ]);
})(angular.module('onfileApp'));

