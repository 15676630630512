(function (app) {
    app.controller('RecentFilingsListCtrl', [
        '$scope', 'EServiceResource', 'CaseNameService',
        'ElasticSearchResource', 'EnvironmentService', 'SearchQueryComposerBuilder',
        '$rootScope', 'CourtResource', 'ScheduleHearingService',
        'customEvents', 'JOINED_FIRM_ID_TARGET', 'JOINED_USER_ID_SOURCE',

        function (
            $, EServiceResource, CaseNameService,
            ElasticSearchResource, EnvironmentService, SearchQueryComposerBuilder,
            $rootScope, CourtResource, ScheduleHearingService,
            customEvents, JOINED_FIRM_ID_TARGET, JOINED_USER_ID_SOURCE
        ) {
            $rootScope.showList = true;

            $.items = [];
            $.query = null;
            $.paginationParams = {
                size: 25,
                from: 0
            };

            var hearingStateChangedHandler = function (event, data) {
                var envelopeNumber = data.envelopeNumber;
                var targetItems = $.items.filter(function (item) {
                    return item && item.envelope_number === envelopeNumber;
                });

                ScheduleHearingService.populateHearingDataToFilingList(targetItems);
            };

            $rootScope.$on(customEvents.envelopeHearingStatusChanged, hearingStateChangedHandler);
            $rootScope.$on(customEvents.hearingScheduled, hearingStateChangedHandler);

            $.loadServices = function () {
                var userIds = EnvironmentService.getUsersIdentifiers();

                if (JOINED_FIRM_ID_TARGET.indexOf(EnvironmentService.firm_id()) >= 0) {
                    userIds = userIds.concat(JOINED_USER_ID_SOURCE);
                }

                var query = SearchQueryComposerBuilder.build()
                    .setQuery(ElasticSearchResource.getFilingsQueryObject())
                    .attachFiler(userIds)
                    .attachFilingType()
                    .attachFilledDateSorting()
                    .attachPagination($.paginationParams)
                    .getQuery();

                $rootScope.startLoader({ message: 'Loading filings' });

                return ElasticSearchResource.searchES(query)
                    .finally(function () {
                        $rootScope.stopLoader();
                    })
                    .then(function (res) {
                        $.items = $.items.concat(res);
                        $.paginationParams.from += res.length;

                        if (!res.length) {
                            $.hideLoadMore = true;
                        }

                        return $.items;
                    })
                    .then(function (items) {
                        return ScheduleHearingService.populateHearingDataToFilingList(items);
                    });
            };

            $.loadServices();
        }
    ]);
})(angular.module('onfileApp'));
