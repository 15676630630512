(function (app) {
    app.controller('ReviewCtrl', [
        'EServiceResource', 'CaseService', 'TextNotificationService',
        '$rootScope', '$scope', '$timeout',
        'FeeResource', 'OptionalServiceService', 'FeesService',
        'AttorneyService', 'utils',

        function (
            EServiceResource, CaseService, TextNotificationService,
            $rootScope, $, $timeout,
            FeeResource, OptionalServiceService, FeesService,
            AttorneyService, utils
        ) {
            angular.extend($, {
                globalTextNotificationEnabled: false,
                creationStatus: null,
                composeCaseName: CaseService.composeCaseName,
                minDate: new Date()
            });

            TextNotificationService.getNode()
                .then(function (settings) {
                    $.globalTextNotificationEnabled = settings.enabled;
                });

            // When a Case is selected
            $rootScope.$on('CaseChanged', function (e, eCase) {
                $.case = eCase;

                processAlertPreference();
            });

            var timer;
            var processAlertPreference = function () {
                TextNotificationService.getNode()
                    .then(function (settings) {
                        $.globalTextNotificationEnabled = settings.enabled;
                    })
                    .then(function () {
                        // Set text notification enabled by default

                        $.case.text_alert_on_change = $.globalTextNotificationEnabled;

                        if (!$.globalTextNotificationEnabled) {
                            if (!timer) timer = $timeout(processAlertPreference, 500);
                        }
                        else {
                            if (timer)
                                $timeout.cancel(timer);
                        }
                    });
            };

            $.calculateFees = function () {
                return FeesService.calculate($.case);
            };

            $.formattedDate = utils.formatDate();

            $.getAttorneyName = function (eCase) { return AttorneyService.getFullName(eCase); };
        }
    ]);
})(angular.module('onfileApp'));