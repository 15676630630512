(function (app) {
    app.directive('customSidebar', [
        '$mdSidenav', '$rootScope', 'UsersResource',
        'FilerService', 'serviceStatusCodes', 'utils',
        'SearchQueryComposerBuilder', 'EnvironmentService', 'ElasticSearchResource',
        '$state', 'PubSubService', '$filter',
        'CaseService',
        function (
            $mdSidenav, $rootScope, UsersResource,
            FilerService, serviceStatusCodes, utils,
            SearchQueryComposerBuilder, EnvironmentService, ElasticSearchResource,
            $state, PubSubService, $filter,
            CaseService
        ) {
            return {
                strict: 'A',
                link: function ($) {
                    var statuses = [
                        'accepted', 'submitted', 'submitting', 'rejected', 'cancelled', 'reviewed', 'underReview'
                    ];

                    $.debounceMs = 300;
                    $.search = {};
                    $.toggleLeft = utils.buildToggler($mdSidenav)('left');
                    $.filers = [];
                    $.statuses = statuses.map(function (status) {
                        return {
                            name: utils.capitalize(status),
                            code: serviceStatusCodes[status]
                        };
                    });

                    $.statuses.unshift({ name: 'All', code: null });
                    $.clearSearch = function () {
                        $.search = {};
                        $.selectedCase = null;
                        $.docketIdText = null;
                        $.participantsText = null;
                    };
                    $.searchFilings = function () {
                        var filerName;
                        var filerIds = parseFilerIds($.search.filer);
                        var statusCode = parseStatusCode($.search.status);
                        var dateFrom = parseDateFrom($.search);
                        var dateTo = parseDateTo($.search);
                        var userId = EnvironmentService.userId();

                        var searchQueryComposer = SearchQueryComposerBuilder.build()
                            .setQuery(ElasticSearchResource.getFilingsQueryObject())
                            .attachFilingType()
                            .attachFilledDateSorting()
                            .attachEnvironment(EnvironmentService.environment())
                            .attachFirmId(EnvironmentService.firm_id())
                            .attachDateRange(dateFrom, dateTo);

                        $rootScope.filingSearchFilterInfo = {};

                        if ($.selectedCase && $.selectedCase.id) {
                            searchQueryComposer.attachCaseId($.selectedCase.id);
                        }

                        if ($.selectedCase && $.selectedCase.participantsString) {
                            $rootScope.filingSearchFilterInfo.caseParty = $.selectedCase.participantsString;
                        }

                        if ($.selectedCase && $.selectedCase.docket_id) {
                            $rootScope.filingSearchFilterInfo.caseNumber = $.selectedCase.docket_id;
                        }

                        if (dateFrom) {
                            $rootScope.filingSearchFilterInfo.dateFrom = $filter('amDateFormat')(
                                new Date(dateFrom), $rootScope.shortDate
                            );
                        }

                        if (dateTo) {
                            $rootScope.filingSearchFilterInfo.dateTo = $filter('amDateFormat')(
                                new Date(dateTo), $rootScope.shortDate
                            );
                        }

                        if (filerIds.length) {
                            searchQueryComposer.attachFiler(filerIds);
                            filerName = $.search.filer.firstName + ' ' + $.search.filer.lastName;

                            if (filerIds.indexOf(userId) >= 0) {
                                filerName = 'Me';
                            }

                            $rootScope.filingSearchFilterInfo.filer = filerName;
                        }

                        if (statusCode !== null) {
                            searchQueryComposer.attachStatusCode(statusCode);
                            $rootScope.filingSearchFilterInfo.status = $.search.status.name;
                        }

                        if ($.search.caseParty) {
                            searchQueryComposer.attachCaseTitle($.search.caseParty);
                            $rootScope.filingSearchFilterInfo.caseParty = $.search.caseParty;
                        }

                        if ($.search.envelopeNumber) {
                            searchQueryComposer.attachEnvelopeNumber($.search.envelopeNumber);
                            $rootScope.filingSearchFilterInfo.envelopeNumber = $.search.envelopeNumber;
                        }

                        if ($.search.reference) {
                            searchQueryComposer.attachReference($.search.reference);
                            $rootScope.filingSearchFilterInfo.reference = $.search.reference;
                        }

                        if ($.search.description) {
                            searchQueryComposer.attachDescription($.search.description);
                            $rootScope.filingSearchFilterInfo.description = $.search.description;
                        }

                        $state.transitionTo('searchFilings');
                        $rootScope.toggleLeftSidebar();
                        PubSubService.emit('filingSearchChanged', searchQueryComposer.getQuery());
                    };

                    $.searchCasesByDocketId = function (e) {
                        return ElasticSearchResource.searchCaseByDocketId(e).then(fixCaseNames);
                    };

                    $.searchByParticipants = function (e) {
                        return ElasticSearchResource.searchByParticipants(e).then(fixCaseNames);
                    };

                    $.searchCasesByEnvelopeNumber = function (e) {
                        return ElasticSearchResource.searchCasesByEnvelopeNumber(e).then(fixCaseNames);
                    };

                    $.onKeyPress = function (e) {
                        if ([13, 32].includes(e.which)) {
                            $.searchFilings();
                        }
                    };

                    $rootScope.toggleLeftSidebar = function () {
                        angular.element('#search-background').toggleClass('is-visible');
                        $.toggleLeft();
                    };

                    UsersResource.query()
                        .$promise
                        .then(function (users) {
                            $.filers = (users || []).map(function (user) {
                                return Object.assign({}, user, {
                                    name: FilerService.composeName(user)
                                });
                            });
                            $.filers.unshift({
                                name: 'Any',
                                userID: null
                            });
                        });

                    var parseFilerIds = function (filer) {
                        var res = [];

                        if (!filer) {
                            return res;
                        }

                        if (filer.userID) {
                            res.push(filer.userID);
                        }

                        if (filer.email) {
                            res.push(filer.email);
                        }

                        return res;
                    };

                    var parseStatusCode = function (status) {
                        if (status && status.code !== null && status.code !== undefined) {
                            return status.code;
                        }

                        return null;
                    };

                    var parseDateFrom = function (search) {
                        if (search.submissionRange === 0) {
                            return false;
                        }

                        if (!search.submissionDateRange || !search.submissionDateRange.from) {
                            return false;
                        }

                        return search.submissionDateRange.from.toISOString();
                    };

                    var parseDateTo = function (search) {
                        if (search.submissionRange === 0) {
                            return false;
                        }

                        if (!search.submissionDateRange || !search.submissionDateRange.to) {
                            return false;
                        }
                        if (search.submissionDateRange.to.toISOString() === search.submissionDateRange.from.toISOString()) {
                            search.submissionDateRange.to.setDate(search.submissionDateRange.to.getDate() + 1)
                        }
                        return search.submissionDateRange.to.toISOString();
                    };

                    var fixCaseNames = function (items) {
                        return items.map(function (item) {
                            item.case_title = CaseService.composeCaseName(item);
                            item.participantsString = CaseService.participantsToString(item);

                            return item;
                        });
                    };
                },
                templateUrl: '/assets/app/shared/directives/sidebar/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
