;(function (app) {
    app.factory('BatesSchemaResource', [
        '$q', '$resource', 'urlEncodedResource',
        'FirebaseService', 'EnvironmentService',

        function ($q, $resource, urlEncodedResource,
                  FirebaseService, EnvironmentService) {
            const resource = $resource('/bates/apply', {}, {
                apply: urlEncodedResource({
                    method: 'POST',
                    isArray: false,
                    cache: false
                })
            });
            const listOfValues = {
                numbersOfDigits: [1, 2, 3, 4, 5, 6, 7, 8],
                fontStyles: [
                    {name: 'Arial Black', value: 'arialb'},
                    {name: 'Arial', value: 'arial'},
                    {name: 'Times New Roman', value: 'troman'},
                    {name: 'Courier', value: 'courier'},
                    {name: 'Verdana', value: 'verdana'}
                ],
                fontSizes: [
                    6, 8, 10, 12, 14, 16, 18, 20, 24, 28, 30, 32
                ],
                fontColors: [
                    {name: 'Black', value: '0 0 0'},
                    {name: 'Red', value: '1 0 0'},
                    {name: 'Green', value: '0 1 0'},
                    {name: 'Blue', value: '0 0 1'}
                ],
                pageNumberings: [
                    {
                        name: 'Number all pages across all documents with single sequence',
                        value: 2,
                        alt: '(000123, 000124, 000125, ...)'
                    }, {
                        name: 'Number all pages within each document the same',
                        value: 0,
                        alt: '(000123, 000123, 000123, ...)'
                    }, {
                        name: 'Number each document\'s first page only',
                        value: 1,
                        alt: '000123'
                    }
                ],
                documentNamings: [
                    {
                        name: 'Do not rename documents',
                        value: false,
                        alt: ''
                    }, {
                        name: 'Rename each document with first page\'s number',
                        value: 'first',
                        alt: '(000123, 000123, 000123, ...)'
                    }, {
                        name: 'Rename document the document\'s Bates range',
                        value: 'range',
                        alt: '(000123 - 000157)'
                    }
                ],
                numberPlacements: [
                    {name: 'Top center', value: 'top'},
                    {name: 'Top right corner', value: 'topright'},
                    {name: 'Bottom right corner', value: 'bottomright'},
                    {name: 'Bottom center', value: 'bottom'},
                    {name: 'Bottom left corner', value: 'bottomleft'},
                    {name: 'Top left corner', value: 'topleft'}
                ]
            };
            const schemasGroups = [
                {
                    name: '',
                    default: true,
                    schemas: [
                        {
                            id: 1,
                            name: 'NEW',
                            numberOfDigits: 6,
                            startNumber: 1,
                            prefix: '',
                            suffix: '',
                            fontStyle: 'arial',
                            fontSize: 12,
                            fontColor: '0 0 0',
                            pageNumbering: 2,
                            documentNaming: false,
                            numberPlacement: 'bottomright',
                            autoResize: false,
                            padding: 1
                        }
                    ]
                }
            ];

            const getPathFoFirmSchemas = function (subPath) {
                subPath = subPath === undefined ? '' : '/' + subPath;

                return '/bates/' + EnvironmentService.firm_id() + '/schemas' + subPath;
            };

            return {
                update: function (id, schema) {
                    const pathToFirmSchema = getPathFoFirmSchemas(id);

                    return {
                        $promise: FirebaseService.saveNode(pathToFirmSchema, schema)
                    };
                },
                save: function (schema) {
                    const pathToFirmSchemas = getPathFoFirmSchemas();

                    return {
                        $promise: FirebaseService.addNode(pathToFirmSchemas, schema)
                    };
                },
                query: function () {
                    const pathToFirmSchemas = getPathFoFirmSchemas();
                    const promise = FirebaseService.getNodeAsArray(pathToFirmSchemas)
                        .$loaded()
                        .then(function (customSchemas) {
                            // Adds to Firm User schemas to defaults
                            if (customSchemas.length !== 0) {
                                return schemasGroups.concat([
                                    {
                                        name: 'Saved',
                                        default: false,
                                        schemas: customSchemas.map(function (schema) {
                                            return Object.assign({}, schema, {
                                                'id': schema.$id
                                            });
                                        })
                                    }
                                ]);
                            }

                            return schemasGroups;
                        })
                        .then(function (schemasGroups) {
                            return schemasGroups.map(function (group) {
                                const isDefault = !!group.default;

                                return Object.assign({}, group, {
                                    schemas: group.schemas.map(function (item) {
                                        return Object.assign({}, item, {
                                            default: isDefault
                                        });
                                    })
                                });
                            });
                        });

                    return {
                        $promise: promise
                    }
                },
                listOfValues: function () {
                    const deferred = $q.defer();

                    deferred.resolve(listOfValues);

                    return {
                        $promise: deferred.promise
                    }
                },
                apply: resource.apply,
                delete: function (id) {
                    const path = getPathFoFirmSchemas(id);

                    return {
                        $promise: FirebaseService.removeNode(path)
                    }
                }
            };
        }
    ]);
})(angular.module('onfileApp'));