(function (app) {
    app.constant(
        'extensionTypeMap',
        [
            [ 'aac', 'AAC audio' ],
            [ 'abw', 'AbiWord document' ],
            [ 'arc', 'Archive document (multiple files embedded)' ],
            [ 'avi', 'AVI: Audio Video Interleave' ],
            [ 'azw', 'Amazon Kindle eBook format' ],
            [ 'bin', 'Any kind of binary data' ],
            [ 'bz', 'BZip archive' ],
            [ 'bz2', 'BZip2 archive' ],
            [ 'csh', 'C-Shell script' ],
            [ 'css', 'Cascading Style Sheets (CSS)' ],
            [ 'csv', 'Comma-separated values (CSV)' ],
            [ 'doc', 'Microsoft Word' ],
            [ 'docx', 'Microsoft Word (OpenXML)' ],
            [ 'eot', 'MS Embedded OpenType fonts' ],
            [ 'epub', 'Electronic publication (EPUB)' ],
            [ 'es',
                'ECMAScript (IANA Specification) (RFC 4329 Section 8.2)' ],
            [ 'gif', 'Graphics Interchange Format (GIF)' ],
            [ 'htm', 'HyperText Markup Language (HTML)' ],
            [ 'html', 'HyperText Markup Language (HTML)' ],
            [ 'ico', 'Icon format' ],
            [ 'ics', 'iCalendar format' ],
            [ 'jar', 'Java Archive (JAR)' ],
            [ 'jpeg', 'JPEG images' ],
            [ 'jpg', 'JPEG images' ],
            [ 'js', 'JavaScript (IANA Specification) (RFC 4329 Section 8.2)' ],
            [ 'json', 'JSON format' ],
            [ 'mid', 'Audio' ],
            [ 'midi', 'Musical Instrument Digital Interface (MIDI)' ],
            [ 'mpeg', 'MPEG Video' ],
            [ 'mpkg', 'Apple Installer Package' ],
            [ 'odp', 'OpenDocument presentation document' ],
            [ 'ods', 'OpenDocument spreadsheet document' ],
            [ 'odt', 'OpenDocument text document' ],
            [ 'oga', 'OGG audio' ],
            [ 'ogv', 'OGG video' ],
            [ 'ogx', 'OGG' ],
            [ 'otf', 'OpenType font' ],
            [ 'png', 'Portable Network Graphics' ],
            [ 'pdf', 'Adobe Portable Document Format (PDF)' ],
            [ 'ppt', 'Microsoft PowerPoint' ],
            [ 'pptx', 'Microsoft PowerPoint (OpenXML)' ],
            [ 'rar', 'RAR archive' ],
            [ 'rtf', 'Rich Text Format (RTF)' ],
            [ 'sh', 'Bourne shell script' ],
            [ 'svg', 'Scalable Vector Graphics (SVG)' ],
            [ 'swf', 'Small web format (SWF) or Adobe Flash document' ],
            [ 'tar', 'Tape Archive (TAR)' ],
            [ 'tif', 'Text' ],
            [ 'tiff', 'Tagged Image File Format (TIFF)' ],
            [ 'ts', 'Typescript file' ],
            [ 'ttf', 'TrueType Font' ],
            [ 'vsd', 'Microsoft Visio' ],
            [ 'wav', 'Waveform Audio Format' ],
            [ 'weba', 'WEBM audio' ],
            [ 'webm', 'WEBM video' ],
            [ 'webp', 'WEBP image' ],
            [ 'woff', 'Web Open Font Format (WOFF)' ],
            [ 'woff2', 'Web Open Font Format (WOFF)' ],
            [ 'xhtml', 'XHTML' ],
            [ 'xls', 'Microsoft Excel' ],
            [ 'xlsx', 'Microsoft Excel (OpenXML)' ],
            [ 'xml', 'XML' ],
            [ 'xul', 'XUL' ],
            [ 'zip', 'ZIP archive' ],
            [ '3gp', '3GPP audio/video container' ],
            [ '3g2', '3GPP2 audio/video container' ],
            [ '7z', '7-zip archive' ]
        ]
    );
})(angular.module('onfileApp'));