(function (app) {
    app.controller('UploaderCtrl', [
        '$rootScope', 'Upload', '$scope',
        function ($rootScope, Upload, $) {
            $rootScope.showList = false;

            var fileExplorer;
            var getPaths = function (fileList) {
                return Object.keys(fileList).map(function (i) {
                    return fileList[i].webkitRelativePath || fileList[i].path;
                });
            };

            $.check = function () {
                if (fileExplorer === undefined) {
                    fileExplorer = jQuery('.file-explorer');
                } else {
                    fileExplorer.removeData('fileTree').undelegate('li a', 'click');
                }

                fileExplorer.fileTree({ root: '/' }, function (file) {
                    console.log(file);
                });

                fileExplorer.on('filetreeexpand', function (e, data) {
                    var items = fileExplorer.find('li');
                    items.removeClass('expanded');

                    data.li.addClass('expanded');
                });
            };
            $.check();

            jQuery('body').on('click', '.size-badge', function () {
                jQuery(this).closest('a').click();
            });

            jQuery('body').on('click', '.directory', function (e) {
                e.stopPropagation();

                var target = jQuery(e.target);
                var innerChild = target.children()[0];

                if (innerChild) {
                    innerChild.click();
                }
            });

            $.$watch('uploadFile', function (files) {
                if (!files || !(files.length || files.name !== undefined)) {
                    return false;
                }

                uploadFiles.call({ files: files });
            });

            var uploadFiles = function () {
                Upload.upload({
                    url: '/files/upload',
                    data: {
                        files: this.files,
                        paths: getPaths(this.files)
                    },
                    headers: {'X-Requested-With': 'XMLHttpRequest'}
                }).then($.check);
            };

            document.getElementById('folder-uploader').onchange = uploadFiles;
        }
    ]);
})(angular.module('onfileApp'));
