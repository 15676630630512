(function (app) {
    app.directive('crossReferenceParameters', [
        'CrossReferenceResource', 'courtCrossReferenceSettings', 'EnvironmentService',
        '$timeout', 'utils', '$q',
        'FirebaseService', 'ToastrService',

        function (
            CrossReferenceResource, courtCrossReferenceSettings, EnvironmentService,
            $timeout, utils, $q,
            FirebaseService, ToastrService
        ) {
            return {
                strict: 'A',
                scope: {
                    case: '='
                },
                link: function ($) {
                    var allCrossReferenceTypes = [];
                    var crossReferencesLoaded = $q.defer();
                    var crossReferencesCodesPopulated = $q.defer();
                    var market = EnvironmentService.market();
                    var attorneyCodeType = 'attorneyCode';
                    var motionType = 'motion';
                    var firebaseNodePath = '/filings/' + EnvironmentService.firm_id() +
                        '/crossReferences/:attorneyId/attorneyCode';

                    $.crossReferenceTypes = [];
                    $.crossReferenceMotionTypes = [];
                    $.isCrossReferenceAvailable = false;
                    $.crossReferences = [];

                    $.crossReferenceChanged = function () {
                        $.case.crossReferences = $.crossReferences
                            .filter(function (item) { return item.value; })
                            .map(function (item) {
                                item.code = (item.type && item.type.code) || null;

                                return item;
                            });
                    };

                    $.saveAttorneyCode = function (crossRef) {
                        var value = crossRef.value;
                        var type = crossRef.type;

                        if (new RegExp(type.validationregex).test(value) === false) {
                            ToastrService.showError(type.customvalidationfailuremessage);

                            return false;
                        }

                        var attorneyId = utils.getOr(function () {
                            return $.case.attorney.attorneyID;
                        });

                        if (!attorneyId) {
                            return false;
                        }

                        return FirebaseService.saveNode(getPath(attorneyId), value);
                    };

                    $.$watchGroup(['case.type_code_id', 'case.type.code'], function (values) {
                        var caseTypeId = values[0] || values[1];

                        if (caseTypeId) {
                            populateCodes(caseTypeId).then(function () {
                                var locationCode = utils.getOr(function () { return $.case.court.code; }, null);

                                if ($.case.court.code) {
                                    return prepareFields(locationCode);
                                }
                            });
                        }
                    });

                    $.$watch('case.court.code', function (locationCode) {
                        if (locationCode) {
                            queryCodes(locationCode)
                                .then(function () { return crossReferencesCodesPopulated.promise; })
                                .then(function () {
                                    var caseTypeCode = utils.getOr(function () {
                                        return $.case.type_code_id || $.case.type.code;
                                    }, null);

                                    if (!caseTypeCode) {
                                        return;
                                    }

                                    return populateCodes($.case.type_code_id || $.case.type.code)
                                        .then(function () { return prepareFields(locationCode); });
                                });
                        }
                    });

                    $.$watch('case.attorney.attorneyID', function (attorneyId) {
                        if (attorneyId) {
                            populateAttorneyCode(attorneyId);
                        }
                    });

                    var addCrossReference = function (data) {
                        var recordIndex;
                        var value = data && data.value;
                        var type = (data && data.type) || $.crossReferenceTypes.find(function (t) {
                            return utils.isTrue(t.isdefault);
                        });
                        var displayType = data && data.displayType;
                        var displayData = data && data.displayData;

                        for (var i = 0; i < $.crossReferences.length; i++) {
                            if ($.crossReferences[i] && $.crossReferences[i].displayType === displayType) {
                                recordIndex = i;
                                break;
                            }
                        }

                        var refData = {
                            code: (type && type.code) || null,
                            value: value,
                            displayType: displayType,
                            displayData: displayData,
                            type: type
                        };

                        if (recordIndex >= 0) {
                            $.crossReferences[recordIndex] = Object.assign($.crossReferences[recordIndex], refData);

                            return;
                        }

                        // Attorney x-ref code always goes above Motion
                        if (displayType === attorneyCodeType) {
                            $.crossReferences.unshift(refData);
                        } else {
                            $.crossReferences.push(refData);
                        }
                    };

                    var populateCodes = function (caseTypeId) {
                        return crossReferencesLoaded.promise
                            .then(function () {
                                $.crossReferenceTypes = allCrossReferenceTypes.filter(function (c) {
                                    return c && c.casetypeid === caseTypeId;
                                });
                                $.isCrossReferenceAvailable = $.crossReferenceTypes.length > 0;

                                crossReferencesCodesPopulated.resolve();
                            });
                    };

                    var queryCodes = function (locationCode) {
                        return CrossReferenceResource.query({ locationCode: locationCode })
                            .$promise
                            .then(function (data) {
                                allCrossReferenceTypes = data;
                                crossReferencesLoaded.resolve(allCrossReferenceTypes);

                                return allCrossReferenceTypes;
                            });
                    };

                    var prepareFields = function (locationCode) {
                        var promise = $q.resolve();
                        var settings = findSettings(locationCode);

                        $.crossReferences = [];

                        if (!settings) {
                            return $q.resolve();
                        }

                        if (settings.attorneyCode) {
                            promise = promise.then(function () {
                                var attorneyCode = $.case.attorney && $.case.attorney.attorneyID;
                                var pr = attorneyCode ? getAttorneyCode(attorneyCode) : $q.resolve('');

                                return pr.then(function (number) {
                                    return addCrossReference({
                                        value: number,
                                        type: $.crossReferenceTypes.find(findAttorneyOnly),
                                        displayType: attorneyCodeType,
                                        displayData: settings.attorneyCode
                                    });
                                });
                            });
                        }

                        if (settings.motion) {
                            promise = promise.then(function () {
                                $.crossReferenceMotionTypes = $.crossReferenceTypes.filter(findMotionOnly);

                                return addCrossReference({
                                    value: 'Motion',
                                    type: {},
                                    displayType: motionType,
                                    displayData: settings.motion
                                });
                            });
                        }

                        return promise;
                    };

                    var getAttorneyCode = function (attorneyId) {
                        return FirebaseService.getNode(getPath(attorneyId)).$loaded()
                            .then(function (v) {
                                if (v && v.$value) {
                                    return v.$value;
                                }

                                return null;
                            });
                    };

                    var findMotionOnly = function (type) {
                        return type.name.toLowerCase().indexOf('attorney') === -1;
                    };

                    var findAttorneyOnly = function (type) {
                        return type.name.toLowerCase().indexOf('attorney') >= 0;
                    };

                    var findSettings = function (locationCode) {
                        var neededLocationCode;
                        var temp = courtCrossReferenceSettings[market] || courtCrossReferenceSettings.default;

                        if (!temp) {
                            return null;
                        }

                        neededLocationCode = Object.keys(temp).find(function (curr) {
                            return locationCode.indexOf(curr) >= 0;
                        });

                        if (!neededLocationCode || !temp[neededLocationCode]) {
                            return null;
                        }

                        return temp[neededLocationCode];
                    };

                    var populateAttorneyCode = function (attorneyId) {
                        return crossReferencesLoaded.promise
                            .then(function () {
                                return FirebaseService.getNode(getPath(attorneyId)).$loaded();
                            })
                            .then(function (code) {
                                var field;

                                if (!code || !code.$value) {
                                    return false;
                                }

                                field = $.crossReferences.find(function (ref) {
                                    return ref && ref.displayType === attorneyCodeType;
                                });

                                if (!field) {
                                    return false;
                                }

                                field.number = code.$value;
                                field.selected = true;
                            })
                            .then(function () {
                                $.crossReferenceChanged();
                            });
                    };

                    var getPath = function (attorneyId) {
                        return firebaseNodePath.replace(':attorneyId', attorneyId);
                    };
                },
                templateUrl: '/assets/app/shared/directives/crossReferenceParameters/template.html?' +
                    '{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
