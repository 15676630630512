(function (app) {
    app.controller('TextNotificationSettingsCtrl', [
        '$rootScope', '$scope', 'TextNotificationService',

        function ($rootScope, $, TextNotificationService) {
            $rootScope.showList = false;

            angular.extend($, {
                enabled: null,
                phone: '',
                phoneMask: '+1999-999-9999',

                enableAlert: function () {
                    return TextNotificationService.setMode(!$.enabled);
                },
                savePhone: function () {
                    return TextNotificationService.setPhone($.phone);
                },
                clearPhone: function () {
                    $.phone = '';
                }
            });

            TextNotificationService.getNode()
                .then(function (settings) {
                    $.enabled = settings.enabled;
                    $.phone = settings.phone;
                });
        }
    ]);
})(angular.module('onfileApp'));