(function (app) {
    app.controller('PartyInformationCtrl', [
        'PartyCodeResource', 'CountryResource', 'StateResource',
        'AttorneyResource', 'SuffixResource', 'PartyService',
        '$scope', '$state', '$rootScope', 'ToastrService', '$q', 'AttorneyService',
        '$mdDialog', '$document', 'utils',

        function (
            PartyCodeResource, CountryResource, StateResource,
            AttorneyResource, SuffixResource, PartyService,
            $, $state, $rootScope, ToastrService, $q, AttorneyService,
            $mdDialog, $document, utils
        ) {
            angular.extend($, {
                isEditingIndex: null,
                partyTypes: [],
                countries: [],
                newParty: {
                    person: {},
                    business: {}
                },
                states: [],
                suffixes: [],
                addPartyToggle: false,
                isNewPartyAllowed: $rootScope.hasOwnProperty('isNewPartyAllowed') ? !!$rootScope.isNewPartyAllowed : true
            });

            initController();

            function initController(eCase) {
                if ($.case === undefined && eCase === undefined)
                    return;

                if (eCase === undefined)
                    eCase = $.case;

                $.case = angular.extend(eCase, {
                    filingPartyIndex: 0,
                    getParties: function () {
                        if ($.case.hasOwnProperty('party')) {
                            return $.case.party;
                        } else if ($.case.hasOwnProperty('parties')) {
                            return $.case.parties;
                        }
                    },
                    prepareParties: function () {
                        if (!$.case.hasOwnProperty('party'))
                            return;

                        for (var i = 0; i < $.case.party.length; i++) {
                            $.case.party[i] = new PartyService($.case.party[i]);
                            $.case.party[i].setIsRemovable(false);
                            $.case.party[i].setIsEditable(false);
                        }

                        return $.case;
                    },
                    selectClient: function (index) {
                        var parties = $.case.getParties();
                        if (!parties || parties.length === 0)
                            return false;

                        for (var i = 0; i < $.case.getParties().length; i++) {
                            $.case.getParties()[i].isClient = false;
                        }

                        $.case.getParties()[index].isClient = true;
                        $.case.filingPartyIndex = index;

                        return true;
                    }
                });

                $.case.selectClient(0);
                $.case.prepareParties();

            }

            $rootScope.$on('CaseChanged', function (e, eCase) {
                initController(eCase);
                //CaseChangedForDocument
                $.$emit('CaseChangedForDocument', null);
            });

            CountryResource.query().$promise
                .then(function (countries) {
                    $.countries = countries;
                    $.defaultCountry = (function (countries) {
                        for (var i = 0; i < countries.length; i++) {
                            if (countries[i].code === 'US')
                                return countries[i];
                        }

                        return {};
                    })($.countries);
                });

            AttorneyResource.query().$promise
                .then(function (attorneys) {
                    for (var i = 0; i < attorneys.length; i++) attorneys[i] = new AttorneyService(attorneys[i]);
                    $.attorneys = attorneys;
                });

            $.getStates = function (country) {
                if (!country)
                    return false;

                // Reset selected state for both models
                $.newParty.person.state = null;
                $.newParty.business.state = null;

                StateResource.query({country_code: country.code}).$promise
                    .then(function (states) {
                        $.states = states;
                    });
            };

            $.clientSelected = function (parties) {
                if (parties) {
                    for (var i = 0; i < parties.length; i++) {
                        if (parties[i].isClient === true)
                            return true;
                    }
                }

                return false;
            };

            $.saveParty = function (type) {
                var editedParty;

                if (!$.newParty[type])
                    return false;

                editedParty = $.newParty[type];
                editedParty.entityType = type;
                //storing for drafts, since it does not get bound
                //correct method would have been a validation logic
                editedParty.isValid = true;
                editedParty.$setValidity(true);

                if (editedParty.hasOwnProperty('$index')) {
                    $.case.getParties()[editedParty.$index] = editedParty;
                } else {
                    editedParty.isNew = true; //this flag is useful for efile into existing case, when a new party is added
                    $.case.getParties().push(editedParty);
                }

                if (editedParty.isRepresentingParty || editedParty.is_client) {
                    $.case.selectClient(editedParty.hasOwnProperty('$index') ? editedParty.$index : $.case.getParties().length - 1);
                }

                $.isEditingIndex = null;
                return true;
            };

            $.editRequired = function () {
                for (var i = 0; i < $.case.getParties().length; i++) {
                    var party = $.case.getParties()[i];

                    if (!party.$valid && party.type && party.type.hasOwnProperty('isRequired') && party.type.isRequired) {
                        $.showAddPartyForm();
                        $.editParty(party, i);
                    }
                }

                return true;
            };

            $.editParty = function (party, $index) {
                $.isEditingIndex = $index;

                party.$setValidity(false);

                $.newParty.person = angular.copy(party);
                $.newParty.person.$index = $index;

                $.newParty.business = angular.copy(party);
                $.newParty.business.$index = $index;
                if (party.hasOwnProperty('entityType'))
                    $.addPartyTab = party.entityType;
            };

            $.cancelPartyForm = function (form) {
                if($.isEditingIndex) {
                    var party = $.case.getParties()[$.isEditingIndex];
                    if (party)
                        party.$setValidity(true);
                }
                $.hideAddPartyForm(form);
            };

            $.hideAddPartyForm = function (form) {
                $.addPartyToggle = false;

                if (!form)
                    return true;

                if (form.hasOwnProperty('$setPristine'))
                    form.$setPristine();

                if (form.hasOwnProperty('$setUntouched'))
                    form.$setUntouched();

                $.isEditingIndex = null;

                return true;
            };

            $.showAddPartyForm = function () {
                $.newParty.person = new PartyService();
                $.newParty.business = new PartyService();

                $.addPartyToggle = true;
                $state.go($rootScope.baseState + '.newParty');

                $.addPartyTab = 'person';

                return true;
            };

            // When Case Type is changed
            $.$watch('case.type', function (type, oldValue) {
                if (!$.case)
                    return;

                var selectedParties = $.case.hasOwnProperty('parties') && $.case.parties ?
                    angular.copy($.case.parties) : [];

                $.case.parties = [];
                $.partyTypes = [];

                if (!$.case.court || !$.case.court.hasOwnProperty('code') || !$.case.court.code || !$.case.type)
                    return;

                // Loads Party Types based on Court Code
                PartyCodeResource.query({court_code: $.case.court.code}).$promise
                    .then(function (partyTypes) {
                        var result = [];
                        for (var i = 0; i < partyTypes.length; i++) {
                            var type = partyTypes[i];
                            if (type.casetypeid === $.case.type.code || type.casetypeid === '')
                                result.push(type);
                        }
                        $.partyTypes = result;
                        return $.partyTypes;

                    })
                    .then(function (partyTypes) {
                        if (!type || !type.hasOwnProperty('code'))
                            return;

                        // If there are data about parties of the same Case Type - uses it instead of drop it
                        if (selectedParties.length) {
                            selectedParties.map(function (partyData) {
                                if (type.code == partyData.type.casetypeid) {
                                    $.case.parties.push(new PartyService(partyData));
                                }
                            });

                        }

                        if (!$.case.parties.length) {
                            for (var i = 0, partyType; i < partyTypes.length; i++) {
                                partyType = angular.merge({
                                    isFixed: true,
                                    isRequired: (partyTypes[i].isrequired === 'True')
                                }, partyTypes[i]);

                                if (
                                    (partyType.casetypeid === '' || partyType.casetypeid === type.code) == false ||
                                    partyType.isRequired === false
                                ) {
                                    continue;
                                }

                                $.case.parties.push(new PartyService({type: partyType}));
                            }
                        }

                        //open the first party which is not valid and required
                        //for drafts isValid flag is stored in the database not ideal.
                        for (var index = 0; index < $.case.parties.length; index++) {
                            if (
                                $.case.parties[index] &&
                                $.case.parties[index].type.isRequired && !$.case.parties[index].$valid
                            ) {
                                $.showAddPartyForm();
                                $.editParty($.case.parties[index], index);
                                break;
                            }
                        }

                        // // Opens editing window for the first Required Party
                        // (function (index) {
                        //   if (
                        //     $.case.parties.length && $.case.parties[index] &&
                        //     $.case.parties[index].type.isRequired
                        //   ) {
                        //     $.showAddPartyForm(); $.editParty($.case.parties[index], index);
                        //   }
                        // })(0);
                    });
            });

            $.filteredPartyTypes = function (query) {
                var partyTypes = $.partyTypes;
                var availableForNewPartiesFilter = function (t) {
                    return t.isavailablefornewparties === 'True';
                };

                if (query) {
                    partyTypes = $.partyTypes.filter(function (party) {
                        var partyName = angular.lowercase(party.name);
                        var preparedQuery = angular.lowercase(query);

                        return partyName.indexOf(preparedQuery) === 0;
                    });
                }

                return partyTypes.filter(availableForNewPartiesFilter);
            };

            $.isPartiesValid = function ($scope) {

                $rootScope.isPartiesInformationStageisValid = false;

                if ($ === undefined)
                    $ = $scope;

                if ($.isEditingIndex !== null) {
                    ToastrService.showMessageByKey('party_being_edited');
                    return false;
                }

                // require at least one party to be selected
                if ($.case.getParties().length == 0) {
                    ToastrService.showMessageByKey('parties_required');
                    return false;
                }

                for (var i = 0; i < $.case.parties.length; i++) {
                    if (!$.case.parties[i].$valid) {
                        ToastrService.showMessageByKey('parties_invalid');
                        return false;
                    }
                }

                $rootScope.isPartiesInformationStageisValid = true;

                return true;
            };

            $.removeParty = function (index) {
                $.case.getParties().splice(index, 1);
                $.showAddPartyForm();
            };

            $.showAttorneysPopup = function () {
                var templateUrl = '/assets/app/shared/controllers/partyInformation/_attorneys-popup.html?' +
                    '{{onfileApplicationVersion}}';

                $mdDialog.show({
                    controller: [
                        '$scope',
                        function ($scope) {
                            $scope.hideAttorneysPopup = function () {
                                $mdDialog.hide();
                            };

                            var attorneysMap = {};

                            $scope.leadAttorneys = $.case.participants
                                .filter(function (p) {
                                    return p && p.type_code === 'ATTY';
                                })
                                .map(function (p) {
                                    var address1Parts = [
                                        utils.getOr(function () {
                                            return p.all.EntityPerson.PersonAugmentation.ContactInformation
                                                .ContactMailingAddress.StructuredAddress.LocationStreet.StreetFullText;
                                        }, null),
                                        utils.getOr(function () {
                                            return p.all.EntityPerson.PersonAugmentation.ContactInformation
                                                .ContactMailingAddress.StructuredAddress.AddressSecondaryUnitText;
                                        }, null)
                                    ];
                                    var address2Parts = [
                                        utils.getOr(function () {
                                            return p.all.EntityPerson.PersonAugmentation.ContactInformation
                                                .ContactMailingAddress.StructuredAddress.LocationCityName;
                                        }, null),
                                        utils.getOr(function () {
                                            return p.all.EntityPerson.PersonAugmentation.ContactInformation
                                                .ContactMailingAddress.StructuredAddress.LocationStateName;
                                        }, null),
                                        utils.getOr(function () {
                                            return p.all.EntityPerson.PersonAugmentation.ContactInformation
                                                .ContactMailingAddress.StructuredAddress.LocationPostalCode;
                                        }, null)
                                    ];

                                    return {
                                        name: p.name,
                                        address1: address1Parts.filter(function (i) { return i; }).join(', '),
                                        address2: address2Parts.filter(function (i) { return i; }).join(', '),
                                        phone: p.phone
                                    };
                                })
                                .filter(function (p) {
                                    if (attorneysMap[p.name + p.phone]) {
                                        return false;
                                    }

                                    attorneysMap[p.name + p.phone] = true;

                                    return true;
                                });
                        }
                    ],
                    templateUrl: templateUrl,
                    parent: angular.element($document.body),
                    clickOutsideToClose: true
                });
            };

            // When Case Court is changed
            $.$watch('case.court', function (court) {
                $.suffixes = [];

                if (!court || !court.hasOwnProperty('code'))
                    return;

                // Loads Suffixes based on Court Code
                SuffixResource.query({court_code: court.code}).$promise
                    .then(function (suffixes) {
                        $.suffixes = suffixes;
                    });
            });
        }
    ]);
})(angular.module('onfileApp'));