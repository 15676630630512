(function (app) {
    app
        .constant('elasticSearchUrls', {
            texas: {
                production: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/production-search-api-new',
                    cases: {
                        index: 'cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'filings',
                        type: 'filing'
                    }
                },
                development: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/development-search-api',
                    cases: {
                        index: 'cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'filings',
                        type: 'filing'
                    }
                }
            },
            california: {
                production: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/production-search-api',
                    cases: {
                        index: 'ca_cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'ca_filings',
                        type: 'filing'
                    }
                },
                development: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/development-search-api',
                    cases: {
                        index: 'ca_cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'ca_filings',
                        type: 'filing'
                    }
                }
            },
            illinois: {
                development: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/development-search-api',
                    cases: {
                        index: 'il_cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'il_filings',
                        type: 'filing'
                    }
                },
                production: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/production-search-api',
                    cases: {
                        index: 'il_cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'il_filings',
                        type: 'filing'
                    }
                }
            }
        })
        .constant('templateUrls', {
            createFiling: {
                illinois: '/assets/app/components/eFilings/create/create_alt.html?{{onfileApplicationVersion}}',
                default: '/assets/app/components/eFilings/create/create.html?{{onfileApplicationVersion}}'
            },
            createServiceContacts: {
                illinois: '/assets/app/shared/controllers/serviceContacts/_create-service-contacts_alt.html?' +
                    '{{onfileApplicationVersion}}',
                default: '/assets/app/shared/controllers/serviceContacts/_create-service-contacts.html?' +
                    '{{onfileApplicationVersion}}'
            }
        })
        .constant('areServiceTypesNeeded', function (market) {
            var config = {
                illinois: true,
                default: false
            };

            return config.hasOwnProperty(market) ? config[market] : config.default;
        })
        .constant('dictionary', {
            illinois: {
                'Total Provider Service Fees': 'OnFile value-added service fees',
                'Bar Number': 'ARDC number',
                'Bar No': 'ARDC number',
            }
        })
        .constant('statusesAllowedForHearing', [1, 7, 8])
        .constant('courtsDocumentsMappingUrl', {
            illinois: {
                development: {
                    url: 'https://s3.us-east-2.amazonaws.com/ofs-assets/illinois/CourtsDocumentsMapping.json',
                    docUrl: 'https://s3.us-east-2.amazonaws.com/ofs-assets/illinois/Division+rule+docs+PDF/{{type}}.pdf'
                },
                production: {
                    url: 'https://s3.us-east-2.amazonaws.com/ofs-assets/illinois/CourtsDocumentsMapping.json',
                    docUrl: 'https://s3.us-east-2.amazonaws.com/ofs-assets/illinois/Division+rule+docs+PDF/{{type}}.pdf'
                }
            }
        })
        .constant('filingTips', {
            'Reference/matter': {
                text: 'The "Reference/matter" field can contain any information of your choosing, such as your ' +
                    'internal case or matter number for this filing or an associated client number. This field is ' +
                    'solely for your use and benefit, and the court and e-filing system does not require any ' +
                    'specific inputted value.'
            },
            Category: {
                text: 'The document Category must be selected for each uploaded document. Please note that certain ' +
                    'court locations, such as Cook County, require that you upload an order allowing for a document ' +
                    'to be filed confidentially if you select "Confidential" from the Category drop-down.'
            },
            'Case Category': {
                text: 'Please select the Case Category that most closely corresponds to the type of case that you ' +
                    'are creating. Please note that the list of available Case Categories is determined by the court ' +
                    'location into which you are filing. You are encouraged to contact the court clerk for the ' +
                    'location into which you are filing if you have any questions or issues determining which Case ' +
                    'Category to select.'
            },
            'Case Type': {
                text: 'Please select the Case Type that most closely corresponds to the type of case that you are ' +
                    'creating. Please note that the list of available Case Types is based on your selected Case ' +
                    'Category and determined by the court location into which you are filing. You are encouraged to ' +
                    'contact the court clerk for the location into which you are filing if you have any questions or ' +
                    'issues determining which Case Type to select.'
            },
            'Filing type': {
                text: 'Please select the Filing Type that most closely corresponds to the filing that you are ' +
                    'submitting. Please note that the list of available Filing Types is determined by the court ' +
                    'location into which you are filing. You are encouraged to contact the court clerk for the ' +
                    'location into which you are filing if you have any questions or issues determining which Filing ' +
                    'Type to select.'
            },
            'Filing description': {
                text: 'Please provide a precise description of the filing that you are submitting. Filers often ' +
                    'enter into this field the exact title of the document that they are uploading.'
            }
        })
        .constant('searchCaseTabsOrderingRules', {
            illinois: [
                'search', 'select'
            ],
            default: [
                'select', 'search'
            ]
        })
        .constant('customEvents', {
            envelopeHearingStatusChanged: 'envelopeHearingStatusChanged',
            hearingScheduled: 'hearingScheduled',
            courtChanged: 'courtChanged',
            caseCategoryChanged: 'caseCategoryChanged'
        })
        .constant('courtCrossReferenceSettings', {
            default: {
                'cook:cvd': {
                    attorneyCode: {
                        verbiage: 'Filing attorney has Cook County Attorney Number'
                    }
                },
                'cook:law': {
                    attorneyCode: {
                        verbiage: 'Filing attorney has Cook County Attorney Number'
                    },
                    motion: {
                        verbiage: 'Will this filing include a motion that needs to be scheduled for Calendar 5 or Calendar M1?',
                        // subVerbiage: '(for Calendar 5 and Calendar M1 only)'
                    }
                },
                'cook:chd': {
                    attorneyCode: {
                        verbiage: 'Filing attorney has Cook County Attorney Number'
                    },
                    motion: {
                        verbiage: 'Will this filing include a motion that needs to be scheduled?'
                    }
                },
                'cook:dr': {
                    attorneyCode: {
                        verbiage: 'Filing attorney has Cook County Attorney Number'
                    },
                    motion: {
                        verbiage: 'Will this filing include a motion that needs to be scheduled?'
                    }
                },
                'cook:pr': {
                    attorneyCode: {
                        verbiage: 'Filing attorney has Cook County Attorney Number'
                    },
                    motion: {
                        verbiage: 'Will this filing include a motion that needs to be scheduled?'
                    }
                }
            }
        })
        .constant('marketTimezones', {
            default: 'America/Chicago'
        })
        .constant('marketCoverSheetSettings', {
            default: {},
            illinois: {
                'cook:cvd': {
                    name: 'Civil action cover sheet - CCM 0520',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/cook_cvd.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        'cook:cvd',
                        /cook:cvd.*/
                    ]
                },
                'CCG_N005': {
                    name: 'Citation to Discover Assets - CCG 0005',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCG_N005.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook.*/
                    ]
                },
                'CCM_N020': {
                    name: 'Fee Exempt and Reduced Fee Agency Cover Sheet - CCG 0020',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCM_N020.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:*/
                    ]
                },
                'CCP_N368': {
                    name: 'Citation - CCP N368',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_N368.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr*/
                    ]
                },
                'cook:dr': {
                    name: 'Case cover sheet - CCDR 0601',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/cook_dr.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:dr.*/
                    ]
                },
                'cook:pr': {
                    name: 'Case cover sheet - CCP 0199',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/cook_pr.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'cook:law': {
                    name: 'Civil action cover sheet - CCL 0520',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/cook_law.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:law.*/
                    ]
                },
                'cook:chd': {
                    name: 'Civil Cover Sheet - General Chancery Section - CCCH 0623',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/cook_chd.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:chd.*/
                    ]
                },
                'cook:chd2': {
                    name: 'Civil Cover Sheet - Mortgage Foreclosure/Mechanics Lien - CCCH 0624',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/cook_chd.2.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:chd.*/
                    ]
                },
                'CCG_N648': {
                    name: 'Citation Notice - CCG 0648',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCG_N648.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook*/
                    ]
                },

                'CCG_0001': {
                    name: 'Summons - CCG 0001',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCG_0001.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook*/
                    ]
                },
                'CCG_0514': {
                    name: 'Summons for Discovery - CCG 0514',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCG_0514.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook*/
                    ]
                },

                'CCG_N009': {
                    name: 'Appearance and Jury Demand - CCG 0009',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCG_N009.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook*/
                    ]
                },

                'CCG_N003': {
                    name: 'Notice of Motion - CCG N003',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCG_N003.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook*/
                    ]
                },

                'CCM_N751': {
                    name: 'Small Claims Summons - CCM N751',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCM_N751.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:cvd.*/
                    ]
                },

                'CCM_0702': {
                    name: 'Summons - CCM 0702',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCM_0702.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:cvd.*/
                    ]
                },
                'CCP_1011': {
                    name: 'Final Report of Independent Representative - CCP 1011',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1011.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ],
                    caseTypes: [
                        64929,
                        71984,
                        71985,
                        71990,
                        71991
                    ]
                },
                'CCP_1012': {
                    name: 'Order of Discharge - Independent Administration - CCP 1012',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1012.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ],
                    caseTypes: [
                        64929,
                        71984,
                        71985,
                        71990,
                        71991
                    ]
                },
                'CCP_1017': {
                    name: 'Receipt and Approval on Closing of Decedent’s Estate in Independent Administration - CCP 1017',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1017.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ],
                    caseTypes: [
                        64929,
                        71984,
                        71985,
                        71990,
                        71991
                    ]
                },
                'CCL__N530': {
                    name: 'Appearance - CCL N530 ',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCL__N530.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:law.*/
                    ]
                },
                'CCM_0652': {
                    name: 'Summons - CCM 0652',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCM_0652.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:cvd.*/
                    ]
                },
                'CCP_N368': {
                    name: 'Citation - CCP 0368',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_N368.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCDR_N004': {
                    name: 'Appearance - CCDR N004',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCDR_N004.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:dr.*/
                    ]
                },
                'CCL_0017': {
                    name: 'Summons for Discovery - CCL 0017',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCL_0017.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:law.*/
                    ]
                },
                'CCP_0048': {
                    name: 'Motion Slip - CCP 0048',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_0048.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCM_0649': {
                    name: 'Summons - CCM 0649',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCM_0649.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:cvd.*/
                    ]
                },
                'CCDR_0005': {
                    name: 'Notice of Motion - CCDR 0005',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCDR_0005.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:dr.*/
                    ]
                },
                'CCDR_0001': {
                    name: 'Summons/Alias Summons - CCDR 0001',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCDR_0001.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:dr.*/
                    ]
                },
                'CCCH_0100': {
                    name: 'Mortgage Foreclosure Summons - CCCH 0100',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCCH_0100.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:chd.*/
                    ]
                },
                'CCP_0317': {
                    name: 'Affidavit - Copy of Will - CCP 0317',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_0317.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_N009': {
                    name: 'Appearance and Jury Demand - CCP 0009',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_N009.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_N380': {
                    name: 'Appearance on Account - CCP 0380',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_N380.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_N303': {
                    name: 'Waiver of Notice - CCP 0303 ',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_N303.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_1004': {
                    name: 'Notice to Interested Parties Regarding Petition for Letters of. Admin. - CCP 1004',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1004.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_1007': {
                    name: 'Notice of Termination of Indep. Admin. - CCP 1007',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1007.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_1008': {
                    name: 'Notice Regarding Final Report in Indep. Admin. - CCP 1008',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1008.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_1020': {
                    name: 'Notice to Heirs and Legatees - Will Admitted - CCP 1020',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1020.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_1003': {
                    name: 'Notice to Heirs and Legatees Regarding Rights During Ind. Admin. - CCP 1003',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1003.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_1021': {
                    name: 'Notice to Heirs and Legatees - Will Denied - CCP 1021',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1021.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_1022': {
                    name: 'Notice to Heirs and Legatees - Foreign Will Admitted - CCP 1022',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1022.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_1023': {
                    name: 'Notice to Heirs and Legatees - Foreign Will Denied - CCP 1023',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1023.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_0216': {
                    name: 'Claim Order - CCP0216',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_0216.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_0345': {
                    name: 'Claim - CCP 0345',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_0345.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_0503': {
                    name: 'Order for Admission of Will & Summary Administration - CCP N503',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_503.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_0122': {
                    name: 'Guardian\'s Notice to Court and Parents - CCP 0122',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_0122.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_1000': {
                    name: 'Proof of Mailing and Publication - CCP 1000',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_1000.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_0302': {
                    name: 'Petition for Letters of Administration - CCP 0302',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_0302.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCP_0501': {
                    name: 'Order for Summary Administration (Intestate) - CCP 501',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_0501.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                },
                'CCDR_0048': {
                    name: 'Motion Slip - CCDR 0048',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCDR_0048.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:dr.*/
                    ]
                },
                'CCM_0081': {
                    name: 'Eviction Summons for Trial - CCM 0081',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCM_0081.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:cvd.*/
                    ]
                },
                'CCP_0334': {
                    name: 'Inventory - CCP 0334 ',
                    file: '/assets/app/shared/directives/courtCoverSheet/sheets/CCP_0334.html?' +
                        '{{onfileApplicationVersion}}',
                    locations: [
                        /cook:pr.*/
                    ]
                }
            }
        })
        .constant('typeOfNotarizationOptions', onfileShared.typeOfNotarizationOptions)
        .constant('JOINED_FIRM_ID_SOURCE', [
            'c23bd28c-2ca0-4712-a83b-95419ca29e4b'
        ])
        .constant('JOINED_FIRM_ID_TARGET', [
            'c6f2da86-f95a-45af-a5bc-558fa539a6bc'
        ])
        .constant('JOINED_USER_ID_SOURCE', [
            'd18048ac-97e5-435b-9c3c-e17f6ac1b26b'
        ])
        .constant('MAXIMUM_DRAFTS_AGE', 3600 * 24 * 30)
        .constant('documentComponentCodes', [
            {
                name: 'Lead/standalone',
                value: '331',
                textColor: '#0b6cfd',
                style: {
                    color: '#fff',
                    backgroundColor: '#0b6cfd'
                },
                isDefault: true
            }, {
                name: 'Exhibit/Supporting',
                value: '332',
                textColor: '#999999',
                style: {
                    color: '#fff',
                    backgroundColor: '#999999'
                }
            }, {
                name: 'Proposed order',
                value: '333',
                textColor: '#404059',
                style: {
                    color: '#000',
                    backgroundColor: '#F0EFFF'
                }
            }
        ])

        .constant('NOTARIZATION_REQUESTED', 3)
        .constant('NOTARIZATION_SCHEDULED', 0)
        .constant('NOTARIZATION_COMPLETED', 1)
        .constant('ALL_SESSION_STATUSES', [1, 0, 3])
        .constant('SESSION_STAGES', [
            'start',
            'payment',
            'paymentByPayer',
            'paymentStatusWait',
            'personalDetails',
            'checkPersonalDetails',
            'idVerificationStart',
            'idVerificationFront',
            'idVerificationBack',
            'idVerificationFailed',
            'KBAStart',
            'KBAInProgress',
            'KBAFails',
            'KBAInSuccess',
            // 'signatureInitial',
            // 'signatureStart',
            // 'signatureCaptured',
            'userInRoom',
            'officialInRoom',
            'userSigningProcessStart',
            'userSigned',
            'officialSealed',
            'waitingForTheDocument',
            'sessionEnded',
            'userSessionEnded',
            'cancelled',
            'cameraCheckStart',
            'mobileVerificationIdCapture',
            'mobileVerificationIdCaptureBack',
            'mobileVerificationSignatureInitial',
            'mobileVerificationSignatureCreation',
            'mobileVerificationPersonalDetails'
        ])
        .constant('TIMEZONES', [
            'Europe/Andorra',
            'Asia/Dubai',
            'Asia/Kabul',
            'Europe/Tirane',
            'Asia/Yerevan',
            'Antarctica/Casey',
            'Antarctica/Davis',
            'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
            'Antarctica/Mawson',
            'Antarctica/Palmer',
            'Antarctica/Rothera',
            'Antarctica/Syowa',
            'Antarctica/Troll',
            'Antarctica/Vostok',
            'America/Argentina/Buenos_Aires',
            'America/Argentina/Cordoba',
            'America/Argentina/Salta',
            'America/Argentina/Jujuy',
            'America/Argentina/Tucuman',
            'America/Argentina/Catamarca',
            'America/Argentina/La_Rioja',
            'America/Argentina/San_Juan',
            'America/Argentina/Mendoza',
            'America/Argentina/San_Luis',
            'America/Argentina/Rio_Gallegos',
            'America/Argentina/Ushuaia',
            'Pacific/Pago_Pago',
            'Europe/Vienna',
            'Australia/Lord_Howe',
            'Antarctica/Macquarie',
            'Australia/Hobart',
            'Australia/Currie',
            'Australia/Melbourne',
            'Australia/Sydney',
            'Australia/Broken_Hill',
            'Australia/Brisbane',
            'Australia/Lindeman',
            'Australia/Adelaide',
            'Australia/Darwin',
            'Australia/Perth',
            'Australia/Eucla',
            'Asia/Baku',
            'America/Barbados',
            'Asia/Dhaka',
            'Europe/Brussels',
            'Europe/Sofia',
            'Atlantic/Bermuda',
            'Asia/Brunei',
            'America/La_Paz',
            'America/Noronha',
            'America/Belem',
            'America/Fortaleza',
            'America/Recife',
            'America/Araguaina',
            'America/Maceio',
            'America/Bahia',
            'America/Sao_Paulo',
            'America/Campo_Grande',
            'America/Cuiaba',
            'America/Santarem',
            'America/Porto_Velho',
            'America/Boa_Vista',
            'America/Manaus',
            'America/Eirunepe',
            'America/Rio_Branco',
            'America/Nassau',
            'Asia/Thimphu',
            'Europe/Minsk',
            'America/Belize',
            'America/St_Johns',
            'America/Halifax',
            'America/Glace_Bay',
            'America/Moncton',
            'America/Goose_Bay',
            'America/Blanc-Sablon',
            'America/Toronto',
            'America/Nipigon',
            'America/Thunder_Bay',
            'America/Iqaluit',
            'America/Pangnirtung',
            'America/Atikokan',
            'America/Winnipeg',
            'America/Rainy_River',
            'America/Resolute',
            'America/Rankin_Inlet',
            'America/Regina',
            'America/Swift_Current',
            'America/Edmonton',
            'America/Cambridge_Bay',
            'America/Yellowknife',
            'America/Inuvik',
            'America/Creston',
            'America/Dawson_Creek',
            'America/Fort_Nelson',
            'America/Vancouver',
            'America/Whitehorse',
            'America/Dawson',
            'Indian/Cocos',
            'Europe/Zurich',
            'Africa/Abidjan',
            'Pacific/Rarotonga',
            'America/Santiago',
            'America/Punta_Arenas',
            'Pacific/Easter',
            'Asia/Shanghai',
            'Asia/Urumqi',
            'America/Bogota',
            'America/Costa_Rica',
            'America/Havana',
            'Atlantic/Cape_Verde',
            'America/Curacao',
            'Indian/Christmas',
            'Asia/Nicosia',
            'Asia/Famagusta',
            'Europe/Prague',
            'Europe/Berlin',
            'Europe/Copenhagen',
            'America/Santo_Domingo',
            'Africa/Algiers',
            'America/Guayaquil',
            'Pacific/Galapagos',
            'Europe/Tallinn',
            'Africa/Cairo',
            'Africa/El_Aaiun',
            'Europe/Madrid',
            'Africa/Ceuta',
            'Atlantic/Canary',
            'Europe/Helsinki',
            'Pacific/Fiji',
            'Atlantic/Stanley',
            'Pacific/Chuuk',
            'Pacific/Pohnpei',
            'Pacific/Kosrae',
            'Atlantic/Faroe',
            'Europe/Paris',
            'Europe/London',
            'Asia/Tbilisi',
            'America/Cayenne',
            'Africa/Accra',
            'Europe/Gibraltar',
            'America/Godthab',
            'America/Danmarkshavn',
            'America/Scoresbysund',
            'America/Thule',
            'Europe/Athens',
            'Atlantic/South_Georgia',
            'America/Guatemala',
            'Pacific/Guam',
            'Africa/Bissau',
            'America/Guyana',
            'Asia/Hong_Kong',
            'America/Tegucigalpa',
            'America/Port-au-Prince',
            'Europe/Budapest',
            'Asia/Jakarta',
            'Asia/Pontianak',
            'Asia/Makassar',
            'Asia/Jayapura',
            'Europe/Dublin',
            'Asia/Jerusalem',
            'Asia/Kolkata',
            'Indian/Chagos',
            'Asia/Baghdad',
            'Asia/Tehran',
            'Atlantic/Reykjavik',
            'Europe/Rome',
            'America/Jamaica',
            'Asia/Amman',
            'Asia/Tokyo',
            'Africa/Nairobi',
            'Asia/Bishkek',
            'Pacific/Tarawa',
            'Pacific/Enderbury',
            'Pacific/Kiritimati',
            'Asia/Pyongyang',
            'Asia/Seoul',
            'Asia/Almaty',
            'Asia/Qyzylorda',
            //'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
            'Asia/Aqtobe',
            'Asia/Aqtau',
            'Asia/Atyrau',
            'Asia/Oral',
            'Asia/Beirut',
            'Asia/Colombo',
            'Africa/Monrovia',
            'Europe/Vilnius',
            'Europe/Luxembourg',
            'Europe/Riga',
            'Africa/Tripoli',
            'Africa/Casablanca',
            'Europe/Monaco',
            'Europe/Chisinau',
            'Pacific/Majuro',
            'Pacific/Kwajalein',
            'Asia/Yangon',
            'Asia/Ulaanbaatar',
            'Asia/Hovd',
            'Asia/Choibalsan',
            'Asia/Macau',
            'America/Martinique',
            'Europe/Malta',
            'Indian/Mauritius',
            'Indian/Maldives',
            'America/Mexico_City',
            'America/Cancun',
            'America/Merida',
            'America/Monterrey',
            'America/Matamoros',
            'America/Mazatlan',
            'America/Chihuahua',
            'America/Ojinaga',
            'America/Hermosillo',
            'America/Tijuana',
            'America/Bahia_Banderas',
            'Asia/Kuala_Lumpur',
            'Asia/Kuching',
            'Africa/Maputo',
            'Africa/Windhoek',
            'Pacific/Noumea',
            'Pacific/Norfolk',
            'Africa/Lagos',
            'America/Managua',
            'Europe/Amsterdam',
            'Europe/Oslo',
            'Asia/Kathmandu',
            'Pacific/Nauru',
            'Pacific/Niue',
            'Pacific/Auckland',
            'Pacific/Chatham',
            'America/Panama',
            'America/Lima',
            'Pacific/Tahiti',
            'Pacific/Marquesas',
            'Pacific/Gambier',
            'Pacific/Port_Moresby',
            'Pacific/Bougainville',
            'Asia/Manila',
            'Asia/Karachi',
            'Europe/Warsaw',
            'America/Miquelon',
            'Pacific/Pitcairn',
            'America/Puerto_Rico',
            'Asia/Gaza',
            'Asia/Hebron',
            'Europe/Lisbon',
            'Atlantic/Madeira',
            'Atlantic/Azores',
            'Pacific/Palau',
            'America/Asuncion',
            'Asia/Qatar',
            'Indian/Reunion',
            'Europe/Bucharest',
            'Europe/Belgrade',
            'Europe/Kaliningrad',
            'Europe/Moscow',
            'Europe/Simferopol',
            'Europe/Kirov',
            'Europe/Astrakhan',
            'Europe/Volgograd',
            'Europe/Saratov',
            'Europe/Ulyanovsk',
            'Europe/Samara',
            'Asia/Yekaterinburg',
            'Asia/Omsk',
            'Asia/Novosibirsk',
            'Asia/Barnaul',
            'Asia/Tomsk',
            'Asia/Novokuznetsk',
            'Asia/Krasnoyarsk',
            'Asia/Irkutsk',
            'Asia/Chita',
            'Asia/Yakutsk',
            'Asia/Khandyga',
            'Asia/Vladivostok',
            'Asia/Ust-Nera',
            'Asia/Magadan',
            'Asia/Sakhalin',
            'Asia/Srednekolymsk',
            'Asia/Kamchatka',
            'Asia/Anadyr',
            'Asia/Riyadh',
            'Pacific/Guadalcanal',
            'Indian/Mahe',
            'Africa/Khartoum',
            'Europe/Stockholm',
            'Asia/Singapore',
            'America/Paramaribo',
            'Africa/Juba',
            'Africa/Sao_Tome',
            'America/El_Salvador',
            'Asia/Damascus',
            'America/Grand_Turk',
            'Africa/Ndjamena',
            'Indian/Kerguelen',
            'Asia/Bangkok',
            'Asia/Dushanbe',
            'Pacific/Fakaofo',
            'Asia/Dili',
            'Asia/Ashgabat',
            'Africa/Tunis',
            'Pacific/Tongatapu',
            'Europe/Istanbul',
            'America/Port_of_Spain',
            'Pacific/Funafuti',
            'Asia/Taipei',
            'Europe/Kiev',
            'Europe/Uzhgorod',
            'Europe/Zaporozhye',
            'Pacific/Wake',
            'America/New_York',
            'America/Detroit',
            'America/Kentucky/Louisville',
            'America/Kentucky/Monticello',
            'America/Indiana/Indianapolis',
            'America/Indiana/Vincennes',
            'America/Indiana/Winamac',
            'America/Indiana/Marengo',
            'America/Indiana/Petersburg',
            'America/Indiana/Vevay',
            'America/Chicago',
            'America/Indiana/Tell_City',
            'America/Indiana/Knox',
            'America/Menominee',
            'America/North_Dakota/Center',
            'America/North_Dakota/New_Salem',
            'America/North_Dakota/Beulah',
            'America/Denver',
            'America/Boise',
            'America/Phoenix',
            'America/Los_Angeles',
            'America/Anchorage',
            'America/Juneau',
            'America/Sitka',
            'America/Metlakatla',
            'America/Yakutat',
            'America/Nome',
            'America/Adak',
            'Pacific/Honolulu',
            'America/Montevideo',
            'Asia/Samarkand',
            'Asia/Tashkent',
            'America/Caracas',
            'Asia/Ho_Chi_Minh',
            'Pacific/Efate',
            'Pacific/Wallis',
            'Pacific/Apia',
            'Africa/Johannesburg'
        ].map(function (s) {
            var name = s.replace(/_/g, ' ') + ' GMT' + moment.tz(s).format('Z');

            return {
                name: name,
                searchable: name.toLowerCase(),
                value: s
            };
        }))
        .run([
            '$rootScope', 'NOTARIZATION_REQUESTED', 'NOTARIZATION_SCHEDULED',
            'NOTARIZATION_COMPLETED', 'utils', 'ALL_SESSION_STATUSES',
            function (
                $rootScope, NOTARIZATION_REQUESTED, NOTARIZATION_SCHEDULED,
                NOTARIZATION_COMPLETED, utils, ALL_SESSION_STATUSES
            ) {
                $rootScope.utils = utils;

                $rootScope.constants = {};
                $rootScope.constants.NOTARIZATION_REQUESTED = NOTARIZATION_REQUESTED;
                $rootScope.constants.NOTARIZATION_SCHEDULED = NOTARIZATION_SCHEDULED;
                $rootScope.constants.NOTARIZATION_COMPLETED = NOTARIZATION_COMPLETED;
                $rootScope.constants.ALL_SESSION_STATUSES = ALL_SESSION_STATUSES;

                $rootScope.constants.INTERNAL_SESSION_REQUESTED = 'INTERNAL_SESSION_REQUESTED';
                $rootScope.constants.INTERNAL_SESSION_CREATED = 'INTERNAL_SESSION_CREATED';
                $rootScope.constants.EXTERNAL_SESSION_REQUESTED = 'EXTERNAL_SESSION_REQUESTED';
                $rootScope.constants.SESSION_FINISHED = 'SESSION_FINISHED';
            }
        ]);

    window.currAppVersion = '{{frontendAppVersion}}';
})(angular.module('onfileApp'));
