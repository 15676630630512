(function (app) {
    app.factory('FilerTypeResource', ['$resource',
        function ($resource) {
            return $resource('/filers', {}, {
                query: {
                    method: 'GET',
                    isArray: true
                }
            });
        }]);
})(angular.module('onfileApp'));