(function (app) {
    app.controller('CreateDocumentUploadRequestCtrl', [
        '$scope', 'ToastrService', '$mdDialog',
        'session', 'DocumentGroupsResourceFactory',
        'NotaryService', 'EnvironmentService',
        function (
            $, ToastrService, $mdDialog,
            session, DocumentGroupsResourceFactory,
            NotaryService, EnvironmentService
        ) {
            var notaryLink = EnvironmentService.environment() === 'development' ? 'https://demo.onlinenotary.us/' : 'https://app.onlinenotary.us/';
            $.model = {};
            $.sent = false;
            $.userLink
            $.createLink = function () {
                console.log(EnvironmentService.environment())
                if (session.uploadSecurity) {
                    $.userLink = NotaryService.createUserLink(
                        notaryLink,
                        session.uploadSecurity
                    ) + '/upload-documents';
                    return true
                }
                return false
            }
            $.linkCopied = function () {
                ToastrService.showSuccess(
                    "LINK COPIED TO CLIPBOARD"
                );
                $.close();
            }
            $.submit = function () {
              console.log(session.appointmentId)
              const options = {
                userId: session.officalUid,
                firmId: session.firmId
              }
                return DocumentGroupsResourceFactory.create(options)
                    .then(function (resource) {
                        return resource.createDocumentRequest({
                            id: session.appointmentId,
                            userEmail: $.model.userEmail,
                            officialEmail: session.official.email,
                            message: $.model.message,
                        }).$promise;
                    })
                    .then(function () {
                        ToastrService.showSuccess(
                            "Request for file production successfully created"
                        );
                        $.sent = true;
                    });
            };

            $.close = function () { $mdDialog.hide(); };
        }
    ]);
})(angular.module('onfileApp'));
