(function (app) {
    app.factory('FilingCodeResource', [
        '$resource',
        function ($resource) {
            return $resource('/filingCodes', {}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: true
                },
                get : {
                    url:'/filingCodes/get',
                    method : 'GET',
                    isArray: false,
                    cache: true
                }
            });
        }
    ]);
})(angular.module('onfileApp'));