(function (app) {
    app.directive('combinedCaseSearch', [
        'ElasticSearchResource', 'CaseService', 'CaseResource',
        '$rootScope', '$stateParams', '$state',

        function (
            ElasticSearchResource, CaseService, CaseResource,
            $rootScope, $stateParams, $state
        ) {
            return {
                strict: 'A',
                scope: {
                    courts: '=?',
                    selectCaseCallback: '&',
                    serviceType: '=?',
                    newCaseState: '=?'
                },
                link: function ($) {
                    var searchProductionParams = { static: { environment: 'production' } };
                    var searchWithourFirmParams = Object.assign({}, searchProductionParams, {
                        ignore: { firmId: true }
                    });

                    $.partySearch = {};
                    $.search = {
                        docketIdPublic: false,
                        participantsPublic: false
                    };

                    $.selectCase = function (caseObject) {
                        $.selectCaseCallback({ caseObject: caseObject });
                    };

                    $.filterCourts = function (query) {
                        return query ? $.courts.filter(createFilterFor(query)) : $.courts;
                    };

                    $.searchCases = function (e) {
                        return ElasticSearchResource.searchCaseByTitleCombined(e)
                            .then(ElasticSearchResource.removeCaseDuplicates.bind(ElasticSearchResource))
                            .then(fixCaseNames);
                    };

                    $.searchCasesByDocketId = function (e) {
                        if ($.search.docketIdPublic) {
                            return [];
                        }

                        return ElasticSearchResource.searchCaseByDocketId(e, searchWithourFirmParams)
                            .then(ElasticSearchResource.removeCaseDuplicates.bind(ElasticSearchResource))
                            .then(fixCaseNames);
                    };

                    $.searchByParticipants = function (e) {
                        return ElasticSearchResource.searchByParticipants(e, searchWithourFirmParams)
                            .then(ElasticSearchResource.removeCaseDuplicates.bind(ElasticSearchResource))
                            .then(fixCaseNames);
                    };

                    $.isValidParticipantsSearch = function (search) {
                        if (!search) {
                            return false;
                        }

                        if (search.firstName && search.lastName) {
                            return true;
                        }

                        return !!search.entityName;
                    };

                    $.publicSearch = {
                        docketId: function (params) {
                            return $.caseSearch({
                                court_code: params.court.code,
                                number: params.docketId
                            });
                        },
                        participants: function (params) {
                            if (params.search.firstName && params.search.lastName) {
                                return $.caseSearch({
                                    court_code: params.court.code,
                                    first_name: params.search.firstName,
                                    last_name: params.search.lastName
                                });
                            }

                            return $.caseSearch({
                                court_code: params.court.code,
                                entity_name: params.search.entityName
                            });
                        }
                    };

                    $.changePublicSearch = function (item) {
                        Object.keys($.search).map(function (key) {
                            if (key === item) {
                                return;
                            }

                            $.search[key] = false;
                        });
                    };

                    $.caseSearch = function (options) {
                        var request = CaseResource.search(options);

                        $rootScope.loading_message = 'Searching state database';
                        $rootScope.cancelableRequests = [request];

                        return request.$promise
                            .then(function (cases) {
                                var error = getError(cases);

                                if (cases && cases.length === 1 && cases[0] === 'non-index-case-not-found-result') {
                                    $.nonIndexedSearchResultFailed = true;
                                } else if (error && error.alias === 'ERROR_CMS_UNREACHABLE') {
                                    $.foundCases = cases;
                                } else {
                                    $.foundCases = cases;
                                    $.nonIndexedSearchResultFailed = false;
                                }
                            })
                            .finally(function () {
                                $rootScope.removeFromCancelableQueue(request);
                                $rootScope.loading_message = '';
                            });
                    };

                    $.clearPublicSearch = function () {
                        $.search.docketIdPublic = $.search.participantsPublic = false;
                        $.foundCases = null;
                        $.selectedCourt = null;
                        $.nonIndexedSearchResultFailed = false;
                        $.docketIdText = null;

                        Object.keys($.partySearch).map(function (key) {
                            $.partySearch[key] = null;
                        });
                    };

                    $.goToNonIndexedNewCase = function (courtCode, caseId) {
                        var params = Object.assign($stateParams, {
                            caseDocketId: caseId,
                            courtId: courtCode
                        });

                        return $state.transitionTo($.newCaseState, params);
                    };

                    var fixCaseNames = function (items) {
                        return items.map(function (item) {
                            item.case_title = CaseService.composeCaseName(item);
                            item.participantsString = CaseService.participantsToString(item);

                            return item;
                        });
                    };

                    var createFilterFor = function (query) {
                        var lowercaseQuery = angular.lowercase(query);

                        return function filterFn(item) {
                            return (angular.lowercase(item.name).indexOf(lowercaseQuery) === 0);
                        };
                    };

                    var getError = function (cases) {
                        for (var i = 0; i < cases.length; i++) {
                            if (cases[i] && cases[i].error && cases[i].error.code !== undefined) {
                                return cases[i].error;
                            }
                        }

                        return null;
                    };
                },
                templateUrl: '/assets/app/shared/directives/combinedCaseSearch/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
