(function (app) {
    app.factory('EServiceResource', [
        '$resource', 'urlEncodedResource', '$q',
        'DataProvider',

        function (
            $resource, urlEncodedResource, $q,
            DataProvider
        ) {
            var metaPath = '/filingsMeta/:id';
            var resource = $resource('/services/:id', {id: '@id'}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: false
                },
                get: {
                    method: 'GET',
                    url: '/services/detail/:id',
                    cache: true
                },
                save: urlEncodedResource({
                    method: 'POST',
                    url: '/services/create'
                }),
                delete: {
                    method: 'DELETE',
                    url: '/services/:id'
                },
                meta: {
                    url: metaPath
                }
            });
            var originGet = resource.get;

            resource.detailedKey = 'filingsDetails';
            resource.commonKey = 'filings';

            resource.query = function (params) {
                var orderByField = 'document_filed_date';
                var modifier = function (query) {
                    query = query.orderByChild(orderByField);

                    if (params && params.limit) {
                        query = query.limitToLast(params.limit);
                    }

                    if (params && params.last) {
                        query = query.endAt(params.last[orderByField]);
                    }

                    return query;
                };
                var handler = DataProvider
                    .getList(resource.commonKey, {
                        modifier: modifier,
                        ignoreCached: true
                    })
                    .then(function (services) {
                        if (!params || !params.last || !params.last[orderByField]) {
                            return services;
                        }

                        return services.filter(function (c) {
                            return c[orderByField] !== params.last[orderByField];
                        });
                    })

                return { $promise: handler };
            };

            resource.get = function (props) {
                if (props && props.firebaseDetail) {
                    return {
                        $promise: DataProvider.getById(
                            resource.detailedKey, props.id
                        )
                    };
                }

                if (props && props.direct) {
                    return {
                        $promise: originGet(props).$promise
                    };
                }

                return {
                    $promise: DataProvider.getById(resource.commonKey, props.id)
                };
            };

            resource.meta = function (props) {
                const path = metaPath.replace(':id', props.id);

                return { $promise: DataProvider.getOneFromPath(path) };
            };

            return resource;
        }]);
})(angular.module('onfileApp'));