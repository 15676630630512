(function (app) {
    app.controller('NotarySessionRequestsHeaderCtrl', [
        '$scope', '$rootScope', '$timeout',
        '$mdDialog', 'utils', 'typeOfNotarizationOptions',
        'SiteConfigurationService', 'NotaryService', 'NotaryFilesService',
        '$state', 'PermissionsService', 'OfficialsResourceFactory',
        'TIMEZONES',
        function (
            $, $rootScope, $timeout,
            $mdDialog, utils, typeOfNotarizationOptions,
            SiteConfigurationService, NotaryService, NotaryFilesService,
            $state, PermissionsService, OfficialsResourceFactory,
            TIMEZONES
        ) {
            $rootScope.showList = true;

            $timeout(function () { $rootScope.customListHeader = true; }, 0);

            var siteConfigurationLoaded = SiteConfigurationService.getConfigurationForPage('notary');

            $.createSessionRequestPopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        var createSession = function () {
                            $scope.errorText = null;
                            $scope.session = {
                                typeOfNotarization: 1,
                                files: []
                            };
                            $scope.activeStep = 0;
                            $scope.created = null;
                            $scope.isUploadStepVisible = false;
                        };
                        createSession();

                        $scope.isAssignmentAvailable = false;
                        $scope.officials = [];
                        $scope.timeSlots = utils.generateTimeSlots('00:00', '23:00', 30);
                        $scope.minDate = new Date();
                        $scope.typeOfNotarizationOptions = utils.clone(typeOfNotarizationOptions);
                        $scope.timezonesFiltered = TIMEZONES.slice();
                        $scope.timezoneSearch = '';

                        $scope.changeDate = function (newDate) {
                            $scope.session.files.map(function (document) {
                                document.date = newDate
                            });
                        }

                        $scope.close = function () { $mdDialog.hide(); };

                        $scope.next = function () {
                            if ($scope.activeStep === 1) {
                                $scope.errorText = '';

                                return siteConfigurationLoaded
                                    .then(function (notary) {
                                        if (!notary || !notary.link) {
                                            throw new Error('Bad configuration. No Notary app link provided');
                                        }

                                        return NotaryService.createSession($scope.session, {
                                            notaryAppLink: notary.link
                                        });
                                    })
                                    .then(function (data) {
                                        $scope.created = data;
                                        $scope.activeStep++;
                                        NotaryFilesService.unshift($scope.created.session);
                                    })
                                    .catch(function (e) {
                                        if (e instanceof InvalidDocumentsError) {
                                            for (var i = 0; i < e.documents.length; i++) {
                                                $scope.session.files[i].fileCheckResult =
                                                    e.documents[i].fileCheckResult;
                                            }
                                        }

                                        if (e.sessionId) {
                                            return NotaryService.deleteSession(e.sessionId);
                                        }

                                        $scope.errorText = e && e.message || JSON.stringify(e);
                                    });
                            }

                            $scope.activeStep++;
                        };

                        $scope.filesSelected = function (files) {
                            var filesData = files.map(function (file) {
                                file.displayedName = file.name;

                                return file;
                            });

                            $scope.session.files = $scope.session.files.concat(filesData);

                            return true;
                        };

                        $scope.deleteFile = function (index) {
                            $scope.session.files.splice(index, 1);
                        };

                        $scope.createNewSession = function () {
                            createSession();
                        };

                        $scope.viewCreated = function () {
                            $state.transitionTo('oneNotarySession', { id: $scope.created.session.id });
                            $scope.close();
                        };

                        $scope.toFileUploadStep = function () {
                            if ($scope.isUploadStepVisible) {
                                $scope.next();
                                return;
                            }

                            $scope.isUploadStepVisible = true;
                            $timeout(function () { $scope.next(); }, 100); // For seamless tab change animation
                        };

                        $scope.isFormInvalid = function () {
                            return !(
                                $scope.session.name && $scope.session.userName && $scope.session.userEmail &&
                                $scope.session.date && $scope.session.time && $scope.session.time
                            );
                        };

                        $scope.updateSearch = function (e) {
                            e.stopPropagation();

                            var search = $scope.timezoneSearch.toLowerCase().trim();

                            if (!search) {
                                $scope.timezonesFiltered = TIMEZONES;

                                return;
                            }

                            $scope.timezonesFiltered = TIMEZONES.filter(function (t) {
                                return t.searchable.indexOf(search) >= 0;
                            });


                            // if (vm.bannedCodes.indexOf(e.keyCode) < 0) {
                            //     if (e.keyCode == 8) {
                            //         $scope.formData.searchTerm =
                            //             $scope.formData.searchTerm.substring(1, $scope.formData.searchTerm.length - 1);
                            //     }
                            // }
                        };

                        PermissionsService.isFeatureAvailable('notaryScheduling')
                            .then(function (isAvailable) {
                                $scope.isAssignmentAvailable = isAvailable;

                                if (!$scope.isAssignmentAvailable) {
                                    return;
                                }

                                return OfficialsResourceFactory.create()
                                    .then(function (resource) {
                                        return resource.query().$promise;
                                    })
                                    .then(function (officials) {
                                        $scope.officials = officials;

                                        return $scope.officials;
                                    });
                            });
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/session-requests/header/' +
                        'create-session-request-popup.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };
        }
    ]);
})(angular.module('onfileApp'));
