(function (app) {
    app.controller('EFilingsDraftSidebarCtrl', [
        'FirebaseService', '$scope', '$rootScope', '$stateParams', '$q', 'MAXIMUM_DRAFTS_AGE',
        function (
            FirebaseService, $, $rootScope, $stateParams, $q, MAXIMUM_DRAFTS_AGE
        ) {
            $rootScope.showList = true;
            $rootScope.eFilingsDrafts = [];

            angular.extend($rootScope, {
                activeEFilingDraftId: $stateParams.id
            });

            // Callback function which is called when URL state changes.
            this.uiOnParamsChanged = function (changedParams) {
                // If current active eService ID is changed - refresh variable for a view.
                if (changedParams.hasOwnProperty('id')) {
                    $rootScope.activeEFilingDraftId = changedParams.id;
                }
            };

            var cases;
            var eFilings;
            var eFilingDefer = $q.defer();
            var newCaseDefer = $q.defer();

            var fetchEFilingsDrafts = function (deleteOldDrafts) {
                deleteOldDrafts = deleteOldDrafts || false;

                FirebaseService.getIdChannel('auth_users', 'efilings_drafts')
                    .then(function (drafts) {
                        $rootScope.efilingsDraftsO = drafts;

                        return drafts.$loaded();
                    }).then(function (loaded) {
                        var currentTimestamp = (new Date()).getTime() / 1000;
                        var draftsWereDeleted = false;

                        for (var i = 0; i < loaded.length; i++) {
                            loaded[i].draftType = 'efile';

                            if (deleteOldDrafts && (loaded[i].time / 1000) < currentTimestamp - MAXIMUM_DRAFTS_AGE) {
                                loaded.$remove(i);

                                draftsWereDeleted = true;
                            }
                        }

                        if (draftsWereDeleted) {
                            fetchEFilingsDrafts();
                        } else {
                            eFilings = loaded;
                            eFilingDefer.resolve(true);
                        }
                    });
            };

            fetchEFilingsDrafts(true);

            FirebaseService.getIdChannel('auth_users', 'cases_drafts')
                .then(function (drafts) {
                    $rootScope.casesDraftsO = drafts;

                    return drafts.$loaded().then(function () {
                        for (var i = 0; i < drafts.length; i++) {
                            var type = 'case';

                            if (
                                drafts[i].item.hasOwnProperty('nonIndexCaseFiling') &&
                                drafts[i].item.nonIndexCaseFiling
                            ) {
                                type = 'nonindexed';
                            }

                            drafts[i].draftType = type;
                        }

                        cases = drafts;
                        newCaseDefer.resolve(true);
                    });
                });

            $q.all([eFilingDefer.promise, newCaseDefer.promise]).then(function () {
                $rootScope.eFilingsDrafts = eFilings.concat(cases);
            });
        }
    ]);
})(angular.module('onfileApp'));
