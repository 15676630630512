(function (app) {
    app.controller('DashboardCtrl', [
        '$scope', '$rootScope', 'SiteConfigurationService',
        function ($, $rootScope, SiteConfigurationService) {
            $rootScope.showList = false;

            $.header = null;
            $.subHeader = null;
            $.videoTemplate = null;

            SiteConfigurationService.getConfigurationForPage('dashboard')
                .then(function (d) {
                    if (d && d[0]) {
                        $.header = d[0].header;
                        $.subHeader = d[0].subHeader;
                        $.videoTemplate = d[0].video_url;
                    }
                });
        }
    ]);
})(angular.module('onfileApp'));
