(function (app) {
    app.controller('RequestDetailsCtrl', [
        '$scope', 'request', 'mode',
        'doc', 'utils',
        function (
            $, request, mode,
            doc, utils
        ) {
            $.request = request;
            $.mode = mode;

            $.receivedAt = utils.getOr(function () {
                return doc.documentRequestRelation[0].updatedAt;
            });
        }
    ]);
})(angular.module('onfileApp'));
