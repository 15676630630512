(function (app) {
    app.service('S3FileUploader', [
        '$http',
        function ($http) {
            this.uploadByPreSignUrl = function (url, file) {
                var params = { headers: { 'Content-Type': 'application/pdf' } };

                return $http.put(url, file, params);
            };
        }
    ]);
})(angular.module('onfileApp'));
