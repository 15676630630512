// Extends resource's params to send a Form Data instead of a raw data in request body

(function (app) {
    app.service('EProductionHelper', [

        '$state', 'FirebaseTokenResource', 'EProductionFileResource',
        'Upload', '$q', 'ProgressHandlerFactory',

        function (
            $state, FirebaseTokenResource, EProductionFileResource,
            Upload, $q, ProgressHandlerFactory
        ) {
            function EProductionHelper() {
                var _this = this;
                var regexp = /^\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)\/(.*)/;
                var E_PRODUCTION_AUTH_KEY = 'eProductionAuth';
                var storage = window.sessionStorage;

                this.getFirmId = function (pathname) {
                    var res = pathname.match(regexp);

                    return res[2];
                };

                this.getPackageId = function (pathname) {
                    var res = pathname.match(regexp);

                    return res[3];
                };

                // Sets User as authenticated for this firm and package
                this.setAuth = function (data) {
                    storage.setItem(E_PRODUCTION_AUTH_KEY, JSON.stringify(data));
                };

                // Sets User as authenticated for this firm and package
                this.removeAuth = function () {
                    storage.removeItem(E_PRODUCTION_AUTH_KEY);
                };

                // Sets User as authenticated for this firm and package
                this.getAuth = function () {
                    return JSON.parse(
                        storage.getItem(E_PRODUCTION_AUTH_KEY)
                    );
                };

                this.configureFirebaseService = function (FirebaseService, data) {
                    data = data || _this.getAuth();

                    FirebaseService.authParams = {
                        resource: FirebaseTokenResource.guest,
                        data: {
                            firmId: data.firmId,
                            packageId: data.packageId,
                            email: data.email
                        }
                    };

                    return FirebaseService;
                };

                // Checks whether User is authenticated for this firm and package
                this.checkAuth = function () {
                    return !!window.localStorage.getItem(E_PRODUCTION_AUTH_KEY);
                };

                this.getSignatureForUploading = function (file, params) {
                    var reqData = Object.assign({}, params, {
                        name: file.name,
                        type: file.type,
                        size: file.size
                    });

                    return EProductionFileResource.getSignature(reqData).$promise
                        .then(function (data) {
                            return data;
                        });
                };

                this.upload = function (files, fileUploadedCallBack, progressChangedCallback, $rootScope) {
                    var progressHandler = ProgressHandlerFactory.create()
                        .setKeys(Object.keys(files))
                        .onProgressChange(progressChangedCallback);
                    $rootScope.cancelableRequests = [];

                    var uploadedPromises = files.map(function (file, index) {
                        var data = Object.assign({
                            data: {
                                file: file
                            }
                        }, file.signature);

                        return Upload.upload(data)
                            .xhr(function (xhr) {
                                $rootScope.cancelableRequests.push(xhr);
                            })
                            .then(function (res) {
                                return this.saveFile(res.data);
                            }.bind(this), {}, progressHandler.createHandler(index))
                            .then(function (file) {
                                fileUploadedCallBack(file);

                                return file;
                            });
                    }.bind(this));

                    return $q.all(uploadedPromises);
                };

                this.saveFile = function (data) {
                    return EProductionFileResource.save(data).$promise
                        .then(function (res) {
                            return res;
                        });
                };
            }

            return new EProductionHelper();
        }

    ]);
})(angular.module('onfileApp'));