(function (app) {
    app.factory('DocumentGroupsResourceFactory', [
        '$resource', '$q', 'notaryTokenUtil',
        'EnvironmentService',
        function (
            $resource, $q, notaryTokenUtil,
            EnvironmentService
        ) {
            return {
                create: function (options) {
                    var headers = {
                        'X-OFFICIAL-ID': (options && options.userId) || EnvironmentService.userId(),
                        'X-FIRM-ID': (options && options.firmId) || EnvironmentService.firm_id()
                    };
                    var pathParams = {
                        id: '@id'
                    };
                    var resource = $resource(notaryTokenUtil.notaryApiHost + '/document-groups', pathParams, {
                        token: {
                            url: '/token/notary',
                            method: 'GET',
                            isArray: false,
                            cache: false
                        },
                        query: {
                            method: 'GET',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        querySilent: {
                            method: 'GET',
                            cache: false,
                            ignoreLoaderInterceptor: true,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        search: {
                            url: notaryTokenUtil.notaryApiHost + '/document-groups/search',
                            method: 'POST',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        get: {
                            url: notaryTokenUtil.notaryApiHost + '/document-groups/:id',
                            method: 'GET',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        create: {
                            method: 'POST',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        update: {
                            url: notaryTokenUtil.notaryApiHost + '/document-groups/:id',
                            method: 'PUT',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers),
                            transformRequest: function (body) {
                                var params = Object.keys(pathParams);

                                for (var i = 0; i < params.length; i++) {
                                    if (body.hasOwnProperty(params[i])) {
                                        delete body[params[i]];
                                    }
                                }

                                return angular.toJson(body);
                            }
                        },
                        uploadUrl: {
                            url: notaryTokenUtil.notaryApiHost + '/documents/generate-upload-url?filename=:filename',
                            method: 'GET',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        readUrl: {
                            url: notaryTokenUtil.notaryApiHost + '/documents/generate-read-url?key=:key',
                            method: 'GET',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        createDocumentsBatch: {
                            url: notaryTokenUtil.notaryApiHost + '/documents/batch',
                            method: 'POST',
                            isArray: true,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        generateAppUrl: {
                            url: notaryTokenUtil.notaryApiHost + '/document-groups/:id/app-url',
                            method: 'GET',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        delete: {
                            url: notaryTokenUtil.notaryApiHost + '/documents/:id',
                            method: 'DELETE',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        deleteDocGroup: {
                            url: notaryTokenUtil.notaryApiHost + '/document-groups/:id',
                            method: 'DELETE',
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        createDocumentRequest: {
                            url: notaryTokenUtil.notaryApiHost + '/upload-document-request/:id',
                            method: 'POST',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers),
                            transformRequest: function (body) {
                                var params = Object.keys(pathParams);

                                for (var i = 0; i < params.length; i++) {
                                    if (body.hasOwnProperty(params[i])) {
                                        delete body[params[i]];
                                    }
                                }

                                return angular.toJson(body);
                            }
                        }
                    });

                    return notaryTokenUtil.checkToken(resource, $q)
                        .then(function () {
                            return resource;
                        });
                }
            };
        }]
    );
})(angular.module('onfileApp'));
