(function (app) {
    app.controller('EFilingDraftCtrl', [
        'FirebaseService', 'MarketRulesService',
        '$scope', '$rootScope', '$stateParams',
        '$state','ToastrService', 'CaseResource',

        function (FirebaseService, MarketRulesService,
            $, $rootScope, $stateParams,
            $state, ToastrService, CaseResource) {
            angular.extend($, {
                eFilingDraft: null
            });

            this.uiOnParamsChanged = function (changedParams) {
                $rootScope.$watch('eFilingsDrafts', function (eFilingsDrafts) {
                    if (!eFilingsDrafts)
                        return false;

                    for (var i = 0; i < eFilingsDrafts.length; i++) {
                        var d = eFilingsDrafts[i];
                        if (d.$id === changedParams.id) {
                            $.eFilingDraft = d;
                            break;
                        }
                    }
                });
            };

            $.resumeDraft = function () {
                if ($.eFilingDraft.draftType === 'efile') {

                    if (MarketRulesService.isVisible('isCaseAvailableCheck')) {
                        CaseResource.check({
                            id: $.eFilingDraft.item.id,
                            court_code: $.eFilingDraft.item.court_code
                        })
                            .$promise
                            .then(function (status) {
                                if (status.error_code === 10) {
                                    $.caseUnavailable = true;
                                } else if (status.error_code === 130) {
                                    $.caseReportsUnavailable = true;
                                } else {
                                    $.resumeDraftState();
                                }
                            });
                    } else {
                        $.resumeDraftState();
                    }

                }
                else if ($.eFilingDraft.draftType === 'case' || $.eFilingDraft.draftType === 'nonindexed' ) {
                    $state.go('caseCreate', {
                        draftId: $.eFilingDraft.$id
                    });
                }
            };

            $.deleteDraft = function () {
                //below is just to avoid hitting firebase from here directly.
                //will replicate to firebase
                if($.eFilingDraft.draftType === 'efile') {
                    $rootScope.efilingsDraftsO
                        .$remove($.eFilingDraft)
                        .then(function () {
                            ToastrService.showMessageByKey('draft_deleted');
                            $state.go('draftFilings');
                        });
                }
                //will replicate to firebase
                if($.eFilingDraft.draftType === 'case' || $.eFilingDraft.draftType === 'nonindexed'  ) {
                    $rootScope.casesDraftsO
                        .$remove($.eFilingDraft)
                        .then(function () {
                            ToastrService.showMessageByKey('draft_deleted');
                            $state.go('draftFilings');
                        });
                }
                var all = $rootScope.eFilingsDrafts;

                for (var i = 0; i < all.length; i++) {
                    var d = all[i];
                    if (d.$id === $.eFilingDraft.$id) {
                        $rootScope.eFilingsDrafts.splice(i,1);
                        break;
                    }
                }
            };

            $.resumeDraftState = function () {
                $state.go('filingCreate', {
                    caseId: $.eFilingDraft.item.id,
                    courtId: $.eFilingDraft.item.court_code,
                    currentTab: 0,
                    draftId: $.eFilingDraft.$id,
                    resubmitFilingId: $.eFilingDraft.item.resubmittedFrom
                });
            };

            this.uiOnParamsChanged({id: $stateParams.id});
        }
    ]);
})(angular.module('onfileApp'));
