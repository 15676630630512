(function (app) {
    app.factory('EmailService', ['FirebaseService', '$templateCache', '$http', 'EnvironmentService',
        function (FirebaseService, $templateCache, $http, EnvironmentService) {
            function EmailService() {

                this.deleteEmail = function (key, value) {
                    var notifications = FirebaseService.getIdChannel('notifications/outbox', null);
                    notifications.then(function (node) {
                        node.$loaded().then(function (all) {
                            for (var i = 0; i < all.length; i++) {
                                var item = all[i];
                                if (item && item.source && item.source.hasOwnProperty(key)
                                    && item.source[key] === value) {
                                    all.$remove(item);
                                }
                            }
                        });
                    });
                };

                this.sendEmail = function (data, templateName, args) {
                    var template = $templateCache.get(templateName);

                    data.to = data.to || EnvironmentService.email();

                    if (template === undefined) {
                        return $http.get('/assets/app/shared/templates/' + templateName)
                            .then(function (response) {
                                template = response.data;
                                $templateCache.put(templateName, template);

                                return process(data, template, args);
                            });
                    }

                    return process(data, template, args);
                };

                function process(data, template, args) {
                    var insertData = applyTemplate(data, template, args);
                    var notifications = FirebaseService.getIdChannel('notifications/outbox', null);

                    return notifications.then(function (node) {
                        return node.$add(insertData);
                    });
                }

                function applyTemplate(data, template, args) {
                    data.body = format(template, args);

                    return data;
                }

                var format = function (template, args) {
                    var formatted = template;
                    for (var i = 0; i < args.length; i++) {
                        var regexp = new RegExp('\\{{' + args[i].key + '\\}}', 'gi');
                        formatted = formatted.replace(regexp, args[i].value);
                    }
                    return formatted;
                };

                return this;
            }

            return new EmailService();
        }]
    )
    ;
})
(angular.module('onfileApp'));