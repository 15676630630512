(function (app) {
    app.constant('COMPLETED_SESSION_STATUSES', [
        'sessionEnded', 'paymentByPayer', 'payment', 'paymentStatusWait', 'userSessionEnded'
    ])
        .constant('SCHEDULED_STATUS', 'scheduled')
        .constant('CANCELLED_STATUS', 'cancelled')
        .constant('COMPLETED_STATUS', 'completed')
        .constant('ALL_STATUS', 'all');

    app.service('NotaryFilesService', [
        'COMPLETED_SESSION_STATUSES', 'SCHEDULED_STATUS', 'COMPLETED_STATUS',
        'CANCELLED_STATUS',
        function (
            COMPLETED_SESSION_STATUSES, SCHEDULED_STATUS, COMPLETED_STATUS,
            CANCELLED_STATUS
        ) {
            var status = 'scheduled';
            var items = {
                all: [],
                scheduled: [],
                completed: []
            };
            var activeItemIndex = 0;
            var onActiveChangedHandler;
            var onItemsSetHandler;

            this.emptyItems = function () {
                items = {
                    all: [],
                    scheduled: [],
                    completed: []
                };

                return this;
            };

            this.setItems = function (data) {
                this.emptyItems();

                for (var i = 0; i < data.length; i++) {
                    // Do not show cancelled sessions
                    if (data[i].cancelledAt) {
                        continue;
                    }

                    this.unshift(data[i], { doNotSortAfter: true });
                }

                sortItems();
                onItemsSetHandler && onItemsSetHandler();

                return this;
            };

            this.getItems = function () {
                return items[status];
            };

            this.map = function (cb) {
                for (var i = 0; i < items.all.length; i++) {
                    cb(items.all[i]);
                }

                return this;
            };

            this.unshift = function (item, options) {
                items.all.unshift(item);

                if (COMPLETED_SESSION_STATUSES.indexOf(item.stage) >= 0) {
                    item.isCompleted = true;

                    items.completed.unshift(item);
                } else if (item.cancelledAt) {
                    item.isCancelled = true;

                    items.scheduled.unshift(item);
                } else if (item.scheduledAt) {
                    item.isScheduled = true;

                    items.scheduled.unshift(item);
                }

                if (!options || !options.doNotSortAfter) {
                    sortItems();
                }

                return this;
            };

            this.getActive = function () {
                return items.all[activeItemIndex];
            };

            this.setActiveIndex = function (index) {
                activeItemIndex = index;

                if (onActiveChangedHandler) {
                    onActiveChangedHandler(this.getActive());
                }

                return this;
            };

            this.setActiveById = function (id) {
                for (var i = 0; i < items.all.length; i++) {
                    if (items.all[i].id === id) {
                        this.setActiveIndex(i);

                        return true;
                    }
                }

                return false;
            };

            this.deleteById = function (id) {
                var filterFn = filterOutByIdFn(id);

                items.all = items.all.filter(filterFn);
                items.scheduled = items.scheduled.filter(filterFn);
                items.completed = items.completed.filter(filterFn);

                return this;
            };

            this.onActiveChanged = function (cb) {
                onActiveChangedHandler = cb;

                return this;
            };

            this.onItemsSet = function (cb) {
                onItemsSetHandler = cb;

                return this;
            };

            this.triggerItemsSet = function () {
                onItemsSetHandler && onItemsSetHandler();

                return this;
            };

            this.displayByStatus = function (newStatus) {
                status = newStatus;

                return this;
            };

            this.getStatus = function () {
                return status;
            };

            this.moveTo = function (item, statusTo) {
                var statusFrom = SCHEDULED_STATUS;

                if (item.isCompleted) {
                    statusFrom = COMPLETED_STATUS;
                }

                item.isCompleted = false;
                item.isScheduled = false;

                if (statusTo === CANCELLED_STATUS) {
                    item.isCancelled = true;

                    return this;
                }

                for (var i = 0; i < items[statusFrom].length; i++) {
                    if (items[statusFrom][i].id === item.id) {
                        items[statusFrom].splice(i, 1);
                        break;
                    }
                }

                if (statusTo === SCHEDULED_STATUS) {
                    item.isScheduled = true;
                } else if (statusTo === COMPLETED_STATUS) {
                    item.isCompleted = true;
                }

                items[statusTo].push(item);
                sortItems();

                return this;
            };

            var sortItems = function () {
                items.all = items.all.sort(scheduledAtSortFn);
                items.scheduled = items.scheduled.sort(scheduledAtSortFn);
                items.completed = items.completed.sort(finishedAtSortFn);
            };

            var scheduledAtSortFn = function (a, b) {
                if (a.scheduledAt._seconds > b.scheduledAt._seconds) {
                    return 1;
                }

                if (a.scheduledAt._seconds < b.scheduledAt._seconds) {
                    return -1;
                }

                return 0;
            };

            var finishedAtSortFn = function (a, b) {
                if (!a.finishedAt) {
                    return -1;
                }

                if (!b.finishedAt) {
                    return 1;
                }

                if (a.finishedAt._seconds > b.finishedAt._seconds) {
                    return -1;
                }

                if (a.finishedAt._seconds < b.finishedAt._seconds) {
                    return 1;
                }

                return 0;
            };

            var filterOutByIdFn = function (id) {
                return function (item) {
                    return item.id !== id;
                };
            };

            this.sortItems = sortItems;
        }
    ]);
})(angular.module('onfileApp'));
