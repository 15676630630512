;(function (app) {
    app.service('ReminderWatcherService', [
        'ToastrService', 'ReminderService', '$state',

        function (ToastrService, ReminderService, $state) {
            const displayToaster = function (reminder) {
                ToastrService.showError(
                    'You have a reminder "' + reminder.description + '" that is due tomorrow. Click here to view.',
                    '',
                    {
                        autoDismiss: false,
                        allowHtml: true,
                        timeOut: 0,
                        extendedTimeOut: 0,
                        closeButton: true,
                        tapToDismiss: false,
                        onTap: function () {
                            $state.go('viewReminder', {id: reminder.$id});
                        },
                        onHidden: function () {
                            ReminderService.markDismissed(reminder.$id);
                        }
                    }
                );
            };

            this.watch = function () {
                ReminderService.getExpiring()
                    .then(function (reminders) {
                        reminders.map(displayToaster);
                    });
            }
        }
    ]);
})(angular.module('onfileApp'));