(function (app) {
    app.constant('caseInformationValidationStateEvent', 'caseInformationValidationState');
    app.controller('CaseInformationCtrl', [
        'CourtResource', 'CaseCategoryResource', 'CaseTypeResource',
        'FilerTypeResource', 'AttorneyResource', 'DamageAmountResource',
        'ProcedureRemedyResource', 'PaymentAccountResource', '$scope',
        '$rootScope', 'ToastrService', 'AttorneyService',
        'MarketRulesService', 'CourtService', 'FilingCreateCacheService',
        'SiteConfigurationService', '$q', '$timeout',
        'customEvents', 'caseInformationValidationStateEvent',

        function (
            CourtResource, CaseCategoryResource, CaseTypeResource,
            FilerTypeResource, AttorneyResource, DamageAmountResource,
            ProcedureRemedyResource, PaymentAccountResource, $,
            $rootScope, ToastrService, AttorneyService,
            MarketRulesService, CourtService, FilingCreateCacheService,
            SiteConfigurationService, $q, $timeout,
            customEvents, caseInformationValidationStateEvent
        ) {
            var caseInfoPopulated = false;

            angular.extend($, {
                courtChangedEvent: customEvents.courtChanged,
                caseCategoryChangedEvent: customEvents.caseCategoryChanged,
                caseCategoriesLoaded: $q.defer(),
                caseTypesLoaded: $q.defer(),
                courts: [],
                categories: [],
                types: [],
                filerTypes: [],
                attorneys: [],
                damageAmounts: [],
                procedureRemedies: [],
                paymentAccounts: [],
                lockVerbiage: SiteConfigurationService.getContent('lockVerbiage'),
                FilingCreateCacheService: FilingCreateCacheService,

                filterCaseTypes: function (category) {
                    return category ? $.types.filter(function (o) {
                        return o.initial === 'True' && o.casecategory === category;
                    }) : $.types;
                },
                filterCaseCategories: function () {
                    if (!$.categories || $.categories.length === 0) {
                        return [];
                    }

                    if (!$.types || $.types.length === 0) {
                        return $.categories;
                    }

                    return $.categories.filter(function (c) {
                        return $.types.find(function (t) {
                            return t.initial === 'True' && t.casecategory === c.code;
                        });
                    });
                }
            });

            console.log($.case);

            $.procedureTextChanged = function (ev) {
                ev.stopPropagation();
            };

            $.procedureSearchTerm = '';
            $.clearSearchTerm = function () {
                this.procedureSearchTerm = '';
            };

            $.selectCourt = function (court) {
                $.case.court = court;

                if (court !== undefined && court !== null) {
                    $.case.court_code = court.code;

                    $rootScope.$broadcast($.courtChangedEvent, { object: $.case });
                }

                return $.getCaseCategories(court);
            };

            $.rules = MarketRulesService;
            $.nonIndexFiling = false;
            $rootScope.nonIndexedCaseFiling = false;

            // for non-indexed case court_code
            //TODO: need to simplify this
            if ($.case.court_code !== undefined) {
                $.nonIndexFiling = $.case.nonIndexedCaseFiling; //!$.case.hasOwnProperty('draft') || $.case.hasOwnProperty("nonIndexCaseFiling") ;
                $rootScope.nonIndexedCaseFiling = $.case.nonIndexedCaseFiling; //!$.case.hasOwnProperty('draft') || $.case.hasOwnProperty("nonIndexCaseFiling");

                CourtResource.query({search: $.case.court_code}).$promise
                    .then(function (res) {
                        if (res === null || res.length === 0) {
                            var errorMessage = 'could not translate court code into court object from the server';

                            console.error(errorMessage);
                            throw new Error(errorMessage);
                        }

                        var court = res[0];

                        $.case.court = court;
                        $.courtSearch = court.name;
                        $.selectCourt(court);

                        var nonindexed = $.case.nonIndexedCaseFiling; //!$.case.hasOwnProperty('draft') || $.case.hasOwnProperty("nonIndexCaseFiling");

                        $rootScope.nonIndexedCaseFiling = nonindexed;
                        $rootScope.nonIndexFiling = nonindexed;
                        $.nonIndexFiling = nonindexed;
                        $.nonIndexedCaseFiling = nonindexed;
                        $.case.nonIndexCaseFiling = nonindexed;
                    });
            }

            // Loads Firm's attorneys
            $.attorneysLoaded = AttorneyResource.query().$promise
                .then(function (attorneys) {
                    $.attorneys = attorneys.map(function (item) { return new AttorneyService(item); });
                });

            // Loads Payment accounts
            $.paymentAccountsLoaded = PaymentAccountResource.query().$promise
                .then(function (paymentAccounts) {
                    $.paymentAccounts = paymentAccounts;

                    if (paymentAccounts === null || paymentAccounts === undefined || paymentAccounts.length === 0)
                        ToastrService.showMessageByKey('no_payment_account');
                });

            // Function for searching a Court by name
            $.searchCourt = function (search) {
                //CourtResource.query({search: search}).$promise
                CourtResource.query().$promise
                    .then(function (res) {
                        $.courts = res;
                    });
            };

            $.filterCourts = function (query) {
                return query ? $.courts.filter(createFilterFor(query)) : $.courts;
            };

            function createFilterFor(query) {
                var lowercaseQuery = angular.lowercase(query);

                return function filterFn(item) {
                    return (angular.lowercase(item.name).indexOf(lowercaseQuery) === 0);
                };

            }

            $.courtsLoaded = CourtResource.query({ initial: true })
                .$promise
                .then(function (res) {
                    $.courts = res;
                });

            /**
             * @param eCase
             * @returns {boolean}
             */
            $.isNextStepVisible = function (eCase) {
                if (!eCase) {
                    return false;
                }

                return eCase ? !!(eCase.court && eCase.category && eCase.type) : false;
            };

            /**
             * Callback for changing a Court location
             *
             * @param court
             * @returns {boolean}
             */
            $.getCaseCategories = function (court) {
                $.categories = [];
                $.types = [];
                $.filerTypes = [];
                $.damageAmounts = [];
                $.procedureRemedies = [];

                // If passed Court is invalid terminates loading
                if (!court || !court.hasOwnProperty('code')) {
                    return false;
                }

                // Loads Case Category Codes
                CaseCategoryResource.query({ court_code: court.code }).$promise
                    .then(function (categories) {
                        $.categories = categories;
                    })
                    .then(function () {
                        $timeout(function () {
                            $.caseCategoriesLoaded.resolve();
                            $.caseCategoriesLoaded = $q.defer();
                        }, 0);
                    })
                    .catch(function () {
                        $timeout(function () {
                            $.caseCategoriesLoaded.reject();
                            $.caseCategoriesLoaded = $q.defer();
                        }, 0);
                    });

                // Loads Case Type Codes
                CaseTypeResource.query({ court_code: court.code }).$promise
                    .then(function (types) {
                        $.types = types;
                    })
                    .then(function () {
                        $timeout(function () {
                            $.caseTypesLoaded.resolve();
                            $.caseTypesLoaded = $q.defer();
                        }, 0);
                    })
                    .catch(function () {
                        $timeout(function () {
                            $.caseTypesLoaded.reject();
                            $.caseTypesLoaded = $q.defer();
                        }, 0);
                    });

                // Loads Filer Type Codes
                FilerTypeResource.query({ court_code: court.code }).$promise
                    .then(function (types) {
                        $.filerTypes = types;
                    });

                // Loads Damage Amount codes
                DamageAmountResource.query({ courtCode: court.code }).$promise
                    .then(function (damageAmounts) {
                        $.damageAmounts = damageAmounts;
                    });

                // Loads Procedure Remedy codes
                ProcedureRemedyResource.query({ courtCode: court.code }).$promise
                    .then(function (procedureRemedies) {
                        $.procedureRemedies = procedureRemedies;
                    });

                return true;
            };

            $.validate = function () {
                var valid = true;

                if (!$.case.category)
                    return false;

                var procedureRemedyRequired = (!$.nonIndexedCaseFiling && $.case.category.procedureremedyinitial === 'Required')
                    || ($.nonIndexedCaseFiling && $.case.category.procedureremedysubsequent === 'Required');

                if (procedureRemedyRequired && ($.case.procedureRemedies === null || $.case.procedureRemedies.length === 0)) {
                    valid = false;
                    ToastrService.showMessageByKey('procedure_required');
                }

                valid = valid && $.caseForm.$valid;

                $.caseForm.$setSubmitted();

                return valid;
            };

            $.populateCachedData = function () {
                if (!caseInfoPopulated) {
                    FilingCreateCacheService.populateData($.case);
                    caseInfoPopulated = true;
                }

                return true;
            };

            $.findByName = function (data, property) {
                var name = '';
                var collectionName = property === 'category' ? 'categories' : 'types';

                if (data && data.toLowerCase) {
                    name = data.toLowerCase();
                }

                return $[collectionName].find(function (item) {
                    return item && item.name && item.name.toLowerCase() === name;
                });
            };

            $.caseCategoryChanged = function () {
                $timeout(function () {
                    $rootScope.$broadcast($.caseCategoryChangedEvent, { object: $.case });
                }, 0);
            };

            $.$watch('[caseForm.$valid, caseForm.$submitted]', function () {
                $.$emit(caseInformationValidationStateEvent, $.caseForm);
            });

            if ($.case.court) {
                $.selectCourt($.case.court);
            }
        }
    ]);
})(angular.module('onfileApp'));