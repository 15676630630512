(function (app) {
    app.controller('IndexECaseCtrl', [
        '$rootScope', '$scope', 'RefreshDataService',
        '$interval',

        function (
            $rootScope, $, RefreshDataService,
            $interval
        ) {
            $rootScope.showList = true;

            $.onBoarded = true;

            const intervalId = $interval(function () {
                RefreshDataService.isOnBoarded()
                    .then(function (isOnBoarded) {
                        $.onBoarded = !!isOnBoarded;
                        
                        console.log($.onBoarded);

                        if ($.onBoarded === true) {
                            $interval.cancel(intervalId);
                        }
                    })
            }, 500);
        }
    ]);
})(angular.module('onfileApp'));