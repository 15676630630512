(function (app) {
    app.filter('filerFilter', [
        'FilingService',
        function filerFilter(FilingService) {
            return function (array, filer) {
                return array.filter(function (service) {
                    return FilingService.isUserFiler(service, filer.values);
                });
            };
        }

    ]);
})(angular.module('onfileApp'));
