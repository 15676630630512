(function (app) {
    app.service('UserNotificationService', [
        'ToastrService', 'FirebaseService', 'EnvironmentService', '$window',
        function (ToastrService, FirebaseService, EnvironmentService, $window) {
            var viewedNotificationNode = 'viewedNotification';
            var viewedNotificationNotaryNode = 'viewedNotificationNotary'
            var viewedNotificationEfilerNode = 'viewedNotificationEfiler'

            this.check = function () {
                var isAuth = !!EnvironmentService.userId();

                if (isAuth) {
                    return this.showForUser();
                }

                return this.showForGuest();
            };

            this.showForGuest = function () {
                return FirebaseService.getNode('/configuration/userNotifications/guest').$loaded().then(function (v) {
                    if (!v || !v.text) {
                        return null;
                    }

                    return ToastrService.showWarning(v.text, {
                        autoDismiss: false,
                        allowHtml: true,
                        timeOut: 0,
                        extendedTimeOut: 0,
                        closeButton: true,
                        tapToDismiss: false,
                        toastClass: 'toast toast-without-icon'
                    });
                });
            };

            this.showForUser = function () {
                if ($window.location.toString() === (EnvironmentService.base_url() + '/notary'))
                {
                    FirebaseService.getNode('/configuration/userNotifications/auth_notary').$loaded().then(function (v) {
                        var viewedNotification = $window.sessionStorage.getItem(viewedNotificationNotaryNode);

                        if (!v || !v.text || viewedNotification) {
                            return null;
                        }

                        return ToastrService.showWarning(v.text, {
                            autoDismiss: false,
                            allowHtml: true,
                            timeOut: 0,
                            extendedTimeOut: 0,
                            closeButton: true,
                            tapToDismiss: false,
                            onHidden: function () {
                                return $window.sessionStorage.setItem(viewedNotificationNotaryNode, '1');
                            },
                            toastClass: 'toast toast-without-icon'
                        });
                    });
                }
                if ($window.location.toString().includes((EnvironmentService.base_url() + '/filings/create')) || $window.location.toString().includes((EnvironmentService.base_url() + '/cases/create')))
                {
                    FirebaseService.getNode('/configuration/userNotifications/auth_efiler').$loaded().then(function (v) {
                        var viewedNotification = $window.sessionStorage.getItem(viewedNotificationEfilerNode);

                        if (!v || !v.text || viewedNotification) {
                            return null;
                        }

                        return ToastrService.showWarning(v.text, {
                            autoDismiss: false,
                            allowHtml: true,
                            timeOut: 0,
                            extendedTimeOut: 0,
                            closeButton: true,
                            tapToDismiss: false,
                            onHidden: function () {
                                return $window.sessionStorage.setItem(viewedNotificationEfilerNode, '1');
                            },
                            toastClass: 'toast toast-without-icon'
                        });
                    });
                }
                return FirebaseService.getNode('/configuration/userNotifications/auth').$loaded().then(function (v) {
                    var viewedNotification = $window.sessionStorage.getItem(viewedNotificationNode);

                    if (!v || !v.text || viewedNotification) {
                        return null;
                    }

                    return ToastrService.showWarning(v.text, {
                        autoDismiss: false,
                        allowHtml: true,
                        timeOut: 0,
                        extendedTimeOut: 0,
                        closeButton: true,
                        tapToDismiss: false,
                        onHidden: function () {
                            return $window.sessionStorage.setItem(viewedNotificationNode, '1');
                        },
                        toastClass: 'toast toast-without-icon'
                    });
                });
            };
        }
    ]);
})(angular.module('onfileApp'));
