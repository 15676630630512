(function (app) {
    app.constant(
        'isIE',
        (function (navigator) {
            var ua = navigator.userAgent;
            var names = [
                ['Microsoft Internet Explorer', 'MSIE ([0-9]{1,}[\.0-9]{0,})'],
                ['Netscape', 'Trident/.*rv:([0-9]{1,}[\.0-9]{0,})']
            ];
            var ie = names.find(function (name) {
                return navigator.appName === name[0];
            });

            if (!ie) {
                return false;
            }

            return !!(new RegExp(ie[1]).exec(ua));
        })(window.navigator)
    );
})(angular.module('onfileApp'));
