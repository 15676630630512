(function (app) {
    app.controller('LoginCtrl', [
        '$scope', '$stateParams', '$rootScope',
        'LoginResource', '$state', '$window',
        'WhitelistAccessService', 'ToastrService', '$q',
        'EnvironmentService', 'SiteConfigurationService', '$sce',
        'UserProfileService',

        function ($, $stateParams, $rootScope,
                  LoginResource, $state, $window,
                  WhitelistAccessService, ToastrService, $q,
                  EnvironmentService, SiteConfigurationService, $sce,
                  UserProfileService) {
            $rootScope.showList = false;
            $rootScope.clearUserData();

            $.showPricing = false;

            const _default_url = 'services/#/dashboard';
            const desiredUrl = angular.element('#destination').val();

            var _base_url = 'services/';

            function checkWhitelistStatus(authData) {
                return WhitelistAccessService
                    .isFirmWhitelisted(authData.firm.firmID)
                    .then(function (res) {
                        return !!res.result;
                    });
            }

            function LogTheUser() {
                return LoginResource
                    .login({
                        email: $.email,
                        password: $.password,
                        token: $.token
                    })
                    .$promise
                    .then(function (res) {
                        var url;
                        var states = $state.get();
                        var homePage = UserProfileService.getProfile().homePage;

                        if (homePage) {
                            for (var i = 0; i < states.length; i++) {
                                if (states[i].name === homePage.name) {
                                  $window.location = homePage.url;
                                  return;
                                }
                            }
                        }

                        if (desiredUrl) {
                            url = desiredUrl;
                        } else {
                            url = _default_url;
                        }

                        $window.location.href = url;
                    });
            }

            function getTag() {
                return angular.element('#tag').val();
            }

            function loadAdvertisementConfiguration() {
                var tag = getTag();
                //TODO: add default;
                SiteConfigurationService.getConfigurationForPage('login').then(function (configuration) {
                    try {
                        var item = configuration[tag];
                        if (item === null || item === undefined)
                            item = configuration['default'];

                        $.advertisement = item;
                        $.header_description = $sce.trustAsHtml(item.header_description);
                        $.video_link = $sce.trustAsHtml(item.video_link);
                        $.header = $sce.trustAsHtml(item.header);
                        $rootScope.showfooter = true;
                    }
                    catch (error) {
                        $rootScope.showfooter = true;
                        $.advertisement = {header: "Welcome to OnFile, easiest way to file."};
                    }
                });
            };

            var _blocked = false;

            angular.extend($, {
                email: '',
                password: '',
                tokenPrompt: false,
                invalidTokenMessage: false,
                trustAsResourceUrl: $sce.trustAsResourceUrl,
                trustAsHtml: $sce.trustAsHtml,
                submit: function () {
                    LoginResource
                        .login({
                            email: $.email,
                            password: $.password,
                            dryRun: true,
                            httpCodeIgnorePopup: true
                        })
                        .$promise
                        .then(
                            function (res) {
                                EnvironmentService.firm(res.firm);
                                console.log('Successful Login', res);
                                checkWhitelistStatus(res)
                                    .then(function (status) {
                                        if (status === true) {
                                            LogTheUser();
                                        }
                                        else {
                                            $.tokenPrompt = true;
                                            _blocked = status === 'blocked';
                                        }
                                    });
                            },
                            function (error) {
                                if (error.status === 422)
                                    $.error_message = error.data.errorText;
                            }
                        )
                        .catch(function (res) {});

                    return false;
                },

                cancel: function () {
                    $.tokenPrompt = false;
                },
                verifyToken: function () {
                    return WhitelistAccessService.isValidToken($.token)
                        .then(function (result) {
                            if (_blocked || !result.valid) {
                                $.invalidTokenMessage = true;
                            } else {
                                $.tokenPrompt = false;
                                LogTheUser();
                            }
                        });
                }
            });

            loadAdvertisementConfiguration();
        }
    ])
    ;
})
(angular.module('onfileApp'));
