(function (app) {
    app.controller('CreateMatterReminderCtrl', [
        '$scope', 'ToastrService', '$mdDialog',
        'request',
        function (
            $, ToastrService, $mdDialog,
            request
        ) {
            $.model = {
                name: request.name
            };

            $.create = function () {
                ToastrService.showSuccess('Reminder created');
                $.close();
            };

            $.close = function () {
                $mdDialog.hide();
            };
        }
    ]);
})(angular.module('onfileApp'));
