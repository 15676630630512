(function (app) {
    app.controller('InfoCtrl', [
        '$rootScope', '$scope', 'SiteConfigurationService',
        function ($rootScope, $, SiteConfigurationService) {
            $rootScope.showList = false;

            SiteConfigurationService.getConfigurationForPage('info')
                .then(function (d) {
                    if (d && d.template) {
                        $.template = d.template;
                    }
                });
        }
    ]);
})(angular.module('onfileApp'));
