(function (app) {
    app.service('BootstrapService', [
        'AttorneyResource', 'ToastrService', 'PaymentAccountResource',
        'EnvironmentService', 'MarketRulesService', '$q',

        function (
            AttorneyResource, ToastrService, PaymentAccountResource,
            EnvironmentService, MarketRulesService, $q
        ) {
            var attorneysAreAbsent = function () {
                return AttorneyResource.query().$promise
                    .then(function (attorneys) {
                        return !attorneys || attorneys.length === 0;
                    });
            };

            var paymentAccountsAreAbsent = function () {
                return PaymentAccountResource.query().$promise
                    .then(function (paymentAccounts) {
                        return !paymentAccounts || paymentAccounts.length === 0;
                    });
            };

            var isAttorneyRequired = function () {
                return MarketRulesService.loaded
                    .then(function () {
                        return MarketRulesService.isRequired('filing_attorney');
                    });
            };

            this.checkRequiredConditions = function () {
                var promises = [attorneysAreAbsent(), isAttorneyRequired()];

                $q.all(promises)
                    .then(function (data) {
                        var attorneysAreAbsentResult = data[0];
                        var isAttorneyRequiredResult = data[1];

                        if (
                            EnvironmentService.isFirmAccount() && attorneysAreAbsentResult && isAttorneyRequiredResult
                        ) {
                            ToastrService.showMessageByKey('attorneys_missing');

                            return false;
                        }

                        return paymentAccountsAreAbsent();
                    })
                    .then(function (paymentAccountsAreAbsentResult) {
                        if (paymentAccountsAreAbsentResult) {
                            ToastrService.showMessageByKey('payment_accounts_missing');

                            return false;
                        }

                        return true;
                    });
            };
        }
    ]);
})(angular.module('onfileApp'));
