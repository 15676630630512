(function (app) {
    app.factory('NotaryNotificationsResourceFactory', [
        '$resource', '$q', 'notaryTokenUtil',
        function ($resource, $q, notaryTokenUtil) {
            return {
                create: function (options) {
                    var headers = {
                        'X-OFFICIAL-ID': options.officialId,
                        'X-FIRM-ID': options.firmId
                    };
                    var resource = $resource(notaryTokenUtil.notaryApiHost + '/notifications', {}, {
                        create: {
                            method: 'POST',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        }
                    });

                    return notaryTokenUtil.checkToken(resource, $q)
                        .then(function () {
                            return resource;
                        });
                }
            };
        }]
    );
})(angular.module('onfileApp'));
