(function (app) {
    app.service('ServiceTypeCodesService', [
        'areServiceTypesNeeded', 'EnvironmentService', 'ServiceTypeResource',
        'utils',

        function ServiceTypeCodesService(
            areServiceTypesNeeded, EnvironmentService, ServiceTypeResource,
            utils
        ) {
            this.getList = function (eCase) {
                var request;
                var neededForMarket = areServiceTypesNeeded(EnvironmentService.market());
                var hasConditionalServiceTypes = eCase && eCase.court && eCase.court.hasconditionalservicetypes &&
                    utils.isTrue(eCase.court.hasconditionalservicetypes);

                if (neededForMarket && hasConditionalServiceTypes) {
                    request = ServiceTypeResource.query(eCase).$promise;
                } else {
                    request = ServiceTypeResource.common().$promise;
                }

                return request.then(function (data) {
                    return this.formatList(data);
                }.bind(this));
            };

            this.formatList = function (data) {
                var res = [];

                if (data && data.map) {
                    res = data.map(function (entry) {
                        if (!entry) {
                            return false;
                        }

                        return {
                            id: entry.ServiceCodeID || entry.code || null,
                            name: entry.Description || entry.name || null,
                            all: entry
                        };
                    }).filter(function (e) { return e; });
                }

                return res;
            };

            this.setInitialTypes = function (serviceContacts, serviceTypes) {
                if (!serviceTypes || !serviceTypes.length) {
                    return false;
                }

                var selectedType = serviceTypes.find(function (serviceType) {
                    return serviceType.name === 'EServe';
                }) || serviceTypes[0];

                if (serviceContacts && serviceContacts.map) {
                    serviceContacts.map(function (serviceContact) {
                        serviceContact.serviceType = selectedType;
                    });
                }

                return serviceContacts;
            };
        }
    ]);
})(angular.module('onfileApp'));