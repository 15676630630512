(function (app) {
    app.service('NotaryNotificationService', [
        'NotaryResourceFactory', 'EnvironmentService', 'SiteConfigurationService',
        '$q',

        function (
            NotaryResourceFactory, EnvironmentService, SiteConfigurationService,
            $q
        ) {
            var officialId = EnvironmentService.userId();
            var firmId = EnvironmentService.firm_id();
            var notificationResource = NotaryResourceFactory.createNotificationsResource({
                officialId: officialId,
                firmId: firmId
            });
            var siteConfigurationLoaded = SiteConfigurationService.getConfigurationForPage('notary');

            this.sendSessionCreated = function (options) {
                var sessionId = options.sessionId;

                return $q.all([notificationResource, siteConfigurationLoaded])
                    .then(function (res) {
                        var resource = res[0];
                        var notaryConfig = res[1];

                        return resource.create({
                            status: 'created',
                            sessionId: sessionId,
                            notaryAppHost: notaryConfig.link
                        });
                    });
            };
        }
    ]);
})(angular.module('onfileApp'));
