(function (app) {
    app.directive('returnDate', [
        'ReturnDateResource', 'RequestsService', 'SiteConfigurationService',
        'utils', '$timeout', 'EnvironmentService',
        function (
            ReturnDateResource, RequestsService, SiteConfigurationService,
            utils, $timeout, EnvironmentService
        ) {
            return {
                strict: 'A',
                scope: {
                    case: '='
                },
                link: function ($) {
                    var timezone = EnvironmentService.defaultTimezone();

                    Object.assign($, {
                        isReturnDateVisible: false,
                        isReturnDateApplicable: true,
                        isReturnDateNeeded: true,
                        returnDateState: 0,
                        requestedReturnDateError: '',
                        isRequestDateDifferent: false,
                        oldReturnDate: null,
                        marketSystemName: null,
                        isDatePickerOpen: false,

                        calculateReturnDate: function calculateReturnDate() {
                            var request;
                            var requestData;
                            var isSubsequentFiling = $.case.id;

                            // For subsequent filing
                            if (isSubsequentFiling) {
                                var data = {
                                    courtCode: encodeURIComponent($.case.court_code),
                                    caseId: encodeURIComponent($.case.id),
                                    filingId: encodeURIComponent($.case.filings[0].filing_code.code),
                                    requestedDate: (
                                        $.case.requestedReturnDate && $.case.requestedReturnDate.toISOString()
                                    )
                                };

                                if ($.case.amountInControversy) {
                                    data.amountInControversy = encodeURIComponent($.case.amountInControversy);
                                }

                                request = ReturnDateResource.query(data).$promise;
                            } else {
                                requestData = Object.assign({}, $.case, {
                                    requestedReturnDate: (
                                        $.case.requestedReturnDate && $.case.requestedReturnDate.toISOString()
                                    )
                                });
                                // For initial case
                                request = ReturnDateResource.queryInitial(requestData).$promise;
                            }

                            var message = 'Getting Return Date from ' + $.marketSystemName + ' system';

                            $.requestedReturnDateError = '';
                            $.isReturnDateConfirmed = false;
                            $.isRequestDateDifferent = false;

                            return RequestsService.setMessageForRequest(request, message)
                                .then(function (res) {
                                    if (res && res.returnDate) {
                                        $.isReturnDateConfirmed = true;
                                        $.case.requestedReturnDate = utils.getDateForTimezone(res.returnDate, timezone);
                                    }

                                    if (res && res.error) {
                                        $.requestedReturnDateError = res.error;
                                    }

                                    if (res && !res.error && !res.returnDate) {
                                        $.isReturnDateNeeded = false;
                                        $.isReturnDateApplicable = false;
                                    }
                                });
                        },

                        switchReturnDateState: function switchReturnDateState(state) {
                            $.returnDateState = +state;

                            return $;
                        },

                        checkReturnDate: function checkReturnDate() {
                            $.oldReturnDate = utils.formatDateYear($.case.requestedReturnDate);

                            return $.calculateReturnDate()
                                .then(function () {
                                    var newDate = utils.formatDateYear($.case.requestedReturnDate);

                                    if ($.oldReturnDate !== newDate) {
                                        $.isRequestDateDifferent = true;
                                    }
                                });
                        },

                        changeIsReturnDateNeeded: function changeIsReturnDateNeeded(isNeeded) {
                            if ($.case === undefined) {
                                return $;
                            }

                            $.case.includesOutOfStateService = false;

                            if (isNeeded === false) {
                                $.requestedReturnDateError = '';
                                $.isReturnDateConfirmed = false;
                                $.isRequestDateDifferent = false;
                                $.case.requestedReturnDate = null;

                                return $;
                            }

                            return $;
                        },

                        activateDatePicker: function activateDatePicker() {
                            // @HACK for making date picker opened right after its container becomes visible in UI.
                            // Otherwise it's placed on top left corner of the screen
                            $timeout(function () {
                                $.isDatePickerOpen = true;
                            }, 0);

                            return $;
                        }
                    });

                    SiteConfigurationService.getStringAsync('eFileTexas')
                        .then(function (string) {
                            $.marketSystemName = string;
                        });



                    $.$watch('case.court', function (court) {
                        if (!court) {
                            return;
                        }

                        $.isReturnDateVisible = !!(
                            court && court.allowreturndate && utils.isTrue(court.allowreturndate)
                        );

                        if ($.isReturnDateVisible === false) {
                            $.isReturnDateNeeded = false;
                        }
                    });

                    $.$watch('isReturnDateNeeded', function (isReturnDateNeeded) {
                        if (isReturnDateNeeded !== undefined) {
                            $.changeIsReturnDateNeeded(isReturnDateNeeded);
                        }
                    });
                },

                templateUrl: '/assets/app/shared/directives/returnDate/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
