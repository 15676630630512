(function (app) {
    app.directive('caseStatusPopup', [
        'CaseService', '$state', '$rootScope',
        function (CaseService, $state, $rootScope) {
            return {
                strict: 'A',
                scope: {
                    caseErrors: '=?'
                },
                link: function ($) {
                    angular.extend($, {
                        forbidFiling: null,
                        errors: [],
                        isVisible: false,
                        messages: $rootScope.caseErrorMessages,

                        composeErrors: function (errors) {
                            var filteredErrors = errors.map(function (error) {
                                var standardizedError = $.messages
                                    .find(function (message) {
                                        return message.errorCode === error.ErrorCode;
                                    });

                                if (!standardizedError) {
                                    return {
                                        errorCode: error.ErrorCode,
                                        message: error.ErrorText,
                                        allowFiling: true
                                    };
                                }

                                return standardizedError;
                            });
                            $.forbidFiling = filteredErrors
                                .find(function (error) {
                                    return error.allowFiling === false;
                                });

                            return filteredErrors;
                        },
                        showPopup: function () {
                            $.isVisible = true;
                        },
                        closePopup: function () {
                            $.isVisible = false;
                            if ($.forbidFiling) {
                                $state.go('dashboard');
                            }

                            return true;
                        }
                    });

                    $rootScope.$on('CaseHasErrors', function (e, data) {
                        $.errors = $.composeErrors(data.errors);
                        $.showPopup();
                    });
                },
                templateUrl: '/assets/app/shared/directives/caseStatusPopup/case-status-popup.html'
            };
        }
    ]);
})(angular.module('onfileApp'));
