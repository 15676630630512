;(function (app) {
    app.service(
        'PermissionsService',
        [   'FirebaseService',
            'EnvironmentService',
            function (FirebaseService, EnvironmentService) {
                this.isAllowed = function (key) {
                    if (rules[key]) {
                        return rules[key]();
                    }

                    return false;
                };

                const rules = {
                    bates: function () {
                        const path =
                            '/whitelist/' +
                            EnvironmentService.firm_id() +
                            '/permissions/bates';
                        const userId = EnvironmentService.userId();

                        return FirebaseService.getNode(path)
                            .$loaded()
                            .then(function (e) {
                                const permission = e.$value;

                                if (typeof permission === 'string') {
                                    return permission.split(',').indexOf(userId) >= 0;
                                }

                                return permission;
                            });
                    }
                };
            }
    ]);
})(angular.module('onfileApp'));
