;(function (app) {
    app.controller('BatesSchemaCtrl', [
        '$scope', '$rootScope', '$q',
        'BatesSchemaResource', 'BatesModel', 'ToastrService',

        function (
            $, $rootScope, $q,
            BatesSchemaResource, BatesModel, ToastrService
        ) {
            angular.extend($, {
                model: BatesModel,
                schemaGroups: [],
                listOfValues: [],
                isDeletePopupVisible: false,
                showAdditionalSettings: false,
                continueSequence: true,
                lastSchemaSnapshot: '',
                linkToPdfPreview: null,
                showPreview: false,
                previewPdfConfig: {
                    zoom: 60,
                    toolbar: 1,
                    scrollbar: 0,
                    navpanes: 1,
                    pagemode: 'thumbs',
                    statusbar: 1
                },

                openDeletePopup: function () {
                    $.isDeletePopupVisible = true;
                },
                closeDeletePopup: function () {
                    $.isDeletePopupVisible = false;
                },
                changeSchema: function (schema) {
                    BatesModel.storage.schema = schema;
                },
                deleteSchema: function (schema) {
                    return BatesSchemaResource.delete(schema.$id)
                        .$promise
                        .then(function () {
                            $.closeDeletePopup();
                        })
                        .then(function () {
                            $.selectedSchema = angular.copy($.schemaGroups[0].schemas[0]);

                            return $.changeSchema($.selectedSchema);
                        })
                        .then(function () {
                            $.schemaGroups = removeSchemaFromList(schema);
                        })
                        .then(function () {
                            return ToastrService.showSuccess('Schema "' + schema.name + '" has been deleted');
                        });
                },
                switchStartNumber: function (continueSequence) {
                    const lastUsedBates = $.model.storage.schema.lastUsedBates;

                    if (continueSequence) {
                        $.model.storage.schema.startNumber = lastUsedBates.startNumber + 1;
                        $.model.storage.schema.prefix = lastUsedBates.prefix;
                        $.model.storage.schema.suffix = lastUsedBates.suffix;
                    }
                },
                previewBates: function () {
                    var previewObject;

                    $.showPreview = false;

                    // If schema was not changed use the same PDF result file
                    if ($.lastSchemaSnapshot === JSON.stringify(BatesModel.storage.schema)) {
                        $.showPreview = true;

                        return;
                    }

                    return BatesModel.getPreviewObject()
                        .then(function (preview) {
                            previewObject = preview;

                            if (preview.url) {
                                return BatesModel.deletePreview(previewObject);
                            }
                        })
                        .then(function () {
                            $rootScope.loading_message = 'Applying labels';

                            return BatesModel.applyBatesToCustomFile(BatesModel.storage.schema)
                                .finally(function () {
                                    $rootScope.loading_message = '';
                                });
                        })
                        .then(function (e) {
                            $.linkToPdfPreview = e.data.url;
                            $.showPreview = true;
                            $.lastSchemaSnapshot = JSON.stringify(BatesModel.storage.schema);

                            previewObject.url = e.data.url;

                            return previewObject.$save();
                        });
                },
                getName: function (prop) {
                    const propInList = prop + 's';

                    if (
                        $.listOfValues === undefined ||
                        $.listOfValues[propInList] === undefined ||
                        $.model.storage.schema === undefined ||
                        $.model.storage.schema[prop] === undefined
                    ) {
                        return;
                    }

                    return $.listOfValues[propInList]
                        .find(function (item) {
                            return item.value === $.model.storage.schema[prop];
                        })
                        .name;
                }
            });

            const removeSchemaFromList = function (schemaToDelete) {
                return $.schemaGroups.map(function (group) {
                    return Object.assign({}, group, {
                        schemas: group.schemas.filter(function (schema) {
                            return schema.id !== schemaToDelete.id;
                        })
                    })
                });
            };

            const promises = [
                $.model.loaded(),

                BatesSchemaResource.query()
                    .$promise
                    .then(function (schemaGroups) {
                        $.schemaGroups = schemaGroups;
                    }),

                BatesSchemaResource.listOfValues()
                    .$promise
                    .then(function (listOfValues) {
                        $.listOfValues = listOfValues;
                    })
            ];

            $q.all(promises)
                .then(function (promises) {
                    // If a package doesn't have a selected schema - select a first one for it
                    if (promises[0].schema === undefined) {
                        BatesModel.storage.schema = $.schemaGroups[0].schemas[0];
                    }

                    $.selectedSchema = angular.copy(BatesModel.storage.schema);
                });
        }
    ]);
})(angular.module('onfileApp'));