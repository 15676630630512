(function (app) {
  app.controller('IndexEServiceCtrl', [
    '$rootScope','RefreshDataService',
    '$interval','$scope',
    function ($rootScope,RefreshDataService, $interval, $) {
      $rootScope.showList = true;

      $.onBoarded = null;

      const intervalId = $interval(function () {
        RefreshDataService.isOnBoarded()
            .then(function (isOnBoarded) {
              $.onBoarded = !!isOnBoarded;

              console.log($.onBoarded);

              if ($.onBoarded === true) {
                $interval.cancel(intervalId);
              }
            })
      }, 500);
    }
  ]);
})(angular.module('onfileApp'));