(function (app) {
    app.factory('DraftService', [
        'FirebaseService',
        '$q',

        function DraftService(
            FirebaseService,
            $q
        ) {
            var _this = this;
            _this.channels = new Object();

            this.draftSubmitted = function(item, type){
                var channel  = getChannel(type);
                var savePromise = save(item, channel);

                $q.when(savePromise, function(){
                //after saving the draft.
                    var submittedPromise = save(item,(type + '_submitted'));
                    $q.when(submittedPromise, function(value){
                        remove(channel);
                        console.log('draft submitted & deleted.');
                    });
                });
            };

            this.saveDraft = function(item, type){
                return save(item, getChannel(type));
            };

            function remove(channel){
                var reference = _this.channels[channel];
                if(reference)
                    reference.remove();
            }

            function getChannel(type){
                return type + '_drafts';
            }

            function save(item, channel)
            {
                var draft = new Object();
                angular.extend(draft, item);

                //firebase restriction + serialization issues...
                //will add more once i encounter
                //need to find a generic way
                //replace anything that starts with $;
                //hack removing $valid from parties

                $.each(draft.parties, function(index,item){
                    delete item.$valid;
                    delete item.$index;
                });

                //remove unwanted nodes...
                draft = angular.fromJson(angular.toJson(draft));
                var reference = _this.channels[channel];

                if(reference) {
                    var deferred = $q.defer();
                    $q.when(FirebaseService.updateReference(reference, draft), function(value){
                        deferred.resolve(value);
                    });

                    return deferred.promise;
                }
                else // first time saving
                {
                    if(channel === '' || channel === undefined) {
                        console.log('channel not provided');
                        return null;
                    }

                    var referencePromise = FirebaseService.insertToUserIdChannel(channel,draft);
                    $q.when(referencePromise, function(value){
                        _this.channels[channel] = value;
                    });

                    return referencePromise;
                }
            }

            return this;
        }
    ]);
})(angular.module('onfileApp'));