;(function (app) {
    app.directive('idlePopup', [
        '$state', '$rootScope', '$window',
        'idleSecondsTimeout', 'autoLogoutSecondsTimeout', '$interval',

        function ($state, $rootScope, $window,
                  idleSecondsTimeout, autoLogoutSecondsTimeout, $interval) {
            return {
                strict: 'A',
                scope: {},
                link: function ($) {
                    angular.extend($, {
                        status: false,

                        closePopup: function () {
                            saveLastActionTimestamp();
                            $.status = false;
                        }
                    });

                    const storage = $window.sessionStorage;
                    const key = 'lastActionTimestamp';

                    const saveLastActionTimestamp = function () {
                        storage.setItem(key, +(new Date()));
                    };

                    const isTimeOut = function (range) {
                        const lastActionTimestamp = +storage.getItem(key) || +(new Date());
                        const currentTimestamp = +(new Date());

                        return (currentTimestamp - lastActionTimestamp) > (range * 1000);
                    };

                    const showPopup = function () {
                        if ($.status === false) {
                            $.status = true;
                        }
                    };

                    const logout = function () {
                        if ($.status === true) {
                            console.log('logging out');
                            $window.location.href = '/auth/logout';
                        }
                    };

                    const watch = function () {
                        saveLastActionTimestamp();

                        $rootScope.$on('$locationChangeStart', function () {
                            saveLastActionTimestamp();
                        });

                        $interval(function () {
                            if ( isTimeOut(autoLogoutSecondsTimeout) ) {
                                logout();
                            } else if ( isTimeOut(idleSecondsTimeout) ) {
                                showPopup();
                            }
                        }, 60000); //check every minute.
                    };

                    watch();
                },
                templateUrl: '/assets/app/shared/directives/IdlePopup/template.html'
            };
        }
    ]);
})(angular.module('onfileApp'));
