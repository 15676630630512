(function (app) {
    app.value('reloadOnEmptyRouterHelper', function ($state) {
        return function (changedParams) {
            var allPropsEmpty = true;

            if (typeof changedParams !== 'object') {
                return;
            }

            for (var prop in changedParams) {
                if (changedParams.hasOwnProperty(prop) === false) {
                    continue;
                }

                if (changedParams.prop !== undefined) {
                    allPropsEmpty = false;
                    break;
                }
            }

            if (allPropsEmpty) {
                $state.reload();
            }
        };
    });
})(angular.module('onfileApp'));