;(function (app) {
    app.controller('BatesDownloadCtrl', [
        '$scope', '$window', '$filter',
        '$rootScope', '$state', 'BatesModel',
        'DownloadZipService', 'CsvExportService', 'BatesSchemaResource',
        '$q', 'ToastrService', '$timeout',

        function (
            $, $window, $filter,
            $rootScope, $state, BatesModel,
            DownloadZipService, CsvExportService, BatesSchemaResource,
            $q, ToastrService, $timeout
        ) {
            angular.extend($, {
                limit: 25,
                isPopupVisible: false,
                batesModel: BatesModel,
                newSchemaName: '',
                previewPdfConfig: {
                    zoom: 60,
                    toolbar: 1,
                    scrollbar: 0,
                    navpanes: 1,
                    pagemode: 'thumbs',
                    statusbar: 1
                },

                closePackage: function (options) {
                    var reload = options && options.hasOwnProperty('reload') ? options.reload : true;
                    var showMessage = options && options.hasOwnProperty('showMessage') ? options.showMessage : true;

                    return BatesModel
                        .setClosedStatus()
                        .then(function () {
                            var name = $.newSchemaName.trim();

                            // If one of the User's schemas is selected
                            if ($.batesModel.storage.schema.default === false) {
                                return updateUserSchema($.batesModel.storage.schema, $.batesModel.storage.files);
                            } else if (name !== '') {
                                // If one of the default schemas is selected and a User wants to save it to collection
                                return createNewUserSchema(name, $.batesModel.storage.files);
                            }
                        })
                        .then(function () {
                            return BatesModel.deleteFilesFromFilestack();
                        })
                        .then(function () {
                            return BatesModel.getPreviewObject()
                                .then(function (deletePreview) {
                                    if (deletePreview && deletePreview.url) {
                                        return BatesModel.deletePreview(deletePreview);
                                    }
                                });
                        })
                        .finally(function () {
                            if (showMessage) {
                                ToastrService.showMessageByKey('settings_saved');
                            }

                            $.batesModel.unwatch();
                            $.batesModel.storage = {};

                            if (reload) {
                                $window.location.reload();
                            }
                        });

                },
                closeConfirmPopup: function () {
                    return $.closePackage({ reload: false, showMessage: false })
                        .then(function () {
                            ToastrService.showMessageByKey('settings_saved');
                            $state.go('case');

                            $timeout(function () {
                                $window.location.reload();
                            }, 1000);
                        });
                },
                showConfirmPopup: function () {
                    $.isPopupVisible = true;
                },
                downloadZip: function () {
                    var links = BatesModel.getBatesLinks();

                    $rootScope.loading_message = 'Downloading labeled files';

                    DownloadZipService.remoteFilesToZip(links)
                        .then($.showConfirmPopup)
                        .then(function () {
                            BatesModel.storage.downloaded = true;

                            return BatesModel.storage.$save();
                        })
                        .finally(function () { return $rootScope.loading_message = ''; });
                },
                downloadIndexFile: function () {
                    var sizeFieldMutator = {
                        size: function (item) {
                            return $filter('bytes')(item);
                        }
                    };

                    return CsvExportService.exportAndDownload({
                        items: BatesModel.storage.files,
                        titles: ['Filename', 'Bates number', 'File size'],
                        fields: ['output_filename', 'bates_number', sizeFieldMutator],
                        filename: 'Labeled_file_index.csv'
                    });
                },
                deleteAllFiles: function () {
                    var promise;
                    var deleteByIndex = function (index) {
                        return $.batesModel.deleteFileFromFilestack(index)
                            .then(function () { $.batesModel.storage.files.splice(index, 1); });
                    };

                    $rootScope.showSpinner('Deleting all files');
                    $.batesModel.storage.files.map(function () {
                        promise = promise === undefined ?
                            deleteByIndex(0) :
                            promise.then(function () {
                                return deleteByIndex(0);
                            });
                    });

                    return (promise ? promise : $q.resolve())
                        .then(function () {
                            ToastrService.showMessageByKey('labeled_files_deleted');
                            $rootScope.batesToStep(0);
                        })
                        .finally(function () {
                            $rootScope.hideSpinner();
                        });
                }
            });

            const updateUserSchema = function (schema, files) {
                return BatesSchemaResource.update(
                    schema.id,
                    Object.assign({}, schema, {
                        startNumber: getStartNumberForNextRun(),
                        lastUsedBates: getLastUsedBates()
                    })
                ).$promise;
            };

            const createNewUserSchema = function (newSchemaName, files) {
                const newSchema = Object.assign(
                    {},
                    BatesModel.storage.schema,
                    {
                        id: null,
                        name: newSchemaName,
                        startNumber: getStartNumberForNextRun(),
                        lastUsedBates: getLastUsedBates()
                    }
                );

                return BatesSchemaResource.save(newSchema).$promise;
            };

            const getLastUsedBates = function () {
                return {
                    startNumber: parseInt(BatesModel.lastBatesApplied()),
                    prefix: BatesModel.storage.schema.prefix,
                    suffix: BatesModel.storage.schema.suffix,
                    numberOfDigits: BatesModel.storage.schema.numberOfDigits
                };
            };

            const getStartNumberForNextRun  = function(){
               return  BatesModel.lastBatesApplied() + 1;
            }
        }
    ]);
})(angular.module('onfileApp'));
