(function (app) {
    app.factory('notaryTokenUtil', [
        'EnvironmentService',
        function (EnvironmentService) {
            var notaryApiHost = EnvironmentService.notaryApiBaseUrl();

            var getToken = function () {
                return localStorage.getItem('notaryAppToken');
            };

            var checkToken = function (resource, $q) {
                var token = getToken();

                if (!token) {
                    if (EnvironmentService.isProduction()) {
                        return resource.token().$promise
                            .then(function (data) {
                                localStorage.setItem('notaryAppToken', data.token);
                            });
                    } else {
                        localStorage.setItem('notaryAppToken', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJmaXJtSWQiOm51bGwsInVzZXJJZCI6bnVsbH0.ogYfGI7VGamBf8Y521EzT8UaWCA5aFou7XZIVCSxhyxsh9Emv9MFjfgjdo0Mi0hCDumgsyujPmpYA7K472X2GA');
                    }
                }

                return $q.resolve();
            };

            var addAuthorizationToken = function (headers) {
                return Object.assign({
                    Authorization: 'Bearer ' + getToken()
                }, headers);
            };

            return {
                checkToken: checkToken,
                notaryApiHost: notaryApiHost,
                addAuthorizationToken: addAuthorizationToken
            };
        }
    ]);
})(angular.module('onfileApp'));
