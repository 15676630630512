(function (app) {
    app.directive('signature', [
        '$timeout', '$mdMenu', 
        'ToastrService', 'Upload',

        function (
            $timeout, $mdMenu, ToastrService, Upload
        ) {
            return {
                scope: {
                    signatures: '=',
                    attorneyName: '=name',
                },
                link: function ($) {
                    var uploadSignatureInputSelector = '#uploadSignature';

                    $.selectedSignature = null;
                    $.printedSignature = null;

                    $.selectSignature = function (signature) {
                        $.printedSignature = null;
                        $.selectedSignature = signature;
                    };
                        
                    $.triggerUploadSignature = function () {                    
                        $timeout(function () {
                            angular.element(uploadSignatureInputSelector).trigger('click');
                            
                            angular.element(uploadSignatureInputSelector).on('change', function (event) {
                                $.onSignatureSelected(event, $);
                            });
                            
                        }, 0);
                    };
                    
                    $.insertDigitalSignature = function () {
                        if (!$.attorneyName) {
                            ToastrService.showMessageByKey('select_filing_attorney');
                         
                            return;
                        }
                    
                        $.printedSignature = null;
                        $.selectedSignature = null;
                        $.printedSignature = '/s/ ' + $.attorneyName;
                    };
                        
                    $.uploadNewSignature = function (Upload, event, $scope) {
                        var uploadData = {
                            url: '/signatures/create',
                            data: {
                                file: event.target.files[0]
                            },
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest'
                            }};
                        
                        return Upload.upload(uploadData)
                            .then(function (response) {
                                var uploadedSignature = response.data;
                                var existingSignature = $.signatures
                                    .find(function (signature) { return signature.id === uploadedSignature.id; });

                                if (!existingSignature) {
                                    $.signatures.push(uploadedSignature);

                                    return uploadedSignature;
                                }
                    
                                // Update existing signature with server data
                                Object.keys(existingSignature).map(function (prop) {
                                    existingSignature[prop] = uploadedSignature[prop];
                                });
                    
                                return existingSignature;
                            });
                    };

                    $.onSignatureSelected = function (event, $scope) {
                        return $scope.uploadNewSignature(Upload, event, $)
                            .then(function (newSignature) {
                                $.selectedSignature = newSignature;
                            });
                    };
                },
                templateUrl: '/assets/app/shared/directives/signature/template.html?' + '{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
