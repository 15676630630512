(function (app) {
    app.service('NotifierService', [
        '$firebaseArray', '$firebaseObject',
        'FirebaseService', 'ToastrService', 'AccessLogItemService', '$q','$state',

        function ($firebaseArray, $firebaseObject,
                  FirebaseService, ToastrService, AccessLogItemService, $q, $state) {
            const toasterTimeout = 10000;

            const getNewLogins = function (accessLog, startOfWatchTimestamp) {
                var newAccesses = [];

                try {
                    for (var emailHash in accessLog) {
                        if (accessLog.hasOwnProperty(emailHash) === false) {
                            continue;
                        }

                        for (var i = 0; i < accessLog[emailHash].length; i++) {
                            if (
                                accessLog[emailHash][i].timestamp && accessLog[emailHash][i].timestamp >= startOfWatchTimestamp &&
                                accessLog[emailHash][i].type && accessLog[emailHash][i].type === AccessLogItemService.TYPE_LOGIN
                            ) {
                                newAccesses.push(accessLog[emailHash][i]);
                            }
                        }
                    }
                } catch (e) { /* Ignore */
                }

                return newAccesses;
            };

            const notifyOfNewAccesses = function (newAccesses, ePackage) {
                newAccesses.map(function (log) {
                    const message = (log.email || 'Someone') + ' just logged into "' +
                        ePackage.batch_header.display_name + '" file download page. Click to view the eProduction package.';

                    ToastrService.showSuccess(message,
                        {
                            timeOut: toasterTimeout,
                            onTap : function(){
                                $state.go("eProductionSubmittedView" , {id : ePackage.$id});
                            }
                        }
                    );
                });
            };

            // const getZeroGtmTimestamp = function () {
            //   const startOfWatch = new Date();
            //   return (+(startOfWatch) + startOfWatch.getTimezoneOffset() * 1000);
            // };


            const getZeroGtmTimestamp = function () {
                var d = new Date();
                var n = d.getTime();
                return n;
            };

            // const getZeroGtmTimestamp = function () {
            //     return Date.parse(
            //         new Date((new Date()).getTime() + (new Date()).getTimezoneOffset() * 60000));
            // };

            this.watchOnGuestLogin = function () {
                var startOfWatchTimestamp = getZeroGtmTimestamp();
                const service = $firebaseArray.$extend({
                    $$updated: function (snap) {
                        const updated = this.$getRecord(snap.key);
                        //
                        var ref = FirebaseService.getfirmIdChannel('/eproduction/packages', snap.key, true);
                        $q.when(ref, function (p) {
                            var node = $firebaseObject(p);
                            node.$loaded().then(function (pkg) {
                                const newAccesses = getNewLogins(pkg.access_log, startOfWatchTimestamp);
                                if (newAccesses.length) {
                                    notifyOfNewAccesses(newAccesses, updated);
                                }
                                ;

                                //not to repeat already shown logs.
                                startOfWatchTimestamp = getZeroGtmTimestamp();
                            });
                        });

                        return true;
                    }
                });

                // @TODO remove specifying the path to some other place
                FirebaseService.getfirmIdChannel('/eproduction/packages', null, true)
                    .then(service);
            };
        }
    ]);
})(angular.module('onfileApp'));