(function (app) {
    app.factory('ReturnDateResource', [
        '$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/return-date', {}, {
                query: {
                    method: 'GET',
                    cache: false,
                    isArray: false,
                    cancellable: true
                },
                queryInitial: urlEncodedResource({
                    url: '/return-date/initial',
                    method: 'POST',
                    cache: false,
                    isArray: false,
                    cancellable: true
                })
            });
        }
    ]);
})(angular.module('onfileApp'));