(function (app) {
    app.filter('oneOf', function oneOf() {
        return function (array, search) {
            var result = [];

            if (!array || !array.hasOwnProperty('length')) {
                return result;
            }

            var searchKeys = Object.keys(search);

            for (var i = 0; i < array.length; i++) {
                var isNeeded = false;

                for (var j = 0; j < searchKeys.length; j++) {
                    for (var k = 0; k < search[searchKeys[j]].length; k++) {
                        if (array[i][searchKeys[j]] === search[searchKeys[j]][k]) {
                            isNeeded = true;
                            break;
                        }
                    }

                    if (isNeeded) {
                        break;
                    }
                }

                if (isNeeded) {
                    result.push(array[i]);
                }
            }

            return result;
        };
    });
})(angular.module('onfileApp'));
