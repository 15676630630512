(function (app) {
    app.constant('NotaryRecordUpdatedEvent', 'NotaryRecordUpdated');

    app.directive('notaryRecord', [
        'utils', 'NotaryService', '$rootScope',
        'NotaryRecordUpdatedEvent', 'NotaryResourceFactory', 'EnvironmentService',
        function (
            utils, NotaryService, $rootScope,
            NotaryRecordUpdatedEvent, NotaryResourceFactory, EnvironmentService
        ) {
            return {
                strict: 'A',
                scope: {
                    item: '=?'
                },
                link: function ($) {
                    $.formName = 'notaryRecord' + $.$id;
                    $.recordingUrl = null;
                    $.isLinkLoading = false;

                    $.cancel = function () {
                        $.editableItem = null;
                        $.editMode = false;
                    };
                    $.cancel();

                    $.edit = function () {
                        $.editableItem = utils.clone($.item);
                        $.editMode = true;
                    };

                    $.save = function () {
                        var data = {
                            id: $.editableItem.id,
                            signerName: $.editableItem.signerName,
                            signerAddress: $.editableItem.signerAddress,
                            documentName: $.editableItem.documentName,
                            documentDate: $.editableItem.documentDate,
                            documentType: $.editableItem.documentType,
                            idEvidence: $.editableItem.idEvidence,
                            fee: $.editableItem.fee,
                            notes: $.editableItem.notes,
                            idExpiration: $.editableItem.idExpiration
                        };

                        return NotaryService.updateNotaryRecords(data)
                            .then(function (data) {
                                $rootScope.$broadcast(NotaryRecordUpdatedEvent, { data: data });
                                $.cancel();
                            });
                    };

                    $.isFormValid = function () {
                        return $[$.formName].$valid;
                    };

                    $.redirectToRecordingPage = function () {
                        $.isLinkLoading = true;

                        return NotaryResourceFactory.createSessionsResource({
                            officialId: EnvironmentService.userId(),
                            firmId: EnvironmentService.firm_id()
                        }).then(function (resource) {
                            return resource.getRecordingUrl({ sessionId: $.item.sessionId }).$promise;
                        }).then(function (data) {
                            $.isLinkLoading = false;

                            var url = data.url;

                            if (!url) {
                                console.error('Error during downloading recording', url);

                                return alert('Error during downloading recording');
                            }

                            $.recordingUrl = url;
                        }).catch(function () {
                            $.isLinkLoading = false;
                        });
                    };
                },
                templateUrl: '/assets/app/shared/directives/notaryRecord/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
