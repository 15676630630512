(function (app) {
    app.controller('DocumentRequestCtrl', [
        '$scope', '$mdDialog', 'NotaryRequestDocumentsResourceFactory',
        'S3FileUploader', '$q', '$state',
        '$rootScope',
        function (
            $, $mdDialog, NotaryRequestDocumentsResourceFactory,
            S3FileUploader, $q, $state,
            $rootScope
        ) {
            var getResource = NotaryRequestDocumentsResourceFactory.create();
            var requestId = +new Date() + '.' + Math.ceil(Math.random() * 1000);

            $.created = false;
            $.createdId = null;
            $.model = {};
            $.files = [];

            $.close = function () { $mdDialog.hide(); };

            $.submit = function () {
                return getResource.then(function (resource) {
                    var recipients = [];
                    var documents = $.files.map(function (f) {
                        return ['filename', 'key', 'displayedName']
                            .reduce(function (all, prop) {
                                all[prop] = f[prop];

                                return all;
                            }, {});
                    });

                    if ($.model.recipients) {
                        recipients = Object.keys($.model.recipients)
                            .filter(function (key) {
                                return $.model.recipients[key];
                            })
                            .map(function (key) {
                                return $.model.recipients[key];
                            });
                    }

                    var requestData = Object.assign({}, $.model, {
                        id: requestId,
                        documents: documents,
                        recipients: recipients
                    });

                    return resource.create(requestData).$promise;
                }).then(function (res) {
                    $.created = true;
                    $.createdId = res.id;

                    $rootScope.$emit('newRequestAdded');
                });
            };

            $.filesSelected = function (files) {
                return getResource.then(function (resource) {
                    var fileUploads = files.map(function (file) {
                        return resource.uploadUrl({ requestId: requestId, filename: file.name })
                            .$promise
                            .then(function (res) {
                                return S3FileUploader.uploadByPreSignUrl(res.url, file)
                                    .then(function () {
                                        return Object.assign({}, file, res);
                                    });
                            });
                    });

                    return $q.all(fileUploads);
                }).then(function (files) {
                    var newFiles = files.map(function (file) {
                        file.displayedName = file.filename;

                        return file;
                    });

                    $.files = $.files.concat(newFiles);
                });
            };

            $.removeFile = function (i) {
                $.files.splice(i, 1);
            };

            $.viewCreated = function () {
                $state.transitionTo('oneNotaryRequest', { id: $.createdId });
                $.close();
            };
        }
    ]);
})(angular.module('onfileApp'));