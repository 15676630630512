(function (app) {
    app.factory('FilingDocumentsResource', ['$resource',
        function ($resource) {
            return $resource('/filing-documents/:id', {id: '@id'}, {
                query: {
                    method: 'GET',
                    isArray: false,
                    cache: false
                },
                synchronize: {
                    url: '/filing-documents/synchronize',
                    method: 'GET',
                    isArray: false,
                    cache: false
                }
            });
        }]);
})(angular.module('onfileApp'));
