(function (app) {
    app.factory('LoginTokenResource', [
        '$resource', 'urlEncodedResource',

        function ($resource, urlEncodedResource) {
            return $resource('/token/generate', {token : '@token'}, {
                generate: urlEncodedResource({
                    method: 'POST',
                    isArray: false,
                    cache: false
                }),
                verify: {
                    url: '/token/:token/verify',
                    method: 'GET',
                    isArray: false,
                    cache: false
                }
            });
        }
    ]);
})(angular.module('onfileApp'));
