(function (app) {
    app.directive('notaryFilesButton', [
        function () {
            return {
                link: function ($) {
                    $.templateItems = [
                        { name: 'Notice of intention - Central Spinal.pdf' },
                        { name: 'Notice of intention - Children\'s Medical Center.pdf' },
                        { name: 'Notice of Intention - Parker Radiology.pdf' }
                    ];
                },
                templateUrl: '/assets/app/shared/controllers/notary/button/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
