(function (app) {
    app.directive('informationPopup', [
        'filingTips', '$mdDialog',

        function (filingTips, $mdDialog) {
            return {
                strict: 'A',
                scope: {
                    field: '@',
                    iconStyle: '=?'
                },
                link: function ($) {
                    var hideDialog = function hideDialog() {
                        $mdDialog.hide();
                    };

                    $.showDialog = function () {
                        $mdDialog.show({
                            controller: ['$scope', function ($scope) {
                                $scope.title = $.field;
                                $scope.content = filingTips[$.field] && filingTips[$.field].text;

                                $scope.close = function () {
                                    hideDialog();
                                };
                            }],
                            templateUrl: '/assets/app/shared/directives/information-popup/dialog-template.html?' +
                                '{{onfileApplicationVersion}}',
                            parent: angular.element(document.body),
                            clickOutsideToClose: true
                        });
                    };
                },
                templateUrl: '/assets/app/shared/directives/information-popup/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
