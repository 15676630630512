(function (app) {
    app.controller('NotaryRequestSessionsListCtrl', [
        '$q', '$rootScope', 'DocumentGroupsResourceFactory',
        '$scope', 'EnvironmentService', 'utils',
        '$timeout',
        function (
            $q, $rootScope, DocumentGroupsResourceFactory,
            $, EnvironmentService, utils,
            $timeout
        ) {
            var initialLoadFinished = false;
            var deferred = $q.defer();
            var validBefore = 2 * 3600 * 1000;

            $rootScope.items = [];
            $rootScope.itemsLoaded = deferred.promise;

            $.toSystemTime = EnvironmentService.toSystemTime;

            var sortByRequestedTime = function (a, b) {
                if (a.requestedDatetimeDate && b.requestedDatetimeDate) {
                    return utils.compare(a.requestedDatetimeDate, b.requestedDatetimeDate);
                }

                if (a.requestedDatetimeDate) {
                    return -1;
                }

                if (b.requestedDatetimeDate) {
                    return 1;
                }

                return utils.compare(a.id, b.id);
            };

            $rootScope.loadSessionRequests = function () {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        if (initialLoadFinished) {
                            return resource.querySilent({ type: 'notaryRequest' }).$promise;
                        }

                        return resource.query({ type: 'notaryRequest' }).$promise;
                    })
                    .then(function (res) {
                        $rootScope.items = res.items
                            .map(function (item) {
                                if (item.requestedDatetime) {
                                    item.requestedDatetimeDate = new Date(item.requestedDatetime);
                                    item.isExpired = (item.requestedDatetimeDate - new Date()) < validBefore;
                                }

                                return item;
                            })
                            .sort(sortByRequestedTime);


                        if (!initialLoadFinished) {
                            deferred.resolve($rootScope.items);
                        }
                    })
                    .finally(function () {
                        initialLoadFinished = true;

                        $timeout(function () {
                            $rootScope.loadSessionRequests();
                        }, 5e3);
                    });
            };

            $rootScope.loadSessionRequests();
        }
    ]);
})(angular.module('onfileApp'));
