(function (app) {
    app.controller('AuthCtrl', [
        '$scope', '$rootScope', '$state',
        'AuthResource', 'FirebaseService', 'FirebaseTokenResource',
        'EProductionHelper', 'AccessLogItemService', 'IpResource',
        'SiteConfigurationService', 'utils', 'messages',

        function (
            $, $rootScope, $state,
            AuthResource, FirebaseService, FirebaseTokenResource,
            EProductionHelper, AccessLogItemService, IpResource,
            SiteConfigurationService, utils, messages
        ) {
            $rootScope.showNavigation = false;
            $rootScope.showWorkspace = false;

            angular.extend($, {
                error: null,
                loading: false,
                senderName: null,
                packageExists: null,
                pdfConfig: {
                    zoom: 110,
                    toolbar: 0,
                    navpanes: 0,
                    scrollbar: 0
                },
                supportPhone: '',
                supportEmail: ''
            });

            // Parse firmId and packageId params from current URI
            var firmId = EProductionHelper.getFirmId(window.location.pathname);
            var packageId = EProductionHelper.getPackageId(window.location.pathname);
            var header;
            var ePackageNode;

            function goToFiles() {
                return window.location.href = '/#/files/index';
            }

            EProductionHelper.configureFirebaseService(FirebaseService, {
                firmId: firmId,
                packageId: packageId,
                email: $.emailAddress === undefined || $.emailAddress === null ? 'anonymous@onfilesystems.com' : $.emailAddress
            });

            var loadPackage = function () {
                FirebaseService.auth({force: true})
                    .then(function () {
                        FirebaseService
                            .getEProductionGuestHeaderNode(packageId)
                            .$loaded()
                            .then(function (item) {
                                header = item;
                                $.senderName = item.sender_name;
                            });
                    });
            };

            loadPackage();

            var validateAccess = function (email) {
                if (header.hasOwnProperty('service_contacts') && header.service_contacts) {
                    for (var i = 0; i < header.service_contacts.length; i++) {
                        if (header.service_contacts[i].email === email) {
                            return true;
                        }
                    }
                    return false;
                }
            };

            $.auth = function (email) {
                email = email && email.toLowerCase();
                $.loading = true;

                EProductionHelper.configureFirebaseService(FirebaseService, {
                    firmId: firmId,
                    packageId: packageId,
                    email: email
                });

                // this will validate the email address in the header node;
                if (!validateAccess(email)) {
                    $.error = utils.replace(messages.forbiddenEmail, { email: email });
                    $.loading = false;

                    return;
                }

                // comes here if validated.
                FirebaseService.auth()
                    .then(function () {
                        FirebaseService
                            .getEProductionNode(firmId, packageId)
                            .$loaded()
                            .then(function (item) {
                                console.log(item);
                                var isExpired = ( item.batch_header.submitted_at + item.batch_header.expire_time * 24 * 60 * 60 * 1000 - (new Date).getTime()) < 0;

                                if (isExpired)
                                    throw new Error('This package has expired.');

                                var isSubmitted = item.batch_header.status === 'submitted';

                                if (!isSubmitted)
                                    throw new Error('The eProduction package is not yet available.');

                                return item;
                            })
                            .then(function (item) {
                                ePackageNode = item;
                                AccessLogItemService.init({
                                    type: AccessLogItemService.TYPE_LOGIN,
                                    emailHash: FirebaseService.emailHash,
                                    email: FirebaseService.email
                                });

                                return IpResource.get().$promise;
                            })
                            .then(function (ipResponse) {
                                return AccessLogItemService.init({
                                    ip: ipResponse.ip
                                });
                            })
                            .then(function () {
                                return AccessLogItemService.insertToLog(ePackageNode).$save();
                            })
                            .then(function () {
                                // Sets auth information into local storage
                                EProductionHelper.setAuth({
                                    email: FirebaseService.email,
                                    token: FirebaseService.token,
                                    firmId: FirebaseService.firmId,
                                    packageId: FirebaseService.packageId,
                                    emailHash: FirebaseService.emailHash
                                });

                                goToFiles();
                            })
                            .catch(function (e) {
                                $.error = e.message;
                            })
                            .finally(function () {
                                $.loading = false;
                            });
                    });
            };

            SiteConfigurationService.getConfigurationForPage('guestDownload')
                .then(function (data) {
                    $.supportPhone = data.supportPhone;
                    $.supportEmail = data.supportEmail;
                });
        }
    ]);
})(angular.module('onfileApp'));
