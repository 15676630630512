;(function (app) {
    app.filter('expiringReminder', [
        'moment',
        function (moment) {
            return function (reminders) {
                const tomorrowDate = moment().add(1, 'day').startOf('day');
                const isTomorrow = function (date) {
                    return tomorrowDate.isSame(date, 'd') === true;
                };

                var result = [];

                if (!reminders || !reminders.hasOwnProperty('length'))
                    return result;

                for (var i = 0, item; i < reminders.length; i++) {
                    item = reminders[i];

                    if (
                        item.status === 'created' && isTomorrow(item.due_date_timestamp) &&
                        (item.dismissed === undefined || item.dismissed === false)
                    ) {
                        result.push(item);
                    }
                }

                return result;
            }
        }
    ]);
})(angular.module('onfileApp'));
