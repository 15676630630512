(function (app) {
    app.directive('nativePreviewPdf', [
        'SecurityService', '$sce', 'isIE',
        function (SecurityService, $sce, isIE) {
            return {
                strict: 'A',
                scope: {
                    link: '=?',
                    isActive: '=?',
                    linkMode: '=?',
                    linkClass: '=?',
                    pdfConfig: '=?',
                    disableSecurity: '=?',
                    proxy: '=?',
                    waitFor: '&',
                    linkText: '@linkText',
                    triggerProperty: '=?'
                },
                link: function ($) {
                    var pdfConfig = $.pdfConfig || {
                        zoom: 100,
                        toolbar: 0,
                        navpanes: 0,
                        scrollbar: 0
                    };
                    var composePdfConfig = function (config) {
                        var res = [];

                        for (var prop in config) {
                            res.push( prop + '=' + config[prop] );
                        }

                        return res.join('&');
                    };
                    var composeLink = function (link) {
                        var config = composePdfConfig(pdfConfig);
                        var resLink = $.proxy ? '/pdf?link=' + encodeURIComponent(link) : link;

                        return resLink + '#' + config;
                    };
                    var handlePromise = function (p) {
                        var f = p[0];
                        var args = p.slice(1);

                        return f.apply(this, args)
                            .then(function (d) {
                                var link = d && d.link ? d.link : d;

                                $.fullLink = composeLink(link);

                                return $.fullLink;
                            });
                    };

                    $.$watch('link', function (link) {
                        if (!link) {
                            $.fullLink = '';
                            return;
                        }

                        if ($.disableSecurity === true) {
                            $.fullLink = composeLink(link);
                            return;
                        }

                        SecurityService.addParamsToLink(link)
                            .then(function (url) {
                                $.fullLink = composeLink(url);
                            });
                    });

                    $.$watch('triggerProperty', function (trigger) {
                        if (trigger === true) {
                            $.changeState(trigger);
                        }
                    });


                    angular.merge($, {
                        isIE: isIE,
                        fullLink: '',
                        changeState: function (isActive) {
                            $.isActive = !!isActive;

                            if ($.isActive) {
                                $.triggerProperty = false;
                                var w = $.waitFor();
                                if (w && w.length) {
                                    return handlePromise(w);
                                }
                            }
                        }
                    });
                },
                templateUrl: '/assets/app/shared/directives/nativePreviewPdf/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
