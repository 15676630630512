(function (app) {
    app.controller('CreateFollowupRequestCtrl', [
        '$scope', '$mdDialog', '$rootScope',
        function ($, $mdDialog, $rootScope) {
            $.model = $rootScope.followupRequest || {};
            $.files = $rootScope.followupRequestFiles || [];

            $.close = function () {
                $mdDialog.hide();
            };

            $.submit = function () {
                $.close();
            };

            $.filesSelected = function (files) {
                var newFiles = files.map(function (file) {
                    file.displayedName = file.name;

                    return file;
                });

                $.files = $.files.concat(newFiles);
            };

            $.removeFile = function (i) {
                $.files.splice(i, 1);
            };
        }
    ]);
})(angular.module('onfileApp'));
