(function (app) {
    app.factory('FileResource', [
        '$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/files', {}, {
                delete: urlEncodedResource({
                    url: '/files/delete',
                    method: 'POST',
                    isArray: false
                })
            });
        }]);
})(angular.module('onfileApp'));