(function (app) {
    app.controller('NotaryRequestsListCtrl', [
        '$scope', '$rootScope', 'NOTARIZATION_REQUESTED',
        'NOTARIZATION_SCHEDULED', 'NOTARIZATION_COMPLETED', 'DocumentGroupsResourceFactory',
        '$q', 'EnvironmentService', 'SiteConfigurationService', 'NotaryRequestService',
        function (
            $, $rootScope, NOTARIZATION_REQUESTED,
            NOTARIZATION_SCHEDULED, NOTARIZATION_COMPLETED, DocumentGroupsResourceFactory,
            $q, EnvironmentService, SiteConfigurationService, NotaryRequestService
        ) {
            var deferred = $q.defer();

            $rootScope.items = [];
            $rootScope.itemsLoaded = deferred.promise;
            $rootScope.constants.NOTARIZATION_REQUESTED = NOTARIZATION_REQUESTED;
            $rootScope.constants.NOTARIZATION_SCHEDULED = NOTARIZATION_SCHEDULED;
            $rootScope.constants.NOTARIZATION_COMPLETED = NOTARIZATION_COMPLETED;

            var updateList = function (search) {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        if (search) {
                            return resource.search(search).$promise;
                        }

                        return resource.query().$promise;
                    })
                    .then(function (data) {
                        $rootScope.items = data.items;
                        deferred.resolve();
                    })
                    .catch(function (e) {
                        deferred.reject();

                        throw e;
                    });
            };

            var connectWsMessaging = function () {
                return SiteConfigurationService.getConfigurationForPage('notary')
                    .then(function (config) {
                        var wsBaseUrl = config.wsHost;

                        if (!wsBaseUrl) {
                            console.error('No wsHost configuration set');
                        }

                        var userId = EnvironmentService.userId();
                        var firmId = EnvironmentService.firm_id();
                        var ws = new WebSocket(wsBaseUrl);

                        ws.onopen = function () {
                            ws.send(JSON.stringify({
                                data: {
                                    userId: userId,
                                    firmId: firmId
                                }
                            }));
                        };

                        ws.onmessage = function (e) {
                            if (!$rootScope.items) {
                                return;
                            }

                            try {
                                var message = JSON.parse(e.data);
                                var event = message.event;

                                if (event !== 'requestEntityUpdated') {
                                    return;
                                }

                                NotaryRequestService.updateInList(message.data);
                            } catch (e) {
                                console.log(e);
                            }
                        };
                    });
            };

            $rootScope.$on('newRequestAdded', function () {
                updateList();
            });

            $rootScope.$on('requestSearchParametersUpdated', function (e, data) {
                updateList(data.search);
            });

            $rootScope.$on('updateAllMatters', function (e, data) {
                updateList().then(function () {
                    $rootScope.$emit('requestUpdated', { id: data.reloadMatterId });
                    $rootScope.$emit('updateAllMatters.finished');
                });
            });

            updateList();
            connectWsMessaging();
        }
    ]);
})(angular.module('onfileApp'));
