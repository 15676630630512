(function (app) {
    app.factory('FilingComponentCodeResource', ['$resource',
        function ($resource) {
            return $resource('/filingComponentCodes?court_code=:court_code', {court_code: '@court_code'}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: true
                }
            });
        }
    ]);
})(angular.module('onfileApp'));