(function (app) {
    app.controller('EServiceCtrl', [
        '$scope', '$stateParams', '$rootScope',
        '$cacheFactory', '$state', 'EServiceResource',
        'FilingService', 'CaseService', 'FirebaseService',
        'ToastrService', 'CourtResource', 'EnvironmentService',
        'CaseResource', 'EServiceService', 'DownloadZipService',
        'CaseNameService', 'DocumentLoadService', '$q',
        'SecurityService', 'FilingsMetaTransformer', 'PrintService',
        'utils', 'statusesAllowedForHearing', 'ScheduleHearingService',
        'FilingTransformer', 'customEvents', 'FilingDocumentService',
        'CaseService', 'RefreshDataService',

        function (
            $, $stateParams, $rootScope,
            $cacheFactory, $state, EServiceResource,
            FilingService, CaselastUpdatedTimestampService, FirebaseService,
            ToastrService, CourtResource, EnvironmentService,
            CaseResource, EServiceService, DownloadZipService,
            CaseNameService, DocumentLoadService, $q,
            SecurityService, FilingsMetaTransformer, PrintService,
            utils, statusesAllowedForHearing, ScheduleHearingService,
            FilingTransformer, customEvents, FilingDocumentService,
            CaseService, RefreshDataService
        ) {
            var id = $stateParams.id;
            var action = $stateParams.action;

            angular.extend($, {
                service: {},
                error: '',
                filingPartyName: '',
                isCaseAvailableForFiling: FilingService.isCaseAvailableForFiling,
                isCancelable: FilingService.isCancelable,
                filingService: FilingService,
                contact: {},
                isCreateReminderPopupVisible: false,
                filingsMetaTransformer: null,
                filingDetailsTransformer: null,
                isRejected: null,
                loadDocument: DocumentLoadService.loadDocument,
                scheduledHearing: null,

                setPopupVisible: function () {
                    $.isCreateReminderPopupVisible = true;
                },
                downloadAll: function () {
                    var links = [];

                    if (!$.service.documents) {
                        throw new Error('There is no documents for this service to download');
                    }

                    $rootScope.loading_message = 'Downloading file(s)';

                    return $.service
                        .documents
                        .reduce(function (promise, doc) {
                            return promise
                                .then(function () {
                                    var pr = [ DocumentLoadService.loadDocument(doc.original.link, $.service.id) ];

                                    if (doc.transmitted && doc.transmitted.link) {
                                        pr.push( DocumentLoadService.loadDocument(doc.transmitted.link, $.service.id) );
                                    }

                                    return $q.all(pr);
                                })
                                .then(function (docs) {
                                    return docs.map(function (doc) {
                                        var parts = doc.filename.split('.');
                                        var ext = parts.pop();
                                        var filename = parts.join('.');

                                        links.push({
                                            url: doc.link,
                                            name: filename,
                                            ext: ext
                                        });
                                    });
                                });
                        }, $q.resolve())

                        // Save ZIP
                        .then(function () {
                            return DownloadZipService.remoteFilesToZip(
                                links,
                                $.service.envelope_number + '_docs'
                            );
                        })
                        .finally(function () { $rootScope.loading_message = ''; });
                },
                printFees: function () {
                    var content = angular.element('#print').html();

                    return PrintService.print(content);
                },
                refresh: function (id) {
                    if (!id) {
                        throw new Error('No Filing ID provided');
                    }
                    if (!$.service.case_id) {
                        throw new Error('No Case ID provided');
                    }

                    CaseService.removeCaseById($.service.case_id);
                    FilingService.removeFilingById(id)
                        .then(function () {
                            this.uiOnParamsChanged({ id: id });
                        }.bind(this));
                    
                }.bind(this),
            });

            $rootScope.showList = true;
            $.eProdId;

            $.$watch('service', function (service) {
                if (service && service.hasOwnProperty('id')) {
                    if (service.hasOwnProperty('eProdId')) {
                        $.eProdiId = service.eProdId;
                    } else {
                        FirebaseService
                            .getNode('eproduction/submissions/' + EnvironmentService.firm_id() + '/' + service.id)
                            .$loaded()
                            .then(function (item) {
                                if (item && item.hasOwnProperty('eProdId')) {
                                    $.eProdId = item.eProdId;
                                }
                            });
                    }
                }
            });

            $.$watch('service.documents', function (documents) {
                FilingDocumentService.populateFilingDocumentComponentCodes(documents, $.service.complete);
            });

            // Callback for ui.router directive when state is changed.
            this.uiOnParamsChanged = function (changedParams) {
                var promise;

                if (changedParams && changedParams.id) {
                    $.error = '';
                    $.service = {};
                    flushFilingData();
                    promise = loadFromApi(changedParams);
                }

                if (promise && changedParams.action && changedParams.action === 'scheduleHearing') {
                    promise.then(function () {
                        $.scheduleHearing();
                    });
                }
            };

            function flushFilingData() {
                $.isHearingAllowed = false;
                $.contact = false;
                $.filingPartyName = false;
                $.service = {};
                $.isRejected = false;
                $.isHearingResubmissionAllowed = true;
            }

            function loadContact(court) {
                return CourtResource
                    .getContact({ id: court })
                    .$promise
                    .then(function (contact) {
                        $.contact = contact;
                    });
            }

            function loadCourt(court) {
                return CourtResource
                    .query({ search: court })
                    .$promise
                    .then(function (locations) {
                        if (locations && locations.length && locations[0]) {
                            var location = locations[0];

                            $.isHearingAllowed = utils.isTrue(location.allowhearing);
                        }
                    });
            }

            function loadHearing(envelopeNumber, scheduledHearing) {
                var data = { envelopeNumber: envelopeNumber };

                return CourtResource.getHearingStatus(data).$promise
                    .then(function (hearing) {
                        var systemResult;
                        var filingDetailsResult;
                        var statusChanged;
                        var onlyFilingDetailsHearingIsAvailable;

                        // "null" is incorrectly de-serialized. Needs to be checked extra.
                        hearing = utils.getOr(function () {
                            if (hearing) {
                                var f = hearing.toJSON();

                                return Object.keys(f).length > 0 ? f : null;
                            }

                            return null;
                        });

                        if (hearing) {
                            systemResult = ScheduleHearingService.format(hearing);
                        }

                        if (scheduledHearing) {
                            filingDetailsResult = ScheduleHearingService.formatHearingFromDetails(scheduledHearing);
                        }

                        statusChanged = (
                            systemResult && filingDetailsResult &&
                            systemResult.style.name !== filingDetailsResult.style.name
                        );
                        onlyFilingDetailsHearingIsAvailable = !systemResult && filingDetailsResult;

                        if (statusChanged || onlyFilingDetailsHearingIsAvailable) {
                            ScheduleHearingService.patchEnvelopeInformation(envelopeNumber, scheduledHearing)
                                .then(function () {
                                    $rootScope.$broadcast(customEvents.envelopeHearingStatusChanged, {
                                        envelopeNumber: envelopeNumber,
                                        scheduledHearing: scheduledHearing
                                    });
                                });
                        }

                        return filingDetailsResult || systemResult;
                    })
                    .then(function (hearing) {
                        var forbidsHearingResubmission = utils.getOr(function () {
                            return hearing.style.allowsResubmit === false;
                        }, false);

                        if (forbidsHearingResubmission) {
                            $.isHearingResubmissionAllowed = !forbidsHearingResubmission;
                        }

                        return hearing;
                    });
            }

            function loadDetailedService(id, commonServiceInformation) {
                return EServiceResource
                    .get({id: id, firebaseDetail: true})
                    .$promise
                    .then(function (service) {
                        if (
                            (service.hasOwnProperty('$value') && service.$value === null) ||
                            (service.status_code !== commonServiceInformation.status_code)
                        ) {
                            console.log(service)
                            RefreshDataService.forceRefreshFiling(service.date)
                            return EServiceService.updateDetailed(id, $.service)
                                .then(function () {
                                    return EServiceResource.get({id: id, firebaseDetail: true}).$promise;
                                });
                        }

                        return service;
                    })
                    .then(function (service) {
                        $.service = Object.assign($.service, service);
                        $.scheduledHearing = ScheduleHearingService.getFromFiling($.service);
                        $.filingDetailsTransformer = new FilingTransformer.import($.service);
                    })
                    .then(function () {
                        return CaseService
                            .getParticipantById({
                                caseId: $.service.case_id,
                                participantId: $.service.filing_party_id
                            })
                            .then(function (participant) {
                                $.filingPartyName = participant && participant.name ? participant.name : '';
                            });
                    });
            }

            function loadFromApi(changedParams) {
                $.isRejected = null;

                EServiceResource.meta({ id: changedParams.id })
                    .$promise
                    .then(function (filingMeta) {
                        $.filingsMetaTransformer = FilingsMetaTransformer.import(filingMeta);
                    });

                return EServiceResource.get({id: changedParams.id})
                    .$promise
                    .then(function (service) {
                        $.service = service;
                        $.lastUpdatedTimestamp = +new Date();

                        return loadDetailedService(changedParams.id, service)
                            .then(function () {
                                return $.service;
                            });
                    })
                    .then(function (service) {
                        return CaseResource.get({ id: service.case_id }).$promise;
                    })
                    .then(function (eCase) {
                        var courtCode;
                        var promises = [];

                        $.case = eCase;
                        $.service.case_title_composed = CaseService.composeCaseName(eCase);
                        $.service.filing_attorney = FilingService.getFilingAttorney(changedParams.id);
                        $.service.case = eCase;
                        $.isRejected = FilingService.isRejected($.service);
                        $.lastUpdatedTimestamp = +new Date();

                        CaseNameService.decorate($.service);
                        courtCode = eCase.court_code || $.service.court_name;

                        if (courtCode) {
                            promises.push(loadContact(courtCode));
                        }

                        if (courtCode && statusesAllowedForHearing.indexOf($.service.status_code) >= 0) {
                            loadCourt(courtCode);
                        }

                        if ($.service.envelope_number) {
                            loadHearing($.service.envelope_number, $.scheduledHearing)
                                .then(function (hearing) {
                                    $.service.hearingScheduled = hearing;
                                });
                        }

                        return $q.all(promises);
                    });
            }

            $.cancelFiling = function (id) {
                return EServiceResource.delete({ id: id })
                    .$promise
                    .then(function () {
                        $.service.sent_to_canceling = true;

                        return EServiceService.updateProp(id, 'sent_to_canceling', true);
                    })
                    .then(function () {
                        // Removes Services/Filings list from Cache in order to upload new list with created Filing
                        $cacheFactory.get('$http').remove('/services');
                        $cacheFactory.get('$http').remove('/services/detail/' + id);
                        ToastrService.showMessageByKey('pending_submission_cancelled');

                        // Changing page to created Filing details
                        $state.transitionTo('filingsView', {id: id}, {
                            reload: true,
                            inherit: false,
                            notify: true
                        });
                    })
                    .catch(function (e) {
                        console.error(e);
                    });
            };

            $.resubmit = function (service) {
                var data;
                var state = null;

                if ($.filingService.isSubsequentFiling(service)) {
                    data = {
                        caseId: service.case.id,
                        courtId: service.case.court_code,
                        envelopeId: service.envelope_number,
                        resubmitFilingId: service.id
                    };
                    state = 'filingCreate';
                } else if ($.filingService.isInitialFiling(service)) {
                    data = {
                        envelopeId: service.envelope_number,
                        resubmitFilingId: service.id
                    };
                    state = 'caseCreate';
                }

                if (state !== null)
                    $state.go(state, data);
            };

            $.scheduleHearing = function () {
                $.schedulePopupVisible = true;
            };

            $rootScope.$on(customEvents.hearingScheduled, function (event, data) {
                var filingId = data.filingId;

                this.uiOnParamsChanged({ id: filingId });
            }.bind(this));

            this.uiOnParamsChanged({ id: id, action: action });
        }
    ]);
})(angular.module('onfileApp'));
