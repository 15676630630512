(function (app) {
    app.controller('DocumentsCtrl', [
        'UploadDocumentService', 'DocumentResource', 'PaymentAccountResource',
        'AttorneyResource', 'DocumentTypeResource', 'eProductionService', 'FilingCodeResource', 'AntiVirusResource',
        '$scope', '$q', '$rootScope',
        '$http',
        '$timeout', 'emailSequencePattern', 'ToastrService', '$window', 'filepickerApikey', 'EnvironmentService',
        'SecurityService', 'utils',

        function (
            UploadDocumentService, DocumentResource, PaymentAccountResource,
            AttorneyResource, DocumentTypeResource, eProductionService, FilingCodeResource, AntiVirusResource,
            $, $q, $rootScope, $http,
            $timeout, emailSequencePattern, ToastrService, $window, filepickerApikey, EnvironmentService,
            SecurityService, utils
        ) {
            var documentTypesLoaded;
            var maxSizeMb = 35;
            var maxSize = maxSizeMb * 1024 * 1024;
            var uploadDocumentService = new UploadDocumentService();
            $.envelopeOverTheLimit = false;

            uploadDocumentService.setFilesMaxSize(maxSize);

            angular.extend($, {
                allowMultiples: true,
                documents: [],
                accounts: [],
                attorneys: [],
                //case: {},
                addDocumentTab: '',
                showFilePicker: true,
                emailSequencePattern: emailSequencePattern,
                maxSizeMb: maxSizeMb,
                filesFilling: uploadDocumentService.filesFilling
            });

            initController();

            DocumentResource.query().$promise
                .then(function (documents) {
                    $.documents = documents;
                });

            PaymentAccountResource.query().$promise
                .then(function (accounts) {
                    $.accounts = accounts;
                });

            AttorneyResource.query().$promise
                .then(function (attorneys) {
                    $.attorneys = attorneys;
                });

            function initController() {
                if ($.case === undefined || $.case.court_code === undefined)
                    return;

                if (!$.case.hasOwnProperty('filing')) {
                    $.case.filing = {docs: []};
                }
                else if (!$.case.filing.hasOwnProperty('docs')) {
                    $.case.filing = {docs: []};
                }

                documentTypesLoaded = DocumentTypeResource.query({
                    court_code: $.case.court_code,
                    filing_code_id: '-365'
                }).$promise
                    .then(function (documentTypes) {
                        $.documentTypes = documentTypes;

                        return $.documentTypes;
                    });

                FilingCodeResource.query({
                    court_code: $.case.court_code,
                    category_code: $.case.category_code,
                    type_code_id: $.case.type_code_id
                }).$promise
                    .then(function (filingCodes) {
                        $.filingCodes = filingCodes;
                    })
                    .catch(function (res) {
                        // @TODO handle error
                    });
            }

            // When a Case is selected
            $rootScope.$on('CaseChanged', function (e, eCase) {
                initController(eCase);
            });

            $rootScope.$watch('eProductionSummaryDoc', function (url) {
                if (url !== null && $.case) {
                    initFilings();

                    // Remove all eProduction documents if exiust
                    $.case.filing.docs.map(function (el, idx) {
                        if (el.eProduction) {
                            $.case.filing.docs.splice(idx, 1);
                        }
                    });

                    var d = uploadDocumentService.getS3FileInfo(url);
                    var doc = Object.assign({}, d, {
                        scan_status: 'Clean',
                        file_scanned: true,
                        is_ok: true,
                        isNew: true
                    });

                    $.documents.push(doc);
                    $.case.filing.docs.push(doc);

                    if (documentTypesLoaded) {
                        documentTypesLoaded.then(function (documentTypes) {
                            return preSelectDocType(documentTypes);
                        });
                    }
                }
            });

            function addDocumentToCase(document) {
                //HACK: don't know why all of a sudden we are getting this.
                if (!$.case.filing.hasOwnProperty('docs')) {
                    $.case.filing = {docs: []};
                }

                $.case.filing.docs.push(document);
            }

            $.documentScanned = function () {
                var result = true;
                angular.forEach($.case.filing.docs, function (d) {
                    if (d.is_ok !== true) {
                        result = false;
                    }
                });
                return result;
            };

            // Callback for Document Uploaded event
            $.documentUploaded = function (doc) {
                doc.isNew = true;
                doc.scan_status = 'scanning';
                doc.file_scanned = false;
                doc.is_ok = false;

                $.documents.push(doc);
                addDocumentToCase(doc);

                $.envelopeOverTheLimit = !uploadDocumentService.checkEnvelopeSize($.case.filing.docs);

                preSelectDocType($.documentTypes);

                AntiVirusResource.query({name: doc.url}).$promise
                    .then(function (res) {
                        if (res.result) {
                            doc.scan_status = res.result;
                            doc.file_scanned = true;
                            doc.is_ok = res.result === 'Clean';
                        }
                    });
            };
            $window.filepicker.setKey(filepickerApikey);

            $.openFilestackDialog = function () {
                var dialogParams = {
                    services: ['COMPUTER', 'BOX', 'DROPBOX', 'GOOGLE_DRIVE', 'SKYDRIVE', 'FTP'],
                    container: 'modal',
                    multiple: true,
                    folders: true,
                    extensions: ['.txt', '.rtf', '.doc', '.docx', '.pdf', '.png', '.jpg', '.jpeg', '.gif', '.bmp', '.tif', '.tiff', '.odt', '.odp', '.xls', '.xlsx', '.html', '.htm', '.ppt', '.pptx', '.ods', '.psd', '.ai', 'svg'],
                };

                var storeParams = {
                    location: 'S3',
                    access: 'public',
                    container: EnvironmentService.s3BucketLocation($.case.draftId)
                };

                SecurityService.getDialogSecParams().then(function (secParams) {
                    angular.extend(dialogParams, secParams);
                    $window.filepicker.pickAndStore(
                        dialogParams,
                        storeParams,
                        function (result) {
                            filestackUploaded(result);
                        });
                });
            };

            function deleteFsFile(fObj) {
                var defer = $q.defer();
                SecurityService.getRemoveSecParams().then(function (secParams) {
                    $window.filepicker
                        .remove(fObj.url_filestack, secParams, function () {
                            defer.resolve(true);
                        }, function (err) {
                            defer.reject(err);
                        });
                });

                return defer.promise;
            }

            $.deleteDocument = function (document, index) {
                $.documents.push(document);
                $.case.filing.docs.splice(index, 1);

                if ($.getProvider(document) === 'filestack') {
                    deleteFsFile(document);
                }
                ToastrService.showMessageByKey('document_deleted');
            };

            $.setDocumentStageValid = function () {
                $rootScope.isDocumentStageIsValid = true;
                return true;
            };


            $.isValid = function () {

                var result = $.newService.$valid
                    && $.documentScanned()
                    && $.case.filing.docs
                    && $.case.filing.docs.length > 0;

                if (!result)
                    ToastrService.showMessageByKey('lead_missing_service');

                return result;

            };

            $.envelopeOverLimitMessage = function () {
                return ToastrService.getMessage('envelope_size');
            };

            $.validateCourtesyCopies = function () {
                var isValid = $.case.filing.courtesy_copies === undefined || $.case.filing.courtesy_copies === '' || $.emailSequencePattern.test($.case.filing.courtesy_copies);
                $.newService.courtesyCopies.$setValidity('pattern', isValid);
            };

            $.getProvider = function (file) {
                return file.url_filestack ? 'filestack' : null;
            };

            var filestackUploaded = function (fpfiles) {
                for (var i = 0; i < fpfiles.length; i++) {
                    uploadDocumentService.updateFile(fpfiles[i])
                        .then(function (res) {
                            var doc = res;

                            doc.isNew = true;
                            doc.scan_status = 'scanning';
                            doc.file_scanned = false;
                            doc.is_ok = false;

                            $.documents.push(doc);
                            addDocumentToCase(doc);

                            $.envelopeOverTheLimit = !uploadDocumentService.checkEnvelopeSize($.case.filing.docs);

                            AntiVirusResource.query({name: doc.url}).$promise
                                .then(function (res) {
                                    if (res.result) {
                                        doc.scan_status = res.result;
                                        doc.file_scanned = true;
                                        doc.is_ok = res.result === 'Clean';
                                    }
                                });

                            preSelectDocType($.documentTypes);
                        });
                }
            };

            var preSelectDocType = function (documentTypes) {
                utils.getOr(function () {
                    var neededDocType = documentTypes.find(function (d) {
                        return d.name.toLowerCase() === 'confidential';
                    });

                    if (!neededDocType) {
                        neededDocType = documentTypes.find(function (d) {
                            return d.name.toLowerCase() === 'contains sensitive data';
                        });
                    }

                    if (!neededDocType) {
                        return;
                    }

                    $.case.filing.docs.map(function (document) {
                        if (document.document_type) {
                            return false;
                        }

                        document.document_type = neededDocType;
                    });
                });
            };

            var initFilings = function () {
                var fn = function (eCase) {
                    eCase.filing = { docs: [] };

                    return eCase;
                };

                if ($.case.hasOwnProperty('filing') === false) {
                    return fn($.case);
                }

                if ($.case.filing.hasOwnProperty('docs') === false) {
                    return fn($.case);
                }

                return $.case;
            };
        }
    ]);
})(angular.module('onfileApp'));
