(function (app) {
    app.service('TextNodeFactory', [
        '$timeout', '$q',
        function ($timeout, $q) {
            function TextNode() {
                var styleProperties = ['fontSize'];

                this.text = 'text';
                this.fontSize = 25;
                this.orderNumber = 0;
                this.idTextPart = 'inserted-text';
                this.draggableHandleIdTextPart = 'inserted-text-drag-handle';

                this.getStyles = function () {
                    return styleProperties.reduce(function (all, curr) {
                        all[curr] = this[curr];

                        return all;
                    }.bind(this), {});
                };

                this.setOrderNumber = function (number) {
                    this.orderNumber = number;
                    this.id = this.idTextPart + '-' + this.orderNumber;
                    this.handleId = this.draggableHandleIdTextPart + '-' + this.orderNumber;

                    return this;
                };

                this.setDraggable = function (status) {
                    var deferred = $q.defer();
                    var activate = status === undefined ? true : !!status;

                    $timeout(function () {
                        if (activate) {
                            this.getDomElement().draggable({
                                cursor: 'move',
                                handle: this.getDraggableHandleSelector()
                            });
                        } else {
                            this.getDomElement().draggable('disable');
                        }

                        deferred.resolve();
                    }.bind(this), 0);

                    return deferred.promise;
                };

                this.getDomElement = function () {
                    if (this.domElement === undefined) {
                        this.domElement = jQuery('#' + this.id);
                    }

                    return this.domElement;
                };

                this.getDraggableHandleSelector = function () {
                    return '#' + this.handleId;
                };

                this.fix = function () {
                    this.isFixed = true;
                    this.setDraggable(false);

                    return this;
                };
            }

            return {
                create: function () {
                    return new TextNode();
                }
            };
        }
    ]);
})(angular.module('onfileApp'));
