(function (app) {
    app.factory('UploadDocumentService', [
        'Upload', 'DocumentResource', '$window',
        'filepickerApikey', 'EnvironmentService', '$q',
        'ToastrService', 'SecurityService',

        function (
            Upload, DocumentResource, $window,
            filepickerApikey, EnvironmentService, $q,
            ToastrService, SecurityService
        ) {
            return function UploadDocumentService() {
                var maxSize;
                var S3Container = EnvironmentService.s3BucketName();

                this.upload = function (file, index, opt) {
                    var container = opt.container;

                    return Upload.upload({
                        url: '/filings/upload',
                        data: {
                            file: file,
                            index: index,
                            bucket: container
                        },
                        headers: {'X-Requested-With': 'XMLHttpRequest'}
                    });
                };

                this.convertToPdf = function (file, cb) {
                    var f = {
                        url: file.url,
                        filename: file.filename,
                        mimetype: 'image/png',
                        isWriteable: false,
                        size: file.size
                    };
                    SecurityService.getConvertSecParams()
                        .then(function (convertParams) {
                            convertParams.format = 'pdf';
                            $window.filepicker.convert(f,
                                convertParams,
                                {
                                    location: 'S3',
                                    container: S3Container,
                                    access: 'public'
                                },
                                function (converted) {
                                    // because conversion links don't have
                                    // 'container' field it has to be manually
                                    // inserted
                                    converted.container = S3Container;
                                    cb(converted);
                                });

                        });
                };

                this.processFileForPdf = function (file) {
                    $window.filepicker.setKey(filepickerApikey);
                    var loader = angular.element('#workspace-loading');
                    loader.addClass('is-visible');
                    var def = $q.defer();
                    this.convertToPdf(file, function (f) {
                        // Remove source file
                        $window.filepicker.remove(file);
                        loader.removeClass('is-visible');
                        def.resolve(DocumentResource.save(f).$promise);
                    });
                    return def.promise;
                };

                this.updateFile = function (file) {
                    var def = $q.defer();

                    if (file.mimetype !== 'application/pdf') {
                        def.promise = this.processFileForPdf(file);
                    } else {
                        def.promise = DocumentResource.save(file).$promise;
                    }

                    return def.promise;
                };

                this.setFilesMaxSize = function (bytes) {
                    maxSize = bytes;
                    return this;
                };

                this.checkEnvelopeSize = function (files, calculated) {
                    var result;

                    if (files) {
                        result = this.filesFilling(files);
                    }
                    else if (calculated) {
                        result = calculated;
                    }

                    var overTheLimit = result.size > 35; ///use config service

                    if (overTheLimit) //map this to a config service
                        ToastrService.showMessageByKey('envelope_size');

                    return !overTheLimit;
                };

                /**
                 * Computing of a size of uploaded files (in percents)
                 *
                 * @param files
                 * @returns {{percent: integer, size: string}}
                 */
                this.filesFilling = function (files) {
                    var sizeSum = (function (files) {
                        if (!files || !files.reduce) {
                            return 0.0;
                        }

                        return files.reduce(function (sum, file) {
                            return sum + parseFloat(file.file_size || file.size);
                        }, 0.0);
                    })(files);

                    var percent = (function (sizeSum, maxSize) {
                        var res = Math.min(sizeSum / maxSize * 100, 100);

                        return (res < 1 ? 0 : Math.floor(res));
                    })(sizeSum, maxSize);

                    return {
                        percent: percent,
                        size: (sizeSum / (1024 * 1024)).toFixed(2)
                    };
                };

                this.removeFile = function (file) {
                    var defer = $q.defer();

                    try {
                        SecurityService.getRemoveSecParams()
                            .then(function (secParams) {
                                $window.filepicker.remove(
                                    file.url_filestack ? file.url_filestack : file.url,
                                    secParams,
                                    function () {
                                        defer.resolve(true);
                                    },
                                    function (err) {
                                        defer.reject(err);
                                    }
                                );

                            });
                    } catch (err) {
                        defer.reject(err);
                    }

                    return defer.promise;
                };

                this.getS3FileInfo = function (url) {
                    var getKey = function (url) {
                        var separator = '/';

                        return url.split(separator).slice(0, -1).join(separator);
                    };
                    var getContainer = function (url) {
                        var match = url.match(/^https*:\/\/([\w-_]+)\..+$/);

                        if (match && match[1]) {
                            return match[1];
                        }

                        throw new Error('Could not get S3 container from url:' + url);
                    };
                    var filename = 'File Download Instructions.pdf';

                    return {
                        url: url,
                        isWriteable: false,
                        mimetype: 'application/pdf',
                        size: 100000,
                        key: getKey(url),
                        container: getContainer(url),
                        filename: filename,
                        file_name: filename,
                        eProduction: true
                    };
                };

                return this;
            };
        }
    ]);
})(angular.module('onfileApp'));
