(function (app) {
    app.controller('CreateDocumentRequestCtrl', [
        '$scope', '$mdDialog', 'DocumentGroupsResourceFactory',
        'S3FileUploader', '$q', '$state',
        '$rootScope', 'docGroup', 'ToastrService',
        'NotaryRequestService',
        function (
            $, $mdDialog, DocumentGroupsResourceFactory,
            S3FileUploader, $q, $state,
            $rootScope, docGroup, ToastrService,
            NotaryRequestService
        ) {
            var resourceCreated = DocumentGroupsResourceFactory.create();

            $.created = false;
            $.createdId = null;
            $.model = {
                name: 'Document request from Matter #' + docGroup.id,
                extensions: {
                    userName: docGroup.userName,
                    userEmail: docGroup.userEmail,
                    recipients: []
                },
                type: 'documentRequest'
            };
            $.files = docGroup.documents.filter(function (d) { return d.isChecked; });

            $.close = function () { $mdDialog.hide(); };

            $.submit = function () {
                resourceCreated.then(function (resource) {
                    var requestData = Object.assign($.model, {
                        documentIds: $.files.map(function (d) {
                            return d.id;
                        }),
                        parentDocumentGroupId: docGroup.id
                    });

                    if (!$.model.extensions.recipients || !$.model.extensions.recipients.length) {
                        delete $.model.extensions.recipients;
                    }

                    return resource.create(requestData).$promise;
                }).then(function (matter) {
                    NotaryRequestService.updateInList(matter);
                    ToastrService.showSuccess('Request sent');
                    $.close();
                });
            };

            $.removeFile = function (i) {
                $.files.splice(i, 1);
            };
        }
    ]);
})(angular.module('onfileApp'));