(function (app) {
    app.run([
        '$rootScope', 'HeadingService',
        function ($rootScope, HeadingService) {
            $rootScope.cardsHeading = HeadingService.cardsHeading;
            $rootScope.currentPageType = HeadingService.currentPageType;
            $rootScope.filterPlaceHolder = HeadingService.filterPlaceHolder;
            $rootScope.HeadingService = HeadingService;
        }
    ]);
})(angular.module('onfileApp'));
