(function (app) {
    app.factory('DocumentResource', ['$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/filings', {}, {
                query: {
                    method: 'GET',
                    isArray: true
                },
                save: urlEncodedResource({
                    url: '/filings/save_document',
                    method: 'POST',
                    isArray: false
                }),
                makeSearchable: urlEncodedResource({
                    url: '/documents/make-searchable',
                    method: 'POST',
                    isArray: false
                }),
                updateContent: urlEncodedResource({
                    url: '/documents/update-content',
                    method: 'POST',
                    isArray: false
                })
            });
        }]);
})(angular.module('onfileApp'));