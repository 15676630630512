(function (app) {
    app.factory('CrossReferenceResource', ['$resource',
        function ($resource) {
            return $resource('/cross-references', {}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: true
                }
            });
        }]);
})(angular.module('onfileApp'));