(function (app) {
    app.service('ReportsService', [
        '$filter', 'SiteConfigurationService',
        function ($filter, SiteConfigurationService) {
            this.transformFilingsReportForImport = function (params) {
                var attorneys = params.attorneys;
                var dateFormat = params.dateFormat;
                var rawFilingDetails = params.rawFilingDetails;

                return rawFilingDetails.map(function (o) {
                    return {
                        'Envelope': o.getEnvelopeId(),
                        'Status': o.getStatus(),
                        'Case no.': o.getDocketId(),
                        'Reference/matter': o.getMatter(),
                        'Type': o.getFilingType(),
                        'Filing description': o.getFilingDescription(),
                        'Filed': $filter('amDateFormat')(
                            o.getSubmissionDate(), dateFormat
                        ),
                        'Accepted': $filter('amDateFormat')(
                            o.getPaymentCaptureDate(), dateFormat
                        ),
                        'Posted': '',
                        'Payment account': o.getPaymentAccount(),
                        'Attorney': o.getAttorneyName(attorneys),
                        'Filer' : o.getFilerName()
                    };
                });
            };

            this.transformPaymentReportForImport = function (params) {
                var attorneys = params.attorneys;
                var dateFormat = params.dateFormat;
                var rawFilingDetails = params.rawFilingDetails;
                var feesHeaders = this.getFeesHeaders();

                var envelopesHashMap = {};

                return rawFilingDetails
                    // Filters out duplicated Envelope number entries
                    .filter(function (o) {
                        var envelopeId = o.getEnvelopeId();

                        if (envelopesHashMap[envelopeId] === undefined) {
                            envelopesHashMap[envelopeId] = true;

                            return true;
                        }

                        return false;
                    })
                    .map(function (o) {
                        var fees = feesHeaders.reduce(function (all, header) {
                            all[header] = this.getFeeValue(header, o);

                            return all;
                        }.bind(this), {});

                        return Object.assign({
                            'Envelope ID': o.getEnvelopeId(),
                            'Case name': o.getCaseName(),
                            'Case number': o.getDocketId(),
                            'Reference/matter': o.getMatter(),
                            'Status': o.getStatus(),
                            'Filer': o.getFilerName(),
                            'Attorney': o.getAttorneyName(attorneys),
                            'Filing type': o.getFilingType(),
                            'Payment capture date': $filter('amDateFormat')(
                                o.getPaymentCaptureDate(), dateFormat
                            ),
                            'Payment account': o.getPaymentAccount()
                        }, fees);
                    }.bind(this));
            };

            this.getFeesHeaders = function () {
                return [
                    'Filing Court Fees',
                    'Filing & Service Fee',
                    'Convenience Fee',
                    'Total Court Case Fees',
                    'Total Court Party Fees',
                    'Total Court Filing Fees',
                    'Total Filing & Service Fees',
                    'Total Service Tax Fees',
                    'Total Court Service Fees',
                    'Total Provider Service Fees',
                    'Total Provider Tax Fees',
                    'Grand Total'
                ].map(function (item) {
                    return SiteConfigurationService.translateStatic(item);
                });
            };

            this.getFeeValue = function (key, item) {
                var filingFees = item.getFilingFees();
                var envelopeFees = item.getEnvelopeFees();

                if (Array.isArray(filingFees) === false) {
                    filingFees = [];
                }
                if (Array.isArray(envelopeFees) === false) {
                    envelopeFees = [];
                }

                var fees = filingFees.concat(envelopeFees);
                var fee = fees.find(function (o) {
                    return o.AllowanceChargeReason === key;
                });

                return fee ? fee.Amount : null;
            };

            this.feesHeadersMap = (function (feesHeaders) {
                var res = {};

                feesHeaders.map(function (header, index) {
                    res[header] = index;
                });

                return res;
            })(this.getFeesHeaders());
        }
    ]);
})(angular.module('onfileApp'));
