(function (app) {
    app.service('NotaryRequestService', [
        '$rootScope', 'DocumentGroupsResourceFactory',
        function ($rootScope, DocumentGroupsResourceFactory) {
            this.updateInList = function (request) {
                for (var i = 0; i < $rootScope.items.length; i++) {
                    if ($rootScope.items[i].id === request.id) {
                        $rootScope.items[i] = request;
                        $rootScope.$emit('requestUpdated', { id: request.id });

                        return true;
                    }
                }

                return false;
            };

            this.isSharedDocumentGroupCreated = function (docGroup) {
                return docGroup.childDocumentGroups && docGroup.childDocumentGroups[0] &&
                    docGroup.childDocumentGroups[0].url;
            };

            this.getSharedDocumentGroup = function (docGroup) {
                if (this.isSharedDocumentGroupCreated(docGroup)) {
                    return docGroup.childDocumentGroups[0];
                }

                return null;
            };

            this.createNewSharedDocumentGroup = function (docGroup) {
                // Create document group first
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        return resource.create({
                            name: 'Shared document document group from matter #' + docGroup.id,
                            type: 'shared',
                            parentDocumentGroupId:docGroup.id
                        }).$promise;
                    })
                    .then(function () {
                        $rootScope.$emit('updateAllMatters', { reloadMatterId: docGroup.id });
                    });
            };
        }
    ]);
})(angular.module('onfileApp'));
