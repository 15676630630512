(function (app) {
    app.factory('UsersResource', [
        '$resource',
        function ($resource) {
            return $resource('/users', {}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: true
                },
                settings: {
                    url: '/users/settings',
                    method: 'GET',
                    isArray: false
                },
                systemList: {
                    url: '/api/system/users',
                    method: 'GET',
                    isArray: true
                }
            });
        }]
    );
})(angular.module('onfileApp'));
