;(function (app) {
    app.service('S3LocationsService', [
        'EnvironmentService',
        function (EnvironmentService) {
            this.batesOriginalFile = function (packageId) {
                const userId = EnvironmentService.userId();

                return 'bates_files/' + userId + '/' + packageId + '/original';
            };

            this.batesLabeledFile = function (packageId, fileKey) {
                const firmId = EnvironmentService.firm_id();
                const userId = EnvironmentService.userId();

                return '/' + firmId + '/bates_files/' + userId + '/' + packageId + '/labeled/' + fileKey + '.pdf';
            };

            this.previewBatesFile = function () {
                const userId = EnvironmentService.userId();
                const firmId = EnvironmentService.firm_id();

                return '/' + firmId + '/bates_files/' + userId + '/preview/';
            };

            this.eProductionFiles = function (caseId, eProductionId) {
                var firmId = EnvironmentService.firm_id();

                return '/' + firmId + '/eproductions/' + caseId + '/' + eProductionId;
            };
        }
    ]);
})(angular.module('onfileApp'));