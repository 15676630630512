(function (app) {
    app.directive('privilegeLog', [
        'EnvironmentService', '$window', 'filepickerApikey',
        'PrivilegeLogService', '$rootScope', 'downloadFile',
        'ToastrService', 'CsvExportService', 'prepareProxyLink',

        function (
            EnvironmentService, $window, filepickerApikey,
            PrivilegeLogService, $rootScope, downloadFile,
            ToastrService, CsvExportService, prepareProxyLink
        ) {
            return {
                strict: 'A',
                scope: {
                    eProdId: '=?',
                    caseId: '=?',
                    fileCount: '='
                },
                link: function ($) {
                    var firmId = EnvironmentService.firm_id();

                    $.mode = $.eProdId === 'direct' ? 'case' : 'eprod';
                    $.showSearch = false;
                    $.deleteAllFilesWarn = false;

                    $.uploaderConfig = { draftId: $.caseId + '-' + $.prodId };

                    $.$watch('[caseId, eProdId]', function (variables) {
                        var caseId = variables[0];
                        var prodId = variables[1];

                        $.uploaderConfig = { draftId: caseId + '-' + prodId };

                        if (!caseId || !prodId) {
                            return;
                        }

                        if (caseId) {
                            $.batch.init({
                                firmId: firmId,
                                caseId: caseId,
                                packageId: prodId
                            });
                        }
                    });

                    angular.merge($, {
                        batch: PrivilegeLogService,
                        limit: $.mode === 'case' ? 25 : 5,
                        offset: 0,
                        currentPage: 1,

                        countPages: function () {
                            return Math.ceil($.batch.getFiles().length / $.limit);
                        },
                        getPages: function () {
                            return new Array($.countPages());
                        },
                        showPage: function (index) {
                            if (index === 'last' || index > $.countPages()) {
                                $.currentPage = $.countPages();
                            } else if (index === 'first' || !index || index <= 0) {
                                $.currentPage = 1;
                            } else {
                                $.currentPage = parseInt(index);
                            }

                            if (isNaN($.currentPage) || $.currentPage <= 0) {
                                $.currentPage = 1;
                            }

                            $.offset = ($.currentPage - 1) * $.limit;
                            $.offset = $.offset < 0 ? 0 : $.offset;
                        },
                        deleteFile: function (file) {
                            return $.batch.delete(file)
                                .then(function () {
                                    ToastrService.showSuccess('File removed from log');
                                });
                        },
                        documentUploaded: function (file) {
                            var normalizedData = Object.assign({
                                container: getContainerFromUrl(file.url),
                                key: getKeyFromUrl(file.url)
                            }, file);

                            return $.batch.addFiles(normalizedData);
                        },
                        filesTotalSize: function () {
                            var total = 0;
                            var files = $.batch.getFiles();

                            if ($.fileCount !== undefined)
                                $.fileCount = files.length;

                            for (var i = 0; i < files.length; i++) {
                                total += files[i].size;
                            }

                            return total;
                        },
                        getExtension: function (file) {
                            return file.split('.').pop();
                        },
                        moveToEProduction: function (file) {
                            return $.batch
                                .moveToEProduction(file, $.eProdId)
                                .then(function () {
                                    $rootScope.$emit('privilegeLogMovedToEProduction', {
                                        file: file,
                                        eProdId: $.eProdId
                                    });
                                })
                                .then(function () {
                                    ToastrService.showMessageByKey('file_moved_to_e_production');
                                });
                        },
                        commitDownload: function (file) {
                            return downloadFile({
                                url: prepareProxyLink(file.url),
                                filename: file.filename
                            });
                        },
                        deleteAllLogs: function (warn) {
                            //HACK don't know why right from the html it would not work
                            if (warn) {
                                $.deleteAllFilesWarn = true;
                                console.log('warning before deleting all logs');
                                return;
                            }
                            var files = $.batch.getFiles();
                            for (var i = 0; i < files.length; i++)
                                $.deleteFile(files[i]);

                            $.deleteAllFilesWarn = false;
                        },
                        exportLogs: function () {
                            const timeFieldMutator = {
                                time: function (item) {
                                    return new Date(item).toString()
                                }
                            };

                            return CsvExportService.exportAndDownload({
                                items: $.batch.getFiles(),
                                titles: ['original_filename', 'filename', 'time', 'size'],
                                fields: ['filename', 'display_name', timeFieldMutator, 'size'],
                                filename: 'privilege_log.csv'
                            });
                        }
                    });

                    $.filesTotalSize();

                    $.$watch('batch.getFiles', function () {
                        $.filesTotalSize();
                    });

                    var getContainerFromUrl = function (url) {
                        try {
                            return url.split('/')[2].split('.')[0]
                        } catch (e) {
                            return null;
                        }
                    };

                    var getKeyFromUrl = function (url) {
                        try {
                            return url.split('/').slice(3).join('/');
                        } catch (e) {
                            return null;
                        }
                    };
                },

                templateUrl: '/assets/app/shared/directives/privilegeLog/template.html?{{onfileApplicationVersion}}'
            }
        }
    ]);
})(angular.module('onfileApp'));
