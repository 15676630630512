(function (app) {
    app.service('PermissionsService',[
        'FirebaseService', 'EnvironmentService', 'utils',
        function (FirebaseService, EnvironmentService, utils) {
            var getPermissions = function () {
                var path = '/whitelist/' + EnvironmentService.firm_id() + '/permissions';

                return FirebaseService.getNode(path).$loaded();
            };

            var isFeatureAvailable = function (feature) {
                return getPermissions()
                    .then(function (permissions) {
                        return utils.isFeatureAvailable(permissions[feature],{
                            userId: EnvironmentService.userId()
                        });
                    });
            };

            return {
                getPermissions: getPermissions,
                isFeatureAvailable: isFeatureAvailable
            };
        }
    ]);
})(angular.module('onfileApp'));
