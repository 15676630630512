(function (app) {
    app.factory('PdfConversionResource', [
        '$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/cover-sheets', {}, {
                convert: urlEncodedResource({
                    method: 'POST',
                    url: '/cover-sheets/convert'
                })
            });
        }
    ]);
})(angular.module('onfileApp'));