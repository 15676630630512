(function (app) {
    app.directive('cachedData', [
        'CachedDataService', '$q', 'utils',
        function (CachedDataService, $q, utils) {
            var initialSetData = function ($, object) {
                var promise = $.waitFor || $q.resolve();

                return promise.then(function () {
                    var property = $.propertyName;
                    var targetObject = object || $.object;
                    var cachedData = CachedDataService.get(getStorageKey($));

                    if (!cachedData) {
                        return;
                    }

                    if ($.matchingFn) {
                        property = $.propertyNameForProvisioning || property;
                        cachedData = $.matchingFn({ data: cachedData, property: property });
                    }

                    utils.setProperty(targetObject, property, cachedData);

                    if ($.callAfterProvisioning) {
                        $.callAfterProvisioning({ data: cachedData });
                    }
                });
            };

            var attachReFillListener = function ($, eventName) {
                $.$on(eventName, function (e, data) {
                    var object = data && data.object;

                    initialSetData($, object);
                });
            };

            var getStorageKey = function ($) {
                return $.storageKey || $.propertyName;
            };

            return {
                strict: 'A',
                scope: {
                    propertyName: '@',
                    object: '=',
                    waitFor: '=?',
                    reFillOnEvent: '@?',
                    storageKey: '@?',
                    propertyNameForProvisioning: '@?',
                    callAfterProvisioning: '&?',
                    matchingFn: '&?'
                },
                link: function ($) {
                    $.isActivated = !!CachedDataService.get(getStorageKey($));

                    $.onChangeState = function () {
                        var val = utils.getProperty($.object, $.propertyName);

                        if ($.isActivated === false || !val) {
                            CachedDataService.remove(getStorageKey($));
                            return;
                        }

                        CachedDataService.set(getStorageKey($), val);
                    };

                    $.$watch('object.' + $.propertyName, function (val) {
                        if (val && $.isActivated) {
                            CachedDataService.set(getStorageKey($), val);
                        }
                    });

                    if ($.reFillOnEvent) {
                        attachReFillListener($, $.reFillOnEvent);
                    }

                    initialSetData($);
                },
                templateUrl: '/assets/app/shared/directives/cachedData/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
