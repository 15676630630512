(function (app) {
    app.controller('SidebarHeaderCtrl', [
        '$rootScope', '$scope', '$timeout',
        'EnvironmentService', '$http', 'NotaryFilesService',
        '$mdDialog', 'utils', 'NotaryService',
        'SiteConfigurationService', '$state', '$q',
        'typeOfNotarizationOptions', 'PermissionsService', 'OfficialsResourceFactory',

        function (
            $rootScope, $, $timeout,
            EnvironmentService, $http, NotaryFilesService,
            $mdDialog, utils, NotaryService,
            SiteConfigurationService, $state, $q,
            typeOfNotarizationOptions, PermissionsService, OfficialsResourceFactory
        ) {
            $.search = '';

            $.scheduleNotary = function () {
                return $rootScope.scheduleNotary();
            };

            $.showStatus = function (status) {
                NotaryFilesService.displayByStatus(status);
            };

            $.getCurrentStatus = function () {
                return NotaryFilesService.getStatus();
            };

            $.submitSearch = function (keyEvent) {
                if (keyEvent.which === 13) {
                    $rootScope.$emit('searchSessions', { search: $.search });
                }
            };

            var siteConfigurationLoaded = SiteConfigurationService.getConfigurationForPage('notary');

            $.createSessionPopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        var createSession = function () {
                            $scope.errorText = null;
                            $scope.session = {
                                typeOfNotarization: 1,
                                files: []
                            };
                            $scope.activeStep = 0;
                            $scope.created = null;
                            $scope.isUploadStepVisible = false;
                        };
                        createSession();

                        $scope.isAssignmentAvailable = false;
                        $scope.officials = [];
                        $scope.timeSlots = utils.generateTimeSlots('00:00', '23:00', 30);
                        $scope.minDate = new Date();
                        $scope.typeOfNotarizationOptions = utils.clone(typeOfNotarizationOptions);

                        $scope.changeDate = function (newDate) {
                            $scope.session.files.map(function (document) {
                                document.date = newDate;
                            });
                        };

                        $scope.close = function () { $mdDialog.hide(); };

                        $scope.next = function () {
                            if ($scope.activeStep === 1) {
                                $scope.errorText = '';

                                return siteConfigurationLoaded
                                    .then(function (notary) {
                                        if (!notary || !notary.link) {
                                            throw new Error('Bad configuration. No Notary app link provided');
                                        }

                                        return NotaryService.createSession($scope.session, {
                                            notaryAppLink: notary.link
                                        });
                                    })
                                    .then(function (data) {
                                        $scope.created = data;
                                        $scope.activeStep++;
                                        NotaryFilesService.unshift($scope.created.session);
                                    })
                                    .catch(function (e) {
                                        if (e instanceof InvalidDocumentsError) {
                                            for (var i = 0; i < e.documents.length; i++) {
                                                $scope.session.files[i].fileCheckResult =
                                                    e.documents[i].fileCheckResult;
                                            }
                                        }

                                        if (e.sessionId) {
                                            return NotaryService.deleteSession(e.sessionId);
                                        }

                                        $scope.errorText = e && e.message || JSON.stringify(e);
                                    });
                            }

                            $scope.activeStep++;
                        };

                        $scope.filesSelected = function (files) {
                            var filesData = files.map(function (file) {
                                file.displayedName = file.name;

                                return file;
                            });

                            $scope.session.files = $scope.session.files.concat(filesData);

                            return true;
                        };

                        $scope.deleteFile = function (index) {
                            $scope.session.files.splice(index, 1);
                        };

                        $scope.createNewSession = function () {
                            createSession();
                        };

                        $scope.viewCreated = function () {
                            $state.transitionTo('oneNotarySession', { id: $scope.created.session.id });
                            $scope.close();
                        };

                        $scope.toFileUploadStep = function () {
                            if ($scope.isUploadStepVisible) {
                                $scope.next();
                                return;
                            }

                            $scope.isUploadStepVisible = true;
                            $timeout(function () { $scope.next(); }, 100); // For seamless tab change animation
                        };

                        $scope.isFormInvalid = function () {
                            return !(
                                $scope.session.name && $scope.session.userName && $scope.session.userEmail &&
                                $scope.session.date && $scope.session.time && $scope.session.time
                            );
                        };

                        PermissionsService.isFeatureAvailable('notaryScheduling')
                            .then(function (isAvailable) {
                                $scope.isAssignmentAvailable = isAvailable;

                                if (!$scope.isAssignmentAvailable) {
                                    return;
                                }

                                return OfficialsResourceFactory.create()
                                    .then(function (resource) {
                                        return resource.query().$promise;
                                    })
                                    .then(function (officials) {
                                        $scope.officials = officials;

                                        return $scope.officials;
                                    });
                            });
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/sidebarHeader/' +
                        'create-session-popup.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };
        }
    ]);
})(angular.module('onfileApp'));
