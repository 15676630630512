(function (app) {
    app.service('FilingDocumentService', [
        'utils', 'documentComponentCodes',
        function (utils, documentComponentCodes) {
            this.populateFilingDocumentComponentCodes = function (documents, rawData) {
                if (!documents || !documents.length) {
                    return documents;
                }

                var documentLinkMap = documents.reduce(function (all, curr) {
                    all[curr.original.link] = curr;

                    return all;
                }, {});
                var documentComponentCodesValueMap = documentComponentCodes.reduce(function (all, curr) {
                    all[curr.value] = curr;

                    return all;
                }, {});
                var rawDocData = utils.getOr(function () {
                    return rawData.FilingLeadDocument.DocumentRendition.DocumentRenditionMetadata.DocumentAttachment;
                });

                if (!rawDocData || !rawDocData.length) {
                    return documents;
                }

                rawDocData.map(function (rawDoc) {
                    var link = rawDoc.BinaryLocationURI;

                    if (!link || !documentLinkMap[link] || !documentComponentCodesValueMap[rawDoc.BinaryCategoryText]) {
                        return;
                    }

                    documentLinkMap[link].documentComponentCode =
                        documentComponentCodesValueMap[rawDoc.BinaryCategoryText];
                });
            };
        }
    ]);
}(angular.module('onfileApp')));
