(function (app) {
    app.factory('PartyResource', ['$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/parties', {}, {
                save: urlEncodedResource({
                    url: '/parties/create',
                    method: 'POST',
                    isArray: false
                })
            });
        }]);
})(angular.module('onfileApp'));