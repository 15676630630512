(function (app) {
    app.controller('NotaryLogsCtrl', [
        '$rootScope', '$scope', 'SiteConfigurationService',
        'NotaryService', '$location', 'UserAgentService',
        'utils', '$interval', 'FirebaseService',
        'CsvExportService',
        function (
            $rootScope, $, SiteConfigurationService,
            NotaryService, $location, UserAgentService,
            utils, $interval, FirebaseService,
            CsvExportService
        ) {
            $rootScope.showList = false;
            $.sessionId = null;
            $.isLoading = false;
            $.term = null;
            $.sessions = null;
            $.selectedSession = null;
            $.stages = [];
            $.selectedStage = undefined;
            $.showOfficialConnectivityInfo = false;
            $.showSignerConnectivityInfo = false;
            $.searchForm = {
                id: ''
            };

            var updateSessionInterval = null;
            var sessions = [];
            var notaryAppLink = null;
            var searchObject = $location.search();

            SiteConfigurationService.getConfigurationForPage('info')
                .then(function (d) {
                    if (d && d.template) {
                        $.template = d.template;
                    }
                });

            SiteConfigurationService.getConfigurationForPage('notary')
                .then(function (d) {
                    if (!d || !d.link) {
                        throw new Error('Bad configuration. No Notary app link provided');
                    }

                    notaryAppLink = d.link;
                });

            $.stageSelected = function (selectedStage) {
                $.selectedStage = selectedStage;

                $.sessions = sessions.filter(function (x) {
                    return $.selectedStage === 'None' || x.stage === $.selectedStage;
                });
            };

            $.isFormValid = function () {
                var searchForm = FirebaseService.purifyObject(
                    Object.assign({}, $.searchForm)
                );
                var props = Object.keys(searchForm);

                if (!props.length) {
                    return false;
                }

                for (var i = 0; i < props.length; i++) {
                    if (!searchForm[props[i]] || !searchForm[props[i]].trim()) {
                        return false;
                    }
                }

                return true;
            };

            $.search = function () {
                $.isLoading = true;
                $.sessions = [];
                sessions = [];

                var searchForm = FirebaseService.purifyObject(
                    Object.assign({}, $.searchForm)
                );

                if (searchForm.emailCopies) {
                    searchForm.emailCopies = searchForm.emailCopies.split(',').map(function (s) {
                        return s.trim();
                    });
                }

                return NotaryService.searchSessions(searchForm)
                    .then(function (data) {
                        data = data.sort(function (a, b) {
                            if (a.finishedAt && b.finishedAt) {
                                return b.finishedAt._seconds - a.finishedAt._seconds;
                            }

                            if (b.finishedAt) {
                                return 1;
                            }

                            if (a.finishedAt) {
                                return - 1;
                            }

                            return b.scheduledAt._seconds - a.scheduledAt._seconds;
                        }).map(function (session) {
                            session.downloadPageLink = NotaryService.createUserDownloadPageLink(
                                notaryAppLink, session.security
                            );

                            return session;
                        });

                        $.sessions = data;
                        sessions = data;

                        $.stages = Array.from(
                            // eslint-disable-next-line no-undef
                            new Set(data.map(function (x) {
                                return x.stage;
                            }))
                        );
                    })
                    .finally(function () {
                        $.isLoading = false;
                    });
            };

            $.select = function (item, options) {
                if (updateSessionInterval) {
                    $interval.cancel(updateSessionInterval);

                    updateSessionInterval = null;
                }

                $.selectedSession = item;
                $.sessionId = item.id;

                $.credentialAnalysisStatus = getCredentialAnalysisStatus();
                $.quizStatus = getQuizStatus();
                $.device = '';

                if (item.principalDevice) {
                    $.device = UserAgentService.decodeOS(item.principalDevice) + ' ' +
                        UserAgentService.decodeBrowser(item.principalDevice);
                }

                $.isOfficialOnline = utils.isUserOnline($.selectedSession, 'officialLastOnlineAt');
                $.isSignerOnline = utils.isUserOnline($.selectedSession, 'signerLastOnlineAt');
                $.officialConnectivityStats = '';
                $.signerConnectivityStats = '';
                $.isSignerWebRTCOk = false;
                $.isSignerWebRTCError = false;

                if ($.selectedSession.officialConnectivity && $.selectedSession.officialConnectivity.stats) {
                    $.officialConnectivityStats = JSON.parse($.selectedSession.officialConnectivity.stats);
                }

                if ($.selectedSession.signerConnectivity && $.selectedSession.signerConnectivity.stats) {
                    $.signerConnectivityStats = JSON.parse($.selectedSession.signerConnectivity.stats);
                    $.isSignerWebRTCOk = true;
                }

                if ($.selectedSession.signerConnectivity && $.selectedSession.signerConnectivity.error) {
                    $.isSignerWebRTCError = $.selectedSession.signerConnectivity.error;
                }

                if (!options || !options.skipLogs) {
                    $.getLogs();
                }
            };

            $.getLogs = function () {
                var id = $.sessionId;

                return NotaryService.getLogs({ id: id })
                    .then(function (data) {
                        $.data = data;
                        $.audits = $.data.audits || [];
                        $.mitekErrors = $.data.audits.filter(function (entry) {
                            return utils.getProperty(entry, 'extra.findings.authenticated') === false;
                        });
                    });
            };

            $.export = function () {
                return CsvExportService.exportAndDownload({
                    items: $.sessions,
                    titles: [
                        'Session id',
                        'Stage',
                        'Signer name',
                        'signer email',
                        'cc recipient email',
                        'third party payer email',
                        'FirmID',
                        'UserID (official)',
                        'Email (official)',
                        'Number of session documents',
                        'Date (scheduled)',
                        'Date (completed)',
                        'Session type (KBA or PK)',
                        'Download page',
                        'Acuity appointment ID',
                        'Acuity appointment type'
                    ],
                    fields: [
                        'id',
                        'stage',
                        {
                            user: function (item) { return item.fullName; }
                        }, {
                            user: function (item) { return item.email; }
                        }, {
                            emailCopies: function (item) {
                                if (!item || !item.length) {
                                    return '';
                                }

                                if (!Array.isArray(item)) {
                                    item = [item];
                                }

                                return item.filter(Boolean).join(', ');
                            }
                        },
                        'payerEmail',
                        'firmId',
                        'officialUid',
                        {
                            official: function (item) {
                                return item && item.email || '';
                            }
                        },
                        'documentsCount',
                        {
                            createdAt: function (item) {
                                if (item) {
                                    return utils.toDate(item).toISOString() || '';
                                }

                                return '';
                            }
                        }, {
                            finishedAt: function (item) {
                                if (item) {
                                    return utils.toDate(item).toISOString() || '';
                                }

                                return '';
                            }
                        }, {
                            isPersonallyKnown: function (item) { return item ? 'PK' : 'KBA'; }
                        },
                        'downloadPageLink',
                        'appointmentId',
                        'appointmentType'
                    ],
                    filename: 'Sessions.csv'
                });
            };

            var getCredentialAnalysisStatus = function () {
                if (!$.selectedSession || !$.selectedSession.stage) {
                    return {};
                }

                if ($.selectedSession.stage === 'start') {
                    return { text: 'Not started', class: '' };
                }

                if ($.selectedSession.stage === 'idVerificationFailed') {
                    return { text: 'Failed', class: '' };
                }

                if (
                    [
                        'idVerificationStart',
                        'idVerificationFront',
                        'mobileVerificationIdCapture',
                        'mobileVerificationPersonalDetails',
                        'mobileVerificationSignatureInitial',
                        'mobileVerificationSignatureCreation'
                    ].indexOf($.selectedSession.stage) >= 0
                ) {
                    return { text: 'In progress', class: '' };
                }

                return { text: 'Passed', class: '' };
            };

            var getQuizStatus = function () {
                if (!$.selectedSession || !$.selectedSession.stage) {
                    return {};
                }

                if (
                    [
                        'start',
                        'idVerificationFront',
                        'idVerificationFailed',
                        'mobileVerificationIdCapture',
                        'mobileVerificationPersonalDetails',
                        'mobileVerificationSignatureInitial',
                        'mobileVerificationSignatureCreation',
                        'personalDetails',
                        'cameraCheckStart',
                        'signatureInitial',
                        'signatureStart',
                        'signatureCaptured',
                        'userInRoom',
                        'officialInRoom',
                        'checkPersonalDetail'
                    ].indexOf($.selectedSession.stage) >= 0
                ) {
                    return { text: 'Not started', class: '' };
                }

                if ($.selectedSession.stage === 'KBAFails') {
                    return { text: 'Failed', class: '' };
                }

                if (
                    [
                        'idVerificationFront',
                        'mobileVerificationIdCapture',
                        'mobileVerificationPersonalDetails',
                        'mobileVerificationSignatureInitial',
                        'mobileVerificationSignatureCreation'
                    ].indexOf($.selectedSession.stage) >= 0
                ) {
                    return { text: 'In progress', class: '' };
                }

                return { text: 'Passed', class: '' };
            };

            if (searchObject.hasOwnProperty('sessionid')) {
                $.searchForm.id = searchObject.sessionid;
                $.sessionId = searchObject.sessionid;
                $.search();
            }
        }
    ]);
})(angular.module('onfileApp'));
