(function (app) {
    app.controller('SearchFilingsListCtrl', [
        '$scope', 'EServiceResource', 'CaseNameService',
        'ElasticSearchResource', 'EnvironmentService', 'SearchQueryComposerBuilder',
        '$rootScope', 'PubSubService', 'ScheduleHearingService',

        function (
            $, EServiceResource, CaseNameService,
            ElasticSearchResource, EnvironmentService, SearchQueryComposerBuilder,
            $rootScope, PubSubService, ScheduleHearingService
        ) {
            $rootScope.showList = true;

            $.items = [];
            $.query = null;

            var reset = function () {
                $.paginationParams = {
                    size: 25,
                    from: 0
                };
                $.hideLoadMore = false;
                $.query = null;
            };

            var getQueryBuilder = function (query) {
                var searchQueryComposer = SearchQueryComposerBuilder.build();

                if (query) {
                    return searchQueryComposer.setQuery(query);
                }

                if ($.query) {
                    return searchQueryComposer.setQuery($.query);
                }

                return searchQueryComposer
                    .setQuery(ElasticSearchResource.getFilingsQueryObject())
                    .attachFilingType()
                    .attachFirmId(EnvironmentService.firm_id())
                    .attachFilledDateSorting();
            };

            $.loadServices = function (newQuery, params) {
                var isNewSearch = !!newQuery;
                var query = getQueryBuilder(newQuery)
                    .attachPagination($.paginationParams)
                    .getQuery();
                var loaderMessage = (params && params.message) || 'Searching filings';

                $rootScope.startLoader({ message: loaderMessage });

                return ElasticSearchResource.searchES(query)
                    .finally(function () {
                        $rootScope.stopLoader();
                    })
                    .then(function (res) {
                        if (isNewSearch) {
                            $.items = [];
                        }

                        $.items = $.items.concat(res);
                        $.paginationParams.from += res.length;

                        if (res.length < $.paginationParams.size) {
                            $.hideLoadMore = true;
                        }

                        return $.items;
                    })
                    .then(function (items) {
                        return ScheduleHearingService.populateHearingDataToFilingList(items);
                    });
            };

            reset();
            $.loadServices(undefined, { message: 'Loading filings' });

            PubSubService.on('filingSearchChanged', function (query) {
                reset();
                $.query = query;

                return $.loadServices(query);
            });
        }
    ]);
})(angular.module('onfileApp'));
