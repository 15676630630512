(function (app) {
    app.factory('NotarySessionsResourceFactory', [
        '$resource', '$q', 'notaryTokenUtil',
        function ($resource, $q, notaryTokenUtil) {
            return {
                create: function (options) {
                    var headers = {
                        'X-OFFICIAL-ID': options.officialId,
                        'X-FIRM-ID': options.firmId
                    };

                    if (options.sessionId) {
                        headers['X-SESSION-ID'] = options.sessionId;
                    }

                    var pathParams = { sessionId: '@sessionId' };
                    var resource = $resource(notaryTokenUtil.notaryApiHost + '/sessions', {
                        sessionId: '@sessionId',
                        cursor: '@cursor'
                    }, {
                        token: {
                            url: '/token/notary',
                            method: 'GET',
                            isArray: false,
                            cache: false
                        },
                        logs: {
                            url: notaryTokenUtil.notaryApiHost + '/logs/:id',
                            method: 'GET',
                            isArray: false,
                            cache: false,
                            ignoreLoaderInterceptor: true
                        },
                        search: {
                            url: notaryTokenUtil.notaryApiHost + '/sessions/search',
                            method: 'POST',
                            isArray: true,
                            cache: false,
                            ignoreLoaderInterceptor: true,
                            ignoreErrorPopup: true
                        },
                        query: {
                            method: 'GET',
                            isArray: true,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        create: {
                            method: 'POST',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        status: {
                            url: notaryTokenUtil.notaryApiHost + '/sessions/status?session-id=:sessionId',
                            method: 'GET',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers),
                            ignoreLoaderInterceptor: true
                        },
                        update: {
                            url: notaryTokenUtil.notaryApiHost + '/sessions/:sessionId',
                            method: 'PUT',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers),
                            transformRequest: function (body) {
                                var params = Object.keys(pathParams);

                                for (var i = 0; i < params.length; i++) {
                                    if (body.hasOwnProperty(params[i])) {
                                        delete body[params[i]];
                                    }
                                }

                                return angular.toJson(body);
                            }
                        },
                        delete: {
                            url: notaryTokenUtil.notaryApiHost + '/sessions/:sessionId',
                            method: 'DELETE',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        getRecordingUrl: {
                            url: notaryTokenUtil.notaryApiHost + '/sessions/:sessionId/recording',
                            method: 'GET',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        sendPaymentEmail: {
                            url: notaryTokenUtil.notaryApiHost + '/payment/email',
                            method: 'POST',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        sendDownloadPageEmail: {
                            url: notaryTokenUtil.notaryApiHost + '/notifications',
                            method: 'POST',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        get: {
                            url: notaryTokenUtil.notaryApiHost + '/sessions/:id',
                            method: 'GET',
                            isArray: false,
                            cache: false,
                            ignoreLoaderInterceptor: true,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        getBatch: {
                            url: notaryTokenUtil.notaryApiHost + '/sessions/batch',
                            method: 'POST',
                            isArray: true,
                            cache: false,
                            ignoreLoaderInterceptor: true,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        deleteDocument: {
                            url: notaryTokenUtil.notaryApiHost + '/documents/:documentId/session',
                            method: 'DELETE',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        reducedSearch: {
                            url: notaryTokenUtil.notaryApiHost + '/sessions/reduced',
                            method: 'POST',
                            isArray: true,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        uploadId: {
                            url: notaryTokenUtil.notaryApiHost + '/id/verify',
                            method: 'POST',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        sendMobileVerificationLink: {
                            url: notaryTokenUtil.notaryApiHost + '/verification-link',
                            method: 'POST',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        getPersonaDocument: {
                            url: notaryTokenUtil.notaryApiHost + '/persona/document/:id',
                            method: 'GET',
                            ignoreLoaderInterceptor: true,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        }
                    });

                    return notaryTokenUtil.checkToken(resource, $q)
                        .then(function () {
                            return resource;
                        });
                }
            };
        }]
    );
})(angular.module('onfileApp'));
