(function (app) {
    app.controller('IndexFilesCtrl', [
        '$scope', '$rootScope', 'PubSubService',

        function ($, $rootScope, PubSubService) {
            $rootScope.showNavigation = true;
            $rootScope.showWorkspace = true;

            angular.extend($, {
                package: null
            });

            $.$on('eProductionPackage.loaded', function (e, ePackage) {
                $.package = ePackage;
            });

            PubSubService.on('fileManagerFilesReceived', function (data) {
                var response = data.response;
                var path = data.path;

                if (path === '/') {
                    $.filesInfoSize = parseInt(response.getResponseHeader('x-total-size'));
                    $.$apply(); // @HACK force to re-render a view.
                }
            });
        }
    ]);
})(angular.module('onfileApp'));