(function (app) {
    app.factory('ServiceTypeResource', [
        '$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/service-types', {}, {
                query: urlEncodedResource({
                    method: 'POST',
                    isArray: true,
                    cache: false
                }),
                common: {
                    url: '/service-types/common',
                    method: 'GET',
                    isArray: true,
                    cache: false
                }
            });
        }
    ]);
})(angular.module('onfileApp'));