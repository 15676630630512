(function (app) {
    app.factory('RefreshDataResource', ['$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/api/data/onboard', {}, {
                onBoard: urlEncodedResource({
                    method: 'GET',
                    isArray: false
                }),
                refresh: {
                    url: '/api/data/refresh',
                    method: 'GET',
                    isArray: false
                }
            });
        }]);
})(angular.module('onfileApp'));