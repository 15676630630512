(function (app) {
    app.factory('LoginResource', ['$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/auth/login', {}, {
                login: urlEncodedResource({
                    method: 'POST',
                    isArray: false,
                    cache: false
                })
            });
        }]);
})(angular.module('onfileApp'));
