(function (app) {
    app.controller('EServicesListCtrl', [
        '$scope', '$rootScope', '$stateParams',
        '$state', 'EServiceResource', '$anchorScroll',
        'FilingService', 'CaseNameService', 'EnvironmentService',

        function (
            $, $rootScope, $stateParams,
            $state, EServiceResource, $anchorScroll,
            FilingService, CaseNameService, EnvironmentService
        ) {
            var FILING_TYPE = 'filing';
            var SERVICE_TYPE = 'service';
            var CASE_TYPE = 'case';
            var SERVICE_AND_FILING_TYPE = 'service_and_filing';

            var currentStateName = $state.$current.name;
            $rootScope.search = '';
            $rootScope.filersList = [
                {
                    title: 'Me',
                    values: [EnvironmentService.userId(), EnvironmentService.email()]
                }, {
                    title: 'All filers'
                }
            ];
            $rootScope.selectedFiler = $rootScope.filersList[1];

            angular.extend($, {
                activeServiceId: $stateParams.id,
                section: $state.$current.name,
                services: [],
                pageSize: 250,
                lastService: null
            });

            $.FilingService = FilingService;

            $.getItemLinkState = function () {
                if (getFilingType() === FILING_TYPE) {
                    return 'filingsView({id: item.id})';
                } else if (getFilingType() === SERVICE_TYPE) {
                    return 'servicesView({id: item.id})';
                } else if (getFilingType() === CASE_TYPE) {
                    return 'caseView({id: item.case_id})';
                }
            };

            // Callback function which is called when URL state changes.
            this.uiOnParamsChanged = function (changedParams) {
                // If current active eService ID is changed - refresh variable for a view.
                if (changedParams.hasOwnProperty('id')) {
                    $.activeServiceId = changedParams.id;
                }
            };

            // If LoadServices method is not defined - defines its own method
            if ($.loadServices === undefined) {
                $.loadServices = function () {
                    return EServiceResource
                        .query({ limit: $.pageSize, last: $.lastService })
                        .$promise
                        .then(function (services) {
                            $.lastService = services[0];

                            return services;
                        })
                        .then(function (services) {
                            var currentFilingType = getFilingType();
                            var list = services
                                .filter(function (services) {
                                    return filterFilings(services, currentFilingType);
                                });

                            FilingService.services(services);
                            list = $.pageSize > list.length ? list : list.slice(list.length - $.pageSize);

                            list = list.map(function (service) {
                                CaseNameService.decorate(service);
                                return service;
                            });

                            $.services = $.services.concat(list);

                            $anchorScroll();
                        });
                };
            }

            $.loadServices();

            var getFilingType = function () {
                if (currentStateName.indexOf(FILING_TYPE) >= 0) {
                    return FILING_TYPE;
                } else if (currentStateName.indexOf(SERVICE_TYPE) >= 0) {
                    return SERVICE_TYPE;
                } else if (currentStateName.indexOf(CASE_TYPE) >= 0) {
                    return CASE_TYPE;
                }
            };

            var filterFilings = function (service, type) {
                if (type === FILING_TYPE) {
                    return service.type_code === FILING_TYPE || service.type_code === SERVICE_AND_FILING_TYPE;
                }

                return type === service.type_code;
            };
        }
    ]);
})(angular.module('onfileApp'));
