(function (app) {
    app.service('FilerTypeService', [
        function () {
            var defaultFilerTypes = ['Not Applicable'];

            this.getDefaultFilerType = function (filerTypes) {
                var selected;

                if (!filerTypes || !filerTypes.length) {
                    return null;
                }

                for (var i = 0; i < defaultFilerTypes.length; i++) {
                    selected = filerTypes.find(function (f) { return f.name === defaultFilerTypes[i]; });

                    if (selected) {
                        return selected;
                    }
                }

                return null;
            };
        }
    ]);
})(angular.module('onfileApp'));
