(function (app) {
    app.service('downloadFile', [
        function () {
            function downloadFile(options) {
                var downloadLink = angular.element('<a></a>');

                downloadLink.attr('href', options.url);
                downloadLink.attr('download', options.filename);

                if (options.separateWindow) {
                    downloadLink.attr('target', '_blank');
                }

                return downloadLink[0].click();
            }

            return downloadFile;
        }
    ]).service('prepareProxyLink', [
        function () {
            function prepareProxyLink(url) {
                return '/pdf?link=' + encodeURIComponent(url);
            }

            return prepareProxyLink;
        }
    ]);
})(angular.module('onfileApp'));