(function (app) {
    app.service('PubSubService', [
        function () {
            var subscribers = {};
            var pendingEvents = [];

            this.on = function (event, cb) {
                if (subscribers[event] === undefined) {
                    subscribers[event] = [];
                }

                subscribers[event].push(cb);
                handlePendingEvents(event, cb);

                return this;
            };

            this.emit = function (event, data) {
                if (subscribers[event]) {
                    subscribers[event].map(function (cb) {
                        cb(data);
                    });
                } else {
                    pushToPendingEvents(event, data);
                }

                return this;
            };

            var handlePendingEvents = function (event, cb) {
                for (var i = 0; i < pendingEvents.length; i++) {
                    if (pendingEvents[i].event !== event) {
                        continue;
                    }

                    cb(pendingEvents[i].data);
                    pendingEvents.splice(i, 1);
                }
            };

            var pushToPendingEvents = function (event, data) {
                pendingEvents.push({ event: event, data: data });
            };
        }
    ]);
})(angular.module('onfileApp'));
