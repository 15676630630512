(function(app){
    app.directive('previewableLink',['$sce','$document','$http',
        function($sce,$document, $http){
            return {
                templateUrl : '',
                strict :'A',
                scope : {
                    url : '@url'
                },
                controller : function($scope, $sce, $http){
                    $scope.trustedUrl = $sce.trustAsResourceUrl($scope.url);
                },
                link : function($, el){
                    $.showPreview = function($event){
                        $.show = true;
                        $event.stopPropagation();
                        $.frameUrl = $.trustedUrl;
                        //return false;

                        // $http.get({url : $.url ,method :'jsonp'},
                        //     {responseType:'arraybuffer'})
                        //     .success(function (response) {
                        //         var file = new Blob([(response)], {type: 'application/pdf'});
                        //         var fileURL = window.URL.createObjectURL(file);
                        //         $.content = $sce.trustAsResourceUrl(fileURL);
                        //     });
                    },
                    $.hidePreview = function(){
                        $.show = false;
                    };
                    $document.bind('click', function (e) {
                        $.$apply(function () { $.hidePreview(); });
                    });
                },
                templateUrl: '/assets/app/shared/directives/previewableLink/previewableLinkTemplate.html'
            };
        }
    ]);
})(angular.module('onfileApp'));