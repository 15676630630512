(function (app) {
    app.run([
        '$rootScope', 'MarketRulesService',
        function ($rootScope, MarketRulesService) {
            $rootScope.IsEnabled = function () {
                return false;
            };

            $rootScope.IsBlocked = function () {
                return false;
            };

            $rootScope.IsRequired = function () {
                return false;
            };

            // @HACK for async loading of configuration.
            MarketRulesService.loaded.then(function () {
                $rootScope.IsEnabled = function (feature) {
                    return MarketRulesService.enabled(feature);
                };

                $rootScope.IsBlocked = $rootScope.IsDisabled = function (feature) {
                    return !$rootScope.IsEnabled(feature);
                };

                $rootScope.IsRequired = function (feature) {
                    if (feature === 'filing_attorney' && $rootScope.isIndividualFirm) {
                        return false;
                    }

                    return MarketRulesService.isRequired(feature);
                };
            });
        }
    ]);
})(angular.module('onfileApp'));
