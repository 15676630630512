/**
 * This directive is used for displaying of a Case information (Case Participants, Service contacts) in tabs
 * For passing case information into directive use `case` attribute (optional).
 *
 * Directive can fetch Cse information itself if there is needed params in URL (`caseId` and `courtId`)
 * and `case` attribute is not set.
 *
 * Example of URL:
 * http://onfile.com/some-resource/#/some-angular-resource/id?caseId=a58f8422-46ad-425c-aa7f-b94ad783f6f8&courtId=coa:8
 */

(function (app) {
    app.directive('caseDetails', [
        'CaseResource',
        function (CaseResource) {
            function getValueByPattern(string, pattern) {
                var match = string.match(pattern);
                return match && match.hasOwnProperty(1) ? match[1] : null;
            }

            return {
                strict: 'A',
                scope: {
                    // When case is selected initiates changing of URL state to state, which is stored in this property
                    case: '=?'
                },
                link: function ($, element, attrs) {
                    var isCasePassed = false;

                    for (var i = 0; i < element[0].attributes.length; i++) {
                        if (element[0].attributes[i].nodeName === 'case') {
                            isCasePassed = true;
                            break;
                        }
                    }

                    $.getHistory = function (eCase) {
                        eCase.serviceContactHistory = undefined;
                        CaseResource.history({
                            case_id: eCase.id,
                            court_code: eCase.court_code
                        }).$promise
                            .then(function (items) {
                                eCase.serviceContactHistory = items;
                            });
                    };

                    $.errorForCodeExists = function (code) {
                        if ($.case && $.case.errors && $.case.errors.filter) {
                            return $.case.errors && $.case.errors.filter(function (error) {
                                return error.ErrorCode === code;
                            }).length > 0;
                        }

                        return false;
                    };


                    var serializeCase = function(item)
                    {
                        if(item === undefined || item === null)
                            return 'case object not available at this time.';

                        
                        var keys = Object.keys(item);
                        var newItem = {};
                        for(var i = 0; i <keys.length; i++)
                        {
                            if(keys[i].indexOf('$') >= 0 )
                                continue;

                            newItem[keys[i]] = item[keys[i]];
                        }


                        return JSON.stringify(newItem, null,2);
                    };

                    $.$watch('case',function(){
                        $.raw = serializeCase($.case);
                    });

                    // If the 'case' variable is not passed to Directive, the Case should be loaded
                    if (!isCasePassed) {
                        var caseId = getValueByPattern(window.location.href, /caseId=([a-zA-Z0-9-]+)*/);
                        var courtId = getValueByPattern(window.location.href, /courtId=([a-zA-Z0-9-:]+)*/);

                        // If there is a Court's ID and a Case ID searching a Case.
                        if (caseId && courtId) {
                            CaseResource.get({
                                court_code: courtId,
                                tracking_number: caseId,
                                with: 'participants,service_contacts'
                            }).$promise
                                .then(function (res) {
                                    $.case = res;
                                })
                                .catch(function (res) {
                                    // @TODO handler if something went wrong...
                                });
                        }
                    }
                },
                templateUrl: '/assets/app/shared/directives/caseDetails/case-details.html'
            };
        }
    ]);
})(angular.module('onfileApp'));
