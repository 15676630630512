(function (app) {
    app.service('WhitelistAccessService', [
        'FirebaseService', '$filter', 'ToastrService',
        '$q', 'EnvironmentService', 'LoginTokenResource',
        'FirmResource',

        function (FirebaseService, $filter, ToastrService,
            $q, EnvironmentService, LoginTokenResource,
            FirmResource) {
            this.isFirmWhitelisted = function (firmId) {
                return FirmResource
                    .isWhitelisted({id: firmId})
                    .$promise;
            };

            this.isValidToken = function (token) {
                return LoginTokenResource.verify({token: token})
                    .$promise
                    .then(function (res) {
                        var valid = getExpiryDate(res.week, res.days).getTime() > Date.now();

                        return {
                            valid: valid,
                            tag: res.tag
                        };
                    });
            };

            function getExpiryDate(weekNumber, noOfDays) {
                var year = new Date().getFullYear();
                weekNumber = parseInt(weekNumber);
                noOfDays = parseInt(noOfDays);

                //Create a date object starting january first of chosen year, plus the number of days in a week multiplied by the week number to get the right date.
                //new Date(year, month, day, hours, minutes, seconds, milliseconds)
                return new Date(year, 0, 1 + ((weekNumber - 1) * 7) + noOfDays);
            }

            Date.prototype.getWeek = function () {
                var date = new Date(this.getTime());
                date.setHours(0, 0, 0, 0);
                // Thursday in current week decides the year.
                date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
                // January 4 is always in week 1.
                var week1 = new Date(date.getFullYear(), 0, 4);
                // Adjust to Thursday in week 1 and count number of weeks from date to week1.
                return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
            };

            String.prototype.format = function () {
                var formatted = this;
                for (var i = 0; i < arguments.length; i++) {
                    var regexp = new RegExp('\\{' + i + '\\}', 'gi');
                    formatted = formatted.replace(regexp, arguments[i]);
                }
                return formatted;
            };
        }
    ]);
})(angular.module('onfileApp'));