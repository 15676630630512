(function (app) {
    app.service('TemplateService', [
        '$http', 'EnvironmentService', 'templateUrls',
        function TemplateService($http, EnvironmentService, templateUrls) {
            var market = EnvironmentService.market();

            this.getTemplateUrl = function (templateName) {
                return templateUrls[templateName][market] || templateUrls[templateName].default;
            };

            this.getTemplate = function (templateName) {
                var url = this.getTemplateUrl(templateName);

                return $http
                    .get(url)
                    .then(function (response) {
                        return response.data;
                    });
            };
        }]
    );
})(angular.module('onfileApp'));
