(function (app) {
    app.service(
        'UserProfileService', ['$state', '$window', 'ToastrService', userProfileService]);
    function userProfileService ($state, $window, ToastrService) {
        var profileKey = 'userProfile';

        function getProfile () {
            var result = $window.localStorage.getItem(profileKey);
            if (!result) {
                return {};
            }
            return JSON.parse(result);
        }

        function saveHomePage () {
            var name = '',
                profile = getProfile(),
                currentUrl = $window.location.href;
            if ($state.current && $state.current.name) {
                name = $state.current.name;
            }

            if (profile && profile.homePage && profile.homePage.url === currentUrl) {
                return;
            }

            profile.homePage = {
                name: name,
                url: currentUrl
            };

            $window.localStorage.setItem(profileKey, JSON.stringify(profile));

            ToastrService.showSuccess('New homepage set');
        }

        return {
            saveHomePage: saveHomePage,
            getProfile: getProfile
        };
    }
})(angular.module('onfileApp'));
