(function (app) {
    app.controller('NotaryRequestSessionCtrl', [
        '$scope', '$rootScope', '$stateParams',
        '$mdDialog', '$timeout', 'EnvironmentService',
        'DocumentGroupsResourceFactory', 'ToastrService', 'PermissionsService',
        'OfficialsResourceFactory', 'PDFService', 'APPOINTMENT_ID_COPIED_TOASTER_KEY',
        function (
            $, $rootScope, $stateParams,
            $mdDialog, $timeout, EnvironmentService,
            DocumentGroupsResourceFactory, ToastrService, PermissionsService,
            OfficialsResourceFactory, PDFService, APPOINTMENT_ID_COPIED_TOASTER_KEY
        ) {
            $.IframeUrl = null;
            $.tab = 'docs';
            $.isAssignmentAvailable = false;
            $.officials = [];
            $.model = {
                selectedOfficial: null
            };
            $.currentCount = { count: null };
            $.isAdmin = EnvironmentService.isAdmin() == 1 ? true : false;
            $.isNotaryAdmin = false;
            $.toSystemTime = EnvironmentService.toSystemTime;

            $.changeTab = function (tab) {
                $.tab = tab;
            };

            $.showDocument = function (doc) {
                $.currDoc = doc;
                console.log(doc)
                if (
                    doc.documentRequestRelation &&
                    doc.documentRequestRelation[0] &&
                    doc.documentRequestRelation[0].status === "signed" &&
                    doc.documentRequestRelation[0].url
                ) {
                    $.currDoc.url = doc.documentRequestRelation[0].url;
                }
                if(EnvironmentService.environment() === 'development') {
                    // $.IframeUrl = `https://demo.onlinenotary.us/#/document/${encodeURIComponent($.currDoc.url)}`;
                    $.IframeUrl = `https://demo.onlinenotary.us/#/document/${$.currDoc.documentId}`;
                    console.log($.IframeUrl)
                }
                else if (EnvironmentService.environment() === 'production') {
                    $.IframeUrl = `https://app.onlinenotary.us/#/document/${$.currDoc.documentId}`;
                }
            };

            $.delete = function () {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        return resource.deleteDocGroup({ id: $.item.id }).$promise;
                    })
                    .then(function () {
                        ToastrService.showSuccess('Request has been removed');

                        $rootScope.loadSessionRequests()
                            .then(function (items) {
                                return establishCurrentItem(items);
                            });
                    });
            };

            $.createNotarySession = function () {
                $mdDialog.show({
                    controller: 'CreateNotarySessionCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/CreateInternalNotarySession/' +
                        'create-notary-session.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    locals: {
                        matter: Object.assign({}, $.item, {
                            documents: $.item.documents.map(function (document) {
                                document.isChecked = true;
                                document.date = $.item.requestedDatetime
                                return document;
                            })
                        })
                    }
                });
            };

            $.changeOfficial = function () {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        return resource.update({
                            id: $.item.id,
                            assignedToFirmId: $.model.selectedOfficial.firmId,
                            assignedToUserId: $.model.selectedOfficial.uid
                        }).$promise;
                    })
                    .then(function () {
                        $.item.assignedToUserId = $.model.selectedOfficial.uid;

                        ToastrService.showSuccess('New notary successfully assigned');
                    });
            };

            $.changeDocumentCount = function () {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        return resource.update({
                            id: $.item.id,
                            documentsCount: $.currentCount.count
                        }).$promise;
                    })
                    .then(function () {
                        $.item.documentsCount = $.currentCount.count;

                        ToastrService.showSuccess(
                            "Documents Count Updated!"
                        );
                    });
            };

            $.openAddPrivateNotePopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        $scope.officialPrivateNotes = $.item.officialPrivateNotes || '';

                        $scope.close = function () {
                            $mdDialog.hide();
                        };

                        $scope.submit = function () {
                            return DocumentGroupsResourceFactory.create()
                                .then(function (resource) {
                                    return resource.update({
                                        id: $.item.id,
                                        extensions: {
                                            officialPrivateNotes: $scope.officialPrivateNotes
                                        }
                                    }).$promise;
                                })
                                .then(function () {
                                    $.item.officialPrivateNotes = $scope.officialPrivateNotes;

                                    ToastrService.showSuccess('Notes successfully added');
                                    $scope.close();
                                });
                        };
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/session-requests/view/private-note-popup.html' +
                        '?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.download = function (link, filename) {
                if (!link) {
                    link = $.currDoc.url;
                }
                if (!filename) {
                    filename = $.currDoc.name;
                }
                return PDFService.download(link, filename);
            };

            $.appointmentIdCopied = function () {
                ToastrService.showMessageByKey(APPOINTMENT_ID_COPIED_TOASTER_KEY);
            };

            var officialsLoaded = OfficialsResourceFactory.create()
                .then(function (resource) {
                    return resource.query().$promise;
                })
                .then(function (officials) {
                    $.officials = officials;
                });

            PermissionsService.isFeatureAvailable('notaryScheduling')
                .then(function (isAvailable) {
                    $.isAssignmentAvailable = isAvailable;
                });

            var establishCurrentItem = function (items, currentId) {
                if (!currentId) {
                    $.item = null;

                    return null;
                }

                $.item = items.find(function (r) {
                    return r.id === parseInt(currentId);
                });

                if ($.item && $.item.documents[0]) {
                    $.showDocument($.item.documents[0]);
                    $.currentCount.count = $.item.documentsCount
                }

                if ($.item) {
                    officialsLoaded.then(function () {
                        $.model.selectedOfficial = $.officials.find(function (official) {
                            $.isNotaryAdmin = official.roles ? official.roles.some((item) => ['notaryAdmin', 'scheduler', 'remoteSignSessionCreator'].includes(item)) : false;
                            return $.item.assignedToUserId === official.uid;
                        });
                    });
                }
            };

            this.uiOnParamsChanged = function (changedParams) {
                if (!changedParams || !changedParams.id) {
                    return;
                }

                $timeout(function () { $rootScope.customListHeader = true; }, 0);
                $rootScope.itemsLoaded.then(function (items) {
                    establishCurrentItem(items, changedParams.id);
                });
            };

            this.uiOnParamsChanged($stateParams);
        }
    ]);
})(angular.module('onfileApp'));
