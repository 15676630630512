(function (app) {
    app.controller('AddDocumentsToMatterCtrl', [
        '$scope', '$mdDialog', 'DocumentGroupsResourceFactory',
        'S3FileUploader', '$q', '$state',
        '$rootScope', 'ToastrService', 'matter',
        'utils',
        function (
            $, $mdDialog, DocumentGroupsResourceFactory,
            S3FileUploader, $q, $state,
            $rootScope, ToastrService, matter,
            utils
        ) {
            var resourceCreated = DocumentGroupsResourceFactory.create();
            $.files = [];

            $.close = function () { $mdDialog.hide(); };

            $.submit = function () {
                var cachedResource;

                return resourceCreated
                    .then(function (resource) {
                        cachedResource = resource;

                        var fileUploads = $.files.map(function (file) {
                            return cachedResource.uploadUrl({ filename: file.name })
                                .$promise
                                .then(function (res) {
                                    return S3FileUploader.uploadByPreSignUrl(res.url, file)
                                        .then(function () {
                                            return Object.assign({}, file, res);
                                        });
                                });
                        });

                        return $q.all(fileUploads);
                    })
                    .then(function (files) {
                        var requestData = files.map(function (file) {
                            file.displayedName = file.displayedName || file.filename;

                            return Object.assign(
                                {},
                                utils.extractProps(file, ['filename', 'key', 'displayedName']),
                                {
                                    documentGroupId: matter.id,
                                    type: matter.type
                                }
                            );
                        });

                        return cachedResource.createDocumentsBatch(requestData).$promise;
                    })
                    .then(function () {
                        ToastrService.showSuccess('New documents uploaded');
                        $rootScope.$emit('updateAllMatters', { reloadMatterId: matter.id });
                        $.close();
                    });
            };

            $.filesSelected = function (files) {
                var newFiles = files.map(function (file) {
                    file.displayedName = file.name;

                    return file;
                });

                $.files = $.files.concat(newFiles);

                return true;
            };

            $.removeFile = function (i) {
                $.files.splice(i, 1);
            };
        }
    ]);
})(angular.module('onfileApp'));
