(function (app) {
    app.controller('CreateNotarySessionCtrl', [
        '$scope', '$mdDialog', '$state',
        '$rootScope', 'utils', 'typeOfNotarizationOptions',
        'matter', 'SiteConfigurationService', 'NotaryService',
        'DocumentGroupsResourceFactory', 'PermissionsService', 'OfficialsResourceFactory',
        'EnvironmentService',
        function (
            $scope, $mdDialog, $state,
            $rootScope, utils, typeOfNotarizationOptions,
            matter, SiteConfigurationService, NotaryService,
            DocumentGroupsResourceFactory, PermissionsService, OfficialsResourceFactory,
            EnvironmentService
        ) {
            var siteConfigurationLoaded = SiteConfigurationService.getConfigurationForPage('notary');
            var mapSessionProps = [
                ['payerEmail', 'payerEmail'],
                ['userTimezone', 'userTimezone'],
                ['cc', 'copy1'],
                ['officialPrivateNotes', 'officialPrivateNotes'],
                ['paymentSettings.rate', 'paymentSettings.rate'],
                ['paymentSettings.additionalDocumentsRate', 'paymentSettings.additionalDocumentsRate'],
                ['paymentMode', 'paymentMode'],
                ['documentsCount', 'documentsCount']
            ];

            $scope.officials = [];
            $scope.isAssignmentAvailable = false;
            $scope.session = {
                official: null,
                name: matter.name,
                typeOfNotarization: typeOfNotarizationOptions[0].value,
                userName: matter.userName,
                userEmail: matter.userEmail,
                userPhone: matter.userPhone,
                officialNotes: matter.message || null,
                appointmentId: matter.appointmentId,
                appointmentType: matter.appointmentType,
                multipleSigner: matter.multipleSigner === 'yes',
                documentsCount: matter.documentsCount,
                lowesEmail: matter.lowesEmail,
            };

            for (var i = 0; i < mapSessionProps.length; i++) {
                if (matter[mapSessionProps[i][0]]) {
                    $scope.session[mapSessionProps[i][1]] = matter[mapSessionProps[i][0]];
                }
            }

            $scope.files = matter.documents
                .filter(function (d) {
                    return d.isChecked;
                })
                .map(function (d) {
                    d.isCopied = true;

                    return d;
                });
            $scope.activeStep = 0;
            $scope.timeSlots = utils.generateTimeSlots('00:00', '23:00', 30);
            $scope.minDate = new Date();
            $scope.typeOfNotarizationOptions = utils.clone(typeOfNotarizationOptions);

            if (matter.requestedDatetime) {
                var requestedDatetime = matter.requestedDatetime ?
                    EnvironmentService.toSystemTime(matter.requestedDatetime, function (m) {
                        return m.toDate();
                    }) : new Date();
                var timeslot = utils.generateTimeSlot(requestedDatetime);

                $scope.session.date = requestedDatetime;
                $scope.session.time = $scope.timeSlots.find(function (slot) {
                    return slot.formatted === timeslot.formatted;
                });

                if (!$scope.session.time) {
                    $scope.session.time = $scope.timeSlots.find(function (slot) {
                        return slot.hours === timeslot.hours;
                    });
                }
            }

            $scope.changeDate = function (newDate) {
                $scope.files.map(function (document) {
                    document.date = newDate;
                });
            };

            $scope.next = function () {
                if ($scope.activeStep === 1) {
                    $scope.errorText = '';

                    return siteConfigurationLoaded
                        .then(function (notary) {
                            if (!notary || !notary.link) {
                                throw new Error('Bad configuration. No Notary app link provided');
                            }

                            $scope.session.files = $scope.files;

                            return NotaryService.createSession($scope.session, { notaryAppLink: notary.link });
                        })
                        .then(function (data) {
                            var cachedResource = null;

                            $scope.created = data;
                            $scope.activeStep++;

                            return DocumentGroupsResourceFactory.create()
                                .then(function (resource) {
                                    cachedResource = resource;

                                    return resource.create({
                                        name: $scope.session.name,
                                        type: 'notarySession',
                                        extensions: {
                                            isPersonallyKnown: $scope.session.isPersonallyKnown,
                                            message: $scope.session.message,
                                            userEmail: $scope.session.userEmail,
                                            userName: $scope.session.userName,
                                            scheduledAt: toTimestamp($scope.session.date, $scope.session.time),
                                            typeOfNotarization: $scope.session.typeOfNotarization,
                                            notarySessionFirestoreId: data.session.id,
                                            officialPrivateNotes: $scope.session.officialPrivateNotes,
                                            multipleSigner: $scope.session.multipleSigner
                                        },
                                        documentIds: utils.flattenArrayByProp($scope.files, 'id')
                                    }).$promise;
                                })
                                .then(function () {
                                    return cachedResource.deleteDocGroup({ id: matter.id }).$promise;
                                });
                        })
                        .catch(function (e) {
                            if (e instanceof InvalidDocumentsError) {
                                for (var i = 0; i < e.documents.length; i++) {
                                    $scope.session.files[i].fileCheckResult =
                                        e.documents[i].fileCheckResult;
                                }
                            }

                            if (e.sessionId) {
                                return NotaryService.deleteSession(e.sessionId);
                            }

                            $scope.errorText = e && e.message || JSON.stringify(e);
                        });
                }

                $scope.activeStep++;
            };

            $scope.close = function () { $mdDialog.hide(); };

            $scope.filesSelected = function (files) {
                var newFiles = files.map(function (file) {
                    file.displayedName = file.name;

                    return file;
                });

                $scope.files = $scope.files.concat(newFiles);
                $scope.session.documentsCount += newFiles.length;
            };

            $scope.viewCreated = function () {
                $scope.close();
                $state.transitionTo('oneNotarySession', { id: $scope.created.session.id });
            };

            $scope.deleteFile = function (i) {
                $scope.files.splice(i, 1);
                $scope.session.documentsCount--;
            };

            var toTimestamp = function (date, time) {
                date.setHours(time.hours, time.minutes);

                return Math.floor(date.getTime() / 1000);
            };

            PermissionsService.isFeatureAvailable('notaryScheduling')
                .then(function (isAvailable) {
                    $scope.isAssignmentAvailable = isAvailable;

                    if (!$scope.isAssignmentAvailable) {
                        return;
                    }

                    return OfficialsResourceFactory.create()
                        .then(function (resource) {
                            return resource.query().$promise;
                        })
                        .then(function (officials) {
                            $scope.officials = officials;

                            if (matter.assignedToUserId) {
                                $scope.session.official = $scope.officials.find(function (official) {
                                    return official.uid === matter.assignedToUserId;
                                }) || null;
                            }

                            return $scope.officials;
                        });
                });
        }
    ]);
})(angular.module('onfileApp'));
