(function (app) {
    app.controller('NotaryBookCtrl', [
        '$scope', '$rootScope', 'NotaryService',
        'NotaryRecordUpdatedEvent', 'CsvExportService', '$filter',
        function (
            $, $rootScope, NotaryService,
            NotaryRecordUpdatedEvent, CsvExportService, $filter
        ) {
            $rootScope.showList = false;
            $rootScope.showLoader = true;
            $.records = [];
            $.pagination = {
                limit: 100,
                begin: 0
            };

            $.export = function () {
                var mutators = {
                    notarizationDate: function (item) {
                        return $filter('amDateFormat')(item, $rootScope.shortDateTime) + ' Central Time';
                    }
                };

                return CsvExportService.exportAndDownload({
                    items: $.records,
                    titles: [
                        'Type Of Notarization', 'Notarization Date', 'Signer',
                        'Signer Address', 'Document Description', 'Document Date',
                        'Identification', 'Basis of Knowledge', 'Fee charged',
                        'Copy Recipient'
                    ],
                    fields: [
                        'typeOfNotarizationText', mutators, 'signerName',
                        'signerAddress', 'documentName', 'documentDate',
                        'documentType', 'idEvidence', 'fee',
                        {
                            emailCopies: function (item) {
                                if (!item || !item.length) {
                                    return '';
                                }

                                return item.join(', ');
                            }
                        }
                    ],
                    filename: 'Electronic notary records.csv'
                });
            };

            NotaryService.getNotaryRecords({ with: 'screenShareUrl', status: 'finished' })
                .then(function (records) {
                    $.records = records;
                })
                .finally(function () {
                    $rootScope.showLoader = false;
                });

            $rootScope.$on(NotaryRecordUpdatedEvent, function (event, eventData) {
                var data = eventData.data;

                for (var i = 0; i < $.records.length; i++) {
                    if ($.records[i].id === data.id) {
                        $.records[i] = data;
                        return;
                    }
                }
            });
        }
    ]);
})(angular.module('onfileApp'));
