(function (app) {
    app.factory('CaseTypeResource', ['$resource',
        function ($resource) {
            return $resource('/caseTypes', {}, {
                query: {
                    url: '/caseTypes',
                    method: 'GET',
                    isArray: true,
                    cache: true
                }
            });
        }]);
})(angular.module('onfileApp'));