(function (app) {
    app.service('ReminderService', [
        'FirebaseService', '$filter', 'ToastrService',
        'EmailService', 'EnvironmentService', 'utils',

        function ReminderService(
            FirebaseService, $filter, ToastrService,
            EmailService, EnvironmentService, utils
        ) {
            var STATUS_CREATED = 'created';
            var CHANEL_NAME = 'reminders';
            var self = this;

            var getNodePath = function () {
                return '/reminders/' + FirebaseService.uid;
            };

            /**
             * @return {Promise}
             */
            this.getAll = function () {
                return FirebaseService.getIdChannel(CHANEL_NAME);
            };

            this.isOverDue = function(date){
                return (new Date(date).setHours(24,0,0,0)) < (new Date()).getTime();
            };

            this.removeExpired = function (all) {
                all.$loaded().then(function (all) {
                    angular.forEach(all, function (reminder) {
                        if (reminder.due_date_timestamp < (new Date()).setDate(-31)) {
                            all.$remove(reminder);
                        }
                    });
                });
            };

            this.create = function (reminder) {
                var dueDateTimestamp = +reminder.due_date;
                var entity = angular.extend(reminder, {
                    case: reminder.case || null,
                    user_id: FirebaseService.uid || null,
                    due_date_timestamp: dueDateTimestamp || null,
                    status: STATUS_CREATED
                });
                var data = entity.getData ? entity.getData() : entity;

                //TODO: cascade to reminder create;
                return FirebaseService.addNode(getNodePath(), data)
                    .then(function (newReminder) {
                        reminder.reminderId = newReminder.key;
                        addEmailReminder(reminder);
                        ToastrService.showMessageByKey('reminder_created');
                    });
            };

            /**
             * @param id
             * @return {Promise}
             */
            this.get = function (id) {
                return FirebaseService.getIdChannelAsObject(CHANEL_NAME, id);
            };

            this.save = function (reminder) {
                return reminder.$save();
            };

            this.delete = function (reminder) {
                //delete email
                EmailService.deleteEmail('reminderId',reminder.$id);
                ToastrService.showMessageByKey('reminder_deleted');

                return reminder.$remove();
            };

            this.markDismissed = function (id) {
                return this.get(id)
                    .then(function (reminder) {
                        reminder.dismissed = true;

                        return self.save(reminder);
                    });
            };

            this.changeStatus = function (reminder, status) {
                return angular.extend(reminder, {
                    status: status
                }).$save();
            };

            this.getExpiring = function () {
                return this.getAll()
                    .then(function (reminders) {
                        return reminders.$loaded();
                    })
                    .then(function (reminders) {
                        return $filter('expiringReminder')(reminders);
                    });
            };

            function addEmailReminder(reminder) {
                var placeholdersReplacements;
                var hour = 8;
                var notification = {
                    when_to_send: getSpecificTimeForTheDate(reminder.due_date_timestamp, hour),
                    subject: reminder.description,
                    source: utils.getOr(function () { return reminder.getData(); }, reminder)
                };
                var templateName = utils.getOr(function () { return reminder.getTemplateName(); }, 'reminder.html');

                reminder.url = EnvironmentService.composeAbsoluteUrl('reminders/' + reminder.reminderId);
                placeholdersReplacements = utils.getOr(
                    function () { return reminder.getPlaceholdersReplacements(EnvironmentService); },
                    [
                        { key: 'DueDate', value: reminder.due_date },
                        { key: 'Description', value: reminder.description },
                        { key: 'Type', value: reminder.type },
                        { key: 'ReminderId', value: reminder.reminderId },
                        { key: 'ReminderUrl', value: reminder.url },
                        { key: 'Name', value: EnvironmentService.first_name() }
                    ]
                );

                EmailService.sendEmail(notification, templateName, placeholdersReplacements);
            }

            function getSpecificTimeForTheDate(dueDate , hour){
                return new Date((new Date(dueDate)).getFullYear(),(new Date(dueDate)).getMonth(),(new Date(dueDate)).getDate(), hour).getTime();
            }
        }
    ]);
})(angular.module('onfileApp'));