(function (app) {
    app.factory('RequestKeyResource', [
        '$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/key', {}, {
                request: urlEncodedResource({
                    url: '/key/request',
                    method: 'POST',
                    isArray: false
                })
            });
        }]);
})(angular.module('onfileApp'));
