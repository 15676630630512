;(function (app) {
    app.service('BatesModel', [
        '$rootScope', '$interval', '$q',
        'FirebaseService', 'EnvironmentService', 'BatesSchemaResource',
        'FilestackService', 'S3LocationsService',

        function ($, $interval, $q,
                  FirebaseService, EnvironmentService, BatesSchemaResource,
                  FilestackService, S3LocationsService) {
            const _this = this;

            const STAMP_TYPE_SEQUENTIAL = 2;

            const STATUS_DRAFTED = 'draft';
            const STATUS_CLOSED = 'closed';

            const FILE_STATUS_FAILED = 'failed';
            const FILE_STATUS_PROCESSED = 'processed';
            const FILE_STATUS_PROCESSING = 'processing';

            this.storage = {};
            this.STATUS_BATES_FINISHED = 'finished';
            this.STATUS_BATES_PROCESSING = 'processing';

            this.FILE_STATUS_FAILED = FILE_STATUS_FAILED;

            const watchers = [];

            const registerAutoUpdate = function () {
                watchers.push(
                    $.$watch(
                        function () {
                            return _this.storage.schema;
                        },
                        function () {
                            _this.storage.$save();
                        },
                        true
                    ),
                    $.$watch(
                        function () {
                            return _this.storage.files;
                        },
                        function () {
                            _this.storage.$save();
                        },
                        true
                    )
                );
            };

            this.unwatch = function () {
                return watchers.map(function (unwatch) {
                    return unwatch();
                });
            };

            const init = function () {
                const path = '/bates/' + EnvironmentService.firm_id() + '/packages/' + EnvironmentService.userId();

                return FirebaseService.getNodeAsArray(
                    path,
                    function (query) {
                        return query.orderByChild('status')
                            .equalTo(STATUS_DRAFTED)
                            .limitToFirst(1);
                    })
                    .$loaded()
                    .then(function (firebaseArray) {
                        if (firebaseArray.length === 0) {
                            return firebaseArray.$add({status: STATUS_DRAFTED})
                                .then(function (e) {
                                    return e.path.toString();
                                })
                        }

                        return firebaseArray.$ref().path.toString() + '/' + firebaseArray.$keyAt(0);
                    })
                    .then(function (pathToPackage) {
                        return FirebaseService.getNode(pathToPackage).$loaded();
                    })
                    .then(function (batesPackage) {
                        _this.storage = batesPackage;

                        registerAutoUpdate();
                    });
            };

            this.totalFilesSize = function () {
                var total = 0;

                if (this.storage.files && this.storage.files.forEach) {
                    this.storage.files
                        .forEach(function (file) {
                            const size = parseInt(file.size);

                            total += isNaN(size) ? 0 : size;
                        });
                }

                return total;
            };

            this.lastBatesApplied = function () {
                const schema = this.storage.schema;
                var batesUsed = 0;

                if (parseInt(schema.pageNumbering) === STAMP_TYPE_SEQUENTIAL) {
                    for (var i = 0; i < _this.storage.files.length; i++) {
                        batesUsed = batesUsed + _this.storage.files[i].number_of_pages;
                    }
                } else {
                    batesUsed = _this.storage.files.length;
                }

                return parseInt(schema.startNumber) + batesUsed - 1;
            };

            this.getFullLastBates = function () {
                // Generate from current state for old version
                if (
                    this.storage.schema.lastUsedBates !== undefined &&
                    this.storage.schema.lastUsedBates.numberOfDigits === undefined
                ) {
                    this.storage.schema.lastUsedBates.numberOfDigits = this.storage.schema.numberOfDigits;
                }

                return this.storage.schema.lastUsedBates;
            };

            //TODO: see if this still works for retries.
            this.applyBates = function (fileKey) {
                const _this = this;
                const file = this.storage.files[fileKey];
                const schema = this.storage.schema;
                const delta = (function (shouldBeSequential, previousFileIndex) {
                    if (shouldBeSequential === false) {
                        return parseInt(fileKey);
                    }
                    var batesUsed = 0;
                    for (var i = 0; i < fileKey; i++)
                        batesUsed = batesUsed + _this.storage.files[i].number_of_pages;

                    return batesUsed;

                })(parseInt(schema.pageNumbering) === STAMP_TYPE_SEQUENTIAL, fileKey);

                const params = this.getApplyParams(
                    schema,
                    {
                        num: parseInt(schema.startNumber) + delta,
                        url: file.url,
                        numPages: file.number_of_pages,
                        path: S3LocationsService.batesLabeledFile(this.storage.$id, fileKey)
                    }
                );

                _this.storage.files[fileKey].status = FILE_STATUS_PROCESSING;

                return BatesSchemaResource.apply(params)
                    .$promise
                    .then(function (res) {
                        if (res.status !== true) {
                            throw Error(res);
                        }

                        _this.storage.files[fileKey].status = FILE_STATUS_PROCESSED;
                        _this.storage.files[fileKey].bates_url = res.data.url;

                        if (res.data.hasOwnProperty('batesNumber') && res.data.batesNumber) {
                            _this.storage.files[fileKey].bates_number = res.data.batesNumber;
                        }

                        _this.storage.files[fileKey].output_filename = _this.composeFilename(
                            _this.storage.files[fileKey],
                            _this.storage.schema
                        );

                        return res.data;
                    })
                    .catch(function (res) {
                        _this.storage.files[fileKey].status = FILE_STATUS_FAILED;

                        throw new Error(res);
                    });
            };

            this.composeFilename = function (file, schema) {
                switch (schema.documentNaming) {
                    case false:
                        return file.display_filename + '.pdf';
                    case 'first':
                        return file.bates_number + '.pdf';
                    default:
                        return file.bates_number + '.pdf';
                }
            };

            this.applyBatesToCustomFile = function (schema) {
                const params = this.getApplyParams(
                    schema,
                    {
                        useExample: true,
                        num: parseInt(schema.startNumber),
                        path: S3LocationsService.previewBatesFile()
                    }
                );

                return BatesSchemaResource.apply(params).$promise;
            };

            this.getApplyParams = function (schema, params) {
                return Object.assign(
                    {},
                    {
                        prefix: schema.prefix,
                        suffix: schema.suffix,
                        color: schema.fontColor,
                        resize: schema.autoResize,
                        fontSize: schema.fontSize,
                        fontType: schema.fontStyle,
                        pos: schema.numberPlacement,
                        padNum: schema.numberOfDigits,
                        stampType: schema.pageNumbering,
                        customFName: schema.documentNaming,
                        padding: schema.padding
                    },
                    params
                );
            };

            this.loaded = function () {
                const deferred = $q.defer();

                const intervalId = $interval(function () {
                    if (_this.storage.$loaded) {
                        _this.storage.$loaded()
                            .then(function (e) {
                                deferred.resolve(e);

                                return e;
                            });

                        $interval.cancel(intervalId);
                    }
                }, 300);

                return deferred.promise;
            };

            this.setBatesFinishedStatus = function () {
                this.storage.bates_status = this.STATUS_BATES_FINISHED;

                return this.storage.$save();
            };

            this.setBatesProcessingStatus = function () {
                this.storage.bates_status = this.STATUS_BATES_PROCESSING;

                return this.storage.$save();
            };

            this.setClosedStatus = function () {
                this.storage.status = STATUS_CLOSED;

                return this.storage.$save();
            };

            this.getBatesLinks = function () {
                const links = [];

                this.storage.files
                    .forEach(function (file) {
                        if (file.bates_url) {
                            links.push({
                                name: file.output_filename,
                                url: file.bates_url
                            });
                        }
                    });

                return links;
            };

            this.areAllFilesBatesProcessed = function () {
                for (var prop in this.storage.files) {
                    if (this.storage.files[prop].status !== FILE_STATUS_PROCESSED) {
                        return false;
                    }
                }

                return true;
            };

            this.deleteFilesFromFilestack = function () {
                const promises = [];

                this.storage.files
                    .forEach(function (file) {
                        promises.push(
                            FilestackService.deleteFile({url: file.url})
                                .then(function () {
                                    return FilestackService.deleteFile({url: file.bates_url});
                                })
                        );
                    });

                return $q.all(promises)
                    .catch(function () {
                    });
            };

            this.deleteFileFromFilestack = function (index) {
                const file = this.storage.files[index];

                return FilestackService
                    .deleteFile({url: file.url})
                    .catch(function () {
                    })
                    .then(function () {
                        if (file.bates_url) {
                            return FilestackService.deleteFile({url: file.bates_url});
                        }
                    })
                    .catch(function () {
                    });
            };

            this.isSchemaDefault = function () {
                if (this.storage.schema && this.storage.schema.default !== undefined) {
                    return this.storage.schema.default === true;
                }

                return undefined;
            };

            this.getPreviewObject = function () {
                const path = '/bates/' + EnvironmentService.firm_id() + '/preview/' + EnvironmentService.userId();

                return FirebaseService.getNode(path).$loaded();
            };

            this.deletePreview = function (deletePreview) {
                return FilestackService
                    .deleteFile({url: deletePreview.url})
                    .catch(function () {
                    })
                    .then(function () {
                        return deletePreview.$remove();
                    });
            };

            init();
        }
    ]);
})(angular.module('onfileApp'));