(function (app) {
    var extCache = {};

    var generateTimeSlot = function (date) {
        if (date instanceof Date) {
            date = moment(date);
        }

        return {
            hours: date.get('hours'),
            minutes: date.get('minutes'),
            formatted: date.format('h:mm a')
        };
    };

    var compare = function (a, b) {
        if (a > b) {
            return 1;
        }

        if (a < b) {
            return -1;
        }

        return 0;
    };

    var fileToBase64 = function (file) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = function () {
                return resolve(reader.result.toString());
            };
            reader.onerror = reject;
        });
    };

    var resizeSignature = function (base64, params) {
        var width = params && params.width || 500;
        var height = params && params.height || 500;
        var format = params && params.format || 'image/png';
        var quality = params && params.quality || 1;
        var img = document.createElement('img');
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');

        format = format || 'image/png';
        quality = quality || 1;

        img.src = base64;

        return new Promise(function (resolve, reject) {
            img.onload = function () {
                ctx.drawImage(img, 0, 0);

                var newWidth = img.width;
                var newHeight = img.height;

                if (newWidth > newHeight) {
                    if (newWidth > width) {
                        newHeight *= width / newWidth;
                        newWidth = width;
                    }
                } else if (newHeight > height) {
                    newWidth *= height / newHeight;
                    newHeight = height;
                }

                canvas.width = newWidth;
                canvas.height = newHeight;
                ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                return resolve(canvas.toDataURL(format, quality));
            };

            img.onerror = reject;
        });
    };

    var removeBase64Header = function (input) {
        for (var i = 0; i < input.length; i++) {
            if (input[i] === ',') {
                return input.substr(i + 1);
            }
        }

        return input;
    };

    var onlineGap = 30 * 1000;

    app.constant('utils', {
        capitalize: function (s) {
            var words = s.match(/[A-Za-z][a-z]*/g);
            var mapFn = function mapFn(word) {
                return word.charAt(0).toUpperCase() + word.substring(1);
            };

            return words.map(mapFn).join(' ');
        },
        buildToggler: function ($mdSidenav) {
            return function (componentId) {
                return function() {
                    $mdSidenav(componentId).toggle();
                };
            };
        },
        getOr: function (fn, defaultValue) {
            try {
                return fn();
            } catch (e) {
                return defaultValue;
            }
        },
        getFileStorageProvider: function (file) {
            return file && file.url && file.url.indexOf('cdn.fs.onfile.legal') >= 0 ?
                'filestack' : null;
        },
        getExtension: function (filename) {
            return filename && filename.split && typeof filename.split === 'function' && filename.split('.').pop();
        },
        getFileType: function (ext, map) {
            var item;
            var type = ext;

            if (!extCache || !extCache[ext]) {
                item = map.find(function (entry) {
                    return entry[0] === ext;
                });

                if (item && item[1]) {
                    type = item[1];
                }

                extCache[ext] = type;
            }

            return extCache[ext];
        },
        replace: function (message, data) {
            var res = message;

            Object.keys(data).map(function (key) {
                res = res.replace('{{' + key + '}}', data[key]);
            });

            return res;
        },
        isTrue: function (e) {
            return e === true || e === 'True';
        },
        isRequired: function (e) {
            return e === true || e === 'Required';
        },
        replacePlaceholders: function (string, attributes) {
            return Object.keys(attributes)
                .reduce(function (res, attr) {
                    return res.replace('{{' + attr + '}}', attributes[attr]);
                }, string);
        },
        formatDate: function (date) {
            date = date || new Date();

            var weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            var months = [
                'Jan', 'Feb', 'Mar',
                'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ];
            var day = weeks[date.getDay()];
            var month = months[date.getMonth()];
            var fullDate = date.getDate();
            var year = date.getFullYear();

            return day + ', ' + month + ' ' + fullDate + ', ' + year;
        },
        getDateForTimezone: function (date, timezone) {
            return moment.tz(date, timezone).toDate();
        },
        formatDateYear: function (date, format) {
            format = format || 'MM/DD/YYYY';

            if (date && date.toISOString) {
                date = date.toISOString();
            }

            return moment(date).format(format);
        },
        getProperty: function (object, property) {
            var temp = object;

            property.split('.')
                .map(function (part) {
                    temp = temp && temp.hasOwnProperty(part) ? temp[part] : undefined;
                });

            return temp;
        },
        setProperty: function (object, property, val) {
            var part;
            var temp = object;
            var parts = property.split('.');

            for (var i = 0; i < parts.length; i++) {
                part = parts[i];

                if (i === parts.length - 1) {
                    temp[part] = val;

                    return object;
                }

                temp = temp[part];
            }

            return null;
        },
        clone: function (val) {
            return JSON.parse(JSON.stringify(val));
        },
        generateTimeSlots: function (start, end, minutes) {
            var startTime = moment(start, 'HH:mm');
            var endTime = moment(end, 'HH:mm');

            var timeStops = [];

            while (startTime <= endTime) {
                var d = new moment(startTime);

                timeStops.push(generateTimeSlot(d));
                startTime.add(minutes, 'minutes');
            }

            return timeStops;
        },
        generateTimeSlot: generateTimeSlot,
        isFeatureAvailable: function (featureValue, params) {
            var userId = params && params.userId;

            if (featureValue === undefined) {
                return false;
            }

            if (typeof featureValue === 'boolean') {
                return featureValue;
            }

            if (typeof featureValue === 'object') {
                var keys = Object.keys(featureValue);

                for (var i = 0; i < keys.length; i++) {
                    if (featureValue[keys[i]] === userId) {
                        return true;
                    }
                }
            }

            return false;
        },
        isInDebugMode: function () {
            return window.location.hash.indexOf('?debug') >= 0;
        },
        sanitizeCsvString: function (input) {
            if (input) {
                input = input.toString();

                return '"' + input.replace(/"/g, '\"').replace(/#/g, '№') + '"';
            }

            return '""';
        },
        propagateProperties: function (obj, data) {
            if (!data) {
                return obj;
            }

            var props = Object.keys(data);

            for (var i = 0; i < props.length; i++) {
                obj[props[i]] = data[props[i]];
            }

            return obj;
        },
        inArray: function (obj, data) {
            return data.indexOf(obj) >= 0;
        },
        toDate: function (input) {
            if (input && input.hasOwnProperty('_seconds')) {
                return new Date(input._seconds * 1000);
            }

            return null;
        },
        extractProps: function (data, props) {
            return props.reduce(function (all, prop) {
                all[prop] = data[prop];

                return all;
            }, {});
        },
        copyToClipboard: function (input) {
            var el = document.createElement('textarea');

            el.value = input;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            return true;
        },
        flattenArrayByProp: function (arr, prop) {
            var res = [];

            for (var i = 0; i < arr.length; i++) {
                if (arr[i] && arr[i][prop]) {
                    res.push(arr[i][prop]);
                }
            }

            return res;
        },
        getSelectedDocumentIds: function (documents) {
            return documents.reduce(function (all, doc) {
                if (doc.isChecked) {
                    all.push(doc.id);
                }

                return all;
            }, []);
        },
        joinIfNotEmpty: function (arr, joinWith) {
            return arr.filter(function (d) { return d; }).join(joinWith || ', ');
        },
        compare: compare,
        reverseCompare: function (a, b) {
            return -1 * compare(a, b);
        },
        toTimestamp: function (date, time) {
            date.setHours(time.hours, time.minutes);

            return Math.floor(date.getTime());
        },
        isUserOnline: function (item, propName) {
            propName = propName || 'officialLastOnlineAt';

            if (!item || !item[propName] || !item[propName]._seconds) {
                return false;
            }

            return (+new Date() - item[propName]._seconds * 1000) < onlineGap;
        },
        getFileString: function (file) {
            return fileToBase64(file)
                .then(function (str) {
                    return resizeSignature(str, {
                        width: 1024,
                        height: 1024,
                        format: 'image/jpeg',
                        quality: 0.7
                    });
                })
                .then(function (str) {
                    return removeBase64Header(str);
                });
        },
        resizeSignature: resizeSignature,
        removeBase64Header: removeBase64Header,
        translateIdVerificationErrors: function (data) {
            var result = [];

            if (!data) {
                return [];
            }

            var codes = Object.keys(data);
            var ID_VERIFICATION_ERRORS = {
                1: 'A barcode symbol was not found',
                2: 'Out of focus',
                3: 'Image too dark / bad lighting',
                4: 'Below minimum fill / camera too distant',
                5: 'Image skewed',
                6: 'Barcode symbol too far away',
                7: 'Glare detected',
                8: 'Four corners not detected',
                9: 'Corrupt image',
                10: 'Not enough padding / too close',
                11: 'Low contrast',
                12: 'Busy background',
                13: 'Image too small / < 450 pixels',
                'GLARE': 'Rejected due to glare on received document images.',
                'INCOMPLETE_DOCUMENT': 'The number of images submitted did not meet the expected number of images for this specific document. Please make sure you have submitted an image for each page of the document.',
                'NOT_VISIBLE_FOUR_CORNERS': 'One or more of the submitted images could not be processed due to all four corners of the document not being visible. Please retake the images before resubmitting them, mindful of anything that could be blocking the corners of the document from being seen.',
                'NOT_VISIBLE_SECURITY_FEATURES': 'One or more of the submitted images could not be processed due to one or more expected security features not being visible. Please retake the images before resubmitting them, mindful of anything that could be blocking a security feature from being seen.',
                'OUT_OF_FOCUS': 'One or more of the submitted images could not be processed due to the image being out of focus. Please retake the images before resubmitting them.',
                'POOR_CONTRAST': 'One or more of the submitted images could not be processed due to the image having poor contrast. Please retake the images before resubmitting them.',
                'POOR_EXPOSURE': 'One or more of the submitted images could not be processed due to the image having poor exposure. Please retake the images before resubmitting them.',
                'UNCLASSIFIED_DOCUMENT': 'The submitted images could not be classified or are not supported. Please submit only supported documents.',
                'UNREADABLE_FIELD': 'One or more of the submitted images could not be processed due to one or more fields being unreadable. Please retake the images before resubmitting them, mindful of anything that could be blocking a field from being processed.',
                'UNSUPPORTED_LANGUAGE': 'One or more of the submitted images contains a document with a language that we do not support. Please submit only supported documents.',
                'EVIDENCE_OF_FORGERY_BIODATA_FONTS': 'Manual review of the submitted document indicated that details had been manipulated in the biographical text of the document.',
                'EVIDENCE_OF_FORGERY_MRZ_FONTS': 'Manual review of the submitted document indicated that details had been manipulated in the MRZ section of the document.',
                'EVIDENCE_OF_FORGERY_NOT_HUMAN_FACE': 'The document shows strong evidence of forgery missing a human face on document portrait.',
                'EVIDENCE_OF_FORGERY_PHOTO_ZONE': 'Manual review of the submitted document indicated that there may have been an attempt to manipulate the photo zone of the document.',
                'EVIDENCE_OF_FORGERY_BLACK_AND_WHITE_COPY': 'Manual review of the submitted document indicated that the image was a black and white copy.',
                'EVIDENCE_OF_FORGERY_DOCUMENT_STRUCTURE': 'Manual review of the submitted document indicated that the document structure was not consistent with the known document.',
                'EVIDENCE_OF_FORGERY_MRZ_CHECKSUMS': 'After proofreading of the MRZ by the manual review team, the resultant MRZ failed one or many checksum validations.',
                'EXPIRED_OR_INVALIDATED': 'The document is expired due to expiry date or invalidated by issuing authorities due to one or many invalidity markups on the document.'
            };

            for (var i = 0; i < codes.length; i++) {
                var currError = data[codes[i]];

                if (ID_VERIFICATION_ERRORS[codes[i]]) {
                    currError = ID_VERIFICATION_ERRORS[codes[i]];
                }

                result.push(currError);
            }

            return result;
        },
        extractFirstAndLastName: function (input) {
            var firstName = null;
            var lastName = null;

            if (!input) {
                return {
                    firstName: firstName,
                    lastName: lastName
                };
            }

            var parts = input.split(' ');

            if (parts.length === 0) {
                return {
                    firstName: firstName,
                    lastName: lastName
                };
            }

            firstName = parts[0];

            if (parts.length > 1) {
                lastName = parts[parts.length - 1];
            }

            return {
                firstName: firstName,
                lastName: lastName
            };
        }
    });
})(angular.module('onfileApp'));
