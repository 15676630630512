(function (app) {
    app.factory('EProductionFileResource', [
        '$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/files/get-signature', {}, {
                getSignature: {
                    method: 'GET',
                    isArray: false
                },
                save: urlEncodedResource({
                    url: '/files/save',
                    method: 'POST',
                    isArray: false
                })
            });
        }]
    );
})(angular.module('onfileApp'));