(function (app) {
    app.factory('SignatureResource', [
        '$resource',
        function ($resource) {
            return $resource('/signatures', {}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: false
                }
            });
        }
    ]);
})(angular.module('onfileApp'));