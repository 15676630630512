(function (app) {
    var themes = [
        {
            themeName: 'greenTheme',
            palettes: {
                primary: {
                    name: 'greenPrimaryPalette',
                    colors: {
                        '50': '#77e8a2',
                        '100': '#61e593',
                        '200': '#4be184',
                        '300': '#36dd75',
                        '400': '#24d666',
                        '500': '#20C05C',
                        '600': '#1caa52',
                        '700': '#199447',
                        '800': '#157e3d',
                        '900': '#116932',
                        'A100': '#8decb1',
                        'A200': '#a3f0c0',
                        'A400': '#b9f3cf',
                        'A700': '#0e5328'
                    }
                }
            }
        }, {
            themeName: 'blueTheme',
            palettes: {
                primary: {
                    name: 'bluePrimaryPalette',
                    colors: {
                        '50': '#e2edff',
                        '100': '#b6d3fe',
                        '200': '#85b6fe',
                        '300': '#5499fe',
                        '400': '#3083fd',
                        '500': '#0b6dfd',
                        '600': '#0a65fd',
                        '700': '#085afc',
                        '800': '#0650fc',
                        '900': '#033efc',
                        'A100': '#ffffff',
                        'A200': '#eff2ff',
                        'A400': '#bcc8ff',
                        'A700': '#a2b3ff'
                    }
                }
            }
        }
    ];

    app.config(['$mdThemingProvider', function ($mdThemingProvider) {
        return themes.map(function (theme) {
            return $mdThemingProvider
                .definePalette(theme.palettes.primary.name, theme.palettes.primary.colors)
                .theme(theme.themeName)
                .primaryPalette(theme.palettes.primary.name);
        });
    }]);
})(angular.module('onfileApp'));
