(function (app) {
    app.factory('ElasticSearchResource', [
        '$http', '$q', 'EnvironmentService', 'elasticSearchUrls',
        function ($http, $q, EnvironmentService, elasticSearchUrls) {
            var ongoingRequestTerminator;
            var requestCancelledStatus = -1;
            var limit = 50;

            var environment = EnvironmentService.environment();
            var market = EnvironmentService.market();
            var queryQS = {
                message: {
                    op: 'search',
                    index: 'cases',
                    type: 'case',
                    body: {
                        size: 25,
                        query: {
                            bool: {
                                must: [
                                    {
                                        query_string: {
                                            query: ''
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            };

            var concatResults = function (result) {
                return result.reduce(function (all, curr) {
                    if (Array.isArray(all) && Array.isArray(curr)) {
                        return all.concat(curr);
                    }

                    return all;
                }, []);
            };

            var buildHashMap = function (result) {
                return result.reduce(function (hashMap, curr) {
                    if (curr && curr.id) {
                        hashMap[curr.id] = curr;
                    }

                    return hashMap;
                }, {});
            };

            var getArrayFromHashMap = function (hashMap) {
                return Object.keys(hashMap)
                    .map(function (id) {
                        if (hashMap[id]) {
                            return hashMap[id];
                        }

                        return null;
                    })
                    .filter(function (item) { return item; });
            };

            var getQueryObject = this.getQueryObject = function () {
                return JSON.parse(JSON.stringify(queryQS));
            };

            var getFilingsQueryObject = this.getFilingsQueryObject = function () {
                var clone = getQueryObject();

                clone.message.index = this.getFilingsIndex(environment);
                clone.message.type = this.getFilingsIndexType(environment);
                clone.message.body.query.bool.must = [];

                return clone;
            };

            var parseDate = function (o) {
                var dateRegExp = /^[a-zA-Z]+Case$/;
                var defaultDate = 0;
                var coreNode = o && o.all;

                if (!coreNode) {
                    return defaultDate;
                }

                var caseTypeNode = Object.keys(coreNode).find(function (key) {
                    return dateRegExp.test(key);
                });

                return coreNode && coreNode[caseTypeNode] && coreNode[caseTypeNode].ActivityDate &&
                    coreNode[caseTypeNode].ActivityDate.DateTime &&
                    +new Date(coreNode[caseTypeNode].ActivityDate.DateTime).getTime();
            };

            if (environment === 'local') {
                environment = 'development';
            }

            this.searchES = function (query, params) {
                var res;
                var defer = $q.defer();
                var requestParams = {};
                var url = this.getSearchUrl(params && params.static && params.static.environment);

                // If more than one request at the time allowed.
                if (!params || !params.force) {
                    // For canceling ongoing search if a new search request comes in.
                    if (ongoingRequestTerminator) {
                        ongoingRequestTerminator.resolve();
                        ongoingRequestTerminator = null;
                    }

                    ongoingRequestTerminator = $q.defer();
                    requestParams = {
                        ignoreLoaderInterceptor: true,
                        timeout: ongoingRequestTerminator.promise
                    };
                }

                res = $http.post(url, query, requestParams);
                res.success(function (data) {
                    var items = [];
                    var casesMap = {};

                    if (data && data.length > 0) {
                        for (var j = 0; j < data.length; j++) {
                            var item = data[j]._source.doc;
                            var uniqueId = item.docket_id + '-' + item.id;

                            item.case_title = item.title;

                            if (casesMap[uniqueId] === undefined) {
                                casesMap[uniqueId] = [];
                            }

                            casesMap[uniqueId].push(item);

                            if (j >= limit) {
                                break;
                            }
                        }
                    }

                    Object.keys(casesMap).map(function (docketId) {
                        var currItems = casesMap[docketId];
                        var sortedItems = currItems.sort(function (a, b) {
                            var dateA = parseDate(a);
                            var dateB = parseDate(b);

                            if (dateA > dateB) {
                                return -1;
                            }

                            if (dateA < dateB) {
                                return 1;
                            }

                            return 0;
                        });

                        items.push(sortedItems[0]);
                    });

                    defer.resolve(items);
                });
                res.error(function (data, status) {
                    if (status !== requestCancelledStatus) {
                        console.error('failure message: ' + JSON.stringify({ data: data }));
                    }

                    defer.reject(data);
                });

                return defer.promise
                    .finally(function () {
                        ongoingRequestTerminator = null;
                    });
            };

            this.searchCaseByTitle = function (query, params) {
                if (query.length < 3)
                    return;

                var queryQSLocal = JSON.parse(JSON.stringify(queryQS));
                queryQSLocal.message.body.query.bool.must[0]['query_string'] =
                    { 'query': 'doc.title:' + '*' + query + '*' };

                // queryQSLocal.message.body.query.bool.must[0]['query'] =
                //     {'prefix': {'doc.title:': query}};

                this.attachEnvironment(queryQSLocal.message.body.query.bool.must, params);
                return this.searchES(queryQSLocal, params);
            };

            this.searchCaseByCourt = function (query, params) {
                if (query.length < 2)
                    return;
                var queryQSLocal = JSON.parse(JSON.stringify(queryQS));
                queryQSLocal.message.body.query.bool.must[0] = {
                    'simple_query_string': {
                        'query': query + '*',
                        'fields': ['doc.court.name'],
                        'analyze_wildcard': true,
                        'default_operator': 'and'
                    }
                };
                this.attachEnvironment(queryQSLocal.message.body.query.bool.must, params);

                return this.searchES(queryQSLocal, params);
            };

            this.searchCaseByDocketId = function (query, params) {
                if (query.length < 2)
                    return;
                var queryQSLocal = JSON.parse(JSON.stringify(queryQS));

                // queryQSLocal.message.body.query.bool.must[0] = {
                //     'query_string':
                //         {'query': query + '*', 'fields': ['doc.docket_id'], 'default_operator': 'AND'}
                // };
                // {
                //     '_source':true,
                //     'query':{
                //     'simple_query_string':{
                //         'query':'u-1*',
                //             'analyze_wildcard':true,
                //             'default_operator':'AND'
                //     }
                // }
                // }
                //
                // queryQSLocal.message.body.query.bool.must[0] = {
                //     'query_string':
                //         {
                //             'wildcard': {'doc.docket_id': query + '*'}
                //         }
                // };

                // queryQSLocal.message.body.query = {
                //     'simple_query_string': {
                //         'query': query + '*',
                //         'fields' : ['doc.docket_id'],
                //         'analyze_wildcard': true,
                //         'default_operator': 'and'
                //     }
                // };


                queryQSLocal.message.body.query.bool.must[0] = {
                    'simple_query_string': {
                        'query': query + '*',
                        'fields': ['doc.docket_id'],
                        'analyze_wildcard': true,
                        'default_operator': 'and'
                    }
                };
                this.attachEnvironment(queryQSLocal.message.body.query.bool.must, params);

                return this.searchES(queryQSLocal, params);
            };

            this.searchByParticipants = function (query, params) {
                if (query.length < 2) {
                    return;
                }

                var queryQSLocal = JSON.parse(JSON.stringify(queryQS));

                queryQSLocal.message.body.query.bool.must[0] = {
                    query_string: {
                        query: 'doc.participants.name:' + query + '*',
                        analyze_wildcard: true,
                        default_operator: 'and'
                    }
                };
                this.attachEnvironment(queryQSLocal.message.body.query.bool.must, params);

                return this.searchES(queryQSLocal, params);
            };

            this.attachEnvironment = function (query, params) {
                var additionalParams = {
                    environment: environment
                };

                // Adding hardcoded static params if needed
                if (params && params.static) {
                    additionalParams = Object.assign({}, additionalParams, params.static);
                }

                // Ignoring firmId in ES search query
                if (params && params.ignore && params.ignore.firmId) {
                    return this.addEnvironment(query, additionalParams);
                }

                return this.addEnvironmentAndFirmIdFilter(query, additionalParams);
            };

            this.addEnvironment = function (query, params) {
                query.push({ 'match': { 'doc.environment': params.environment } });

                return query;
            };

            this.addEnvironmentAndFirmIdFilter = function (query, params) {
                query.push({ 'match': { 'doc.firmId': params.firmId || EnvironmentService.firm_id() } });
                query.push({ 'match': { 'doc.environment': params.environment } });

                return query;
            };

            this.searchCaseByTitleCombined = function (query) {
                var queries = [
                    this.searchCaseByTitle(query, { force: true }),
                    this.searchByParticipants(query, { force: true })
                ];

                return $q.all(queries)
                    .then(concatResults)
                    .then(buildHashMap)
                    .then(getArrayFromHashMap);
            };

            this.searchFilingsByFiler = function (userIds, params) {
                var esQuery = getFilingsQueryObject();
                var queries = userIds.map(function (userId) {
                    return {
                        match_phrase: {
                            'doc.document_submitter.email': userId
                        }
                    };
                });

                esQuery.message.body.size = params && params.size || 25;
                esQuery.message.body.from = params && params.from || 0;
                esQuery.message.body.query.bool.must[0] = {
                    bool: {
                        should: queries
                    }
                };
                this.attachEnvironment(esQuery.message.body.query.bool.must, params);

                return this.searchES(esQuery, params);
            };

            this.getSearchUrl = function (env) {
                return elasticSearchUrls[market][env || environment].url;
            };

            this.getCasesIndex = function (env) {
                return elasticSearchUrls[market][env || environment].cases.index;
            };

            this.getCasesIndexType = function (env) {
                return elasticSearchUrls[market][env || environment].cases.type;
            };

            this.getFilingsIndex = function (env) {
                return elasticSearchUrls[market][env || environment].filings.index;
            };

            this.getFilingsIndexType = function (env) {
                return elasticSearchUrls[market][env || environment].filings.type;
            };

            this.searchCasesByEnvelopeNumber = function (search, params) {
                var queryQSLocal;

                if (!search || search.length < 2) {
                    return;
                }

                queryQSLocal = getQueryObject();
                queryQSLocal.message.body.query.bool.must[0] = {
                    simple_query_string: {
                        query: '*' + search + '*',
                        fields: ['doc.envelope_number'],
                        analyze_wildcard: true,
                        default_operator: 'and'
                    }
                };
                this.attachEnvironment(queryQSLocal.message.body.query.bool.must, params);

                return this.searchES(queryQSLocal, params);
            };

            this.removeCaseDuplicates = function (cases) {
                var items = [];
                var casesMap = {};

                if (cases && cases.length > 0) {
                    for (var j = 0; j < cases.length; j++) {
                        var item = cases[j];
                        var uniqueId = item.docket_id;

                        item.case_title = item.title;

                        if (casesMap[uniqueId] === undefined) {
                            casesMap[uniqueId] = [];
                        }

                        casesMap[uniqueId].push(item);

                        if (j >= limit) {
                            break;
                        }
                    }
                }

                Object.keys(casesMap).map(function (docketId) {
                    var currItems = casesMap[docketId];
                    var sortedItems = currItems.sort(function (a, b) {
                        var dateA = parseDate(a);
                        var dateB = parseDate(b);

                        if (dateA > dateB) {
                            return -1;
                        }

                        if (dateA < dateB) {
                            return 1;
                        }

                        return 0;
                    });

                    items.push(sortedItems[0]);
                });

                return items;
            };

            queryQS.message.index = this.getCasesIndex(environment);
            queryQS.message.type = this.getCasesIndexType(environment);

            return this;
        }
    ]);
})(angular.module('onfileApp'));
