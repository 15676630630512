(function (app) {
    app.directive('createReminder', [
        '$mdDialog', '$firebaseUtils', 'ReminderService',
        'CaseService', 'CaseNameService', '$q',

        function ($mdDialog, $firebaseUtils, ReminderService,
                  CaseService, CaseNameService, $q) {
            return {
                strict: 'A',
                scope: {
                    isVisible: '=',
                    case: '='
                },
                link: function ($) {

                    angular.extend($, {
                        types: [
                            {name: 'Court eFiling', value: 'court_efiling'},
                            {name: 'eService to party', value: 'eservice_to_party'},
                            {name: 'eProduction', value: 'eproduction'},
                            {name: 'Other', value: 'other'}
                        ],
                        minDate: (function (date) {
                            return new Date(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate() + 1
                            )
                        })(new Date()),

                        save: function (model, form) {
                            if (form.$valid) {
                                var data = angular.extend({}, model);

                                if ($.case) {
                                    data = angular.extend({}, model, {
                                        case: {
                                            id: $.case.id || null,
                                            title: $.case.title || null,
                                            docket_id: $.case.docket_id || null,
                                            court_code: $.case.court_code || null
                                        }
                                    });
                                } else if (model.case) {
                                    data = angular.extend({}, model, {
                                        case: {
                                            id: model.case.id,
                                            title: model.case.case_title_composed || null,
                                            docket_id: model.case.docket_id || null,
                                            court_code: model.case.court_code || null
                                        }
                                    })
                                }

                                return ReminderService.create(data)
                                    .then(function () {
                                        $.isVisible = false;
                                        hideDialog();
                                    });
                            }
                        }
                    });

                    $.$watch('isVisible', function (isVisible) {
                        isVisible ? showDialog() : hideDialog();
                    });

                    const hideDialog = function hideDialog() {
                        $mdDialog.hide();
                    };

                    const showDialog = function () {
                        $mdDialog.show({
                            controller: ['$scope', function ($scope) {
                                $scope.types = $.types;
                                $scope.cases = [];
                                $scope.model = {
                                    type: null,
                                    due_date: null,
                                    description: null,
                                    case: null
                                };
                                $scope.minDate = $.minDate;
                                $scope.case = $.case;

                                $scope.save = $.save;
                                $scope.close = function () {
                                    $.isVisible = false;
                                    hideDialog();
                                };

                                if (!$.case) {
                                    CaseService.getList()
                                        .then(function (cases) {
                                            const compareUnavailableTitle = CaseNameService.CASE_NAME_UNAVAILABLE.toLowerCase();
                                            const promises = cases
                                                .map(function (eCase) {
                                                    return CaseNameService.decorate(eCase, {
                                                        caseId: 'id',
                                                        filingId: 'id'
                                                    });
                                                });

                                            $q.all(promises)
                                                .then(function () {
                                                    $scope.cases = cases.filter(function (eCase) {
                                                        return eCase.case_title_composed.toLowerCase() !== compareUnavailableTitle;
                                                    });
                                                });
                                        });
                                }
                            }],
                            templateUrl: '/assets/app/shared/directives/createReminder/create.html?' +
                                '{{onfileApplicationVersion}}',
                            parent: angular.element(document.body),
                            clickOutsideToClose: false
                        });
                    }
                }
            };
        }
    ]);
})(angular.module('onfileApp'));
