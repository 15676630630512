;(function (app) {
    app.controller('BatesFilesCtrl', [
        '$scope', '$rootScope', '$q',
        'BatesSchemaResource', 'FilestackService', 'EnvironmentService',
        'BatesModel', 'PDFParserService', 'S3LocationsService',
        function (
            $, $rootScope, $q,
            BatesSchemaResource, FilestackService, EnvironmentService,
            BatesModel, PDFParserService, S3LocationsService
        ) {
            var MESSAGES = {
                uploadingFiles: 'Upload processing',
                deletingFiles: 'Deleting all files',
                deletingFile: 'Deleting file'
            };

            // Setting Uploader config
            BatesModel.loaded()
                .then(function () {
                    var path = $.batesModel &&
                        $.batesModel.storage &&
                        $.batesModel.storage.$id &&
                        S3LocationsService.batesOriginalFile($.batesModel.storage.$id);

                    $.uploaderConfig = { draftId: path || '' };
                });

            angular.extend($, {
                limit: 25,
                batesModel: BatesModel,

                documentUploaded: function (document) {
                    var documentPromiseWrapper = $q.resolve([document]);

                    $rootScope.showSpinner();

                    return documentPromiseWrapper
                        .then(function (res) {
                            $rootScope.showSpinner(MESSAGES.uploadingFiles);

                            return PDFParserService.addPageNumbersToFiles(res);
                        })
                        .then(function (res) {
                            const files = FilestackService.addDisplayFilename(res);

                            if (
                                $.batesModel.storage.hasOwnProperty('files') &&
                                Array.isArray($.batesModel.storage.files)
                            ) {
                                $.batesModel.storage.files = $.batesModel.storage.files.concat(files);
                            } else {
                                $.batesModel.storage.files = files;
                            }
                        })
                        .finally(function () {
                            $rootScope.hideSpinner();
                        });
                },
                deleteFile: function (index) {
                    const deleteAll = function () {
                        var promise;

                        $rootScope.showSpinner(MESSAGES.deletingFiles);
                        $.batesModel.storage.files.map(function () {
                            promise = promise === undefined ?
                                deleteByIndex(0) :
                                promise.then(function () {
                                    return deleteByIndex(0);
                                });
                        });

                        return promise ? promise : $q.resolve()
                            .finally(function () {
                                $rootScope.hideSpinner();
                            });
                    };
                    const deleteByIndex = function (index) {
                        $rootScope.showSpinner(MESSAGES.deletingFile);

                        return $.batesModel.deleteFileFromFilestack(index)
                            .then(function () {
                                $.batesModel.storage.files.splice(index, 1);
                            })
                            .finally(function () {
                                $rootScope.hideSpinner();
                            });
                    };

                    if (typeof index === 'function') {
                        index = index();
                    }

                    return index === 'all' ? deleteAll() : deleteByIndex(index);
                },
                applyBates: function () {
                    for (var i = 0; i < BatesModel.storage.files.length; i++) {
                        BatesModel.storage.files[i].status = null;
                    }

                    return applyBatesFromIndex(0);
                },
                retryBates: function (index) {
                    return applyBatesFromIndex(index, true);
                }
            });

            const applyBatesFromIndex = function (onlyFailed) {
                const batchSize = 5;
                const ongoingPromises = [];
                const fileKeysToProcess = BatesModel.storage.files
                    .map(function (file, key) {
                        if (onlyFailed && file.status !== BatesModel.FILE_STATUS_FAILED) {
                            return undefined;
                        }

                        return key;
                    })
                    .filter(function (key) {
                        return key !== undefined;
                    });

                const getPromise = function (fileKey) {
                    return BatesModel.applyBates(fileKey)

                        // Remove current Promise from the list of Ongoing promises
                        .then(function () {
                            for (var i = 0; i < ongoingPromises.length; i++) {
                                if (ongoingPromises[i].key === fileKey) {
                                    ongoingPromises.splice(i, 1);

                                    return;
                                }
                            }
                        })

                        // Start new requests if batch size allows
                        .then(function () {
                            if (fileKeysToProcess.length > 0 && ongoingPromises.length < batchSize) {
                                const nextFileKey = fileKeysToProcess.shift();

                                ongoingPromises.push({
                                    key: nextFileKey,
                                    promise: getPromise(nextFileKey)
                                });
                            }
                        })

                        // Cleaning up for the last completed package
                        .then(function () {
                            if (fileKeysToProcess.length === 0 && ongoingPromises.length === 0) {
                                $rootScope.toLastStep();

                                return BatesModel.setBatesFinishedStatus();
                            }
                        })
                        .finally(function () {
                            $rootScope.loading_message = '';
                        });
                };
                const initRolling = function () {
                    fileKeysToProcess
                        .filter(function (key) {
                            if (ongoingPromises.length < batchSize) {
                                ongoingPromises.push({
                                    key: key,
                                    promise: getPromise(key)
                                });
                                return true;
                            }
                        })
                        .map(function (key) {
                            const index = fileKeysToProcess.indexOf(key);

                            if (index >= 0) {
                                fileKeysToProcess.splice(index, 1);
                            }

                            return fileKeysToProcess;
                        })
                };

                initRolling();
            };
        }
    ]);
})(angular.module('onfileApp'));