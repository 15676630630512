(function (app) {
    app.controller('CreateServiceContactCtrl', [
        'ServiceContactResource', 'ServiceContactService', 'StateResource',
        'MarketRulesService',
        '$scope', '$rootScope', 'ToastrService',
        'FilingCreateCacheService', 'CaseService', 'areServiceTypesNeeded',
        'ServiceTypeCodesService',

        function (
            ServiceContactResource, ServiceContactService, StateResource,
            MarketRulesService,
            $, $rootScope, ToastrService,
            FilingCreateCacheService, CaseService, areServiceTypesNeeded,
            ServiceTypeCodesService
        ) {
            angular.extend($, {
                serviceContacts: [],
                //serviceContact: {},
                foundContacts: [],
                countryStates: [],
                errors: {},
                FilingCreateCacheService: FilingCreateCacheService,
                serviceContactsPartiesRelations: {},
                serviceTypes: null
            });
            var pageSize = 10;

            // Add selected contact to Service contacts list
            $.addContactToCase = function (contact) {
                var newServiceContact;

                if (!contact)
                    return false;

                if (!$.case.hasOwnProperty('service_contacts')) {
                    $.case.service_contacts = [];
                }

                newServiceContact = new ServiceContactService(contact);
                newServiceContact.addedByUser = true;

                $.case.service_contacts.push(newServiceContact);
                $.createNewContactForm = false;
                $.serviceContact = null;

                var data = {
                    id: contact.id,
                    caseId: $.case.id
                };

                if (data.caseId) {
                    ServiceContactResource.attachToCase(data);
                }

                return true;
            };

            $.getRecipientsChecked = function () {
                if (!$.case || !$.case.service_contacts) {
                    return 'no';
                }
                var contacts = $.case.service_contacts;
                var selectedAll = true;
                var noSelected = true;
                for (var i = 0; i < contacts.length; i++) {
                    noSelected &= !contacts[i].included;
                    selectedAll &= contacts[i].included;
                }

                return (
                    selectedAll && !noSelected ?
                        'all' :
                        !selectedAll && noSelected ?
                            'no' :
                            'indeterminate'
                );
            };

            $.selectServiceContacts = function () {
                var contacts = $.case.service_contacts;
                var state = $.getRecipientsChecked();
                var key = state === 'no';

                for (var i = 0; i < contacts.length; i++) {
                    contacts[i].included = key;
                }
            };

            $.removeContactFromCase = function (contact) {
                for (var i = 0; i < $.case.service_contacts.length; i++) {
                    if (!$.case.service_contacts[i].hasOwnProperty('id') || $.case.service_contacts[i].id !== contact.id)
                        continue;

                    $.case.service_contacts.splice(i, 1);
                    break;
                }
            };

            $.showDetachContactModal = function (contact) {
                $.contactToDetach = contact;
                $.detachContactModal = true;
            };

            $.hideDetachContactModal = function () {
                $.contactToDetach = false;
                $.detachContactModal = false;
            };

            $.detachContactFromCase = function (contact) {
                contact = contact || $.contactToDetach;

                ServiceContactResource.detachFromCase({id: contact.id, caseId: $.case.id}).$promise
                    .then(function () {
                        $.removeContactFromCase(contact);
                        //show success message
                        ToastrService.showSuccess('Service contact detached from Case');
                    });
            };

            var totalfoundContacts;
            $.findContacts = function (search) {
                ServiceContactResource.query(search).$promise
                    .then(function (contacts) {
                        $.currentPage = 1;
                        $.totalPage = Math.ceil(contacts.length / pageSize);
                        $.pager = new Array($.totalPage);
                        $.foundContacts = contacts.slice(0, pageSize);
                        totalfoundContacts = contacts;
                    })
                    .catch(function (res) {
                        // @TODO if something goes wrong
                    });
            };

            $.showPage = function (pageNumber) {
                if (pageNumber <= $.totalPage && pageNumber > 0) {
                    $.currentPage = pageNumber;
                    $.foundContacts = totalfoundContacts.slice((pageNumber - 1) * pageSize, (pageNumber * pageSize));
                }
            };

            $.fullName = function (contact) {
                return contact.firstName + ' ' + contact.lastName;
            };

            // Opens new child window for creation og a Contact
            $.newContact = {};
            $.validate = function (createContact) {
                var data = angular.copy(createContact);

                if (data && data.hasOwnProperty('state') && data.state && data.state.hasOwnProperty('code')) {
                    data.state = data.state.code;
                }

                data.httpCodeIgnorePopup = 422;

                $.errors = {};

                ServiceContactResource.validate(data).$promise
                    .then(function () {
                        ServiceContactResource.create(data).$promise
                            .then(function (contact) {
                                $.isCreateWindowOpen = false;

                                $.addContactToCase(contact);
                                $.serviceContacts.push(contact);
                            })
                            .catch(function (errors) {
                                $.errors.common = errors.data;
                            });
                    })
                    .catch(function (res) {
                        $.errors = res.data;
                    });
            };

            $.$watch('isCreateWindowOpen', function (value) {
                if (value === true && $.countryStates.length === 0) {
                    StateResource.forUSA().$promise
                        .then(function (countryStates) {
                            $.countryStates = countryStates;
                        });
                }
            });

            $.setStageComplete = function () {
                if (!MarketRulesService.isRequired('serviceContacts')) {
                    return true;
                }

                if ($.case.service_contacts && $.case.service_contacts.length) {
                    $rootScope.isServiceContactStageIsValid = true;
                    return true;
                }

                ToastrService.showMessageByKey('service_contact_is_required');
                return false;
            };

            $.getParties = function (e) {
                var parties = [];

                if (e && e.getParties) {
                    parties = e.getParties()
                        .filter(function (p) { return p.id; })
                        .map(function (p) {
                            return { id: p.id, name: p.getName() };
                        });
                }

                return parties;
            };

            $.updateContactParty = function (contact) {
                var data = {
                    id: contact.id,
                    caseId: $.case.id,
                    partyId: contact.party && contact.party.id || null
                };

                return ServiceContactResource.attachToCase(data).$promise
                    .then(function () {
                        return CaseService.saveServiceContactsForParty({
                            caseId: data.caseId,
                            serviceContactId: data.id,
                            partyId: data.partyId
                        });
                    });
            };

            // Fetching all Contacts
            ServiceContactResource.query().$promise
                .then(function (contacts) {
                    $.serviceContacts = contacts;
                });

            $.$watch('case.id', function (caseId) {
                if (!caseId) {
                    return;
                }

                CaseService.getServiceContactsForParty(caseId)
                    .then(function (d) {
                        Object.keys(d).map(function (serviceContactId) {
                            var partyId = d[serviceContactId] && d[serviceContactId].partyId;

                            if (!partyId) {
                                return;
                            }

                            $.serviceContactsPartiesRelations[serviceContactId] = partyId;
                        });
                    })
                    .then(function () {
                        return CaseService.addServiceContactsIfEmpty($.case);
                    })
                    .then(function () {
                        $.case.service_contacts.map(function (contact) {
                            var party;
                            var serviceContactId = contact.id;
                            var parties = $.getParties($.case);
                            var neededPartyId = $.serviceContactsPartiesRelations[serviceContactId];

                            if (neededPartyId) {
                                party = parties.find(function (p) {
                                    return p.id === neededPartyId;
                                });
                            }

                            if (party) {
                                contact.party = party;
                            }

                            return contact;
                        });
                    });
            });

            $.$watch('activeTabIndex', function (currentTab) {
                if (currentTab === 3 && $.serviceTypes === null) {
                    ServiceTypeCodesService.getList($.case)
                        .then(function (data) {
                            if (!data || !data.length) {
                                return;
                            }

                            ServiceTypeCodesService.setInitialTypes($.case.service_contacts, data);
                            $.serviceTypes = data;
                        });
                }
            });
        }
    ]);
})(angular.module('onfileApp'));
