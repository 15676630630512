(function (app) {
    var dataOS = [
        {name: 'Windows Phone', value: 'Windows Phone', version: 'OS'},
        {name: 'Windows', value: 'Win', version: 'NT'},
        {name: 'iPhone', value: 'iPhone', version: 'OS'},
        {name: 'iPad', value: 'iPad', version: 'OS'},
        {name: 'Kindle', value: 'Silk', version: 'Silk'},
        {name: 'Android', value: 'Android', version: 'Android'},
        {name: 'PlayBook', value: 'PlayBook', version: 'OS'},
        {name: 'BlackBerry', value: 'BlackBerry', version: '/'},
        {name: 'Macintosh', value: 'Mac', version: 'OS X'},
        {name: 'Linux', value: 'Linux', version: 'rv'},
        {name: 'Palm', value: 'Palm', version: 'PalmOS'}
    ];
    var dataBrowser = [
        {name: 'Chrome', value: 'Chrome', version: 'Chrome'},
        {name: 'Firefox', value: 'Firefox', version: 'Firefox'},
        {name: 'Safari', value: 'Safari', version: 'Version'},
        {name: 'Internet Explorer', value: 'MSIE', version: 'MSIE'},
        {name: 'Opera', value: 'Opera', version: 'Opera'},
        {name: 'BlackBerry', value: 'CLDC', version: 'CLDC'},
        {name: 'Mozilla', value: 'Mozilla', version: 'Mozilla'}
    ];

    app.service('UserAgentService', [
        function UserAgentService() {
            this.decodeOS = function (navigator) {
                var str = Object.values(navigator).join(' ');

                return this.match(str, dataOS);
            };

            this.decodeBrowser = function (navigator) {
                var str = Object.values(navigator).join(' ');

                return this.match(str, dataBrowser);
            };

            this.match = function (string, data) {
                var regex;
                var regexv;
                var match;
                var matches;
                var version;

                for (var i = 0; i < data.length; i += 1) {
                    regex = new RegExp(data[i].value, 'i');
                    match = regex.test(string);

                    if (match) {
                        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                        matches = string.match(regexv);
                        version = '';

                        if (matches && matches[1]) {
                            matches = matches[1];
                        }

                        if (matches) {
                            // $FlowFixMe
                            matches = matches.split(/[._]+/);

                            for (var j = 0; j < matches.length; j += 1) {
                                if (j === 0) {
                                    version += matches[j] + '.';
                                } else {
                                    version += matches[j];
                                }
                            }
                        } else {
                            version = '0';
                        }

                        return [data[i].name, parseFloat(version)]
                            .filter(function (o) {
                                if (typeof o === 'string' && o) {
                                    return true;
                                }

                                return version !== null && version !== undefined && !isNaN(version);
                            }).join(' ');
                    }
                }

                return null;
            };
        }
    ]);
})(angular.module('onfileApp'));

