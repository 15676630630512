(function (app) {
    app.factory('loaderInterceptor', [
        '$q', '$rootScope',

        function ($q, $rootScope) {
            var countLoadingResources = 0;

            function getLoaderElement() {
                return angular.element('#workspace-loading');
            }

            $rootScope.startLoader = function startLoader(options) {
                var show = options.show === undefined ? true : !!options.show;
                var message = options.message;

                countLoadingResources++;

                if (show) {
                    getLoaderElement().addClass('is-visible');
                }

                if (message) {
                    $rootScope.loading_message = message;
                }
            };

            $rootScope.stopLoader = function stopLoader() {
                countLoadingResources--;

                // If all resources are loaded hide loader.
                if (countLoadingResources === 0) {
                    $rootScope.loading_message = '';
                    getLoaderElement().removeClass('is-visible');
                }
            };

            return {
                request: function (config) {
                    var show = true;

                    if (config && config.ignoreLoaderInterceptor) {
                        show = false;
                    }

                    $rootScope.startLoader({ show: show });

                    return config;
                },
                response: function (response) {
                    $rootScope.stopLoader();

                    return response;
                },
                responseError: function (response) {
                    $rootScope.stopLoader();

                    return $q.reject(response);
                }
            };
        }
    ]);
})(angular.module('onfileApp'));