(function (app) {
    app.controller('CreateMatterCtrl', [
        '$scope', '$mdDialog', 'DocumentGroupsResourceFactory',
        'S3FileUploader', '$q', '$state',
        '$rootScope', 'ToastrService', '$timeout',
        'utils',
        function (
            $, $mdDialog, DocumentGroupsResourceFactory,
            S3FileUploader, $q, $state,
            $rootScope, ToastrService, $timeout,
            utils
        ) {
            var resourceCreated = DocumentGroupsResourceFactory.create();

            $.model = {};
            $.files = [];

            $.close = function () { $mdDialog.hide(); };

            $.submit = function () {
                return resourceCreated
                    .then(function (resource) {
                        var requestData = Object.assign({}, $.model, {
                            type: 'matter',
                            documentIds: $.files.map(function (d) { return d.id; })
                        });

                        return resource.create(requestData).$promise;
                    })
                    .then(function (res) {
                        $rootScope.$emit('newRequestAdded');
                        ToastrService.showSuccess('New matter created');
                        $.close();

                        $timeout(function () {
                            $state.transitionTo('oneNotaryRequest', { id: res.id });
                        }, 1500);
                    });
            };

            $.filesSelected = function (files) {
                var cachedResource;

                return resourceCreated
                    .then(function (resource) {
                        cachedResource = resource;

                        var fileUploads = files.map(function (file) {
                            return cachedResource.uploadUrl({ filename: file.name })
                                .$promise
                                .then(function (res) {
                                    return S3FileUploader.uploadByPreSignUrl(res.url, file)
                                        .then(function () {
                                            return Object.assign({}, file, res);
                                        });
                                });
                        });

                        return $q.all(fileUploads);
                    })
                    .then(function (files) {
                        var requestData = files.map(function (file) {
                            file.displayedName = file.filename;

                            return utils.extractProps(file, ['filename', 'key', 'displayedName']);
                        });

                        return cachedResource.createDocumentsBatch(requestData).$promise;
                    })
                    .then(function (newFiles) {
                        $.files = $.files.concat(newFiles);
                    });
            };

            $.removeFile = function (i) {
                $.files.splice(i, 1);
            };
        }
    ]);
})(angular.module('onfileApp'));
