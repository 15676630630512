(function (app) {
    app.filter('searchFilter', function searchFilter() {
        return function (array, search, properties) {
            var i;
            var item;
            var matchesCount;
            var result = [];
            var patterns = [];

            if (!search) {
                return array;
            }

            search = search.split(' ');

            for (i in search) {
                patterns.push(new RegExp(search[i], 'i'));
            }

            for (i in array) {
                item = array[i];
                matchesCount = 0;

                for (var property in item) {
                    if (typeof item[property] !== 'string') {
                        continue;
                    }

                    if(properties !== undefined && properties.indexOf(property) === -1) {
                        continue;
                    }

                    for (var index in patterns) {
                        if (!item[property].match(patterns[index])) {
                            continue;
                        }

                        matchesCount++;
                    }
                }

                if (matchesCount >= patterns.length) {
                    result.push(item);
                }
            }

            return result;
        };
    });
})(angular.module('onfileApp'));
