(function (app) {
    app.factory('ServiceContactService', ['ServiceContactResource', function (ServiceContactResource) {
        function getFirmServiceContacts() {
            return ServiceContactResource.query().$promise;
        }

        return function ServiceContactService(data) {
            var _this = this;

            this.addedByUser = false;
            this.isFirmContact = false;

            function init(data) {
                angular.extend(this, data);

                if (data.hasOwnProperty('firmName')) {
                    this.company_name = data.firmName;
                }
                if (data.hasOwnProperty('firstName')) {
                    this.name = data.firstName;
                }
                if (data.hasOwnProperty('firstName') && data.hasOwnProperty('lastName')) {
                    this.name = data.firstName + ' ' + data.lastName;
                }
                if (data.hasOwnProperty('serviceContactID')) {
                    this.id = data.serviceContactID;
                }
                this.included = data.hasOwnProperty('included') ? this.included : true;

                getFirmServiceContacts().then(function (firmContacts) {
                    for (var i = 0; i < firmContacts.length; i++) {
                        if (firmContacts[i].id == _this.id) {
                            _this.isFirmContact = true;
                            break;
                        }
                    }
                });
            }

            if (data) {
                init.call(this, data);
            }

            return this;
        };
    }]);
})(angular.module('onfileApp'));