(function (app) {
    app.factory('CourtResource', [
        '$resource', 'urlEncodedResource', '$http',
        'courtsDocumentsMappingUrl', 'EnvironmentService', 'utils',
        '$q',

        function (
            $resource, urlEncodedResource, $http,
            courtsDocumentsMappingUrl, EnvironmentService, utils,
            $q
        ) {
            var market = EnvironmentService.market();
            var environment = EnvironmentService.environment();
            var original = $resource(
                '/courts/:id',
                {
                    id: '@id',
                    envelopeNumber: '@envelopeNumber'
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        cache: true
                    },
                    contact: {
                        method: 'GET',
                        isArray: false,
                        cache: true,
                        url: '/courts/courtcontact/:id'
                    },
                    getContact: urlEncodedResource({
                        method: 'POST',
                        isArray: false,
                        cache: true,
                        url: '/courts/courtContactSecure'
                    }),
                    getAvailableDates: {
                        method: 'GET',
                        isArray: false,
                        url: '/courts/dates'
                    },
                    reserveDates: urlEncodedResource({
                        method: 'POST',
                        isArray: false,
                        url: '/courts/reserveDate'
                    }),
                    getHearingStatuses: urlEncodedResource({
                        method: 'GET',
                        isArray: true,
                        url: '/courts/hearingStatuses'
                    }),
                    getHearingStatus: urlEncodedResource({
                        method: 'GET',
                        isArray: false,
                        url: '/courts/hearingStatus'
                    }),
                    patchEnvelopeInformation: urlEncodedResource({
                        method: 'POST',
                        isArray: false,
                        url: '/courts/:envelopeNumber/hearingStatus'
                    })
                }
            );

            original.getDocumentsMapping = function () {
                var promise = $q.resolve(null);
                var url = utils.getOr(function () {
                    return courtsDocumentsMappingUrl[market][environment].url;
                }, null);

                if (url) {
                    promise = $http.get(url)
                        .then(function (response) {
                            if (response && response.data && typeof response.data === 'object') {
                                return response.data;
                            }

                            return null;
                        })
                        .catch(function () {
                            return null;
                        });
                }

                return {
                    $promise: promise
                };
            };

            return original;
        }]);
})(angular.module('onfileApp'));
