(function (app) {
    app.controller('RemindersSidebarCtrl', [
        '$rootScope', '$scope', '$stateParams',
        'ReminderService',

        function ($rootScope, $, $stateParams,
            ReminderService) {
            $rootScope.showList = true;

            angular.extend($, {
                reminders: [],
                activeReminderId: $stateParams.hasOwnProperty('id') ? $stateParams.id : null,
                isOverDue: ReminderService.isOverDue
            });

            ReminderService.getAll()
                .then(function (all) {
                    ReminderService.removeExpired(all);
                    $.reminders = all;
                });

            this.uiOnParamsChanged = function (changedParams) {
                // If current active Reminder ID is changed - refresh variable for a view.
                if (changedParams.hasOwnProperty('id')) {
                    $.activeReminderId = changedParams.id;
                }
            };
        }
    ]);
})(angular.module('onfileApp'));