(function (app) {
    app.controller('DeleteDocumentConfirmationCtrl', [
        '$scope', '$rootScope', 'ToastrService',
        '$mdDialog', 'documentId', 'matterId',
        'DocumentGroupsResourceFactory',
        function (
            $, $rootScope, ToastrService,
            $mdDialog, documentId, matterId,
            DocumentGroupsResourceFactory
        ) {
            $.delete = function () {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        return resource.delete({ id: documentId }).$promise;
                    })
                    .then(function () {
                        $rootScope.$emit('updateAllMatters', { reloadMatterId: matterId });
                        ToastrService.showSuccess('Document deleted');
                        $.close();
                    });
            };

            $.close = function () {
                $mdDialog.hide();
            };
        }
    ]);
})(angular.module('onfileApp'));
