(function (app) {
    app.service('HeadingService', [
        '$state',
        function HeadingService($state) {
            var states = {
                recentFilings: {
                    name: 'recentFilings',
                    type: 'recentFilings',
                    heading: 'My recent filings'
                },
                searchFilings: {
                    name: 'searchFilings',
                    type: 'searchFilings',
                    heading: ''
                },
                notarySessions: {
                    stateName: 'notarySessions',
                    name: 'notarySessions',
                    type: 'notarySessions',
                    heading: ''
                },
                oneNotarySession: {
                    stateName: 'oneNotarySession',
                    name: 'oneNotarySession',
                    type: 'oneNotarySession',
                    heading: ''
                },
                notaryRequests: {
                    stateName: 'notaryRequests',
                    name: 'notaryRequests',
                    type: 'notaryRequests',
                    heading: ''
                },
                oneNotaryRequest: {
                    stateName: 'oneNotaryRequest',
                    name: 'oneNotaryRequest',
                    type: 'oneNotaryRequest',
                    heading: ''
                }
            };

            this.currentPageType = function () {
                var name = $state.$current.name;
                var pageTypes = [
                    { stateName: 'draftServices', type: 'draftServices' },
                    { stateName: 'draftFilings', type: 'efilingsDrafts' },
                    { stateName: 'serviceInbox', type: 'eServicesInbox' },
                    { stateName: 'filing', type: 'eFilings' },
                    { stateName: 'service', type: 'eServices' },
                    { stateName: 'caseView', type: 'caseView' },
                    { stateName: 'case', type: 'Cases' },
                    { stateName: 'eProductionDraftView', type: 'eProductionDrafts' },
                    { stateName: 'eProductionDrafts', type: 'eProductionDrafts' },
                    { stateName: 'eProduction', type: 'eProduction' },
                    { stateName: 'reminders', type: 'reminders' },
                    { stateName: 'viewReminder', type: 'viewReminder' },
                    { stateName: 'textAlertsSettings', type: 'textAlertsSettings' },
                    { stateName: 'createBates', type: 'createBates' },
                    states.notarySessions,
                    states.oneNotarySession
                ];
                Object.keys(states).map(function (key) {
                    var state = states[key];

                    pageTypes.push({
                        stateName: state.name,
                        type: state.type
                    });
                });


                for (var i = 0; i < pageTypes.length; i++) {
                    if (name.indexOf(pageTypes[i].stateName) >= 0) {
                        return pageTypes[i].type;
                    }
                }
            };

            this.filterPlaceHolder = function (type) {
                if (type === 'Cases')
                    return 'Filter cases';
                if (type === 'eFilings')
                    return 'Filter eFilings';
                if (type === 'eServices')
                    return 'Filter submitted eServices';
                if (type === 'eServicesInbox')
                    return 'Filter received eServices';
                if (type === 'efilingsDrafts')
                    return 'Filter draft eFilings';
                if (type === 'draftServices')
                    return 'Filter draft eServices';
                if (type === 'viewReminder')
                    return 'Filter reminders';
                if (type === 'reminders')
                    return 'Filter reminders';
                if (type === 'eProductionDrafts')
                    return 'Filter draft eProductions';
                return type;
            };

            this.cardsHeading = function (type) {
                var types = {
                    Cases: 'Firm Cases',
                    caseView: 'Firm Cases',
                    eFilings: 'Submitted eFilings',
                    eServices: 'Submitted eServices',
                    eServicesInbox: 'Received eServices',
                    efilingsDrafts: 'Draft eFilings',
                    draftServices: 'Draft eServices',
                    eProduction: 'Submitted eProductions',
                    eProductionDrafts: 'Draft file packages',
                    reminders: 'Reminders',
                    viewReminder: 'Reminders'
                };
                var neededKey = Object.keys(states).find(function (key) {
                    var state = states[key];

                    return state.type === type;
                });

                if (neededKey) {
                    return states[neededKey].heading;
                }

                for (var prop in types) {
                    if (prop === type) {
                        return types[prop];
                    }
                }

                return type;
            };

            this.shouldShowSearchInput = function () {
                var hideFor = [
                    'caseView', 'Cases', states.recentFilings.type, states.searchFilings.type,
                    states.notarySessions.type, states.oneNotarySession.type, states.notaryRequests.type,
                    states.oneNotaryRequest.type
                ];

                return hideFor.indexOf(this.currentPageType()) === -1;
            };

            this.shouldShowSearchButton = function () {
                var showFor = [states.recentFilings.type];

                return showFor.indexOf(this.currentPageType()) >= 0;
            };

            this.shouldShowPrimarySearchButton = function () {
                var showFor = [states.searchFilings.type];

                return showFor.indexOf(this.currentPageType()) >= 0;
            };

            this.shouldShowSearchPanel = function () {
                var showFor = [states.searchFilings.type];

                return showFor.indexOf(this.currentPageType()) >= 0;
            };
        }
    ]);
})(angular.module('onfileApp'));
