(function (app) {
    app.factory('AttorneyService', function () {
        function AttorneyService(data) {
            function init(data) {
                angular.extend(this, data);

                if (data && !data.hasOwnProperty('name') && this.hasOwnProperty('firstName')) {
                    this.fullName = this.firstName + (this.middleName ?
                        (' ' + this.middleName) : '')
                        + ' ' + this.lastName;
                } else if (data.hasOwnProperty('name'))
                    this.fullName = data.name;
                else
                    this.fullName = null;
            }

            if (data) {
                init.call(this, data);
            }

            this.getId = function () {
                return this.attorneyID;
            };

            return this;
        }

        var neededNameParts = ['firstName', 'middleName', 'lastName'];
        AttorneyService.getFullName = function (eCase) {
            if (!eCase || !eCase.attorney) {
                return '';
            }

            if (eCase.attorney.name) {
                return eCase.attorney.name;
            }

            var res = '';
            for (var i = 0; i < neededNameParts.length; i++) {
                if (eCase.attorney[neededNameParts[i]]) {
                    res += eCase.attorney[neededNameParts[i]] + ' ';
                }
            }

            return res.trim();
        };

        return AttorneyService;
    });
})(angular.module('onfileApp'));
