(function (app) {
    app.service('FilingDocumentEntityFactory', [
        'documentComponentCodes', 'utils',
        function (documentComponentCodes, utils) {
            function FilingDocumentEntity(data) {
                this.isNew = true;
                this.scan_status = 'Scanning';
                this.file_scanned = false;
                this.is_ok = false;

                this.setScanResult = function (result) {
                    this.scan_status = result;
                    this.file_scanned = true;
                    this.is_ok = result === 'Clean';

                    return this;
                };

                this.setDefaultComponentCode = function () {
                    for (var i = 0; i < documentComponentCodes.length; i++) {
                        if (documentComponentCodes[i].isDefault) {
                            this.document_component_code = documentComponentCodes[i].value;
                            break;
                        }
                    }

                    return this;
                };

                utils.propagateProperties(this, data);
            }

            return {
                create: function (data) {
                    return new FilingDocumentEntity(data);
                }
            };
        }
    ]);
})(angular.module('onfileApp'));