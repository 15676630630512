(function (app) {
    app.factory('DamageAmountResource', ['$resource',
        function ($resource) {
            return $resource('/damageAmounts?court_code=:courtCode', {courtCode: '@courtCode'}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: true
                }
            });
        }]);
})(angular.module('onfileApp'));