(function (app) {
    app.service('CachedDataService', [
        '$window',
        function ($window) {
            var dataStorage = $window.localStorage;
            var storageKeyPrefix = 'createFilingCache';

            var getFullKey = function (key) { return storageKeyPrefix + '.' + key; };

            this.set = function (key, val) {
                dataStorage.setItem(getFullKey(key), JSON.stringify(val));

                return this;
            };

            this.get = function (key) {
                var data = dataStorage.getItem(getFullKey(key));

                return data ? JSON.parse(data) : null;
            };

            this.remove = function (key) {
                dataStorage.removeItem(getFullKey(key));

                return this;
            };
        }
    ]);
})(angular.module('onfileApp'));
