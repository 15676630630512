(function (app) {
    app.factory('NotaryRequestDocumentsResourceFactory', [
        '$resource', '$q', 'notaryTokenUtil',
        'EnvironmentService',
        function (
            $resource, $q, notaryTokenUtil,
            EnvironmentService
        ) {
            return {
                create: function (options) {
                    var headers = {
                        'X-OFFICIAL-ID': (options && options.userId) || EnvironmentService.userId(),
                        'X-FIRM-ID': (options && options.firmId) || EnvironmentService.firm_id()
                    };
                    var pathParams = { id: '@id' };

                    var resource = $resource(
                        notaryTokenUtil.notaryApiHost + '/requests', pathParams, {
                            token: {
                                url: '/token/notary',
                                method: 'GET',
                                isArray: false,
                                cache: false
                            },
                            uploadUrl: {
                                url: notaryTokenUtil.notaryApiHost + '/request-documents/upload-url?' +
                                    'request-id=:requestId&filename=:filename',
                                method: 'GET',
                                isArray: false,
                                cache: false,
                                headers: notaryTokenUtil.addAuthorizationToken(headers)
                            },
                            addDocument: {
                                url: notaryTokenUtil.notaryApiHost + '/request-documents/batch',
                                method: 'POST',
                                isArray: true,
                                cache: false,
                                headers: notaryTokenUtil.addAuthorizationToken(headers)
                            },
                            create: {
                                method: 'POST',
                                isArray: false,
                                cache: false,
                                headers: notaryTokenUtil.addAuthorizationToken(headers)
                            },
                            update: {
                                url: notaryTokenUtil.notaryApiHost + '/requests/:id',
                                method: 'PUT',
                                isArray: false,
                                cache: false,
                                headers: notaryTokenUtil.addAuthorizationToken(headers)
                            },
                            query: {
                                method: 'GET',
                                isArray: true,
                                cache: false,
                                headers: notaryTokenUtil.addAuthorizationToken(headers)
                            }
                        }
                    );

                    return notaryTokenUtil.checkToken(resource, $q)
                        .then(function () {
                            return resource;
                        });
                }
            };
        }]
    );
})(angular.module('onfileApp'));
