(function (app) {
    app.factory('SuffixResource', ['$resource',
        function ($resource) {
            return $resource('/suffixes', {}, {
                query: {
                    method: 'GET',
                    isArray: true
                }
            });
        }]);
})(angular.module('onfileApp'));