(function (app) {
    app.factory('NotaryFilesResourceFactory', [
        '$resource', '$q', 'notaryTokenUtil',
        function ($resource, $q, notaryTokenUtil) {
            return {
                create: function (options) {
                    var headers = {
                        'X-OFFICIAL-ID': options.officialId,
                        'X-FIRM-ID': options.firmId
                    };

                    var pathParams = {
                        sessionId: '@sessionId',
                        filename: '@filename',
                        documentId: '@documentId'
                    };

                    var resource = $resource(notaryTokenUtil.notaryApiHost + '/documents', pathParams, {
                        token: {
                            url: '/token/notary',
                            method: 'GET',
                            isArray: false,
                            cache: false
                        },
                        query: {
                            url: notaryTokenUtil.notaryApiHost + '/documents?session-id=:sessionId',
                            method: 'GET',
                            isArray: true,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        uploadUrl: {
                            url: notaryTokenUtil.notaryApiHost +
                                '/documents/upload-url?session-id=:sessionId&filename=:filename',
                            method: 'GET',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        create: {
                            method: 'POST',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        copyFromDocumentGroup: {
                            url: notaryTokenUtil.notaryApiHost + '/documents/:documentId/copy',
                            method: 'POST',
                            isArray: false,
                            cache: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers),
                            transformRequest: function (body) {
                                if (body.documentId !== undefined) {
                                    delete body.documentId;
                                }

                                return angular.toJson(body);
                            }
                        }
                    });

                    return notaryTokenUtil.checkToken(resource, $q)
                        .then(function () {
                            return resource;
                        });
                }
            };
        }]
    );
})(angular.module('onfileApp'));
