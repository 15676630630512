;(function (app) {
    app.service('DataProvider', [
        'FirebaseService', 'EnvironmentService', '$q',

        function (FirebaseService, EnvironmentService, $q) {
            const firmId = EnvironmentService.firm_id();
            const storageCache = {};

            /**
             * @param path
             * @param options
             * @return Promise
             */
            const getArrayFromFirebase = function (path, options) {
                const modifier = options ? options.modifier : null;

                return FirebaseService
                    .getNodeAsArray(path, modifier)
                    .$loaded();
            };

            /**
             * @param path
             * @return Promise
             */
            const getObjectFromFirebase = function (path) {
                return FirebaseService
                    .getNode(path)
                    .$loaded();
            };

            /**
             * @param storageName
             * @return Promise
             */
            const getFromCache = function (storageName) {
                const defer = $q.defer();

                defer.resolve( storageCache[storageName] );

                return defer.promise;
            };

            this.getPath = function (storageName, id) {
                var path = '/filings/' + firmId + '/' + storageName + '/data';

                if (id) {
                    path += '/' + id;
                }

                return path;
            };

            /**
             * @param storageName
             * @param options
             * @return Promise
             */
            this.getList = function (storageName, options) {
                const nodePath = this.getPath(storageName);
                var ignoreCached = options && options.ignoreCached;

                if (!ignoreCached && storageCache[storageName]) {
                    return getFromCache(storageName);
                }

                return getArrayFromFirebase(nodePath, options)
                    .then(function (data) {
                        storageCache[storageName] = data;

                        return storageCache[storageName];
                    });
            };

            /**
             * @param storageName
             * @param id
             * @return Promise
             */
            this.getById = function (storageName, id) {
                const nodePath = this.getPath(storageName, id);
                const cacheKey = storageName + '-' + id;

                if (storageCache[cacheKey]) {
                    return getFromCache(cacheKey);
                }

                return getObjectFromFirebase(nodePath)
                    .then(function (data) {
                        storageCache[cacheKey] = data;

                        return storageCache[cacheKey];
                    });
            };

            this.getOneFromPath = function (path) {
                return getObjectFromFirebase(path);
            };
        }
    ]);
})(angular.module('onfileApp'));
