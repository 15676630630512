(function (app) {
    app.service('CourtService', [function CacheService() {
        var cache = {};

        var setCache = function (key, val) {
            cache[key] = val;

            return this;
        };

        var getCache = function (key) {
            if (cache[key]) {
                return cache[key];
            }

            return void(0);
        };

        this.filterListByName = function (name, list) {
            var lowercaseQuery;
            var cached;
            var res = [];

            if (!name) {
                return list;
            }

            if (!list || !list.length) {
                return res;
            }

            lowercaseQuery = name.toLowerCase().trim();
            cached = getCache(lowercaseQuery);

            if (!cached) {
                for (var i = 0; i < list.length; i++) {
                    if (!list[i] && !list[i].name) {
                        continue;
                    }

                    var curr = list[i].name.toLowerCase();

                    if (curr.indexOf(lowercaseQuery) >= 0) {
                        res.push(list[i]);
                    }
                }

                cached = res;
                setCache(lowercaseQuery, res);
            }

            return cached;
        };
    }]);
})(angular.module('onfileApp'));
