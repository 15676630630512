(function (app) {
    app.controller('eProductionCreateCtrl', [
        '$scope', '$state', '$window',
        '$http', '$q', '$rootScope',
        'FirebaseService', 'eProductionService', 'filepickerApikey',
        'EnvironmentService', 'PDFParserService', 'ToastrService',
        'mimeTypeMap', '$rootScope', 'CaseResource',
        'Upload', 'EProductionHelper',

        function (
            $scope, $state, $window,
            $http, $q, $,
            FirebaseService, eProductionService, filepickerApikey,
            EnvironmentService, PDFParserService, ToastrService,
            mimeTypeMap, $rootScope, CaseResource,
            Upload, EProductionHelper
        ) {
            var rootPath = '/';
            var uploadRequest;
            var maximumExpiry = 90;
            var minimumExpiry = 2;
            var defaultExpiry = 10;

            $.showList = false;
            $.eProdId = null;

            $scope.showSelectCasePopup = !$state.params.caseId;
            $scope.caseId = $state.params.caseId;

            $scope.caseId = $state.params.caseId;
            $scope.courtId = $state.params.courtId;
            $scope.eProdId = $.eProdId = $state.params.eProdId;

            $scope.caseName = null;
            $scope.showPreview = false;

            $scope.totalSize = 0;
            $scope.totalPage = 0;
            $scope.eProdNotes = '';
            $scope.expiryOpt = defaultExpiry;
            $scope.tempPdfPreview = '';
            $scope.fileList = [];
            $scope.pageSize = 5;

            $scope.privilegeLogsCount = 0;

            //$scope.pager = 0;

            $scope.currentPage = 1;


            $scope.totalPage = function () {
                return Math.ceil($scope.fileList.length / $scope.pageSize);
            };

            $scope.showPage = function (pageNumber, recalculatePages) {
                if (recalculatePages) $scope.pager = new Array($scope.totalPage());
                if ($scope.eProdBundle !== undefined) {
                    if (pageNumber <= $scope.totalPage() && pageNumber > 0) {
                        $scope.currentPage = pageNumber;

                        $scope.limit = $scope.pageSize;
                        $scope.begin = (pageNumber - 1) * $scope.pageSize;
                    }
                }
            };

            var loadEProduction = function () {
                var loader = angular.element('#workspace-loading');

                loader.addClass('is-visible');

                return eProductionService
                    .init(
                        $scope.caseId,
                        $scope.eProdId,
                        EnvironmentService.firm_id(),
                        $scope.courtId,
                        $scope.caseName
                    )
                    .then(function (bundle) {
                        loader.removeClass('is-visible');

                        if (bundle == null || !bundle.d) {
                            ToastrService.showMessageByKey('eprod_not_found');
                            throw new Error('eProduction bundle not found');
                        }

                        $scope.eProdBundle = bundle;
                        // TODO: refactor to use popup
                        $scope.eProdNotes = $scope.eProdBundle.d.batch_header.notes;
                        $scope.expiryOpt = $scope.eProdBundle.d.batch_header.expire_time;

                        // Avoid creating new eProduction if window is refreshed
                        if (!$scope.eProdId) {
                            var sep = (window.location.href.indexOf('?') === -1) ? '?' : '&';
                            window.location.href = window.location.href +
                                sep + 'eProdId=' + $scope.eProdBundle.eProdId;
                        }
                    });
            };

            $scope.organizeFilesListener = {
                // @TODO change re-writing objects with changing of 'orderNumber' field.
                orderChanged: function () {
                    var ids = $scope
                        .fileList
                        .map(function (item) {
                            return item.id;
                        });

                    var i = 0;
                    for (var id in $scope.eProdBundle.d.batch_body.files) {
                        if (ids.indexOf(id) === -1) {
                            continue;
                        }

                        $scope.eProdBundle.d.batch_body.files[id] = FirebaseService.purifyObject($scope.fileList[i]);
                        i++;
                    }

                    $scope.eProdBundle
                        .synchronize()
                        .then(function () {
                            $scope.fileList = $scope.eProdBundle.filesAsList();
                        });
                }
            };

            $scope.expiryGen = function () {
                var expiryRange = [];

                for (var i = minimumExpiry; i <= maximumExpiry; i += 1) {
                    expiryRange.push(i);
                }

                return expiryRange;
            };

            $scope.getExtension = function (file) {
                return file.split('.').pop();
            };

            $scope.getFType = function (mime) {
                var t = mimeTypeMap[mime];

                if (t == null) {
                    return 'Other';
                }

                return t;
            };

            $scope.selectExpiry = function (opt) {
                $scope.eProdBundle.d.batch_header.expire_time = parseInt(opt);
                $scope.eProdBundle.synchronize();
            };

            $scope.saveNotes = function () {
                $scope.eProdBundle.d.batch_header.notes = $scope.eProdNotes;
                $scope.eProdBundle.synchronize();
                ToastrService.showMessageByKey('eprod_note_saved');
            };

            $scope.deleteBundle = function () {
                var chain = Object.keys($scope.eProdBundle.d.batch_body.files);

                $q.all(chain).then(function () {
                    ToastrService.showMessageByKey('draft_deleted');
                    $scope.eProdBundle.deleteBundle().then(function () {
                        $state.go('eProductionDrafts');
                    });
                });
            };

            $scope.deleteFile = function (data) {
                var path = data.data.path;
                var fileIds = Object.keys($scope.eProdBundle.d.batch_body.files);
                var fileIdToDelete = fileIds.find(function (id) {
                    var f = $scope.eProdBundle.d.batch_body.files[id];

                    return f && f.url && f.url.indexOf && f.url.indexOf(path);
                });

                if (fileIdToDelete) {
                    $scope.eProdBundle.deleteFile(fileIdToDelete);
                }

                ToastrService.showMessageByKey('document_deleted');
            };

            var cachedBundle = null;
            $scope.getEProdPreviewUrl = function () {
                var t;
                var newCachedBundle = JSON.stringify($scope.eProdBundle.d);

                if (newCachedBundle === cachedBundle && $scope.tempPdfPreview) {
                    return $scope.tempPdfPreview;
                }

                cachedBundle = newCachedBundle;
                t = angular.copy($scope.eProdBundle.d);
                t.batch_header.notes = $scope.eProdNotes;

                var params = PDFParserService.getParams(
                    $scope.eProdBundle.eProdId,
                    t,
                    'preview.pdf'
                );
                var layout = 'file-download-instructions';

                $scope.tempPdfPreview = PDFParserService.getPdfLink(
                    layout,
                    Object.assign({ caseId: EnvironmentService.firm_id() }, params)
                );
            };

            $scope.submitBundle = function () {
                $scope.eProdBundle.synchronize().then(function () {
                    var stateParams = {
                        eProdId: $scope.eProdBundle.eProdId,
                        draftId: $scope.eProdBundle.getHeaderData('eservice_draft_id'),
                        caseId: $scope.eProdBundle.getHeaderData('case_id'),
                        courtId: $scope.eProdBundle.getHeaderData('court_id')
                    };

                    return $state.go('servicesCreate', stateParams);
                });
            };

            $scope.moveToPrivilegeLog = function (file) {
                return $scope.eProdBundle.moveToPrivilegeLog(file)
                    .then(function () {
                        ToastrService.showMessageByKey('file_moved_to_privilege_log');
                    });
            };

            $scope.$watch('eProdBundle.d.batch_body.files', function (newF) {
                var total = 0;

                if (newF && Object.keys(newF).length === 0) {
                    $scope.totalSize = 0;
                }

                for (var k in newF) {
                    total += newF[k].size;
                }

                $scope.totalSize = total;
                if ($scope.eProdBundle && $scope.eProdBundle.d) {
                    if (Object.keys($scope.eProdBundle.d.batch_body.files).length !== $scope.fileList) {
                        $scope.fileList = $scope.eProdBundle.filesAsList();
                        $scope.pager = new Array($scope.totalPage());
                        $scope.showPage(1);
                    }
                }
            }, true);

            $scope.previewClickedCallback = function (url) {
                $scope.isPreviewActive = true;
                $scope.previewLink = url;
            };

            $rootScope.$on('privilegeLogMovedToEProduction', function () {
                loadEProduction();
            });

            $scope.$on('CaseSelectionChanged', function (e, eCase) {
                var stateParams = $state.params;

                stateParams.caseId = eCase.id;
                $scope.showSelectCasePopup = false;

                return $state.go('eProductionCreate', stateParams);
            });

            $scope.documentUploaded = function (document) {
                return $scope.eProdBundle.addFiles(document);
            };

            $scope.abortUpload = function () {
                if (uploadRequest) {
                    uploadRequest.abort();
                }
            };

            $scope.filesSelected = function (files) {
                var params = getUploaderParams();
                var approvedFiles = files.filter(function (e) {
                    return e.name !== '.DS_Store';
                });
                var paths = getPaths(approvedFiles);
                var signaturePromises = approvedFiles.map(function (file, index) {
                    var signatureParams = Object.assign({}, params, {
                        path: paths[index]
                    });

                    return EProductionHelper.getSignatureForUploading(file, signatureParams)
                        .then(function (signature) {
                            file.signature = signature;

                            return file;
                        });
                });

                formProgressMessage(0);
                $rootScope.loadingMessageStyle = { fontSize: 40 };

                return $q.all(signaturePromises)
                    .then(function (files) {
                        return EProductionHelper.upload(
                            files,
                            function (document) {
                                $scope.eProdBundle.addFiles(document);
                            },
                            formProgressMessage,
                            $rootScope
                        ).then(function (files) {
                            $.$broadcast('filesChanged', { files: files });
                        }).then(function () {
                            $rootScope.loading_message = '';
                            $rootScope.loadingMessageStyle = null;
                            ToastrService.showMessageByKey('files_uploaded');
                        });
                    });

                var request = Upload.upload({
                    url: '/files/upload',
                    data: {
                        files: approvedFiles,
                        paths: getPaths(approvedFiles),
                        containerPath: $.containerPath,
                        additional: getUploaderParams()
                    },
                    headers: {'X-Requested-With': 'XMLHttpRequest'}
                });

                $rootScope.loading_message = 'Uploading files';
                return request
                    .xhr(function (xhr) {
                        $rootScope.cancelableRequests = [xhr];
                    })
                    .then(function (res) {
                        return res.data.map(function (document) {
                            $scope.eProdBundle.addFiles(document);

                            return document;
                        });
                    }, {}, function (d) {
                        var percent = Math.floor(d.loaded / d.total * 100 * 100) / 100;

                        $rootScope.loading_message = 'Uploading files to the server: ' + percent + '% ready';
                    })
                    .then(function (files) {
                        $.$broadcast('filesChanged', { files: files });
                    })
                    .then(function () {
                        ToastrService.showMessageByKey('files_uploaded');
                    })
                    .finally(function () {
                        $rootScope.cancelableRequests = [];
                    });
            };

            $scope.filesReceived = function (data) {
                var response = data.response;
                var path = data.path;

                if (path === rootPath) {
                    $.total = {
                        size: parseInt(response.getResponseHeader('x-total-size')),
                        quantity: parseInt(response.getResponseHeader('x-total-files'))
                    };
                }
            };

            var getPaths = function (fileList) {
                return Object.keys(fileList).map(function (i) {
                    return fileList[i].webkitRelativePath || fileList[i].path;
                });
            };

            var getUploaderParams = function () {
                return {
                    caseId: $scope.caseId,
                    eProdId: $scope.eProdId,
                    hideDownloadButton: true
                };
            };

            var formProgressMessage = function (percent) {
                if (percent === 100) {
                    $rootScope.loading_message = 'Finalizing upload process - can take several ' +
                        'minutes for very large file sets';

                    return;
                }

                $rootScope.loading_message = 'Successfully uploaded: ' + percent + '%';
            };

            // Callback function which is called when URL state changes.
            this.uiOnParamsChanged = function (changedParams) {
                angular.extend($scope, changedParams);

                // If current active eService ID is changed - refresh variable for a view.
                if (changedParams.hasOwnProperty('caseId') && changedParams.caseId) {
                    loadEProduction()
                        .then(function () {
                            if ($scope.eProdBundle.d.batch_header.case_title) {
                                return null;
                            }

                            return CaseResource.get({id: changedParams.caseId}).$promise;
                        })
                        .then(function (eCase) {
                            if (eCase && eCase.title) {
                                $scope.eProdBundle.d.batch_header.case_title = eCase.title;
                                $scope.eProdBundle.synchronize();
                            }
                        })
                        .catch(function () {});
                }

                // Flush old values
                $.containerPath = null;
                $.total = {};
                $.uploaderParams = {};

                if ($scope.caseId && $scope.eProdId) {
                    $.containerPath = '/';
                    $.uploaderParams = getUploaderParams();
                }
            };
            this.uiOnParamsChanged($state.params);
        }
    ]);
})(angular.module('onfileApp'));

