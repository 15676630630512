(function (app) {
    app.factory('AuthResource', [
        '$resource', 'urlEncodedResource',

        function ($resource, urlEncodedResource) {
            return $resource('/eproduction/auth', {}, {
                auth: urlEncodedResource({
                    method: 'POST',
                    isArray: false
                })
            });
        }]);
})(angular.module('onfileApp'));