;(function (app) {
    app.factory('CaseNameResource', [
        'FirebaseService', 'EnvironmentService',

        function (FirebaseService, EnvironmentService) {
            const firmId = EnvironmentService.firm_id().toString();
            const getPath = function (params) {
                return '/filings/' + firmId + '/caseExtension/' + params.id;
            };

            return {
                get: function (params) {
                    const path = getPath(params);

                    return {
                        $promise: FirebaseService.getNode(path).$loaded()
                    };
                },
                save: function (params) {
                    const path = getPath(params);

                    delete params.id;

                    return {
                        $promise: FirebaseService.saveNode(path, params)
                    };
                }
            };
        }
    ]);
})(angular.module('onfileApp'));