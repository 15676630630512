(function (app) {
    app.factory('eProductionService', [
        '$rootScope', '$q', 'FirebaseService',
        'EServiceResource', 'CaseService', '$filter',
        'PrivilegeLogService', 'EServiceService', 'CaseNameService',
        'MAXIMUM_DRAFTS_AGE',
        function (
            $rootScope, q, FirebaseService,
            EServiceResource, CaseService, $filter,
            PrivilegeLogService, EServiceService, CaseNameService,
            MAXIMUM_DRAFTS_AGE
        ) {
            var STATUS_SUBMITTED = 'submitted';
            var STATUS_SERVED = 'served';
            var sourceEProd = {
                'batch_header': {
                    'display_name': null,
                    'case_id': null,
                    'created_at': null,
                    'submitted_at': null,
                    'updated_at': null,
                    'expire_time': 10,
                    'status': 'draft',
                    'notes': '',
                    'court_id': null,
                    'filing_id': null,
                    'eservice_draft_id': null,
                    'case_name': null,
                    'case_title': '',
                    // "service_contacts": null
                    'sender_name': null,
                },
                'batch_body': {
                    'files': {},
                    'access_log': {}
                }
            };
            var sourceFileObject = {
                'display_name': '',
                'filename': null,
                'url': null,
                'client': null,
                'container': null,
                'size': 0,
                'mimetype': null,
                'key': null,
                'time': (new Date()).getTime()
            };

            var m = {};
            var baseEProdPath = 'eproduction/packages/';
            var scope = $rootScope.$new(true);
            var self = this;

            function generateEproductionID() {
                function gen() {
                    return ('0000' + (Math.random() * Math.pow(36, 4) << 0).toString(36)).slice(-4);
                }

                return gen() + gen();
            }

            function overrideFields(target, src) {
                for (var property in src)
                    if (src.hasOwnProperty(property) && target.hasOwnProperty(property))
                        target[property] = src[property];
            }

            m.getFileKeys = function () {
                var res = [];

                function sNum(a, b) {
                    return a - b;
                }

                Object.keys(m.d.batch_body.files).map(function (e) {
                    res.push(parseInt(e));
                });
                res.sort(sNum);
                return res;
            };
            m.generateFileKey = function () {
                return generateEproductionID(); //random Ids not sequence.
            };

            m.newEProd = function (caseId, firmId, courtId, caseName) {
                var defer = q.defer();
                var _this = this;
                var res = JSON.parse(JSON.stringify(sourceEProd));
                var d = Date.now();

                _this.eProdId = undefined;
                _this.caseId = caseId;
                _this.firmId = firmId;
                _this.courtId = courtId;
                _this.caseName = caseName;

                if (caseName) {
                    res.batch_header.display_name = caseName + ' ' + $filter('date')(d, 'MM/dd/yyyy');
                } else {
                    res.batch_header.display_name = 'eProduction_' + $filter('date')(d, 'MM/dd/yyyy h:mma');
                }

                res.batch_header.created_at = Date.now();

                if (caseId) {
                    res.batch_header.case_id = caseId;
                }

                if (courtId) {
                    res.batch_header.court_id = courtId;
                }

                _this.d = res;
                _this.synchronize().then(function () {
                    defer.resolve(_this);
                });

                return defer.promise;
            };

            m.loadEProd = function (eProdId, firmId) {
                var defer = q.defer();
                var _this = this;
                _this.eProdId = eProdId;
                _this.firmId = firmId;
                var rec = FirebaseService.getNode(_this.getRecKey());
                rec.$loaded().then(function (retrieved) {
                    if (retrieved.$value === null) {
                        defer.resolve(null);
                        return;
                    }
                    var res = angular.copy(sourceEProd);
                    overrideFields(res, retrieved);
                    var files = {};
                    for (var x in res.batch_body.files) {
                        var t = angular.copy(sourceFileObject);
                        overrideFields(t, res.batch_body.files[x]);
                        t.indexKey = x;
                        files[x] = t;
                    }
                    res.batch_body.files = files;
                    _this.d = res;
                    defer.resolve(_this);
                });
                return defer.promise;
            };

            m.addFiles = function (fpFile) {
                var defer = q.defer();
                fpFile.display_name = fpFile.filename.split('.').shift();
                var f = angular.copy(sourceFileObject);
                overrideFields(f, fpFile);
                var newKey = this.generateFileKey();
                f.indexKey = newKey;
                this.d.batch_body.files[newKey] = f;
                this.synchronize().then(function () {
                    defer.resolve(f);
                });
                return defer.promise;
            };

            m.deleteFile = function (k) {
                if (k.hasOwnProperty('id')) {
                    k = k.id;
                } else if (k.hasOwnProperty('$id')) {
                    k = k.$id;
                }

                var defer = q.defer();
                var deleted = this.d.batch_body.files[k];

                delete this.d.batch_body.files[k];

                this.synchronize().then(function () {
                    defer.resolve(deleted);
                });

                return defer.promise;
            };

            m.filesAsList = function () {
                var self = this;
                var result = [];
                Object.keys(self.d.batch_body.files).forEach(function (k) {
                    var res = angular.copy(self.d.batch_body.files[k]);
                    res.id = k;
                    result.push(res);
                });
                return result;
            };

            m.getBasePath = function () {
                return baseEProdPath + this.firmId;
            };

            m.getRecKey = function () {
                return m.getBasePath() + '/' + this.eProdId;
            };

            m.deleteBundle = function () {
                var defer = q.defer();
                var rec = FirebaseService.getNode(this.getRecKey());
                var _this = this;
                rec.$loaded().then(function (res) {
                    res.$remove();
                    defer.resolve(true);
                });
                return defer.promise;
            };

            m.synchronize = function (options) {
                var _this = this;
                var defer = q.defer();

                // @TODO refactor this
                if (options && options.file) {
                    this.d.batch_body.files[options.file.indexKey].display_name = options.file.display_name;
                }

                this.d.batch_header.updated_at = new Date().getTime();

                if (!this.eProdId) {
                    FirebaseService.addNode(
                        m.getBasePath(), this.d
                    )
                        .then(function (e) {
                            _this.eProdId = e.key;

                            e.ref
                                .child('eProdId')
                                .set(_this.eProdId)
                                .then(function () {
                                    defer.resolve(true);
                                });
                        });
                } else {
                    var data = FirebaseService.removeRedundantNodes(this.d);

                    FirebaseService
                        .saveNode(this.getRecKey(), data)
                        .then(function () {
                            defer.resolve(true);
                        });
                }

                return defer.promise;
            };

            m.getHeaderData = function (item) {
                return this.d.batch_header[item];
            };

            self.init = function (caseId, eProdId, firmId, courtId, caseName) {
                // Add loading screen here
                var defer = q.defer();
                if (!eProdId) {
                    m.newEProd(caseId, firmId, courtId, caseName).then(function () {
                        defer.resolve(m);
                    });
                } else {
                    m.loadEProd(eProdId, firmId).then(function () {
                        defer.resolve(m);
                    });
                }
                return defer.promise;
            };

            self.loadDrafts = function (firmId, deleteOldDrafts) {
                deleteOldDrafts = deleteOldDrafts || false;
                let currentTimestamp = (new Date()).getTime() / 1000;
                let draftsWereDeleted = false;

                var defer = q.defer();
                var key = baseEProdPath +
                    firmId + '/';
                var rec = FirebaseService.getNode(key);
                rec.$loaded().then(function (retrieved) {
                    if (retrieved.$value === null) {
                        defer.resolve(null);
                    }
                    var res = [];
                    retrieved.forEach(function (data, eProdId) {
                        data.eProdId = eProdId;

                        if (deleteOldDrafts && (data.batch_header.created_at / 1000) < currentTimestamp - MAXIMUM_DRAFTS_AGE) {
                            retrieved.$remove(data);

                            draftsWereDeleted = true;
                        } else {
                            res.push(data);
                        }
                    });

                    if (draftsWereDeleted) {
                        self.loadDrafts(firmId, false);
                    } else {
                        defer.resolve(res);
                    }
                });

                return defer.promise;
            };

            var submittedeProdServiceIds = null;
            self.geteProdSubmittedServiceIds = function () {
                if (submittedeProdServiceIds === null)
                    submittedeProdServiceIds = FirebaseService.getfirmIdChannel('eproduction/submissions/', null, false);

                return submittedeProdServiceIds;
            };

            self.loadSubmitted = function (firmId, options) {
                var res = [];
                var defer = q.defer();
                var key = 'eproduction/packages/' + firmId + '/';
                var eProductionId = options && options.eProductionId;

                if (eProductionId) {
                    key += eProductionId;
                }

                FirebaseService.getNode(key).$loaded()
                    .then(function (retrieved) {
                        if (retrieved.$value === null) {
                            return defer.resolve(null);
                        }

                        if (eProductionId) {
                            retrieved = [retrieved];
                        }

                        retrieved.forEach(function (data, eProdId) {
                            data.eProdId = eProdId;

                            if (data.batch_header && data.batch_header.status !== STATUS_SUBMITTED) {
                                return;
                            }

                            res.push(data);
                        });

                        return res;
                    })
                    .then(function (submitted) {
                        var promises = [];

                        if (!submitted) {
                            return q.all(promises);
                        }

                        for (var i = 0; i < submitted.length; i++) {
                            var promise = (function (eProduction) {
                                if (!eProduction.batch_header || !eProduction.batch_header.filing_id) {
                                    return;
                                }

                                var filingId = eProduction.batch_header.filing_id;

                                return EServiceService.loadOrImport(filingId)
                                    .then(function (filing) {
                                        eProduction.filing = filing;

                                        return eProduction;
                                    })
                                    .then(function () {
                                        return self.addCaseName(eProduction);
                                    });

                            })(submitted[i]);

                            promises.push(promise);
                        }

                        return q.all(promises);
                    })
                    .then(function () {
                        return defer.resolve(res);
                    });

                return defer.promise;
            };

            m.moveToPrivilegeLog = function (file) {
                return PrivilegeLogService.addFiles(file)
                    .then(function () {
                        return m.deleteFile(file);
                    });
            };

            self.loadById = function (id, firmId) {
                return m.loadEProd(id, firmId);
            };

            self.loadForCase = function (props) {
                return FirebaseService
                    .getNodeAsArray(baseEProdPath + props.firmId, {
                        orderByChild: 'batch_header/case_id',
                        equalTo: props.caseId
                    })
                    .$loaded();
            };

            self.addCaseName = function (eProduction) {
                var deferred = q.defer();

                if (CaseNameService.isNameUnavailable(eProduction.filing.case_title) === false) {
                    return deferred.resolve(eProduction);
                }

                return CaseNameService.decorate(eProduction.filing)
                    .then(function () {
                        return eProduction;
                    });
            };

            return self;
        }]);
})(angular.module('onfileApp'));
