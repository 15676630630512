/**
 * Session expired handler interceptor. If HTTP response code is 401 shows a popup window.
 */
(function (app) {
    app.factory('errorInterceptor', ['$q', '$rootScope', function ($q, $) {
        $.internalError = false;
        var canceledStatus = -1;

        /**
         * Gets error message from response if there is message is present
         *
         * @param response
         * @returns {string|null}
         */
        function getErrorMessage(response) {
            var detailMessage = getErrorDetails(response);
            var url = response && response.config && response.config.url;

            if (detailMessage) {
                return detailMessage;
            }

            var errorMessage = 'Unknown server error occurred. Request URL: "' + url + '"';

            if (!response || !response.hasOwnProperty('data') || !response.data) {
                return null;
            } else if (response.data.hasOwnProperty('error')) {
                errorMessage = response.data.error;
            } else if (response.data.hasOwnProperty('errors')) {
                errorMessage = response.data.errors;
            } else if (response.data.hasOwnProperty('error_message')) {
                errorMessage = response.data.error_message;
            } else {
                errorMessage = response.data;
            }

            if (typeof errorMessage === 'string') {
                return errorMessage;
            }

            return JSON.stringify(errorMessage);
        }

        function getErrorDetails(response) {
            if (response && response.data && response.data.details) {
                return response.data.details;
            }

            return null;
        }

        $.showError = function () {
            $.internalError = true;
        };

        $.hideError = function () {
            $.internalError = false;
        };

        return {
            responseError: function (response) {
                if (
                    response.status !== canceledStatus &&
                    !(
                        response.config && response.config.data &&
                        response.config.data.hasOwnProperty('httpCodeIgnorePopup') &&
                        response.config.data.httpCodeIgnorePopup === response.status &&
                        response.status !== canceledStatus
                    ) && !(
                        response.config &&
                        response.config.hasOwnProperty('ignoreErrorPopup') &&
                        response.config.ignoreErrorPopup
                    )
                ) {
                    // If there are HTTP response codes for ignoring popup message set
                    $.showError();
                    $.errorMessage = getErrorMessage(response);
                }

                return $q.reject(response);
            }
        };
    }]);
})(angular.module('onfileApp'));
