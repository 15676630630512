(function (app) {
    app.constant('SESSION_INVITATION_RESENT_TOASTER_KEY', 'session_invitation_resent')
        .constant('SESSION_CANCELLED_TOASTER_KEY', 'session_cancelled')
        .constant('SESSION_RESTARTED_TOASTER_KEY', 'session_restarted')
        .constant('USER_EMAIL_COPIED_TOASTER_KEY', 'user_email_copied')
        .constant('NOTARY_SESSION_LINK_COPIED_TOASTER_KEY', 'notary_session_link_copied')
        .constant('NOTARY_DOWNLOAD_APP_LINK_COPIED_TOASTER_KEY', 'notary_download_app_link_copied_toaster_key')
        .constant('NOTARY_UPLOAD_APP_LINK_COPIED_TOASTER_KEY', 'notary_upload_app_link_copied_toaster_key')
        .constant('NOTARY_FEEDBACK_ADDED_KEY', 'notary_feedback_added_key')
        .constant('INTERNAL_NOTARY_SESSION_REQUESTED_KEY', 'internal_notary_session_requested_key')
        .constant('INTERNAL_NOTARY_SESSION_CANCELLED_KEY', 'internal_notary_session_cancelled_key')
        .constant('EXTERNAL_NOTARY_SESSION_CANCELLED_KEY', 'external_notary_session_cancelled_key')
        .constant('EXTERNAL_NOTARY_SESSION_REQUESTED_KEY', 'external_notary_session_requested_key')
        .constant('APPOINTMENT_ID_COPIED_TOASTER_KEY', 'appointment_id_copied');

    app.factory('ToastrService', [
        'toastr', '$window', 'SESSION_INVITATION_RESENT_TOASTER_KEY',
        'SESSION_CANCELLED_TOASTER_KEY', 'USER_EMAIL_COPIED_TOASTER_KEY', 'NOTARY_SESSION_LINK_COPIED_TOASTER_KEY', 'SESSION_RESTARTED_TOASTER_KEY',
        'NOTARY_DOWNLOAD_APP_LINK_COPIED_TOASTER_KEY', 'NOTARY_UPLOAD_APP_LINK_COPIED_TOASTER_KEY',
        'NOTARY_FEEDBACK_ADDED_KEY', 'INTERNAL_NOTARY_SESSION_REQUESTED_KEY',
        'INTERNAL_NOTARY_SESSION_CANCELLED_KEY', 'EXTERNAL_NOTARY_SESSION_REQUESTED_KEY',
        'EXTERNAL_NOTARY_SESSION_CANCELLED_KEY', 'APPOINTMENT_ID_COPIED_TOASTER_KEY',
        function (
            toastr, $window, SESSION_INVITATION_RESENT_TOASTER_KEY,
            SESSION_CANCELLED_TOASTER_KEY, USER_EMAIL_COPIED_TOASTER_KEY, NOTARY_SESSION_LINK_COPIED_TOASTER_KEY, SESSION_RESTARTED_TOASTER_KEY,
            NOTARY_DOWNLOAD_APP_LINK_COPIED_TOASTER_KEY, NOTARY_UPLOAD_APP_LINK_COPIED_TOASTER_KEY,
            NOTARY_FEEDBACK_ADDED_KEY, INTERNAL_NOTARY_SESSION_REQUESTED_KEY,
            INTERNAL_NOTARY_SESSION_CANCELLED_KEY, EXTERNAL_NOTARY_SESSION_REQUESTED_KEY,
            EXTERNAL_NOTARY_SESSION_CANCELLED_KEY, APPOINTMENT_ID_COPIED_TOASTER_KEY
        ) {
            function ToastrService() {
                var TYPE_SUCCESS = 'success';
                var TYPE_ERROR = 'error';
                var TYPE_WARNING = 'warning';
                var TYPE_INFO = 'info';

                var messages = {
                    service_contact_is_required: {
                        message: 'At least one Service Contact is required',
                        title: '',
                        type: TYPE_ERROR
                    },
                    link_copied: {
                        message: 'Link to File download page copied to clipboard',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    file_moved_to_privilege_log: {
                        message: 'File moved to Privilege Log. Click "To be logged" tab to view.',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    file_moved_to_e_production: {
                        message: 'File moved back to "To be produced" queue.',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    invalid_file_uploaded: {
                        message: 'You may drag and drop PDF files only. To upload non-PDF files (and convert them to PDF), please click LOAD FROM YOUR CLOUD DRIVE.',
                        title: '',
                        type: TYPE_ERROR,
                        options: { timeOut: 20000 }
                    },
                    files_uploaded: {
                        message: 'Files were successfully uploaded',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    download_instructions_missing: {
                        message: 'File Download Instructions are not loaded into the envelope',
                        title: '',
                        type: TYPE_ERROR
                    },
                    damage_missing: {
                        message: 'Enter Claimed Damages (Ad Damnum) amount',
                        title: '',
                        type: TYPE_ERROR
                    },
                    available_date_missing: {
                        message: 'Please select one of the listed available dates',
                        title: '',
                        type: TYPE_ERROR
                    },
                    return_date_missing: {
                        message: 'Please either select a return date or click the radio button NO after ' +
                            '" Does this envelope need a Return Date."',
                        title: '',
                        type: TYPE_ERROR
                    },
                    attorneys_missing: {
                        message: 'You must have a least one firm attorney on your e-filing account before you can ' +
                            'submit a filing to the court. CLICK HERE TO CREATE A FIRM ATTORNEY',
                        title: '',
                        type: TYPE_WARNING,
                        options: {
                            onTap: function () {
                                $window.location.href = '/firm/attorneys/#/create-attorney';
                            },
                            autoDismiss: false,
                            timeOut: 0,
                            extendedTimeOut: 0,
                            tapToDismiss: false,
                            closeButton: true
                        }
                    },
                    payment_accounts_missing: {
                        message: 'You must set up a payment account before you can submit a filing to the court. ' +
                            'CLICK HERE TO CREATE A PAYMENT ACCOUNT',
                        title: '',
                        type: TYPE_WARNING,
                        options: {
                            onTap: function () {
                                $window.location.href = '/billing/#/modal-add-payment-account';
                            },
                            autoDismiss: false,
                            timeOut: 0,
                            extendedTimeOut: 0,
                            tapToDismiss: false,
                            closeButton: true
                        }
                    },
                    reference_matter_missing: {
                        message: 'Please provide a Reference/matter number (which can be your internal matter, client, ' +
                            'or reference designation)',
                        title: '',
                        type: TYPE_ERROR
                    },
                    filing_description_missing: {
                        message: 'Please provide a Filing Description for this filing',
                        title: '',
                        type: TYPE_ERROR
                    },
                    document_category_missing: {
                        message: 'Please select a document Category from the drop down menu next to each uploaded ' +
                            'document.',
                        title: '',
                        type: TYPE_ERROR
                    },
                    files_missing: {
                        message: 'Please upload at least one document for this filing',
                        title: '',
                        type: TYPE_ERROR
                    },
                    lead_missing: {
                        message: 'Please upload at least one document for this filing',
                        title: '',
                        type: TYPE_ERROR
                    },
                    attorney_code_length: {
                        message: 'Attorney code must be at least five digits',
                        title: '',
                        type: TYPE_ERROR
                    },
                    labeled_files_deleted: {
                        message: 'Labeled files deleted',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    settings_saved: {
                        message: 'Settings saved',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    select_filing_attorney: {
                        message: 'Filing attorney must be selected',
                        title: '',
                        type: TYPE_ERROR
                    }
                };

                messages[SESSION_INVITATION_RESENT_TOASTER_KEY] = {
                    message: 'Session invite re-sent',
                    title: '',
                    type: TYPE_SUCCESS
                };
                messages[SESSION_CANCELLED_TOASTER_KEY] = {
                    message: 'Session cancelled',
                    title: '',
                    type: TYPE_SUCCESS
                };
                messages[SESSION_RESTARTED_TOASTER_KEY] = {
                    message: 'Session re-started',
                    title: '',
                    type: TYPE_SUCCESS
                };
                messages[NOTARY_DOWNLOAD_APP_LINK_COPIED_TOASTER_KEY] = {
                    message: 'Link to File Download Page copied to clipboard',
                    title: '',
                    type: TYPE_INFO
                };
                messages[NOTARY_UPLOAD_APP_LINK_COPIED_TOASTER_KEY] = {
                    message: 'Link to File Upload Page copied to clipboard',
                    title: '',
                    type: TYPE_SUCCESS
                };
                messages[NOTARY_FEEDBACK_ADDED_KEY] = {
                    message: 'Your feedback/issue report was successfully submitted. A support representative will ' +
                        'be contacting you shortly.',
                    title: '',
                    type: TYPE_SUCCESS
                };
                messages.private_notes_added = {
                    message: 'Your notes were successfully added',
                    title: '',
                    type: TYPE_SUCCESS
                };
                messages['document_deleted'] = { message: 'Document deleted', title: '', type: 'success' };
                messages['eprod_note_saved'] = { message: 'Notes saved to File Download', title: '', type: 'success' };
                messages['pending_submission_cancelled'] = {
                    message: 'Cancellation request submitted',
                    title: '',
                    type: 'success'
                };
                messages['no_payment_account'] = {
                    message: 'Add at least one payment account',
                    title: '',
                    type: 'error'
                };
                messages['parties_required'] = {
                    message: 'One or more required parties missing',
                    title: '',
                    type: 'error'
                };
                messages['eprod_not_found'] = { message: 'eProduction record not found', title: '', type: 'error' };
                messages['parties_invalid'] = {
                    message: 'Information found to be missing in one or more of the parties.',
                    title: '',
                    type: 'error'
                };
                messages['party_being_edited'] = {
                    message: 'Please finish the party information.',
                    title: '',
                    type: 'error'
                };
                messages['lead_missing_service'] = {
                    message: 'Filing form incomplete or lead document missing',
                    title: '',
                    type: 'error'
                };
                messages['something_wrong'] = { message: 'Something wrong', title: '', type: 'error' };
                messages['envelope_size'] = {
                    message: 'Reduce total file size of this envelope or submit filing(s) in one or more additional envelopes'
                    , title: 'Total file size of envelope exceeds state limit of 35MB. ', type: 'error'
                };
                messages['procedure_required'] = {
                    message: 'Procedure Remedy is required.'
                    , title: 'Procedure Remedy is required by the selected court for your case category', type: 'error'
                };
                messages['draft_deleted'] = {
                    message: 'Draft successfully deleted'
                    , title: 'Draft deleted', type: 'success'
                };
                messages['reminder_deleted'] = {
                    message: 'Reminder successfully deleted'
                    , title: 'Reminder deleted', type: 'success'
                };
                messages['no_document_found'] = {
                    message: 'Please attache at least one document with the service'
                    , title: 'Missing document attachment', type: 'error'
                };

                messages['reminder_created'] = {
                    message: 'Reminder successfully created.'
                    , title: 'New reminder', type: 'success'
                };

                messages['invalid_token'] = {
                    message: 'Invalid or expired token'
                    , title: 'Invalid token', type: 'error'
                };

                messages['phone_saved'] = { message: 'Phone number saved.', title: 'Text notification', type: 'success' };
                messages['alert_preference_changed'] = {
                    message: 'Alert preference changed.',
                    title: 'Text notification',
                    type: 'success'
                };
                messages[USER_EMAIL_COPIED_TOASTER_KEY] = {
                    message: 'Signer email copied to clipboard',
                    type: TYPE_SUCCESS
                };
                messages[NOTARY_SESSION_LINK_COPIED_TOASTER_KEY] = {
                    message: 'Session link copied to clipboard',
                    type: TYPE_SUCCESS
                };
                messages[INTERNAL_NOTARY_SESSION_REQUESTED_KEY] = {
                    message: 'Internal notary session requested',
                    type: TYPE_SUCCESS
                };
                messages[INTERNAL_NOTARY_SESSION_CANCELLED_KEY] = {
                    message: 'Internal notary request session was cancelled',
                    type: TYPE_SUCCESS
                };
                messages[EXTERNAL_NOTARY_SESSION_REQUESTED_KEY] = {
                    message: 'External notary session was requested',
                    type: TYPE_SUCCESS
                };
                messages[EXTERNAL_NOTARY_SESSION_CANCELLED_KEY] = {
                    message: 'External notary request session was cancelled',
                    type: TYPE_SUCCESS
                };
                messages[APPOINTMENT_ID_COPIED_TOASTER_KEY] = {
                    message: "Appointment ID copied",
                    type: TYPE_SUCCESS,
                };
                this.showSuccess = function (message, title, options) {
                    toastr.success(message, title, options);
                };

                this.getMessage = function (key) {
                    return messages[key];
                };

                this.showMessageByKey = function (key) {
                    var item = messages[key];
                    var title = '';
                    var message = key;
                    var type = 'success';
                    var options;

                    if (item !== undefined && item !== null) {
                        message = item.message;
                        title = item.title;
                        type = item.type;
                        options = item.options;
                    }

                    switch (type) {
                        case TYPE_SUCCESS:
                            this.showSuccess(message, title, options);
                            break;
                        case TYPE_ERROR:
                            this.showError(message, title, options);
                            break;
                        case TYPE_WARNING:
                            this.showWarning(message, title, options);
                            break;
                        case TYPE_INFO:
                            this.showInfo(message, title, options);
                            break;
                        default:
                            toastr.success(message, title, options);
                    }

                };

                this.showError = function (message, title, options) {
                    toastr.error(message, title, options);
                };

                this.showWarning = function (message, title, options) {
                    toastr.warning(message, title, options);
                };

                this.showInfo = function (message, title, options) {
                    toastr.info(message, title, options);
                };

                return this;
            }

            return new ToastrService();
        }
    ]);
})(angular.module('onfileApp'));
