(function (app) {
    app.factory('OptionalServiceService', [
        function OptionalServiceService() {
            var _this = this;

            /**
       * @param optionalService
       * @returns {string|number}
       */
            this.countTotalFee = function (optionalService) {
                var fee;
                var quantity;

                if (!optionalService || !optionalService.hasOwnProperty('fee') || !optionalService.hasOwnProperty('quantity')) {
                    return 0.0;
                }

                fee = isNaN( parseFloat(optionalService.fee) ) ? 0.0 : parseFloat(optionalService.fee);
                quantity = isNaN( parseFloat(optionalService.quantity) ) ? 0.0 : parseFloat(optionalService.quantity);

                return _this.formatDecimal(Math.floor(fee * quantity * 100) / 100);
            };

            /**
       * @param item
       * @param decimals
       * @returns {string}
       */
            this.formatDecimal = function (item, decimals) {
                item = item === undefined ? 0 : item;
                decimals = decimals || 2;

                try {
                    return item.toFixed(decimals);
                } catch (e) {
                    return item;
                }
            };

            /**
       * @param optionalServices
       * @returns {Array}
       */
            this.getSelected = function (optionalServices) {
                var selected = [];

                if (!optionalServices || !optionalServices.hasOwnProperty('length') || !optionalServices.length)
                    return selected;

                for (var i = 0; i < optionalServices.length; i++) {
                    if (!optionalServices || !optionalServices[i] || !optionalServices[i].selected)
                        continue;

                    selected.push(optionalServices[i]);
                }

                return selected;
            };

            /**
       * @param optionalServices
       * @returns {Array}
       */
            this.setSelected = function (selected, master) {

                if (!selected || !master || !selected.hasOwnProperty('length') || !selected.length)
                    return;

                for (var i = 0; i < selected.length; i++) {
                    for(var j =0 ; j< master.length;j++) {
                        if (master[j].code === selected[i].code)
                            master[j].selected = true;
                    }
                }

            };


            return this;
        }
    ]);
})(angular.module('onfileApp'));