(function (app) {
    app.directive('searchMattersSidebar', [
        '$mdSidenav', 'utils', '$rootScope',
        function (
            $mdSidenav, utils, $rootScope
        ) {
            return {
                strict: 'A',
                link: function ($) {
                    $.search = {
                        type: 'matter'
                    };

                    $.toggleMattersSidebar = utils.buildToggler($mdSidenav)('search-matters-sidebar');

                    $.requestStatuses = [
                        { name: 'All', value: 0 },
                        { name: 'Request sent', value: 1 },
                        { name: 'Response received', value: 2 }
                    ];
                    $.notaryStatuses = [
                        { name: 'All', value: 0 },
                        { name: 'Requested', value: 1 },
                        { name: 'Scheduled', value: 2 },
                        { name: 'Notarized', value: 3 }
                    ];
                    $.fileProductionStatuses = [
                        { name: 'All', value: 0 },
                        { name: 'Requested', value: 1 },
                        { name: 'Files Produced', value: 2 }
                    ];

                    $.clearSearch = function () {
                        $.search = {};
                    };

                    $.runSearch = function () {
                        $rootScope.$emit('requestSearchParametersUpdated', {
                            search: $.search
                        });
                        $.toggleMattersSidebar();
                    };

                    $rootScope.toggleSearchMattersSidebar = function () {
                        $.toggleMattersSidebar();
                    };
                },
                templateUrl: '/assets/app/shared/controllers/notary/requests/searchSidebar/' +
                    'template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
