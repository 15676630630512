(function (app) {
    app.factory('CaseResource', [
        '$resource', 'urlEncodedResource', 'DataProvider',

        function ($resource, urlEncodedResource, DataProvider) {
            const resource = $resource('/cases/:id', {id: '@id'}, {
                query: {
                    method: 'GET',
                    isArray: true
                },
                get: {
                    url: '/cases/detail',
                    method: 'GET',
                    isArray: false
                },
                check: {
                    url: '/cases/check',
                    method: 'GET',
                    isArray: false
                },
                history: {
                    url: '/cases/history/:id',
                    method: 'GET',
                    cache: true,
                    isArray: true
                },
                save: urlEncodedResource({
                    method: 'POST',
                    url: '/cases/create'
                }),
                search: {
                    method: 'GET',
                    isArray: true,
                    cancellable: true
                }
            });

            const originGet = resource.get;

            resource.get = function (props) {
                if (props && props.direct) {
                    return originGet(props);
                }

                return {
                    $promise: DataProvider.getById('cases', props.id)
                };
            };

            return resource;
        }]);
})(angular.module('onfileApp'));