(function (app) {
    app.factory('OfficialsResourceFactory', [
        '$resource', '$q', 'notaryTokenUtil',
        'EnvironmentService',
        function (
            $resource, $q, notaryTokenUtil,
            EnvironmentService
        ) {
            return {
                create: function (options) {
                    var headers = {
                        'X-OFFICIAL-ID': (options && options.userId) || EnvironmentService.userId(),
                        'X-FIRM-ID': (options && options.firmId) || EnvironmentService.firm_id()
                    };
                    var pathParams = { id: '@id' };
                    var resource = $resource(notaryTokenUtil.notaryApiHost + '/officials', pathParams, {
                        token: {
                            url: '/token/notary',
                            method: 'GET',
                            isArray: false,
                            cache: false
                        },
                        query: {
                            method: 'GET',
                            cache: true,
                            isArray: true,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        get: {
                            url: notaryTokenUtil.notaryApiHost + '/officials/:id',
                            method: 'GET',
                            cache: true,
                            isArray: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        getUploadUrls: {
                            url: notaryTokenUtil.notaryApiHost + '/officials/onboard/get-upload-urls',
                            method: 'POST',
                            cache: false,
                            isArray: true,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        },
                        create: {
                            method: 'POST',
                            cache: false,
                            isArray: false,
                            headers: notaryTokenUtil.addAuthorizationToken(headers)
                        }
                    });

                    return notaryTokenUtil.checkToken(resource, $q)
                        .then(function () {
                            return resource;
                        });
                }
            };
        }]
    );
})(angular.module('onfileApp'));
