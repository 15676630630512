(function (app) {
    app.factory('PaymentAccountResource', ['$resource',
        function ($resource) {
            return $resource('/billing', {}, {
                query: {
                    method: 'GET',
                    isArray: true
                }
            });
        }]);
})(angular.module('onfileApp'));