(function (app) {
    app.controller('RequestKeyCtrl', [
        '$scope', 'EnvironmentService', 'RequestKeyResource',
        '$window', '$state',
        function (
            $, EnvironmentService, RequestKeyResource,
            $window, $state
        ) {
            var logoUrls = {
                illinois: '/assets/images/il-efile-logo.png'
            };

            $.email = '';
            $.firmName = '';
            $.logo = logoUrls[EnvironmentService.market()] || null;
            $.errors = {};

            $.requestKey = function () {
                $.errors = {};
                var data = {
                    email: $.email,
                    firmName: $.firmName
                };

                return RequestKeyResource.request(data).$promise
                    .then(function () {
                        $window.localStorage.setItem('keyRequested', '1');

                        return $state.go('requestKeySuccess');
                    })
                    .catch(function (e) {
                        $.errors = e.data;
                    });
            };
        }
    ]);
})(angular.module('onfileApp'));
