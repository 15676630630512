(function (app) {
    app.service('CourtCoverSheetService', [
        'utils',
        function CourtCoverSheetService(utils) {
            this.getAttorneyFor = function (eCase) {
                var parties = this.getParties(eCase);

                if (!parties) {
                    return '';
                }

                var filingParty = parties.find(function (p) {
                    return p.isClient;
                }) || parties[0];

                if (!filingParty) {
                    console.warn('No filing party was find');
                    return '';
                }

                return filingParty.getName && filingParty.getName() || filingParty.name || '';
            };

            this.getAttorneyNumber = function (eCase) {
                return eCase && eCase.crossReferences && eCase.crossReferences[0] && eCase.crossReferences[0].number ||
                    '';
            };

            this.getAttorneyName = function (eCase) {
                return eCase && eCase.attorney && eCase.attorney && eCase.attorney.fullName;
            };

            this.getAddress = function (firmInfo) {
                if (!firmInfo && !firmInfo.address || typeof firmInfo.address !== 'object') {
                    return '';
                }

                return ['addressLine1', 'addressLine2']
                    .filter(function (p) { return firmInfo.address[p]; })
                    .map(function (p) { return firmInfo.address[p]; })
                    .join(', ');
            };

            this.getPhone = function (firmInfo) {
                return firmInfo && firmInfo.phoneNumber || '';
            };

            this.getPartyName = function (eCase, type, skip) {
                var party;
                var parties = this.getParties(eCase);

                if(!skip){
                    skip = 0;
                }

                if (!parties) {
                    console.warn('No party was found for cover sheet.');
                    return '';
                }

                var count = 0;
                if (typeof type === 'number') {
                    var partyIndex = type;

                    party = parties && parties[partyIndex];
                } else {
                    party = parties.find(function (item) {
                        var partyType = item && item.type_name || item && item.type && item.type.name;
                        var properPartyType = partyType.toLowerCase().indexOf(type.toLowerCase()) >= 0;
                        if(properPartyType){
                            count++;
                        }

                        return (count - 1) == skip;
                    });
                }

                if (!party) {
                    return '';
                }

                return party.getName && party.getName() || party.name && party.name.toString() || '';
            };

            this.getFilingPartyType = function (eCase) {
                var party;
                var parties = this.getParties(eCase);
                if (!parties) {
                    console.warn('No party was found for cover sheet.');
                    return '';
                }
                party = parties && parties[eCase.filingPartyIndex];

                if (!(party && party.type && party.type.name)) {
                    if (party.type_name) {
                        return party.type_name;
                    }

                    return '';
                }
                return party.type.name;
            };

            this.getFilingPartyName = function (eCase) {
                var party;
                var parties = this.getParties(eCase);
                if (!parties) {
                    console.warn('No party was found for cover sheet.');
                    return '';
                }
                party = parties && parties[eCase.filingPartyIndex];

                if (!party) {
                    return '';
                }

                return party.getName && party.getName() || party.name && party.name.toString() || '';
            };

            this.getPartyAddress = function (eCase, type, skip) {
                var party;
                var parties = this.getParties(eCase);
                
                if(!skip){
                    skip = 0;
                }

                if (!parties) {
                    console.warn('No party was found for cover sheet.');
                    return '';
                }

                var count = 0;
                if (typeof type === 'number') {
                    var partyIndex = type;

                    party = parties && parties[partyIndex];
                } else {
                    party = parties.find(function (item) {
                        var partyType = item && item.type_name || item && item.type && item.type.name;
                        var properPartyType = partyType.toLowerCase().indexOf(type.toLowerCase()) >= 0;
                        if(properPartyType){
                            count++;
                        }

                        return (count - 1) == skip;
                    });
                }

                if (!party) {
                    return '';
                }

                var path = [];
                if (party.address) {
                    path.push(party.address);
                }

                if (party.city) {
                    path.push(party.city);
                }

                if (party.state && party.state.name) {
                    path.push(party.state.name);
                }

                if (party.zip) {
                    path.push(party.zip);
                }

                return path.join(', ');
            };

            this.getDistrict = function (courtCode) {
                var district;
                var match = courtCode.match(/\d+$/);

                if (match && match[0] !== undefined) {
                    district = +match[0];
                }

                if (isNaN(district) || !district || district <= 0) {
                    return '';
                }

                if (district === 1) return district + 'st';
                if (district === 2) return district + 'nd';
                if (district === 3) return district + 'rd';

                return district + 'th';
            };

            this.getCounty = function (courtCode) {
                var match = courtCode.match(/\d+$/);

                if (match && match[0] !== undefined) {
                    return 'Municipal';
                }

                return 'County';
            };

            this.getCaseNumber = function (caseInfo) { return caseInfo.docket_id; };

            this.getCity = function (firmInfo) { return firmInfo && firmInfo.address && firmInfo.address.city; };

            this.getState = function (firmInfo) { return firmInfo && firmInfo.address && firmInfo.address.state; };

            this.getState = function (firmInfo) { return firmInfo && firmInfo.address && firmInfo.address.state; };

            this.getZip = function (firmInfo) { return firmInfo && firmInfo.address && firmInfo.address.zipCode; };

            this.getFirmName = function (firmInfo) { return firmInfo && firmInfo.firmName || ''; };

            this.getFullAddress = function (firmInfo) {
                if (!firmInfo || !firmInfo.address) {
                    return '';
                }

                if (firmInfo.address.city && firmInfo.address.state && firmInfo.address.zipCode) {
                    return firmInfo.address.city + ', ' + firmInfo.address.state + ' ' + firmInfo.address.zipCode;
                }

                if (firmInfo.address.city && firmInfo.address.state) {
                    return firmInfo.address.city + ', ' + firmInfo.address.state;
                }

                return [
                    firmInfo.address.city,
                    firmInfo.address.state,
                    firmInfo.address.zipCode
                ].filter(function (p) { return p; }).join(' ');
            };

            this.prepareHtml = function (html) {
                var removeRegExp = /<!-- DELETE_BEFORE_PDF_GENERATION_START -->((?!<!-- DELETE_BEFORE_PDF_GENERATION_END -->).|\n)*<!-- DELETE_BEFORE_PDF_GENERATION_END -->/mg;

                return html.replace(/ browser-page"/g, '"')
                    .replace(removeRegExp, '');

            };

            this.getParties = function (eCase) {
                if (eCase && eCase.party && eCase.party.find) {
                    return eCase.party;
                }

                if (eCase && eCase.parties && eCase.parties.find) {
                    return eCase.parties;
                }

                return [];
            };

            this.getSheetsList = function (options) {
                var marketCoverSheetSettings = options.marketCoverSheetSettings;
                var courtCode = options.courtCode;
                var caseTypeCode = parseInt(options.caseTypeCode);
                var market = options.market;
                var items = marketCoverSheetSettings.default;

                if (marketCoverSheetSettings[market]) {
                    items = marketCoverSheetSettings[market];
                }

                return Object.keys(items)
                    .filter(function (sheetCode) {
                        var locations = items[sheetCode].locations;

                        return !!locations.find(function (location) {
                            if (typeof location === 'string') {
                                return location === courtCode;
                            }

                            if (location instanceof RegExp) {
                                return courtCode.match(location);
                            }

                            return false;
                        });
                    })
                    .filter(function (sheetCode) {
                        var caseTypes = items[sheetCode].caseTypes;

                        if (!caseTypeCode || !caseTypes) {
                            return true;
                        }

                        return !!caseTypes.find(function (caseType) {
                            if (typeof caseType === 'number') {
                                return caseType === caseTypeCode;
                            }

                            return false;
                        });
                    })
                    .map(function (sheetCode) {
                        return {
                            name: items[sheetCode].name,
                            file: items[sheetCode].file,
                            court: courtCode
                        };
                    });
            };

            this.getStyles = function ($http) {
                return $http.get(
                    '/assets/app/shared/directives/courtCoverSheet/styles.css?{{onfileApplicationVersion}}'
                ).then(function (data) {
                    return data.data;
                });
            };

            this.getFilingPartyCity = function (eCase) {
                var party = this.getFilingParty(eCase);

                if (!party) {
                    return '';
                }

                return utils.getOr(function () {
                    return party.all.EntityPerson.PersonAugmentation.ContactInformation.ContactMailingAddress
                        .StructuredAddress.LocationCityName;
                }, '');
            };

            this.getFilingPartyState = function (eCase) {
                var party = this.getFilingParty(eCase);

                if (!party) {
                    return '';
                }

                return utils.getOr(function () {
                    return party.all.EntityPerson.PersonAugmentation.ContactInformation.ContactMailingAddress
                        .StructuredAddress.LocationStateName;
                }, '');
            };

            this.getFilingPartyZip = function (eCase) {
                var party = this.getFilingParty(eCase);

                if (!party) {
                    return '';
                }

                return utils.getOr(function () {
                    return party.all.EntityPerson.PersonAugmentation.ContactInformation.ContactMailingAddress
                        .StructuredAddress.LocationPostalCode;
                }, '');
            };

            this.getFilingPartyPhone = function () {
                return '';
            };

            this.getFilingPartyAddress = function (eCase) {
                var parts = [
                    this.getFilingPartyCity(eCase),
                    this.getFilingPartyState(eCase),
                    this.getFilingPartyZip(eCase)
                ];

                return parts.filter(function (d) { return d; })
                    .join(', ');
            };

            this.getFilingParty = function (eCase) {
                var parties = this.getParties(eCase);

                if (!parties) {
                    console.warn('No party was found for cover sheet.');
                    return '';
                }

                return (parties && parties[eCase.filingPartyIndex]) || null;
            };
        }
    ]);
})(angular.module('onfileApp'));
