(function (app) {
    app.factory('FilingsMetaTransformer', [
        'TransformerDecorator',
        function (TransformerDecorator) {
            function FilingsMetaTransformer() {}

            return {
                import: function (raw) {
                    return TransformerDecorator(
                        new FilingsMetaTransformer(),
                        raw,
                        {
                            rejectionReason: 'Envelope.Body.NotifyFilingReviewCompleteRequestMessage.' +
                                'ReviewFilingCallbackMessage.ReviewedLeadDocument.RejectReasonText',
                            reviewComment: 'Envelope.Body.NotifyFilingReviewCompleteRequestMessage.' +
                                'ReviewFilingCallbackMessage.ReviewedLeadDocument.FilingReviewCommentsText'
                        }
                    );
                }
            };
        }
    ]);
})(angular.module('onfileApp'));