(function (app) {
    var STATUS_CREATED = 'created';
    var TYPE_HEARING = 'hearing';

    app.constant('Reminder', function Reminder(data) {
        this.case = data.case || null;
        this.type = data.type;
        this.due_date = data.due_date;
        this.description = data.description;
        this.user_id = data.user_id;
        this.status = data.status || STATUS_CREATED;
        this.reminderId = data.reminderId || null;

        this.due_date_timestamp = +this.due_date;

        this.setCase = function (eCase) {
            this.case = {
                id: eCase.id || null,
                title: eCase.title || eCase.case_title_composed || null,
                docket_id: eCase.docket_id || null,
                court_code: eCase.court_code || null
            };
        };

        this.getData = function () {
            var res = {};

            for (var prop in this) {
                if (this.hasOwnProperty(prop) === false || typeof this[prop] === 'function') {
                    continue;
                }

                res[prop] = this[prop];
            }

            return res;
        };

        this.setProp = function (prop, val) {
            this[prop] = val;

            return this;
        };

        this.getTemplateName = function () {
            switch (this.type) {
                case TYPE_HEARING: return 'hearing.html';
                default: return 'reminder.html';
            }
        };

        this.getPlaceholdersReplacements = function (EnvironmentService) {
            return [
                { key: 'DueDate', value: this.due_date },
                { key: 'Description', value: this.description },
                { key: 'Type', value: this.type },
                { key: 'ReminderId', value: this.reminderId },
                { key: 'ReminderUrl', value: this.composeUrl(EnvironmentService) },
                { key: 'Name', value: EnvironmentService.first_name() },
                {
                    key: 'StartDateTime',
                    value: this.hearing && this.hearing.startDate && this.formatDate(this.hearing.startDate) || null
                }, {
                    key: 'EndDateTime',
                    value: this.hearing && this.hearing.endDate && this.formatDate(this.hearing.endDate) || null
                }, { key: 'EnvelopeNumber', value: this.hearing && this.hearing.envelope },
                { key: 'Environment', value: EnvironmentService.getEnvironmentDisplayName() }
            ];
        };

        this.formatDate = function (timestamp) {
            return moment(timestamp).format('MMMM D, YYYY [at] h A');
        };

        this.composeUrl = function (EnvironmentService) {
            if (this.type === TYPE_HEARING) {
                return EnvironmentService.composeAbsoluteUrl('filings/recent?id=' + this.hearing.filingId);
            }

            return this.url;
        };
    });
})(angular.module('onfileApp'));
