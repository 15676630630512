;(function (app) {
    app.controller('EServicesDraftSidebarCtrl', [
        'FirebaseService', 'MAXIMUM_DRAFTS_AGE',
        '$scope', '$rootScope', '$stateParams',

        function (
            FirebaseService, MAXIMUM_DRAFTS_AGE,
            $, $rootScope, $stateParams
        ) {
            $rootScope.showList = true;
            $rootScope.EServicesDrafts = null;

            angular.extend($rootScope, {
                activeEServiceDraftId: $stateParams.id
            });

            // Callback function which is called when URL state changes.
            this.uiOnParamsChanged = function (changedParams) {
                // If current active eService ID is changed - refresh variable for a view.
                if (changedParams.hasOwnProperty('id'))
                    $rootScope.activeEServiceDraftId = changedParams.id;
            };

            var fetchEServicesDrafts = function (deleteOldDrafts) {
                deleteOldDrafts = deleteOldDrafts || false;

                FirebaseService.getIdChannel('auth_users', 'eservices_drafts')
                    .then(function (drafts) {
                        $rootScope.eServicesDrafts = drafts;

                        return drafts.$loaded();
                    }).then(function(l) {
                        let currentTimestamp = (new Date()).getTime() / 1000;
                        let draftsWereDeleted = false;

                        for (var i = 0; i < l.length; i++) {
                            l[i].iseProd = !(l[i].eProdId === null || l[i].eProdId === undefined);

                            if (deleteOldDrafts && (l[i].time / 1000) < currentTimestamp - MAXIMUM_DRAFTS_AGE) {
                                l.$remove(i);

                                draftsWereDeleted = true;
                            }
                        }

                        if (draftsWereDeleted) {
                            fetchEServicesDrafts();
                        }
                    });
            };

            fetchEServicesDrafts(true);
        }
    ])
})(angular.module('onfileApp'));
