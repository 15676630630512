(function (app) {
    app.directive('pdfDocument', [
        '$q', '$window', 'PDFService',
        function (
            $q, $window, PDFService
        ) {
            return {
                strict: 'A',
                scope: {
                    name: '=?',
                    link: '=?'
                },
                link: function ($) {
                    $.currentPage = 1;
                    $.showCanvas = false;
                    $.pdfPreviewCanvasId = null;

                    $.render = function () {
                        getFromCache($.link)
                            .then(function (pdf) {
                                $.pdfPagesQuantity = pdf._pdfInfo.numPages;
                                $.pdfPages = new Array($.pdfPagesQuantity);

                                return pdf.getPage($.currentPage);
                            })
                            .then(function (page) {
                                var scale = 1.3;
                                var viewport = page.getViewport({ scale: scale });

                                // Prepare canvas using PDF page dimensions
                                var canvas = document.getElementById($.pdfPreviewCanvasId);
                                var context = canvas.getContext('2d');

                                canvas.height = viewport.height;
                                canvas.width = viewport.width;

                                // Render PDF page into canvas context
                                var renderContext = {
                                    canvasContext: context,
                                    viewport: viewport
                                };

                                return page.render(renderContext).promise;
                            })
                            .catch(function (reason) { console.error(reason); })
                            .then(function () {
                                $.showCanvas = true;
                            });
                    };

                    $.showPage = function (index) {
                        $.currentPage = index;
                        $.render();
                    };

                    $.print = function () {
                        return PDFService.print($.link);
                    };

                    $.download = function () {
                        return PDFService.download($.link, $.name);
                    };

                    var cache = {};
                    var pdfJsLib = $window['pdfjs-dist/build/pdf'];
                    var getFromCache = function (url) {
                        if (cache[url]) {
                            return $q.resolve(cache[url]);
                        }

                        return pdfJsLib.getDocument(url)
                            .promise
                            .then(function (pdf) {
                                cache[url] = pdf;

                                return cache[url];
                            });
                    };

                    $.$watch('[name, link]', function () {
                        $.pdfPreviewCanvasId = 'preview-document-canvas-' + new Date().getTime().toString();
                        $.render();
                    });
                },

                templateUrl: '/assets/app/shared/directives/pdfDocument/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
