(function (app) {
    app.controller('NotaryIndexCtrl', [
        '$scope', 'SiteConfigurationService',
        function (
            $, SiteConfigurationService
        ) {
            $.link = null;

            SiteConfigurationService.getConfigurationForPage('notary')
                .then(function (c) {
                    $.link = (c && c.indexIframe) || null;
                });
        }
    ]);
})(angular.module('onfileApp'));
