(function (app) {
    app.directive('courtContactPopup', [
        'utils', 'CourtResource', '$mdDialog',
        function (utils, CourtResource, $mdDialog) {
            return {
                strict: 'A',
                scope: {
                    courtCode: '=?'
                },
                link: function ($) {
                    $.show = function () {
                        var courtCode = utils.getOr(function () { return $.courtCode; }, null);

                        if (!courtCode) {
                            return false;
                        }

                        var showDialog = function (courtContact) {
                            $mdDialog.show({
                                controller: ['$scope', function ($scope) {
                                    $scope.contact = courtContact;

                                    $scope.close = function () { $mdDialog.hide(); };
                                }],
                                templateUrl: '/assets/app/shared/templates/courtContactPopup.html?' +
                                    '{{onfileApplicationVersion}}',
                                parent: angular.element(document.body),
                                clickOutsideToClose: true
                            });
                        };

                        return CourtResource
                            .getContact({ id: courtCode })
                            .$promise
                            .then(function (contact) {
                                contact = contact.toJSON();

                                // If Contact has only ID field
                                if (contact && Object.keys(contact).length === 1) {
                                    contact = null;
                                }

                                return showDialog(contact);
                            });
                    };
                },
                templateUrl: '/assets/app/shared/directives/courtContactPopup/template.html?' +
                    '{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
