(function (app) {
    app.controller('SubmittedEProductionCtrl', [
        '$scope', '$rootScope', '$stateParams',
        'EnvironmentService', 'FirebaseService', 'ToastrService',
        'EServiceResource', 'eProductionService', '$timeout',

        function (
            $, $rootScope, $stateParams,
            EnvironmentService, FirebaseService, ToastrService,
            EServiceResource, eProductionService, $timeout
        ) {
            angular.extend($, {
                showList: true,
                activeSubmittedEProductionId: null,
                downloadPdfLink: null,
                eProduction: null,
                lastUpdatedTimestamp: null,
                packageId: $stateParams.id,
                email: FirebaseService.email,
                emailHash: FirebaseService.emailHash,
                firmId: EnvironmentService.firm_id(),

                linkCopied: function () {
                    ToastrService.showMessageByKey('link_copied');
                },
                refresh: function (eProductionId) {
                    $rootScope.showSpinner();

                    return eProductionService
                        .loadSubmitted(EnvironmentService.firm_id(), { eProductionId: eProductionId })
                        .then(function (data) {
                            var firstItem = data && data[0];

                            if (!firstItem) {
                                return;
                            }

                            updateActiveEProduction(firstItem);

                            return composeLinks();
                        })
                        .finally(function () {
                            $timeout(function () { $rootScope.hideSpinner(); }, 300);
                        });
                }
            });

            var composeLinks = function () {
                $.eProdLink = window.location.origin + '/eproduction/' +
                    EnvironmentService.firm_id() + '/' + $.eProduction.eProdId;

                return EServiceResource.get({
                    id: $.eProduction.batch_header.filing_id,
                    firebaseDetail: true
                }).$promise.then(function (eService) {
                    $.downloadPdfLink = getInstructionsFileLink(eService.documents);
                });
            };

            var getInstructionsFileLink = function (documents) {
                var instructionFilenameRegexp = /instruction/gi;
                var statuses = ['transmitted', 'original'];

                var status;
                var document;

                if (documents && documents.length) {
                    for (var i = 0; i < documents.length; i++) {
                        document = documents[i];

                        if (!document) {
                            continue;
                        }

                        for (var j = 0; j < statuses.length; j++) {
                            status = statuses[j];

                            if (
                                document[status] && document[status].name &&
                                document[status].name.match(instructionFilenameRegexp)
                            ) {
                                return document[status].link;
                            }
                        }
                    }
                }

                return null;
            };

            var updateActiveEProduction = function (eProduction) {
                $.eProduction = eProduction;
                $.lastUpdatedTimestamp = +new Date();
            };

            this.uiOnParamsChanged = function (changedParams) {
                $rootScope.activeSubmittedEProductionId = changedParams.id;
                $.packageId = changedParams.id;

                $rootScope.$watch('eProductionsSubmitted', function (eProductionsSubmitted) {
                    if (!eProductionsSubmitted) {
                        return false;
                    }

                    for (var i = 0; i < eProductionsSubmitted.length; i++) {
                        if (eProductionsSubmitted[i].eProdId === changedParams.id) {
                            updateActiveEProduction(eProductionsSubmitted[i]);
                            break;
                        }
                    }

                    composeLinks();
                });
            };

            this.uiOnParamsChanged({ id: $stateParams.id });
        }
    ]);
})(angular.module('onfileApp'));
