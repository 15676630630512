/**
 * Current directive executes given callback when user clicks outside target element in DOM.
 */
(function (app) {
    app.directive('ngClickOutside', [
        '$document',
        function ($document) {
            return {
                strict: 'A',
                scope: {
                    ngClickOutside: '&'
                },
                link: function ($, el) {
                    $document.bind('click', function (e) {
                        if (el !== e.target && !el[0].contains(e.target)) {
                            $.$apply(function () { $.$eval($.ngClickOutside); });
                        }
                    });
                }
            };
        }
    ]);
})(angular.module('onfileApp'));