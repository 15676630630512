(function (app) {
    app.directive('ngFilename', [
        'getForbiddenFilenamePattern',
        function (getForbiddenFilenamePattern) {
            var regexp = getForbiddenFilenamePattern();

            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$validators.filename = function (modelValue, viewValue) {
                        return !regexp.test(viewValue);
                    };
                }
            };
        }
    ]);

})(angular.module('onfileApp'));
