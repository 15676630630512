(function (app) {
    app.service('NotaryService', [
        'NotaryResourceFactory', '$q', 'EnvironmentService',
        '$http',

        function (
            NotaryResourceFactory, $q, EnvironmentService,
            $http
        ) {
            var notaryFilesResource;
            var notarySessionsResource;
            var notificationsResource;
            var recordsResource;

            this.officialId = EnvironmentService.userId();
            this.firmId = EnvironmentService.firm_id();
            this.official = {
                name: EnvironmentService.user_name(),
                email: EnvironmentService.email()
            };

            this.getLogs = function (data) {
                return getNotarySessionsResource()
                    .then(function (resource) {
                        return resource.logs(data).$promise;
                    });
            };

            this.getById = function (data) {
                return getNotarySessionsResource()
                    .then(function (resource) {
                        return resource.get(data).$promise;
                    });
            };

            this.getListWithSearch = function (data) {
                return getNotarySessionsResource()
                    .then(function (resource) {
                        return resource.reducedSearch(data).$promise;
                    });
            };

            this.searchSessions = function (data) {
                return getNotarySessionsResource()
                    .then(function (resource) {
                        return resource.search(data).$promise;
                    });
            };

            this.createSession = function (data, options) {
                var sessionId;
                var sessionResponse;
                var documentsResponse;
                var notaryAppLink = options && options.notaryAppLink;

                return getNotarySessionsResource()
                    // Step 1. Session creation
                    .then(function (notarySessionsResource) {
                        var date = data.date;

                        date.setHours(data.time.hours, data.time.minutes);

                        var scheduledAt = Math.floor(date.getTime() / 1000);
                        var requestData = {
                            name: data.name,
                            user: Object.assign({}, getName(data), {
                                fullName: data.userName,
                                email: data.userEmail,
                                phone: data.userPhone || ''
                            }),
                            scheduledAt: scheduledAt,
                            isPersonallyKnown: data.isPersonallyKnown,
                            documentsCount: data.documentsCount ? data.documentsCount : data.files.length,
                            message: data.message,
                            emailCopies: [data.copy1, data.copy2].filter(function (d) { return d; }),
                            typeOfNotarization: data.typeOfNotarization || null,
                            officialPrivateNotes: data.officialPrivateNotes || null,
                            multipleSigner: data.multipleSigner
                        };
                        var additionalProps = [
                            'official',
                            'userTimezone',
                            'payerEmail',
                            'officialNotes',
                            'paymentSettings',
                            'paymentMode'
                        ];

                        additionalProps.forEach(function (prop) {
                            if (data[prop]) {
                                requestData[prop] = data[prop];
                            }
                        });

                        if (data.appointmentId) {
                            requestData.appointmentId = parseInt(data.appointmentId);
                        }

                        if (data.appointmentType) {
                            requestData.appointmentType = data.appointmentType;
                        }

                        if (data.lowesEmail) {
                            requestData.lowesEmail = data.lowesEmail;
                        }

                        if (data['paymentSettings.rate']) {
                            requestData.paymentSettings = requestData.paymentSettings || {};
                            requestData.paymentSettings.rate = parseInt(data['paymentSettings.rate']);
                            requestData.paymentSettings.additionalDocumentsRate = parseInt(
                                data['paymentSettings.additionalDocumentsRate']
                            );
                        }

                        return notarySessionsResource.create(requestData).$promise;
                    }.bind(this))
                    // Step 2. Documents upload
                    .then(function (session) {
                        sessionId = session.id;
                        sessionResponse = session;

                        var promises = data.files.map(function (file, index) {
                            var resourceLoaded = getNotaryFilesResource();

                            if (file.isCopied) {
                                return resourceLoaded
                                    .then(function (notaryFilesResource) {
                                        return notaryFilesResource.copyFromDocumentGroup({
                                            documentId: file.id,
                                            sessionId: sessionId,
                                            sessionDocumentFilename: generateDocumentName(index)
                                        }).$promise;
                                    })
                                    .then(function (res) {
                                        return Object.assign({}, file, {
                                            hash: getFileHash(file, res)
                                        });
                                    });
                            }

                            return resourceLoaded
                                .then(function (notaryFilesResource) {
                                    return notaryFilesResource.uploadUrl({
                                        sessionId: sessionId,
                                        filename: generateDocumentName(index)
                                    }).$promise;
                                })
                                .then(function (res) {
                                    var params = { headers: { 'Content-type': 'application/pdf' } };

                                    return $http.put(res.url, file, params)
                                        .then(function (data) {
                                            return Object.assign({}, file, {
                                                url: res.url,
                                                hash: getFileHash(file, data)
                                            });
                                        });
                                });
                        });

                        return $q.all(promises);
                    })
                    // Step 3. Notary Documents creation
                    .then(function (files) {
                        return getNotaryFilesResource()
                            .then(function (notaryFilesResource) {
                                var promises = files.map(function (file, index) {
                                    return notaryFilesResource.create({
                                        filename: generateDocumentName(index),
                                        name: file.displayedName,
                                        sessionId: sessionId,
                                        hash: file.hash,
                                        date: file.date && new Date(file.date).getTime(),
                                        documentId: file.documentId
                                    }).$promise;
                                });

                                return $q.all(promises)
                                    .then(function (res) {
                                        return documentsResponse = res;
                                    });
                            });
                    })
                    // Step 4. Sending notifications
                    .then(function () {
                        var invalidDocumentsDetected = false;

                        for (var i = 0; i < documentsResponse.length; i++) {
                            if (
                                documentsResponse[i].fileCheckResult && documentsResponse[i].fileCheckResult.ok !== true
                            ) {
                                invalidDocumentsDetected = true;
                                break;
                            }
                        }

                        if (invalidDocumentsDetected) {
                            throw new InvalidDocumentsError({ documents: documentsResponse, sessionId: sessionId });
                        }

                        return getNotificationsResource()
                            .then(function (notificationsResource) {
                                return notificationsResource.create({
                                    status: 'created',
                                    sessionId: sessionId,
                                    notaryAppHost: notaryAppLink
                                });
                            });
                    })
                    // Step 5. Return session and documents objects
                    .then(function () {
                        return {
                            session: sessionResponse,
                            documents: documentsResponse
                        };
                    })
                    .catch(function (e) {
                        if (sessionId) {
                            e.sessionId = sessionId;
                        }

                        throw e;
                    });
            };

            this.uploadDocuments = function (data) {
                var files = data.files;
                var startingIndex = data.startingIndex;
                var sessionId = data.sessionId;

                var promises = files.map(function (file, index) {
                    return getNotaryFilesResource()
                        .then(function (notaryFilesResource) {
                            return notaryFilesResource.uploadUrl({
                                sessionId: sessionId,
                                filename: generateDocumentName(index + startingIndex)
                            }).$promise;
                        })
                        .then(function (res) {
                            var params = { headers: { 'Content-type': 'application/pdf' } };

                            return $http.put(res.url, file, params)
                                .then(function (data) {
                                    return Object.assign({}, file, {
                                        url: res.url,
                                        hash: getFileHash(file, data)
                                    });
                                });
                        });
                });

                return $q.all(promises)
                    .then(function (files) {
                        return getNotaryFilesResource()
                            .then(function (notaryFilesResource) {
                                var promises = files.map(function (file, index) {
                                    return notaryFilesResource.create({
                                        filename: generateDocumentName(index + startingIndex),
                                        name: file.displayedName,
                                        sessionId: sessionId,
                                        hash: file.hash,
                                        date: file.date && new Date(file.date).getTime()
                                    }).$promise;
                                });

                                return $q.all(promises);
                            });
                    });
            };

            this.deleteSession = function (id) {
                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.delete({ sessionId: id }).$promise;
                    });
            };

            this.cancelSession = function (id) {
                var requestData = { stage: 'cancelled', sessionId: id };

                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.update(requestData).$promise;
                    });
            };

            this.changeSessionStage = function (id, stage) {
                var requestData = { stage: stage, sessionId: id };

                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.update(requestData).$promise;
                    });
            };

            this.changeSessionDocumentCount = function (id, documentsCount) {
                var requestData = { documentsCount: documentsCount, sessionId: id };    

                return getNotarySessionsResource().then(function (
                    notarySessionsResource
                ) {
                    return notarySessionsResource.update(requestData).$promise;
                });
            };
            this.restartSession = function (id) {
                var requestData = { stage: 'start', sessionId: id, idFrontImage: null, selfieImage: null, idCaptureError: null, latestSubStage: 'documentCapture', eventLog: null, idParts: null, documentType: null };

                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.update(requestData).$promise;
                    });
            };

            this.addFeedback = function (id, text) {
                var requestData = { officialFeedback: text, sessionId: id };

                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.update(requestData).$promise;
                    });
            };

            this.addPrivateNotes = function (id, text) {
                var requestData = { officialPrivateNotes: text, sessionId: id };

                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.update(requestData).$promise;
                    });
            };

            this.updateSession = function (requestData) {
                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.update(requestData).$promise;
                    });
            };

            this.deleteDocument = function (options) {
                var documentId = options.documentId;

                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.deleteDocument({ documentId: documentId }).$promise;
                    });
            };

            this.reassignOfficial = function (id, official) {
                var officialData = Object.assign({}, official, {
                    commissionExpirationDate: new Date(official.commissionExpirationDate._seconds * 1000).toString()
                });
                var requestData = { official: officialData, sessionId: id };

                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.update(requestData).$promise;
                    });
            };

            this.getDocuments = function (params) {
                return getNotaryFilesResource()
                    .then(function (notaryFilesResource) {
                        return notaryFilesResource.query(params).$promise;
                    });
            };

            this.getNotaryRecords = function (options) {
                var personalKnowledgeEvidenceText;
                var PERSONAL_KNOWLEDGE_EVIDENCE = window.onfileShared.idEvidence.PERSONAL_KNOWLEDGE_EVIDENCE;
                var personalKnowledgeEvidence = window.onfileShared.idEvidence.options.find(function (option) {
                    return option.value === PERSONAL_KNOWLEDGE_EVIDENCE;
                });

                if (personalKnowledgeEvidence) {
                    personalKnowledgeEvidenceText = personalKnowledgeEvidence.text;
                }

                return getNotaryRecordsResource()
                    .then(function (resource) {
                        return resource.query(options).$promise;
                    })
                    .then(function (records) {
                        var result = Array(records.length).fill(undefined);
                        var sessionIds = [];
                        var sessionIdsRecordIndicesMap = {};

                        records.forEach(function (record, i) {
                            if (record.sessionId) {
                                sessionIds.push(record.sessionId);
                                sessionIdsRecordIndicesMap[record.sessionId] = i;
                            }
                        });

                        var chunkSize = 1000;
                        var promises = [];

                        for (var i = 0; i < sessionIds.length; i += chunkSize) {
                            var sessionIdsChunk = sessionIds.slice(i, i + chunkSize);

                            promises.push(
                                this.getListWithSearch({
                                    ids: sessionIdsChunk,
                                    fields: ['userInfo', 'emailCopies']
                                })
                            );
                        }

                        return $q.all(promises)
                            .then(function (d) {
                                return d.flat();
                            })
                            .then(function (sessions) {
                                sessions.forEach(function (session) {
                                    var recordIndex = sessionIdsRecordIndicesMap[session.id];

                                    if (recordIndex >= 0) {
                                        if (session.userInfo) {
                                            var userInfo = session.userInfo;

                                            records[recordIndex].signerAddress = records[recordIndex].signerAddress + ' ' +
                                                userInfo.city + ', ' + userInfo.state + userInfo.zipCode;
                                        }

                                        if (session[0] && session[0].emailCopies) {
                                            records[recordIndex].emailCopies = session[0].emailCopies;
                                        }

                                        records[recordIndex].identification = ' ';

                                        if (
                                            records[recordIndex].idEvidence !== personalKnowledgeEvidenceText &&
                                            !records[recordIndex].identification
                                        ) {
                                            records[recordIndex].identification = 'Driver\'s license';
                                        }

                                        result[recordIndex] = records[recordIndex];
                                    }
                                });

                                return result.filter(Boolean);
                            });
                    }.bind(this));
            };

            this.updateNotaryRecords = function (data) {
                return getNotaryRecordsResource()
                    .then(function (resource) {
                        return resource.update(data).$promise;
                    });
            };

            this.getSessions = function (options) {
                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.query(options).$promise;
                    });
            };

            this.getSessionsBatch = function (options) {
                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.getBatch(options).$promise;
                    });
            };

            this.getSharedSessions = function (options) {
                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.query(options).$promise;
                    });
            };

            this.createOfficialLink = function (notaryAppLink, security) {
                return notaryAppLink + '?' + generateSecurityCredentials(security) + '#/official';
            };

            this.createUserLink = function (notaryAppLink, security) {
                return notaryAppLink + '?' + generateSecurityCredentials(security) + '#';
            };

            this.createUserPaymentLink = function (notaryAppLink, security) {
                return notaryAppLink + '?' + generateSecurityCredentials(security) + '#/service-params/forward-to-payment-page';
            };

            this.createUserDownloadPageLink = function (notaryAppLink, security) {
                return notaryAppLink + '?' + generateSecurityCredentials(security) + '#/download';
            };

            this.createSharedDocsAppLink = function (notaryAppLink, security) {
                return notaryAppLink + '?' + generateSecurityCredentials(security);
            };

            this.createSharedDocsDownloadAppLink = function (notaryAppLink, security) {
                return notaryAppLink + '?' + generateSecurityCredentials(security);
            };

            this.checkSessionStatus = function (sessionId) {
                return getNotarySessionsResource()
                    .then(function (notarySessionsResource) {
                        return notarySessionsResource.status({ sessionId: sessionId }).$promise;
                    });
            };

            this.sendNotification = function (options) {
                var sessionId = options && options.sessionId;
                var notaryAppLink = options && options.notaryAppLink;
                var status = options && options.status || 'created';
                var note = options && options.note || '';

                return getNotificationsResource()
                    .then(function (notificationsResource) {
                        return notificationsResource.create({
                            note: note,
                            status: status,
                            sessionId: sessionId,
                            notaryAppHost: notaryAppLink
                        }).$promise;
                    });
            };

            this.getNotaryRecord = function (id) {
                return getNotaryRecordsResource()
                    .then(function (resource) {
                        return resource.get({ id: id }).$promise;
                    });
            };

            this.getOriginalDocumentUrl = function (document) {
                return document.file;
            };

            this.getSealedDocumentUrl = function (document) {
                var url = document.fileSealed;

                // Fallback for old sessions
                if (!url) {
                    var originalUrl = this.getOriginalDocumentUrl(document);

                    url = originalUrl && originalUrl.replace('document', 'document-sealed').split('?')[0];
                }

                return url;
            };

            this.getSharedDocumentsList = function (sessionId, params) {
                return NotaryResourceFactory
                    .createSharedDocumentsResource({
                        officialId: this.officialId,
                        firmId: this.firmId,
                        sessionId: sessionId
                    })
                    .then(function (resource) {
                        return resource.query(params).$promise;
                    });
            };

            this.deleteSharedDocument = function (options) {
                var sessionId = options.sessionId;
                var documentId = options.documentId;
                var requestParams = { id: documentId };

                if (options.email) {
                    requestParams.email = options.email;
                }

                return NotaryResourceFactory
                    .createSharedDocumentsResource({
                        officialId: this.officialId,
                        firmId: this.firmId,
                        sessionId: sessionId
                    })
                    .then(function (resource) {
                        return resource.delete(requestParams).$promise;
                    });
            };

            var generateSecurityCredentials = function (securityParams) {
                if (!securityParams) {
                    console.error('No security credentials provided!');

                    return false;
                }

                return Object.keys(securityParams)
                    .map(function (param) {
                        return param + '=' + securityParams[param];
                    })
                    .join('&');
            };

            var getNotaryFilesResource = function () {
                if (notaryFilesResource) {
                    return $q.resolve(notaryFilesResource);
                }

                return NotaryResourceFactory
                    .createFilesResource({
                        officialId: this.officialId,
                        firmId: this.firmId
                    })
                    .then(function (resource) {
                        notaryFilesResource = resource;

                        return notaryFilesResource;
                    });
            }.bind(this);

            var getNotarySessionsResource = function () {
                if (notarySessionsResource) {
                    return $q.resolve(notarySessionsResource);
                }

                return NotaryResourceFactory
                    .createSessionsResource({
                        officialId: this.officialId,
                        firmId: this.firmId
                    })
                    .then(function (resource) {
                        notarySessionsResource = resource;

                        return notarySessionsResource;
                    });
            }.bind(this);

            var getNotificationsResource = function () {
                if (notificationsResource) {
                    return $q.resolve(notificationsResource);
                }

                return NotaryResourceFactory
                    .createNotificationsResource({
                        officialId: this.officialId,
                        firmId: this.firmId
                    })
                    .then(function (resource) {
                        notificationsResource = resource;

                        return notificationsResource;
                    });
            }.bind(this);

            var getNotaryRecordsResource = function () {
                if (recordsResource) {
                    return $q.resolve(recordsResource);
                }

                return NotaryResourceFactory
                    .createRecordsResource({
                        officialId: this.officialId,
                        firmId: this.firmId
                    })
                    .then(function (resource) {
                        recordsResource = resource;

                        return recordsResource;
                    });
            }.bind(this);

            var getName = function (data) {
                var res = {
                    firstName: '',
                    lastName: ''
                };
                var parts = (data && data.userName && data.userName.split(' ')) || [];

                if (parts.length === 0) {
                    return res;
                }

                if (parts.length === 1) {
                    res.firstName = parts[0];

                    return res;
                }

                if (parts.length > 2) {
                    res.firstName = parts[0];
                    res.lastName = parts[parts.length - 1];

                    return res;
                }

                return {
                    firstName: parts[0],
                    lastName: parts[1]
                };
            }.bind(this);

            var getFileHash = function (file, responseData) {
                var etag;

                if (responseData && responseData.CopyObjectResult && responseData.CopyObjectResult.ETag) {
                    etag = responseData.CopyObjectResult.ETag;
                }

                if (!etag) {
                    etag = responseData.headers && responseData.headers('etag');
                }

                if (!etag) {
                    console.error('ETag header was not returned from S3 upload. Check your bucket configuration');

                    return null;
                }

                return etag.replace(/"/g, '');
            };

            var generateDocumentName = function (index) {
                if (!index) {
                    return 'document.pdf';
                }

                return 'document-' + index + '.pdf';
            };
        }
    ]);
})(angular.module('onfileApp'));
