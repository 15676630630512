(function (app) {
    app.factory('EnvironmentService', [
        '$location', '$rootScope', 'marketTimezones',
        function ($location, $rootScope, marketTimezones) {
            function EnvironmentService() {
                var cache = {};
                var handlers = {
                    market: function () { return $('#application_market').val(); },
                    environment: function () { return $('#application_environment').val(); },
                    firmId: function () { return $('#application_firm_id').val(); },
                    isAdmin: function () { return $('#application_is_admin').val(); },
                    userName: function () { return $('#application_logged_in_user').val(); },
                    userId: function () { return $('#application_logged_in_user_id').val(); },
                    firstName: function () { return $('#application_logged_in_user_first_name').val(); },
                    email: function () { return $('#application_logged_in_user_email').val(); },
                    notaryApiBaseUrl: function () { return $('#application_notaryApiBaseUrl').val(); },
                    baseUrl: function () { return $location.$$protocol + '://' + $location.$$host + '/services/#'; },
                    isIndividualAccount: function () {
                        var value = $('#application_isIndividualFirm').val();

                        return isNaN(+value) ? false : +value === 1;
                    }
                };

                var get = function (prop) {
                    if (cache[prop] === undefined) {
                        cache[prop] = handlers[prop]();
                    }

                    return cache[prop];
                };

                this.market = function () { return get('market'); };

                this.environment = function () { return get('environment'); };

                this.firm_id = function () { return get('firmId'); };

                // this.isAdmin = function () { return get('isAdmin'); };

                this.isAdmin = function () { return true; };

                this.user_name = function () { return get('userName'); };

                this.userId = function () { return get('userId'); };

                this.isAuthenticated = function () { return !!get('userId'); };

                this.first_name = function () { return get('firstName'); };

                this.email = function () { return get('email'); };

                this.base_url = function () { return get('baseUrl'); };

                this.isIndividualAccount = function () { return get('isIndividualAccount'); };

                this.notaryApiBaseUrl = function () { return get('notaryApiBaseUrl'); };

                this.isFirmAccount = function () { return !this.isIndividualAccount(); };

                this.composeAbsoluteUrl = function (path) {
                    return this.base_url() + '/' + path;
                };

                this.s3BucketLocation = function (draftId) {
                    return 'onfile-' + this.environment() + '-' + this.market() + '/' + this.firm_id() + '/' + draftId;
                };

                this.s3BucketName = function () {
                    return 'onfile-' + this.environment() + '-' + this.market();
                };

                var _firm;
                this.firm = function (firm) {
                    if (firm !== undefined)
                        _firm = firm;
                    else
                        return _firm;
                };

                this.getFirmInfo = function () {
                    return JSON.parse(
                        $('#application_firm').val()
                    );
                };

                this.isProduction = function () {
                    return this.environment() === 'production';
                };

                this.getUsersIdentifiers = function () {
                    return [this.userId(), this.email()];
                };

                this.getEnvironmentDisplayName = function () {
                    return $rootScope.TryTranslate('eFileTexas');
                };

                this.defaultTimezone = function () {
                    var market = this.market();

                    if (marketTimezones[market]) {
                        return marketTimezones[market];
                    }

                    return marketTimezones.default;
                };

                this.toSystemTime = (function () {
                    var cache = {};
                    var timezone = this.defaultTimezone();

                    return function (date, rawTransformer) {
                        var dateObject = moment(date);
                        var num = parseInt(date);

                        if (dateObject.isValid()) {
                            dateObject = moment.tz(date, timezone);
                        } else if (!isNaN(num)) {
                            dateObject = moment.tz(num, timezone);
                        } else {
                            dateObject = moment.tz(date, timezone);
                        }

                        if (rawTransformer) {
                            return rawTransformer(dateObject);
                        }

                        if (!cache[date]) {
                            cache[date] = dateObject.format($rootScope.shortDateTime);
                        }

                        return cache[date];
                    };
                }.bind(this))();

                return this;
            }

            return new EnvironmentService();
        }]
    );
})(angular.module('onfileApp'));
