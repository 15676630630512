(function (app) {
    app.controller('RequestForFileProductionCtrl', [
        '$scope', 'ToastrService', '$mdDialog',
        'documentGroup', 'DocumentGroupsResourceFactory',
        function (
            $, ToastrService, $mdDialog,
            documentGroup, DocumentGroupsResourceFactory
        ) {
            $.model = {};

            $.submit = function () {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        return resource.update({
                            id: documentGroup.id,
                            extensions: {
                                userName: $.model.userName,
                                userEmail: $.model.userEmail,
                                message: $.model.message
                            }
                        }).$promise;
                    })
                    .then(function () {
                        ToastrService.showSuccess('Request for file production successfully created');
                        $.close();
                    });
            };

            $.close = function () { $mdDialog.hide(); };
        }
    ]);
})(angular.module('onfileApp'));
