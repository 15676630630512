(function (app) {
    app.factory('NotaryRecordsResourceFactory', [
        '$resource', '$q', 'notaryTokenUtil',
        function ($resource, $q, notaryTokenUtil) {
            return {
                create: function (options) {
                    var headers = {
                        'X-OFFICIAL-ID': options.officialId,
                        'X-FIRM-ID': options.firmId
                    };
                    var resource = $resource(
                        notaryTokenUtil.notaryApiHost + '/records?session-id=:sessionId',
                        { id: '@id'},
                        {
                            query: {
                                method: 'GET',
                                isArray: true,
                                cache: false,
                                headers: notaryTokenUtil.addAuthorizationToken(headers)
                            },
                            get: {
                                url: notaryTokenUtil.notaryApiHost + '/records/:id',
                                method: 'GET',
                                isArray: false,
                                cache: false,
                                headers: notaryTokenUtil.addAuthorizationToken(headers)
                            },
                            update: {
                                url: notaryTokenUtil.notaryApiHost + '/records/:id',
                                method: 'PUT',
                                isArray: false,
                                cache: false,
                                headers: notaryTokenUtil.addAuthorizationToken(headers)
                            }
                        }
                    );

                    return notaryTokenUtil.checkToken(resource, $q)
                        .then(function () {
                            return resource;
                        });
                }
            };
        }]
    );
})(angular.module('onfileApp'));
