(function (app) {
    app.service('PDFService', [
        'prepareProxyLink', 'downloadFile',
        function (prepareProxyLink, downloadFile) {
            this.print = function (url) {
                var iFrame = document.createElement('iframe');

                iFrame.style.visibility = 'hidden';
                iFrame.src = prepareProxyLink(url);
                document.body.appendChild(iFrame);
                iFrame.contentWindow.focus();
                iFrame.contentWindow.print();
            };

            this.download = function (url, filename) {
                return downloadFile({
                    url: url,
                    filename: filename,
                    separateWindow: true
                });
            };
        }
    ]);
})(angular.module('onfileApp'));
