(function (app) {
    app.controller('NotaryRequestCtrl', [
        '$scope', '$rootScope', '$stateParams',
        '$mdDialog', 'NOTARIZATION_REQUESTED', 'NOTARIZATION_SCHEDULED',
        'NOTARIZATION_COMPLETED', 'SiteConfigurationService', '$timeout',
        'ToastrService', 'INTERNAL_NOTARY_SESSION_REQUESTED_KEY',
        'NotaryRequestDocumentsResourceFactory', 'NotaryRequestService', 'INTERNAL_NOTARY_SESSION_CANCELLED_KEY',
        'EXTERNAL_NOTARY_SESSION_REQUESTED_KEY', 'EXTERNAL_NOTARY_SESSION_CANCELLED_KEY',
        'DocumentGroupsResourceFactory', 'utils', 'downloadFile',
        'PDFService', 'DownloadZipService', '$q',

        function (
            $, $rootScope, $stateParams,
            $mdDialog, NOTARIZATION_REQUESTED, NOTARIZATION_SCHEDULED,
            NOTARIZATION_COMPLETED, SiteConfigurationService, $timeout,
            ToastrService, INTERNAL_NOTARY_SESSION_REQUESTED_KEY,
            NotaryRequestDocumentsResourceFactory, NotaryRequestService, INTERNAL_NOTARY_SESSION_CANCELLED_KEY,
            EXTERNAL_NOTARY_SESSION_REQUESTED_KEY, EXTERNAL_NOTARY_SESSION_CANCELLED_KEY,
            DocumentGroupsResourceFactory, utils, downloadFile,
            PDFService, DownloadZipService, $q
        ) {
            $.tab = null;
            $.currDoc = null;
            $.docsSeletedIndexes = [];
            $.notarizedDocuments = [];
            $.selection = { areAllDocsSelected: false };

            $.isAvailableForRequest = function () {
                if (!$.request || !$.request.tags) {
                    return true;
                }

                return (
                    $.request.tags.indexOf($rootScope.constants.SESSION_FINISHED) === -1 &&
                    $.request.tags.indexOf($rootScope.constants.INTERNAL_SESSION_CREATED) === -1
                );
            };

            $.linkCopied = function () {
                ToastrService.showSuccess('Link to request copied to clipboard');
            };

            $.changeTab = function (t, force) {
                if ((t === $.tab || !$.request) && !force) {
                    return;
                }

                var target = t === 'docs' ? $.request.documents : $.request.files;

                $.tab = t;
                $.currDoc = target && target.length ? target[0] : null;
            };

            $.showDocument = function (doc) {
                $.currDoc = doc;
            };

            $.openDocAndFilesPopup = function () {
                $rootScope.followupRequest = {
                    officialName: $.request.officialName,
                    officialEmail: $.request.officialEmail
                };
                $rootScope.followupRequestFiles = [{
                    displayedName: 'Synergistic - DWQ.pdf'
                }];

                $mdDialog.show({
                    controller: 'CreateFollowupRequestCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/' +
                        'followup-file-and-document-request.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.openDocumentPopup = function () {
                $mdDialog.show({
                    controller: 'CreateFollowupRequestCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/' +
                        'followup-document-request.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.openFilePopup = function () {
                $mdDialog.show({
                    controller: 'CreateFollowupRequestCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/' +
                        'followup-file-request.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.openSessionPopup = function () {
                $rootScope.notarySessionRequest = {
                    userName: $.request.userName,
                    userEmail: $.request.userEmail
                };
                $rootScope.notarySessionRequestFiles = [{
                    displayedName: 'Synergistic - DWQ.pdf'
                }];

                $mdDialog.show({
                    controller: 'CreateNotarySessionRequestCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/' +
                        'create-notary-session-request.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.createNotaryRequest = function () {
                $mdDialog.show({
                    controller: 'CreateNotaryRequestCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/CreateNotaryRequest/' +
                        'template.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    locals: {
                        matter: $.request,
                        documentIds: utils.getSelectedDocumentIds($.request.documents)
                    }
                });
            };

            $.toggleDocSelection = function () {
                for (var i = 0; i < $.request.documents.length; i++) {
                    $.request.documents[i].isChecked = $.selection.areAllDocsSelected;
                }
            };

            $.createRequestPopup = function () {
                $mdDialog.show({
                    controller: 'CreateDocumentRequestCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/CreateDocumentRequest/' +
                        'template.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    locals: {
                        docGroup: $.request
                    }
                });
            };

            $.showRequestDetails = function (doc) {
                var mode = 'requestSent';

                if (
                    doc && doc.documentRequestRelation && doc.documentRequestRelation[0] &&
                    doc.documentRequestRelation[0] && doc.documentRequestRelation[0].status === 'signed'
                ) {
                    mode = 'requestFinished';
                }

                $mdDialog.show({
                    controller: 'RequestDetailsCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/RequestDetails/' +
                        'template.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        request: $.request,
                        mode: mode,
                        doc: doc
                    }
                });
            };

            $.createReminderPopup = function () {
                $mdDialog.show({
                    controller: 'CreateMatterReminderCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/CreateMatterReminder/' +
                        'template.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        request: $.request
                    }
                });
            };

            $.createAdditionalDocsUploaderPopup = function () {
                $mdDialog.show({
                    controller: 'AddDocumentsToMatterCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/AddDocumentsToMatter/' +
                        'template.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        matter: $.request
                    }
                });
            };

            $.showNotarySessionDetails = function (status, id, document) {
                $mdDialog.show({
                    controller: 'MatterDetailsCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/MatterDetails/' +
                        status + '.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        matterId: id,
                        doc: document
                    }
                });
            };

            $.createRequestForFileProductionPopup = function () {
                var openPopup = function () {
                    $mdDialog.show({
                        controller: 'RequestForFileProductionCtrl',
                        templateUrl: '/assets/app/shared/controllers/notary/requests/popups/RequestForFileProduction/' +
                            'template.html?{{onfileApplicationVersion}}',
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        locals: {
                            documentGroup: NotaryRequestService.getSharedDocumentGroup($.request)
                        }
                    });
                };

                if (!NotaryRequestService.isSharedDocumentGroupCreated($.request)) {
                    return NotaryRequestService.createNewSharedDocumentGroup($.request)
                        .then(function () {
                            var unregister = $rootScope.$on('updateAllMatters.finished', function () {
                                $timeout(function () {
                                    unregister();
                                    openPopup();
                                }, 0);
                            });
                        });
                }

                return openPopup();
            };

            $.createShareAccessPopup = function () {
                $mdDialog.show({
                    controller: 'ShareAccessToProducedFilesCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/ShareAccess/' +
                        'template.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        documentGroup: NotaryRequestService.getSharedDocumentGroup($.request)
                    }
                });
            };

            $.copyAppUrl = function () {
                return getAppUrl().then(function (url) {
                    if (url && utils.copyToClipboard(url)) {
                        return ToastrService.showSuccess('Link to request copied to clipboard');
                    }

                    return ToastrService.showError('Error during copying happened');
                });
            };

            $.copySharingUploadAppUrl = function () {
                var notify = function () {
                    utils.copyToClipboard($.request.childDocumentGroups[0].url);
                    ToastrService.showSuccess('Link to shared documents copied to clipboard');
                };

                if (NotaryRequestService.isSharedDocumentGroupCreated($.request)) {
                    notify();

                    return;
                }

                return NotaryRequestService.createNewSharedDocumentGroup($.request)
                    .then(function () {
                        var unregister = $rootScope.$on('updateAllMatters.finished', function () {
                            $timeout(function () {
                                unregister();
                                notify();
                            }, 0);
                        });
                    });
            };

            $.openApp = function () {
                getAppUrl().then(function (url) {
                    window.open(url, '_blank');
                });
            };

            $.getLatestDocUrl = function (doc) {
                // Check Notary document URL first
                if (
                    doc && doc.documentNotaryRelation &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1] &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1].url
                ) {
                    return doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1].url;
                }

                // Check Document Request URL
                if (
                    doc && doc.documentRequestRelation && doc.documentRequestRelation[0] &&
                    doc.documentRequestRelation[0].url
                ) {
                    return doc.documentRequestRelation[0].url;
                }

                // Original matter document URL
                if (doc.url) {
                    return doc.url;
                }

                return null;
            };

            $.getNotaryDocStatus = function (doc) {
                if (
                    doc.documentNotaryRelation &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1] &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1].status === null
                ) {
                    return 'scheduled';
                }

                if (
                    doc.documentNotaryRelation &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1] &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1].status
                ) {
                    return doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1].status;
                }

                if (
                    doc.documentNotaryRequestRelation &&
                    doc.documentNotaryRequestRelation[doc.documentNotaryRequestRelation.length - 1] &&
                    doc.documentNotaryRequestRelation[doc.documentNotaryRequestRelation.length - 1].status === null
                ) {
                    return 'requested';
                }

                return null;
            };

            $.getNotaryDocUpdatedDate = function (doc) {
                if (
                    doc.documentNotaryRelation &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1] &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1].status === null
                ) {
                    return doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1].updatedAt;
                }

                if (
                    doc.documentNotaryRelation &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1] &&
                    doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1].status
                ) {
                    return doc.documentNotaryRelation[doc.documentNotaryRelation.length - 1].updatedAt;
                }

                if (
                    doc.documentNotaryRequestRelation &&
                    doc.documentNotaryRequestRelation[doc.documentNotaryRequestRelation.length - 1] &&
                    doc.documentNotaryRequestRelation[doc.documentNotaryRequestRelation.length - 1].status === null
                ) {
                    return doc.documentNotaryRequestRelation[doc.documentNotaryRequestRelation.length - 1].updatedAt;
                }

                return null;
            };

            $.deleteDoc = function (id) {
                $mdDialog.show({
                    controller: 'DeleteDocumentConfirmationCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/DeleteDocumentConfirmation/' +
                        'template.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        documentId: id,
                        matterId: $.request.id
                    }
                });
            };

            $.shareMatterFilesPopup = function () {
                $mdDialog.show({
                    controller: 'ShareMatterFilesCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/ShareMatterFiles/' +
                        'template.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        documents: $.request.documents.filter(function (doc) {
                            return doc.isChecked;
                        })
                    }
                });
            };

            $.refresh = function () {
                $rootScope.$emit('updateAllMatters', { reloadMatterId: $.request.id });
            };

            $.getSharedDocumentsList = function () {
                if (
                    $.request && $.request.childDocumentGroups && $.request.childDocumentGroups[0] &&
                    $.request.childDocumentGroups[0].documents
                ) {
                    return $.request.childDocumentGroups[0].documents;
                }

                return [];
            };

            this.uiOnParamsChanged = function (changedParams) {
                if (!changedParams || !changedParams.id) {
                    return;
                }

                var docGroupId = parseInt(changedParams.id);

                $rootScope.itemsLoaded.then(function () {
                    $.lastUpdatedTimestamp = +new Date();
                    $.request = $rootScope.items.find(function (r) {
                        return r.id === docGroupId;
                    });
                    $.sharedDocs = $.getSharedDocumentsList();

                    $.changeTab('docs', true);
                });
                $timeout(function () { $rootScope.customListHeader = true; }, 0);
            };

            this.uiOnParamsChanged($stateParams);

            $rootScope.$on('requestUpdated', function (e, data) {
                this.uiOnParamsChanged({ id: data.id });
            }.bind(this));

            var getAppUrl = function () {
                var docGroupId;

                for (var i = 0; i < $.request.documents.length; i++) {
                    if (
                        $.request.documents[i].documentRequestRelation &&
                        $.request.documents[i].documentRequestRelation.length
                    ) {
                        docGroupId = $.request.documents[i].documentRequestRelation[0].documentGroupId;
                        break;
                    }
                }

                if (!docGroupId) {
                    return;
                }

                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        return resource.generateAppUrl({ id: docGroupId }).$promise;
                    })
                    .then(function (res) {
                        return res.appUrl;
                    });
            };

            $.getNotaryRequestId = function (doc) {
                if (
                    doc.documentNotaryRequestRelation &&
                    doc.documentNotaryRequestRelation[doc.documentNotaryRequestRelation.length - 1] &&
                    doc.documentNotaryRequestRelation[doc.documentNotaryRequestRelation.length - 1]
                        .documentGroupId
                ) {
                    return doc.documentNotaryRequestRelation[doc.documentNotaryRequestRelation.length - 1]
                        .documentGroupId;
                }

                return null;
            };

            $.getNotarySessionId = function (doc) {
                return utils.getOr(function () {
                    return doc.documentNotaryRelation[0].documentGroupId;
                }, null);
            };

            var getDocUrl = (function () {
                var cache = {};

                return function (doc) {
                    if (cache[doc.key] === undefined) {
                        cache[doc.key] = DocumentGroupsResourceFactory.create()
                            .then(function (resource) {
                                return resource.readUrl({ key: doc.key }).$promise;
                            })
                            .then(function (response) {
                                return response && response.url;
                            });
                    }

                    return cache[doc.key];
                };
            })();

            $.download = function (doc) {
                return getDocUrl(doc).then(function (url) {
                    if (url) {
                        return downloadFile({ url: url, separateWindow: true });
                    }

                    ToastrService.showError('Error during downloading a file');
                });
            };



            $.downloadAll = function (docs) {
                $rootScope.loading_message = 'Downloading file(s)';

                var links = [];
                var promises = docs.map(function (doc) {
                    return getDocUrl(doc).then(function (url) {
                        var parts = doc.displayedName.split('.');
                        var ext = parts.pop();
                        var filename = parts.join('.');

                        links.push({
                            url: url,
                            name: filename,
                            ext: ext
                        });
                    });
                });

                return $q.all(promises)
                    .then(function () {
                        return DownloadZipService.remoteFilesToZip(
                            links,
                            'Produced files for ' + $.request.userName
                        );
                    })
                    .finally(function () {
                        $rootScope.loading_message = '';
                    });
            };

            $.print = function (doc) {
                return getDocUrl(doc).then(function (url) {
                    if (url) {
                        return PDFService.print(url);
                    }

                    ToastrService.showError('Error during printing a file');
                });
            };

            $.delete = function (doc) {
                return DocumentGroupsResourceFactory.create()
                    .then(function (resource) {
                        return resource.delete({ id: doc.id }).$promise;
                    })
                    .then(function () {
                        $.sharedDocs = $.sharedDocs.filter(function (currDoc) {
                            return currDoc.id !== doc.id;
                        });

                        return ToastrService.showError('Document was deleted');
                    });
            };
        }
    ]);
})(angular.module('onfileApp'));
