(function (app) {
    app.controller('eProductionDraftCtrl', [
        'FirebaseService', 'eProductionService',
        'EnvironmentService',
        '$q', '$window',
        '$scope', '$rootScope', '$stateParams',
        '$state','ToastrService', 'filepickerApikey',
        function (FirebaseService, eProductionService, EnvironmentService, $q,
            $window, $, $rootScope, $stateParams,
            $state, ToastrService, filepickerApikey) {

            angular.extend($, {
                eProdDraft: null
            });

            $window.filepicker.setKey(filepickerApikey);
            function deleteFsFile(fObj) {
                var defer = $q.defer();
                $window.filepicker.remove(fObj, function(){
                    defer.resolve(true);
                }, function(err){
                    defer.reject(err);
                });
                return defer.promise;
            }

            this.uiOnParamsChanged = function (changedParams) {
                $rootScope.$watch('eProdDrafts', function (eProdDrafts) {
                    if (!eProdDrafts)
                        return false;

                    for (var i = 0; i < eProdDrafts.length; i++) {
                        var d = eProdDrafts[i];
                        if (d.eProdId === changedParams.id) {
                            $.eProdDraft = d;
                            break;
                        }
                    }
                });
            };

            $.resumeEProduction = function () {
                var state = {
                    eProdId: $.eProdDraft.eProdId
                };

                if ($.eProdDraft.batch_header && $.eProdDraft.batch_header.case_id) {
                    state.caseId = $.eProdDraft.batch_header.case_id;
                }

                $state.go('eProductionCreate', state);
            };

            $.deleteEProduction = function() {
                eProductionService
                    .init(null, $.eProdDraft.eProdId, EnvironmentService.firm_id())
                    .then(function(e){
                        var chain = Object
                            .keys(e.d.batch_body.files)
                            .map(function(k){ console.log(k) ; deleteFsFile(e.d.batch_body.files[k]); });
                        $q.all(chain).then(function(){
                            e.deleteBundle().then(function(){
                                ToastrService.showMessageByKey('draft_deleted');
                                $state.go('eProductionDrafts');
                            });
                        });
                        e.deleteBundle().then(function(){
                        });
                    });
            };
            this.uiOnParamsChanged({id: $stateParams.id});
        }
    ]);
})(angular.module('onfileApp'));
