(function (app) {
    app.factory('ProgressHandlerFactory', function () {
        function ProgressEvent(data) {
            data = data || {};

            this.loaded = data.loaded;
            this.total = data.total;
        }

        function ProgressHandlerService() {
            var onProgressChangeCallback;
            var progressMap = {};
            var progressMapKeys = [];

            this.setKeys = function (keys) {
                progressMapKeys = keys;

                for (var i = 0; i < keys.length; i++) {
                    progressMap[keys[i]] = new ProgressEvent();
                }

                return this;
            };

            this.onProgressChange = function (cb) {
                onProgressChangeCallback = cb;

                return this;
            };

            this.createHandler = function (key) {
                return function (progressEvent) {
                    var percent = 0.0;
                    var total = 0.0;
                    var loaded = 0.0;

                    progressMap[key].loaded = progressEvent.loaded;
                    progressMap[key].total = progressEvent.total;

                    for (var i = 0; i < progressMapKeys.length; i++) {
                        total += progressMap[progressMapKeys[i]].total;
                        loaded += progressMap[progressMapKeys[i]].loaded;
                    }

                    if (total) {
                        percent = Math.floor(loaded / total * 100 * 100) / 100;
                    }

                    if (typeof onProgressChangeCallback === 'function') {
                        onProgressChangeCallback(percent);
                    }
                };
            };
        }

        return {
            create: function () {
                return new ProgressHandlerService();
            }
        };
    });
})(angular.module('onfileApp'));