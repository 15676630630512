(function (app) {
    app.factory('DocumentLoadService', [
        '$q', 'FilingDocumentsResource', 'FirebaseService',
        'SecurityService',

        function DocumentLoadService(
            $q, DocumentsLoadResource, FirebaseService,
            SecurityService
        ) {
            var cdnUrl = 'https://cdn.fs.onfile.legal';

            this.loadDocument = function (url, filingId) {
                var docRID = getDocRID(url);

                if (!filingId) {
                    throw new Error('Can\'t load document without filingId');
                }

                if (docRID === null || docRID === '') {
                    throw new Error('Missing RID query param in document url');
                }

                return FirebaseService.getNode('documents/' + filingId)
                    .$loaded()
                    .then(function (fbData) {
                        if (fbData.$value !== null && fbData[docRID] !== undefined) {
                            var d = {
                                status: 'success',
                                processed: fbData
                            };

                            return processData(d, docRID, filingId);
                        }

                        return DocumentsLoadResource.query({ id: filingId })
                            .$promise
                            .then(function (d) {
                                return processData(d, docRID, filingId);
                            });
                    });
            };

            var constructFilestackUrl = function (handle) {
                return cdnUrl + '/' + handle;
            };

            var getDocRID = function (docUrl) {
                var regex = new RegExp('[?&]RID(=([^&#]*)|&|#|$)');
                var results = regex.exec(docUrl);

                if (!results) {
                    return null;
                }

                if (!results[2]) {
                    return '';
                }

                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            };

            var processData = function (data, docRID, filingId) {
                var doc;
                var documentData;

                if (data.status !== 'success') {
                    throw new Error('Could not synchronize documents for filing ID #' + filingId);
                }

                documentData = data.processed;
                if (!documentData || !(docRID in documentData)) {
                    throw new Error(
                        'Document is not in the list of filing documents or has already ' +
                        'been processed for filing ID #' + filingId
                    );
                }

                doc = documentData[docRID];
                if (doc.fpHandle) {
                    doc.link = constructFilestackUrl(doc.fpHandle);

                    return SecurityService.addParamsToLink(doc.link)
                        .then(function (url) {
                            doc.link = url;

                            return doc;
                        });
                }

                return doc;
            };

            return this;
        }
    ]);

}(angular.module('onfileApp')));
