(function (app) {
    app.constant('NotarySessionCancelledEvent', 'NotarySessionCancelled')
       .constant('MITEK_ERROR_MAP_OFFICIAL', {
            MITEK_ERROR_GLARE: 'The image has too much glare. Ask signer to rescan their ID.',
            MITEK_ERROR_FOUR_CORNER: 'The ID could not be scanned. Please make sure that the entire ID and all four corners of it are inside the rectangular image capture area.',
            MITEK_ERROR_TOO_DARK: 'The captured image is too dark. Ask signer to rescan their ID with better lighting.',
            MITEK_ERROR_FOCUS: 'Image too blurry/out of focus. Ask signer to rescan ID.',
            MITEK_ERROR_MRZ_MISSING: 'Unable to scan the MRZ (the two rows of numbers and letters on the bottom of your passport). Please rescan your ID and make sure image capture is clear and the MRZ numers are not cut off or blocked.',
            CV_NO_BARCODE_FOUND: 'Scanning for barcode',
            MITEK_ERROR_TOO_FAR: 'Document Too Far',
            MITEK_ERROR_TOO_CLOSE: 'Document Too Close',
            MITEK_ERROR_NOT_CENTERED: 'Document Not Centered',
            MITEK_ERROR_MIN_PADDING: 'The captured image is too small. Ask signer to rescan their ID, making sure that the ID fills up at least 70% of the image capture rectangle.',
            MITEK_ERROR_HORIZONTAL_FILL: 'The ID could not be scanned. Ask signer to rescan, making sure that the entire ID and all four corners of it are inside the rectangular image capture area .',
            MITEK_ERROR_SKEW_ANGLE: 'Document is skewed. Hold camera directly over your document',
            MITEK_ERROR_BUSY_BACKGROUND: 'Center document on plain background',
            MITEK_ERROR_LOW_CONTRAST: 'Insufficient picture exposure/quality on ID. Have signer rescan ID and make sure image capture is clear and focused. If this issue, persists, ask them to use another form of ID.',
            MISNAP_HEAD_OUTSIDE: 'Place Face in Oval',
            MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
            MISNAP_AXIS_ANGLE: 'The angle between the face and camera is too large.',
            MISNAP_HEAD_TOO_CLOSE: 'Face is too close to the camera.',
            MISNAP_HEAD_TOO_FAR: 'The face is too far from the camera.',
            MISNAP_STAY_STILL: 'Hold Still',
            MISNAP_SUCCESS: 'Success',
            MISNAP_READY_POSE: 'Hold it There',
            NO_FACE_FOUND: 'The face could not be detected in the portrait image.',
            200: 'General problem with image quality. Ask signer to rescan their ID. ',
            201: 'Image too blurry/out of focus. Ask signer to rescan ID.',
            202: 'The image has too much glare. Ask signer to rescan their ID.',
            203: 'The captured image is too dark. Ask signer to rescan their ID with better lighting.',
            204: 'The captured image is too small. Ask signer to rescan their ID, making sure that the ID fills up at least 70% of the image capture rectangle.',
            205: 'The ID could not be scanned. Ask signer to rescan, making sure that the entire ID and all four corners of it are inside the rectangular image capture area.',
            206: 'The type of ID could not be determined. This could be because the Image Capture was a poor quality or because this type of ID is supported.',
            208: 'Invalid Image Type.',
            209: 'Image is too small',
            210: 'Image processing failed after resizing. Use smaller image.',
            212: 'Problem with image quality. Please rescan your ID and make sure the image capture is clearer and focused.',
            501: 'The barcode was found but could not be read. This can be due to image quality, parts of the barcode being obscured, or a misprinted barcode.',
            502: 'The barcode was read but the resulting data was not in the expected format.',
            503: 'The barcode could not be processed.',
            510: 'Data capture error. Ask signer to rescan their ID and make sure the image capture is clearer and focused.',
            511: 'Data capture error. Ask signer to rescan their ID and make sure the image capture is clearer and focused.',
            512: 'Data capture error. Ask signer to rescan their ID and make sure the image capture is clearer and focused.',
            520: 'Data capture error OR data did not pass additional accuracy checks. Ask signer to rescan their ID and make sure the image capture is clearer and focused. If this error repeats, seek alternate actions.',
            610: 'Unable to locate signer\'s portrait on ID. Have them rescan ID and make sure image capture is clear and focused. Or ask them to use another form of ID.',
            611: 'Insufficient picture quality on ID. Have signer rescan ID and make sure image capture is clear and focused. If this issue, persists, ask them to use another form of ID.',
            612: 'Insufficient picture exposure/quality on ID. Have signer rescan ID and make sure image capture is clear and focused. If this issue, persists, ask them to use another form of ID.',
            620: 'Unable to scan the MRZ (the two rows of numbers and letters on the bottom of the passport). Ask signer to rescan their ID and make sure image capture is clear and the MRZ numers are not cut off or blocked.',
            621: 'Unable to scan the MRZ (the two rows of numbers and letters on the bottom of the passport). Ask signer to rescan their ID and make sure image capture is clear and the MRZ numers are not cut off or blocked.',
            630: 'Invalid MRZ (the two rows of numbers and letters on the bottom of signer\'s passport). Ask signer to rescan their ID and make sure image capture is clear and the MRZ numers are not cut off or blocked.'
        }),
    app.controller('NotaryFileCtrl', [
        '$scope', '$rootScope', '$window',
        '$timeout', '$mdDialog', 'TextNodeFactory',
        'SiteConfigurationService', '$q', 'angularMomentConfig',
        '$http', 'NotaryFilesService', 'NotaryService',
        'utils', 'COMPLETED_STATUS', 'PDFService',
        '$stateParams', 'CANCELLED_STATUS', 'ToastrService',
        'SESSION_INVITATION_RESENT_TOASTER_KEY', 'SESSION_CANCELLED_TOASTER_KEY', 'NotaryRecordUpdatedEvent',
        'USER_EMAIL_COPIED_TOASTER_KEY', 'NOTARY_SESSION_LINK_COPIED_TOASTER_KEY', 'typeOfNotarizationOptions',
        'SESSION_RESTARTED_TOASTER_KEY', 'APPOINTMENT_ID_COPIED_TOASTER_KEY',
        'NOTARY_DOWNLOAD_APP_LINK_COPIED_TOASTER_KEY', 'NOTARY_UPLOAD_APP_LINK_COPIED_TOASTER_KEY', '$state',
        'ONE_RECORD_PRODUCTION_ROUTE_NAME', 'EnvironmentService', 'NOTARY_FEEDBACK_ADDED_KEY',
        'PermissionsService', 'OfficialsResourceFactory', 'NotarySessionsResourceFactory',
        'NotaryNotificationService', 'NotarySessionCancelledEvent', 'SESSION_STAGES',
        'UserAgentService', 'downloadFile', 'DownloadZipService', 'MITEK_ERROR_MAP_OFFICIAL',

        function (
            $, $rootScope, $window,
            $timeout, $mdDialog, TextNodeFactory,
            SiteConfigurationService, $q, angularMomentConfig,
            $http, NotaryFilesService, NotaryService,
            utils, COMPLETED_STATUS, PDFService,
            $stateParams, CANCELLED_STATUS, ToastrService,
            SESSION_INVITATION_RESENT_TOASTER_KEY, SESSION_CANCELLED_TOASTER_KEY, NotaryRecordUpdatedEvent,
            USER_EMAIL_COPIED_TOASTER_KEY, NOTARY_SESSION_LINK_COPIED_TOASTER_KEY, typeOfNotarizationOptions,
            SESSION_RESTARTED_TOASTER_KEY, APPOINTMENT_ID_COPIED_TOASTER_KEY,
            NOTARY_DOWNLOAD_APP_LINK_COPIED_TOASTER_KEY, NOTARY_UPLOAD_APP_LINK_COPIED_TOASTER_KEY, $state,
            ONE_RECORD_PRODUCTION_ROUTE_NAME, EnvironmentService, NOTARY_FEEDBACK_ADDED_KEY,
            PermissionsService, OfficialsResourceFactory, NotarySessionsResourceFactory,
            NotaryNotificationService, NotarySessionCancelledEvent, SESSION_STAGES,
            UserAgentService, downloadFile, DownloadZipService, MITEK_ERROR_MAP_OFFICIAL
        ) {
            $rootScope.showList = true;

            var notaryLink;
            var sharedDocumentsAppUrl;
            var updatePdf = updatePdfHandler($window['pdfjs-dist/build/pdf'], $q, $);
            var checkSessionStatusSecondsInterval = 10;
            var recordsCache = {};
            var typeOfNotarizationOptionsMap = utils.clone(typeOfNotarizationOptions)
                .reduce(function (all, item) {
                    all[item.value] = item.text;

                    return all;
                }, {});
            var defaultTab = 'sessionDocs';
            var personaClient = null;
            $.userLink = null;
            $.activeDocument = null;
            $.session = null;
            $.fileInputId = 'signature-file-upload';
            $.insertedSignatureId = 'inserted-signature';
            $.insertedTextId = 'inserted-text';
            $.insertedSignatureImageId = 'inserted-signature-image';
            $.uploadedSignaturesDataImages = [];
            $.insertedSignatures = [];
            $.insertedTextNodes = [];
            $.showCanvas = false;
            $.pdfPagesQuantity = 0;
            $.pdfPages = [];
            $.currentPage = 1;
            $.tab = defaultTab;
            $.isAssignmentAvailable = false;
            $.officials = [];
            $.selectedOfficial = { uid: null };
            $.selectedStage = { stage: null };
            $.sessionStages = SESSION_STAGES;
            $.addThirdParty = null;
            $.addEmailCopy = [];
            $.payerEmail = '';
            $.copyEmail = [];
            $.isAdmin = EnvironmentService.isAdmin() == 1 ? true : false;
            $.IframeUrl = null;
            $.currentCount = { count: null };
            $.isNotaryAdmin = false;
            $.$watch('session.documentsCount', function(newVal, oldVal){
                // console.log(oldVal, newVal)
                if(oldVal && newVal) {
                    $.currentCount.count = newVal
                    var requestParams = { sessionId: $.session.id };
                    NotaryService.getDocuments(requestParams)
                        .then(function (documents) {
                            $.session.documents = documents;
                        });
                }
            })
            $.getS3Url = function (session) {
                let s3Url = 'https://s3.console.aws.amazon.com/s3/buckets/';
                let s3BucketName;
                if(EnvironmentService.environment() === 'development' && EnvironmentService.market() === 'texas') {
                    s3BucketName = 'onfile-notary-files';
                }
                else if (EnvironmentService.environment() === 'production' && EnvironmentService.market() === 'texas') {
                    s3BucketName = 'prod-onfile-notary-files';
                }
                else {
                    s3BucketName = EnvironmentService.s3BucketName();
                }
                s3Url += s3BucketName + "?region=us-west-2&prefix=session-files/" + session.id + "/";
                $window.open(s3Url, "_blank")
                console.log(s3Url);
            };
            $.showThirdPartyField = function (thirdPartyEmail) {
                $.addThirdParty = true;
                if (thirdPartyEmail) {
                    $.payerEmail = thirdPartyEmail;
                }
            };
            $.updateThirdPartyPayer = function (payerEmail) {
                if (payerEmail) {
                    return NotaryService.updateSession({
                        sessionId: $.session.id,
                        payerEmail: payerEmail
                    }).then(function () {
                        $.session.payerEmail = payerEmail;
                        $.addThirdParty = false;
                    });
                }
            }
            $.deleteThirdParty = function () {
                return NotaryService.updateSession({
                    sessionId: $.session.id,
                    payerEmail: null
                }).then(function () {
                    $.session.payerEmail = null;
                    $.addThirdParty = false;
                    $.payerEmail = null;
                });
            }
            $.showEmailCopyField = function (emailCopy, index) {
                $.addEmailCopy[index] = true;
                if (emailCopy) {
                    $.copyEmail[index] = emailCopy[index];
                }
            };
            $.updateEmailCopy = function (copyEmail, index) {
                if (copyEmail[index]) {
                    let emailCopiesTemp = $.session.emailCopies;
                    emailCopiesTemp[index] = copyEmail[index]
                    return NotaryService.updateSession({
                        sessionId: $.session.id,
                        emailCopies: emailCopiesTemp,
                    }).then(function () {
                        $.session.emailCopies = emailCopiesTemp;
                        $.addEmailCopy = [];
                    });
                }
            };
            $.deleteEmailCopy = function (index) {
                let emailCopiesTemp = $.session.emailCopies;
                emailCopiesTemp.splice(index, 1)
                return NotaryService.updateSession({
                    sessionId: $.session.id,
                    emailCopies: emailCopiesTemp,
                }).then(function () {
                   $.session.emailCopies = emailCopiesTemp;
                   $.addEmailCopy = [];
                   $.copyEmail = emailCopiesTemp;
                });
            };
            $.getTypeOfNotarization = function (type) {
                if (!type) {
                    return null;
                }

                return typeOfNotarizationOptionsMap[type] || null;
            };

            $.initFileInput = function () {
                $timeout(function () {
                    angular.element('#' + $.fileInputId).bind('change', uploadedImageHandler($));
                }, 0);
            };

            $.triggerUpload = function () {
                angular.element('#' + $.fileInputId).trigger('click');
            };

            $.copyImage = function (dataImage) {
                var id = '#' + $.insertedSignatureId + '-' + $.insertedSignatures.length;
                var imageId = '#' + $.insertedSignatureImageId + '-' + $.insertedSignatures.length;

                $.insertedSignatures.push({
                    dataImage: dataImage,
                    id: id,
                    imageId: imageId
                });
                $timeout(function () {
                    jQuery(id).draggable();
                    jQuery(imageId).resizable();
                }, 0);
            };

            $.fixImage = function (image) {
                image.isFixed = !image.isFixed;
                jQuery(image.id).draggable('disable');
                jQuery(image.imageId).resizable('disable');
            };

            $.insertText = function () {
                var textNode = TextNodeFactory.create();

                textNode.setOrderNumber($.insertedTextNodes.length);
                $.insertedTextNodes.push(textNode);
                textNode.setDraggable();
            };

            $.openNotarySession = function (path) {
                if (!$.session.security) {
                    console.error('No security credentials provided!');

                    return false;
                }

                var link = NotaryService.createOfficialLink(notaryLink, $.session.security);

                if (path) {
                    link += path;
                }
                $.checkSealedFile();
                window.open(link, 'newwindow', 'width=1200,height=1100');
            };
            $.checkSealedFile = function () {
                $timeout(function () {
                    NotaryService.checkSessionStatus($.session.id)
                        .then(function (data) {
                            if (!data || !data.sealedUrl) {
                                return $.checkSealedFile();
                            }

                            NotaryFilesService
                                .moveTo($.session, COMPLETED_STATUS)
                                .displayByStatus(COMPLETED_STATUS);

                            var sessionData = Object.assign({}, $.session, data);

                            return updateSessionDetails(sessionData, true);
                        }.bind(this))
                        .catch(function (err) {
                            console.error(err);

                            return $.checkSealedFile();
                        });
                }.bind(this), checkSessionStatusSecondsInterval * 1e3);
            }.bind(this);

            $.showPage = function (index) {
                $.currentPage = index;

                return updatePdf(getDocumentUrl($.activeDocument), $.pdfPreviewCanvasId, { page: index });
            };

            $.showDocument = function (document) {
                resetData($);
                $.activeDocument = document;
                $rootScope.showSpinner();
                
                var str = NotaryService.createOfficialLink(notaryLink, $.session.security);
                $.IframeUrl = str.substring(0, str.indexOf('sid=')) + `#/document/${$.activeDocument.document_id}`;
                return updatePdf(getDocumentUrl(document), $.pdfPreviewCanvasId)
                    .then(function () {
                        $rootScope.hideSpinner();
                        $.showCanvas = true;
                    });
            };

            $.print = function () {
                return PDFService.print(getDocumentUrl($.activeDocument));
            };

            $.viewNotaryRecord = function (id) {
                var promise;

                if (recordsCache[$.session.id]) {
                    promise = $q.resolve(recordsCache[$.session.id]);
                } else {
                    promise = NotaryService.getNotaryRecords({
                        sessionId: $.session.id,
                        with: 'screenShareUrl'
                    });
                }

                return promise
                    .then(function (records) {
                        if (!records || !records[0]) {
                            return null;
                        }

                        recordsCache[$.session.id] = records;

                        var record = records.find(function (record) {
                            return record && record.documentId === id;
                        }) || records[0];

                        $mdDialog.show({
                            controller: ['$scope', function ($scope) {
                                $scope.item = record;

                                $scope.close = function () { $mdDialog.hide(); };

                                $rootScope.$on(NotaryRecordUpdatedEvent, function (e, eventData) {
                                    $scope.item = eventData.data;
                                });
                            }],
                            templateUrl: '/assets/app/shared/controllers/notary/view/notary-details-popup.html?' +
                                '{{onfileApplicationVersion}}',
                            parent: angular.element(document.body),
                            clickOutsideToClose: true
                        });
                    });
            };

            $.reSendInvitation = function () {
                return siteConfigurationLoaded.then(function () {
                    return NotaryService.sendNotification({
                        sessionId: $.session.id,
                        notaryAppLink: notaryLink
                    }).then(function () {
                        return ToastrService.showMessageByKey(SESSION_INVITATION_RESENT_TOASTER_KEY);
                    });
                });
            };

            $.cancelSession = function (note, donotSendEmail) {
                return NotaryService.cancelSession($.session.id)
                    .then(function () {
                        return NotaryFilesService.moveTo($.session, CANCELLED_STATUS);
                    })
                    .then(function () {
                        if (!donotSendEmail) {
                            return NotaryService.sendNotification({
                                note: note,
                                status: 'cancelled',
                                sessionId: $.session.id,
                                notaryAppLink: notaryLink
                            });
                        }
                    })
                    .then(function () {
                        $rootScope.$emit(NotarySessionCancelledEvent, { id: $.session.id });

                        return ToastrService.showMessageByKey(SESSION_CANCELLED_TOASTER_KEY);
                    });
            };

            $.openCancelSessionPopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        $scope.note = '';
                        $scope.donotSendEmail = false;
                        $scope.close = function () { $mdDialog.hide(); };
                        $scope.cancelSession = function () {
                            $.cancelSession($scope.note, $scope.donotSendEmail);
                            $scope.close();
                        };
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/view/cancel.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true
                });

            };

            $.openRestartSessionPopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        $scope.close = function () {
                            $mdDialog.hide();
                        };

                        $scope.restartSession = function () {
                            return NotaryService.restartSession($.session.id)
                                .then(function () {
                                    ToastrService.showMessageByKey(SESSION_RESTARTED_TOASTER_KEY);
                                    $scope.close();
                                });
                        };
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/view/restart.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true
                });
            };
            $.appointmentIdCopied = function () {
                ToastrService.showMessageByKey(APPOINTMENT_ID_COPIED_TOASTER_KEY);
            };
            $.emailCopied = function () {
                ToastrService.showMessageByKey(USER_EMAIL_COPIED_TOASTER_KEY);
            }
            $.linkCopied = function () {
                ToastrService.showMessageByKey(NOTARY_SESSION_LINK_COPIED_TOASTER_KEY);
            };

            $.downloadAppLinkCopied = function () {
                ToastrService.showMessageByKey(NOTARY_DOWNLOAD_APP_LINK_COPIED_TOASTER_KEY);
            };

            $.uploadAppLinkCopied = function () {
                ToastrService.showMessageByKey(NOTARY_UPLOAD_APP_LINK_COPIED_TOASTER_KEY);
            };

            $.isCopyLinkButtonVisible = function () {
                return $.session.isScheduled || utils.isInDebugMode();
            };

            $.updateActiveSessionSharedDocs = function () {
                return updateSharedDocs($.session.id);
            };

            var timeoutId;

            $.changeTab = function (newTab) {
                if (newTab !== $.tab) {
                    $.tab = newTab;

                    if (newTab === 'status') {
                        $.idDocumentLink = null;
                        $.mitekErrors = null;

                        NotaryService.getLogs({ id: $.session.id })
                            .then(function (data) {
                                $.mitekErrors = data.audits.filter(function (entry) {
                                    return utils.getProperty(entry, 'extra.findings.authenticated') === false;
                                });
                            });

                        if (timeoutId && $.timeout) {
                            $timeout.clear(timeoutId);
                        }

                        var fn = function () {
                            return NotaryService.getById({ id: $.session.id })
                                .then(function (data) {
                                    $.session = Object.assign($.session, data);
                                    $.idDocumentLink = data.idDocumentLink;

                                    updateSessionData();
                                })
                                .finally(function () {
                                    timeoutId = $timeout(fn, 10e5);
                                });
                        };

                        fn();
                    }
                }
            };

            $.download = function (link, filename) {
                if (!link) {
                    link = getDocumentUrl($.activeDocument);
                }

                if (!filename) {
                    filename = $.activeDocument.name;
                }

                return PDFService.download(link, filename);
            };
            $.downloadAll = function () {
                var links = [];

                if (!$.session.documents) {
                    throw new Error('There is no documents for this service to download');
                }

                $rootScope.loading_message = 'Downloading file(s)';
                $.session
                    .documents
                    .map(function (doc) {
                        var parts = doc.filename.split('.');
                        var ext = parts.pop();
                        var filename = parts.join('.');
                        if (doc.fileSealed) {
                            links.push({
                                url: doc.fileSealed,
                                name: filename,
                                ext: ext
                            });
                        }
                        else {
                            links.push({
                                url: doc.file,
                                name: filename,
                                ext: ext
                            });
                        }
                    });
                // Save ZIP
                return DownloadZipService.remoteFilesToZip(
                    links,
                    $.session.id + '_docs'
                );
            },

                $.deleteSharedDocument = function (doc) {
                    return NotaryService.deleteSharedDocument({
                        sessionId: $.session.id,
                        documentId: doc.id,
                        email: EnvironmentService.email()
                    }).then(function () {
                        return $.updateActiveSessionSharedDocs();
                    });
                };

            $.showFeedbackPopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        $scope.feedbackText = '';

                        $scope.close = function () {
                            $mdDialog.hide();
                        };

                        $scope.submit = function () {
                            return NotaryService.addFeedback($.session.id, $scope.feedbackText)
                                .then(function () {
                                    $scope.close();

                                    return ToastrService.showMessageByKey(NOTARY_FEEDBACK_ADDED_KEY);
                                });
                        };
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/view/notary-feedback-popup.html' +
                        '?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.showDisableWebRTCPopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        $scope.isDisabled = $.session.settings && $.session.settings.isMeetingDisabled;

                        $scope.close = function () {
                            $mdDialog.hide();
                        };

                        $scope.confirm = function () {
                            return NotaryService.updateSession({
                                sessionId: $.session.id,
                                settings: {
                                    isMeetingDisabled: !$scope.isDisabled
                                }
                            }).then(function () {
                                $scope.close();

                                return ToastrService.showSuccess([
                                    'Audio/Video is successfully',
                                    $scope.isDisabled ? 'enabled' : 'disabled',
                                    'for this session'
                                ].join(' '));
                            });
                        };
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/view/disable-video-popup.html' +
                        '?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.changeOfficial = function () {
                if (!$.selectedOfficial.uid || $.selectedOfficial.uid === $.session.official.uid) {
                    return;
                }

                var selectedOfficial = $.officials.find(function (official) {
                    return official.uid === $.selectedOfficial.uid;
                });

                if (!selectedOfficial) {
                    return;
                }

                return NotaryService.reassignOfficial($.session.id, selectedOfficial)
                    .then(function () {
                        $.session.official = selectedOfficial;

                        return NotaryNotificationService.sendSessionCreated({ sessionId: $.session.id });
                    })
                    .then(function () {
                        ToastrService.showSuccess('New notary successfully assigned');
                    });
            };

            $.changeDocumentCount = function (documentsCount) {
                documentsCount = documentsCount || $.currentCount.count;
                if (!documentsCount || documentsCount === $.session.documentsCount) {
                    return $q.resolve();
                }
                return NotaryService.changeSessionDocumentCount(
                    $.session.id,
                    documentsCount
                ).then(function () {
                    $.session.documentsCount = documentsCount;
                    ToastrService.showSuccess(
                        "Documents Count has been changed to " + documentsCount
                    );
                });
            };

            $.changeStage = function (stage) {
                stage = stage || $.selectedStage.stage;

                if (!stage || stage === $.session.stage) {
                    return $q.resolve();
                }
                if (['idVerificationStart', 'idVerificationFront', 'mobileVerificationIdCapture'].includes(stage)) {
                    return NotaryService.updateSession({
                        sessionId: $.session.id,
                        latestSubStage: 'documentCapture',
                        stage: stage,
                        idFrontImage: null
                    });
                }
                return NotaryService.changeSessionStage($.session.id, stage)
                    .then(function () {
                        $.session.stage = stage;

                        ToastrService.showSuccess('Session stage has been changed to ' + stage);
                    });
            };

            $.changeDocumentCount = function (documentsCount) {
                documentsCount = documentsCount || $.currentCount.count;
                console.log(documentsCount)
                if (!documentsCount || documentsCount === $.session.documentsCount) {
                    return $q.resolve();
                }

                return NotaryService.changeSessionDocumentCount(
                    $.session.id,
                    documentsCount
                ).then(function () {
                    $.session.documentsCount = documentsCount;

                    ToastrService.showSuccess(
                        "Documents Count has been changed to " + documentsCount
                    );
                });
            };

            var check = function () {
                $timeout(function () {
                    return NotaryService.checkSessionStatus($.session.id)
                        .then(function (data) {
                            if (!data.updatedAt) {
                                return false;
                            }

                            if (!$.session.updatedAt) {
                                return true;
                            }

                            if (data.updatedAt._seconds !== $.session.updatedAt._seconds) {
                                return true;
                            }
                        })
                        .then(function (checkResult) {
                            if (checkResult) {
                                return window.location.reload();
                            }

                            return check();
                        });
                }, 5e3);
            };

            $.editDocument = function (id) {
                return $.changeStage('customEditDocument')
                    .then(function () {
                        check();

                        return $.openNotarySession('/custom-edit-document?document-id=' + id);
                    });
            };

            $.copyPaymentLink = function () {
                return siteConfigurationLoaded.then(function () {
                    var paymentLink = NotaryService.createUserPaymentLink(notaryLink, $.session.security);

                    if (paymentLink && utils.copyToClipboard(paymentLink)) {
                        return ToastrService.showSuccess('Payment link copied to clipboard');
                    }
                });
            };

            $.resendPaymentLink = function () {
                return NotarySessionsResourceFactory.create({
                    officialId: EnvironmentService.userId(),
                    firmId: EnvironmentService.firm_id(),
                    sessionId: $.session.id
                }).then(function (resource) {
                    return resource.sendPaymentEmail({ token: $.session.security.token }).$promise;
                });
            };

            $.resendDownloadLink = function () {
                return NotarySessionsResourceFactory.create({
                    officialId: EnvironmentService.userId(),
                    firmId: EnvironmentService.firm_id(),
                    sessionId: $.session.id
                }).then(function (resource) {
                    return resource.sendDownloadPageEmail({
                        status: 'completed',
                        sessionId: $.session.id
                    }).$promise;
                });
            };

            $.copyDownloadLink = function () {
                return siteConfigurationLoaded.then(function () {
                    var link = NotaryService.createUserDownloadPageLink(notaryLink, $.session.security);

                    if (link && utils.copyToClipboard(link)) {
                        return ToastrService.showSuccess('Download page link copied to clipboard');
                    }
                });
            };

            $.copyReceiptLink = function () {
                return siteConfigurationLoaded.then(function () {
                    var link = $.session.receiptUrl
                    if (link && utils.copyToClipboard(link)) {
                        return ToastrService.showSuccess('Receipt link copied to clipboard');
                    }
                });
            };

            $.openAddPrivateNotePopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        $scope.officialPrivateNotes = $.session.officialPrivateNotes || '';

                        $scope.close = function () {
                            $mdDialog.hide();
                        };

                        $scope.submit = function () {
                            return NotaryService.addPrivateNotes($.session.id, $scope.officialPrivateNotes)
                                .then(function () {
                                    $.session.officialPrivateNotes = $scope.officialPrivateNotes;
                                    $scope.close();

                                    return ToastrService.showMessageByKey('private_notes_added');
                                });
                        };
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/view/private-note-popup.html' +
                        '?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            var paymentStatuses = {
                paymentByPayer: 1,
                paymentStatusWait: 1,
                payment: 1
            };

            $.isInPaymentStage = function () {
                if (!$.session.isCompleted || $.session.paymentMode !== 'session') {
                    return false;
                }

                return paymentStatuses.hasOwnProperty($.session.stage);
            };

            $.isInVerificationStage = function () {
                const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test($.session.principalDevice.userAgent)

                if (['idVerificationStart', 'idVerificationFront', 'idVerificationBack', 'idVerificationFailed', 'mobileVerificationIdCapture', 'mobileVerificationIdCaptureBack',].includes($.session.stage) && !isMobile) {
                    return true;
                }

                return false;
            };

            $.getErrorDetails = function () {
                let errors = []
                $.session.idCaptureError.map((error, index) => {
                    errors.push(`${index+1}. ${error}`)
                })
                return errors;
            }

            $.openUploadDocumentsPopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        $scope.files = [];
                        
                        $scope.changeDate = function (newDate) {
                            console.log(newDate)
                            $scope.files.map(function (document) {
                                document.date = newDate;
                            });
                        };

                        $scope.close = function () { $mdDialog.hide(); };

                        $scope.filesSelected = function (files) {
                            var filesData = files.map(function (file) {
                                file.displayedName = file.name;

                                return file;
                            });

                            $scope.files = $scope.files.concat(filesData);

                            return true;
                        };

                        $scope.deleteFile = function (index) {
                            $scope.files.splice(index, 1);
                        };

                        $scope.submit = function () {
                            var newDocuments;
                            return NotaryService.uploadDocuments({
                                sessionId: $.session.id,
                                files: $scope.files,
                                startingIndex: Math.floor(new Date().getTime() / 1000)
                            }).then(function (documents) {
                                newDocuments = $.session.documents.concat(documents);
                                $.currentCount.count = $.session.documentsCount + documents.length;
                                return NotaryService.updateSession({
                                    sessionId: $.session.id,
                                    documentsCount: $.session.documentsCount + documents.length
                                });
                            }).then(function () {
                                $.session.documents = newDocuments;

                                $scope.close();
                            });
                        };
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/view/add-document-popup.html' +
                        '?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.deleteDocument = function (doc) {
                return NotaryService.deleteDocument({ documentId: doc.id })
                    .then(function () {
                        $.session.documents = $.session.documents.filter(function (oldDoc) {
                            return oldDoc.id !== doc.id;
                        });
                        $.currentCount.count = $.currentCount.count - 1;
                        return NotaryService.updateSession({
                            sessionId: $.session.id,
                            documentsCount: $.currentCount.count
                        });
                    });
            };

            $.rescheduleSession = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        var requestedDatetime = EnvironmentService.toSystemTime(
                            $.session.scheduledAt._seconds * 1000,
                            function (m) { return m.toDate(); }
                        );
                        var timeslot = utils.generateTimeSlot(requestedDatetime);

                        $scope.date = requestedDatetime;
                        $scope.timeSlots = utils.generateTimeSlots('00:00', '23:00', 30);
                        $scope.time = $scope.timeSlots.find(function (slot) {
                            return slot.formatted === timeslot.formatted;
                        });
                        $scope.minDate = new Date();

                        $scope.close = function () { $mdDialog.hide(); };

                        $scope.submit = function () {
                            $scope.date.setHours($scope.time.hours, $scope.time.minutes);

                            var scheduledAt = $scope.date.toISOString();

                            return NotaryService.updateSession({
                                sessionId: $.session.id,
                                scheduledAt: scheduledAt
                            }).then(function () {
                                return NotaryService.sendNotification({
                                    status: 'created',
                                    sessionId: $.session.id,
                                    notaryAppLink: notaryLink
                                });
                            }).then(function () {
                                $.session.scheduledAt = { _seconds: $scope.date.getTime() / 1000 };

                                $scope.close();
                            });
                        };
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/view/reschedule-session-popup.html?' +
                        '{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true
                });
            };
            $.openPersonaPopup= function() {
                personaClient = new window.Persona.Client({
                    templateVersionId: 'itmplv_D7q7BZVKY8VjwZbzuV3yJ4GwoT7K',
                    referenceId: $.session.id,
                    // environmentId: 'env_zZjUqxyCdNDfenzajwsANmsu3Nrj', //sanbox
                    environmentId: 'env_x1dkEzeHTUvG8Vh9rdEjTSpdSnkE', //production
                    onComplete: ({ inquiryId, status, fields }) => {
                      if (status === 'completed') {
                        $.completeVerification(fields)
                      } else if (status === 'failed') {
                        var errorMessage ="ID Verification Failed";
    
                        return ToastrService.showError(errorMessage);
                      }
                    }
                  })
                personaClient.open()
            }
            $.openPersonaDashboard = async (inquiryId) => {
                const url = `https://app.withpersona.com/dashboard/inquiries/${inquiryId}`
                window.open(url, '_blank').focus();
            }
            $.completeVerification = async (fields) => {
                // if (authenticated === false) {
                //     var errorMessage = utils.translateIdVerificationErrors(extra).join(', ');

                //     return ToastrService.showError(errorMessage);
                // }
                var notarySessionResource = await NotarySessionsResourceFactory.create({
                    officialId: EnvironmentService.userId(),
                    firmId: EnvironmentService.firm_id(),
                    sessionId: $.session.id
                })
                const documentId = fields['current-government-id'].value.id
                var zipCode = fields['address-postal-code'].value;

                var address1 = fields['address-street-1'].value;
                var state = fields['address-subdivision'].value;
                var city = fields['address-city'].value;
                var dob = fields['birthdate'].value;
                var dl = fields['identification-number'].value;
                var idExpiration = fields['expiration-date'].value;
                var firstName = fields['name-first'].value;
                var lastName = fields['name-last'].value;
                var documentType = `${fields['selected-country-code'].value} ${fields['identification-class'].value.toUpperCase()}`;
                var updateData = {
                    sessionId: $.session.id,
                    userInfo: {
                        zipCode: zipCode,
                        address1: address1,
                        city: city,
                        state: state,
                        dob: dob,
                        dl: dl,
                        firstName: firstName,
                        lastName: lastName,
                        documentType: documentType,
                        idExpiration: idExpiration
                    },
                    stage: $.session.isPersonallyKnown ? 'mobileVerificationPersonalDetails' : 'personalDetails'
                };
                var targetMarkets = ['Missouri', 'Florida', 'Montana', 'LOUISIANA', 'MINNESOTA', 'PENNSYLVANIA', 'NEWYORK_BIOMETRIC'].map(function (m) {
                    return m.toLowerCase();
                });

                const options = {
                    method: 'GET',
                    headers: {
                      accept: 'application/json',
                      'Persona-Version': '2023-01-05',
                      authorization: 'Bearer persona_sandbox_efeb9f82-0a8c-4931-8d13-1a65711dcf15'
                    }
                  };
                  await notarySessionResource.getPersonaDocument({ id: documentId }).$promise
                    .then(async (response) => {
                        console.log(response)
                        await fetch(response.data.attributes['front-photo'].url).then(async (response) => {
                            const imageBuffer = await response.arrayBuffer();
                            console.log(imageBuffer)
                            const data = btoa(
                                new Uint8Array(imageBuffer)
                                  .reduce((data, byte) => data + String.fromCharCode(byte), '')
                              );
                            console.log(data)
                            const result = utils.removeBase64Header(
                                await utils.resizeSignature(
                                    `data:image/png;base64,${data}`, {
                                        width: 1024,
                                        height: 1024,
                                        format: 'image/jpeg',
                                        quality: 0.7
                                    }
                                )
                            )
                            console.log(result)
                            updateData.idFrontImage = result;
                            updateData.idCaptureError = null;
                            if ($.session.official && $.session.official.market && targetMarkets.includes($.session.official.market.toLowerCase())) {
                                updateData.latestSubStage = 'selfieCapture';
                                updateData.stage = 'idVerificationFront';
                            }
                            personaClient.destroy();
                            return await notarySessionResource.update(updateData);
                        }).catch(error => console.log(error))
                    })
            }
            $.triggerUploadId = function () {
                $timeout(function () {
                    angular.element('#uploadId').trigger('click');
                    angular.element('#uploadId')
                        .off('change')
                        .on('change', function (event) {
                            var file = event.target.files[0];

                            if (!file) {
                                return;
                            }

                            var notarySessionResource;

                            return Promise.all([
                                utils.getFileString(file),
                                NotarySessionsResourceFactory.create({
                                    officialId: EnvironmentService.userId(),
                                    firmId: EnvironmentService.firm_id(),
                                    sessionId: $.session.id
                                })
                            ]).then(function (data) {
                                var fileData = data[0];

                                notarySessionResource = data[1];

                                return notarySessionResource.uploadId({ front: fileData }).$promise;
                            }).then(function (res) {
                                var authenticated = res.authenticated;
                                var extractedData = res.extractedData;
                                var extra = res.extra;

                                if (authenticated === false) {
                                    var errorMessage = utils.translateIdVerificationErrors(extra).join(', ');

                                    return ToastrService.showError(errorMessage);
                                }

                                var zipCode = utils.getOr(function () { return extractedData.address.postalCode; }, null);

                                zipCode = utils.getOr(function () { return zipCode && zipCode.substr(0, 5); }, null);

                                var address1 = utils.getOr(function () { return extractedData.address.addressLine1; }, null);
                                var state = utils.getOr(function () { return extractedData.address.stateProvince; }, null);
                                var city = utils.getOr(function () { return extractedData.address.city; }, null);
                                var dob = utils.getOr(function () { return extractedData.dateOfBirth; }, null);
                                var extractedName = utils.getOr(function () { return extractedData.name.fullName; }, null);
                                var dl = utils.getOr(function () { return extractedData.documentNumber; }, null);

                                var firstName;
                                var lastName;

                                if (extractedName) {
                                    var name = utils.extractFirstAndLastName(extractedName);

                                    firstName = name.firstName;
                                    lastName = name.lastName;
                                }

                                var updateData = {
                                    sessionId: $.session.id,
                                    userInfo: {
                                        zipCode: zipCode,
                                        address1: address1,
                                        city: city,
                                        state: state,
                                        dob: dob,
                                        dl: dl,
                                        firstName: firstName,
                                        lastName: lastName
                                    },
                                    stage: $.session.isPersonallyKnown ? 'mobileVerificationPersonalDetails' : 'personalDetails'
                                };
                                var targetMarkets = ['Missouri', 'Florida', 'Montana'].map(function (m) {
                                    return m.toLowerCase();
                                });

                                if ($.session.official && $.session.official.market && targetMarkets.includes($.session.official.market.toLowerCase())) {
                                    updateData.latestSubStage = 'selfieCapture';
                                    updateData.stage = 'idVerificationFront';
                                }

                                return notarySessionResource.update(updateData).$promise;
                            });
                        });
                }, 0);
            };

            $.downloadId = function (url) {
                return downloadFile({
                    url: url,
                    filename: $.session.id + '_ID',
                    separateWindow: true
                });
            };

            $.createDocumentUploadRequestPopup = function () {
                var openPopup = function () {
                    $mdDialog.show({
                        controller: 'CreateDocumentUploadRequestCtrl',
                        templateUrl: '/assets/app/shared/controllers/notary/requests/popups/CreateDocumentUploadRequest/' +
                            'template.html?{{onfileApplicationVersion}}',
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        locals: {
                            session: $.session
                        }
                    });
                };
                return openPopup();
            };

            $.sendMobileIdCaptureLink = function () {
                var openPopup = function () {
                    $mdDialog.show({
                        controller: "sendMobileIdCaptureLinkCtrl",
                        templateUrl:
                            "/assets/app/shared/controllers/notary/requests/popups/sendMobileIdCaptureLink/" +
                            "template.html?{{onfileApplicationVersion}}",
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        locals: {
                            session: $.session,
                        },
                    });
                };
                return openPopup();
            };

            var siteConfigurationLoaded = SiteConfigurationService.getConfigurationForPage('notary')
                .then(function (notary) {
                    if (notary && notary.link) {
                        notaryLink = notary.link;
                    }

                    if (notary && notary.sharedDocumentsAppUrl) {
                        sharedDocumentsAppUrl = notary.sharedDocumentsAppUrl;
                    }

                    return notary;
                });

            var getDocumentUrl = function (document) {
                if ($.session.isCompleted) {
                    return NotaryService.getSealedDocumentUrl(document);
                }

                return NotaryService.getOriginalDocumentUrl(document);
            };

            var updateSessionDetails = function (session, force) {
                resetData($);

                if (!session) {
                    return;
                }

                var promise = $q.resolve();
                var requestParams = { sessionId: session.id };

                $.session = session;
                $.selectedOfficial.uid = $.session.official.uid;
                $.selectedStage.stage = $.session.stage;
                $.currentCount.count = $.session.documentsCount;
                $.isNotaryAdmin = session.official.roles ? session.official.roles.some((item) => ['notaryAdmin', 'scheduler', 'remoteSignSessionCreator'].includes(item)) : false;
                if (!$.session.documents || force) {
                    if ($state.current.name === ONE_RECORD_PRODUCTION_ROUTE_NAME) {
                        requestParams.email = EnvironmentService.email();
                    }

                    promise = NotaryService.getDocuments(requestParams)
                        .then(function (documents) {
                            $.session.documents = documents;
                        });
                }

                if ($.session.security) {
                    siteConfigurationLoaded.then(function (data) {
                        $.userLink = NotaryService.createUserLink(notaryLink, $.session.security);
                        $.sharedDocsAppLink = NotaryService.createSharedDocsAppLink(
                            sharedDocumentsAppUrl, $.session.security
                        );
                        $.sharedDocsDownloadAppLink = NotaryService.createSharedDocsDownloadAppLink(
                            data.sharedDocumentsDownloadAppUrl, $.session.security
                        );
                    });
                }

                updateSharedDocs(session.id);

                return promise.then(function () {
                    if ($.session.documents.length) {
                        $.showDocument($.session.documents[0], $.session);
                    }
                });
            };

            var updateSharedDocs = function (sessionId) {
                var requestParams = {};

                if ($state.current.name === ONE_RECORD_PRODUCTION_ROUTE_NAME) {
                    requestParams.email = EnvironmentService.email();
                }

                return NotaryService.getSharedDocumentsList(sessionId, requestParams)
                    .then(function (sharedDocuments) {
                        $.session.sharedDocuments = sharedDocuments;
                    });
            };

            var updateSessionData = function () {
                $.sessionId = $.session.id;

                $.credentialAnalysisStatus = getCredentialAnalysisStatus();
                $.quizStatus = getQuizStatus();
                $.device = '';

                if ($.session.principalDevice) {
                    $.device = UserAgentService.decodeOS($.session.principalDevice) + ' ' +
                        UserAgentService.decodeBrowser($.session.principalDevice);
                }

                $.isOfficialOnline = utils.isUserOnline($.session, 'officialLastOnlineAt');
                $.isSignerOnline = utils.isUserOnline($.session, 'signerLastOnlineAt');
                $.officialConnectivityStats = '';
                $.signerConnectivityStats = '';
                $.isSignerWebRTCOk = false;
                $.isSignerWebRTCError = false;

                if ($.session.officialConnectivity && $.session.officialConnectivity.stats) {
                    $.officialConnectivityStats = JSON.parse($.session.officialConnectivity.stats);
                }

                if ($.session.signerConnectivity && $.session.signerConnectivity.stats) {
                    $.signerConnectivityStats = JSON.parse($.session.signerConnectivity.stats);
                    $.isSignerWebRTCOk = true;
                }
                if ($.session.signerConnectivity && $.session.signerConnectivity.error) {
                    $.isSignerWebRTCError = $.session.signerConnectivity.error;
                }
                if($.session){
                    if (!$.session.userIO || !$.session.userIO.audioDevices || !$.session.userIO.videoDevices || !($.session.userIO.audioDevices.length && $.session.userIO.videoDevices.length)) {
                        $.isSignerWebRTCOk = false;
                    }
                    else {
                        $.isSignerWebRTCOk = true;
                    }
                }
            };

            var getCredentialAnalysisStatus = function () {
                if (!$.session || !$.session.stage) {
                    return {};
                }

                if ($.session.stage === 'start') {
                    return { text: 'Not started', class: '' };
                }

                if ($.session.stage === 'idVerificationFailed' || $.session.idCaptureError) {
                    return { text: 'Failed', class: '' };
                }

                if (
                    [
                        'idVerificationStart',
                        'idVerificationFront',
                        'mobileVerificationIdCapture',
                        'mobileVerificationPersonalDetails',
                        'mobileVerificationSignatureInitial',
                        'mobileVerificationSignatureCreation'
                    ].indexOf($.session.stage) >= 0
                ) {
                    return { text: 'In progress', class: '' };
                }

                if (
                    [
                        'idVerificationBack',
                        'mobileVerificationIdCaptureBack',
                    ].indexOf($.session.stage) >= 0
                ) {
                    return { text: 'Enhanced Analysis', class: '' };
                }

                return { text: 'Passed', class: '' };
            };

            var getQuizStatus = function () {
                if (!$.session || !$.session.stage) {
                    return {};
                }

                if (
                    [
                        'start',
                        'idVerificationFront',
                        'idVerificationFailed',
                        'mobileVerificationIdCapture',
                        'mobileVerificationPersonalDetails',
                        'mobileVerificationSignatureInitial',
                        'mobileVerificationSignatureCreation',
                        'personalDetails',
                        'cameraCheckStart',
                        'signatureInitial',
                        'signatureStart',
                        'signatureCaptured',
                        'userInRoom',
                        'officialInRoom',
                        'checkPersonalDetail'
                    ].indexOf($.session.stage) >= 0
                ) {
                    return { text: 'Not started', class: '' };
                }

                if ($.session.stage === 'KBAFails') {
                    return { text: 'Failed', class: '' };
                }

                if (
                    [
                        'idVerificationFront',
                        'mobileVerificationIdCapture',
                        'mobileVerificationPersonalDetails',
                        'mobileVerificationSignatureInitial',
                        'mobileVerificationSignatureCreation'
                    ].indexOf($.session.stage) >= 0
                ) {
                    return { text: 'In progress', class: '' };
                }

                return { text: 'Passed', class: '' };
            };

            this.uiOnParamsChanged = function (changedParams) {
                if (!changedParams || !changedParams.id) {
                    return;
                }

                var isFound = NotaryFilesService.setActiveById(changedParams.id);

                if (isFound) {
                    if (changedParams.tab === 'status') {
                        $.changeTab(changedParams.tab);
                    }

                    return;
                }

                NotarySessionsResourceFactory.create({
                    officialId: EnvironmentService.userId(),
                    firmId: EnvironmentService.firm_id(),
                    sessionId: changedParams.id
                }).then(function (resource) {
                    return resource.status({ sessionId: changedParams.id }).$promise;
                }).then(function (session) {
                    NotaryFilesService.unshift(session).setActiveById(changedParams.id);

                    if (changedParams.tab === 'status') {
                        $.changeTab(changedParams.tab);
                    }
                });
            };

            NotaryFilesService.onItemsSet(function () {
                this.uiOnParamsChanged($stateParams);
            }.bind(this));

            NotaryFilesService.onActiveChanged(function (session) {
                $.changeTab(defaultTab);
                updateSessionDetails(session);
                updateSessionData();
            });

            PermissionsService.isFeatureAvailable('notaryScheduling')
                .then(function (isAvailable) {
                    $.isAssignmentAvailable = isAvailable;

                    if (!$.isAssignmentAvailable) {
                        return;
                    }

                    return OfficialsResourceFactory.create()
                        .then(function (resource) {
                            return resource.query().$promise;
                        })
                        .then(function (officials) {
                            $.officials = officials;
                        });
                });

            $rootScope.$on('$locationChangeStart', function () {
                if (timeoutId) {
                    $timeout.cancel(timeoutId);
                    timeoutId = undefined;
                }
            });
        }
    ]);

    var updatePdfHandler = function (pdfJsLib, $q, $) {
        var cache = {};
        var getFromCache = function (url) {
            if (cache[url]) {
                return $q.resolve(cache[url]);
            }

            return pdfJsLib.getDocument(url)
                .promise
                .then(function (pdf) {
                    cache[url] = pdf;

                    return cache[url];
                });
        };

        return function (url, canvasId, options) {
            var pageNumber = options && options.page || 1;

            return getFromCache(url)
                .then(function (pdf) {
                    $.pdfPagesQuantity = pdf._pdfInfo.numPages;
                    $.pdfPages = new Array($.pdfPagesQuantity);

                    return pdf.getPage(pageNumber);
                })
                .then(function (page) {
                    var scale = 1.3;
                    var viewport = page.getViewport({ scale: scale });

                    // Prepare canvas using PDF page dimensions
                    var canvas = document.getElementById(canvasId);
                    var context = canvas.getContext('2d');

                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render PDF page into canvas context
                    var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    return page.render(renderContext);
                })
                .catch(function (reason) { console.error(reason); });
        };
    };

    var uploadedImageHandler = function ($) {
        return function () {
            var reader = new FileReader();

            reader.onload = function (e) {
                $.uploadedSignaturesDataImages.push(e.target.result);
            };

            // read the image file as a data URL.
            reader.readAsDataURL(this.files[0]);
        };
    };

    var resetData = function ($) {
        $.showCanvas = false;
        $.pdfPreviewCanvasId = 'preview-document-canvas-' + new Date().getTime().toString();
        $.uploadedSignaturesDataImages = [];
        $.insertedSignatures = [];
        $.insertedTextNodes = [];
    };
})(angular.module('onfileApp'));
