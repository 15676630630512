(function (app) {
    app.factory('FeeResource', ['$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/fees', {}, {
                forFiling: urlEncodedResource({
                    transformRequest: function (data) {
                        data.filing_type = 'filing';
                        return data;
                    },
                    method: 'POST',
                    isArray: false,
                    cache: true
                }),
                forService: urlEncodedResource({
                    transformRequest: function (data) {
                        data.filing_type = 'service';

                        return data;
                    },
                    method: 'POST',
                    isArray: false
                })
            });
        }]);
})(angular.module('onfileApp'));