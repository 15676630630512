(function (app) {
    app.directive('scheduleHearing', [
        '$mdDialog', 'CourtResource', 'ToastrService',
        'HearingDateModelFactory', 'CaseService', '$filter',
        '$rootScope', '$state', 'ReminderService',
        'Reminder', 'EnvironmentService', '$q',
        'FirebaseService', 'UsersResource', 'utils',
        'customEvents',

        function (
            $mdDialog, CourtResource, ToastrService,
            HearingDateModelFactory, CaseService, $filter,
            $rootScope, $state, ReminderService,
            Reminder, EnvironmentService, $q,
            FirebaseService, UsersResource, utils,
            customEvents
        ) {
            return {
                strict: 'A',
                scope: {
                    isVisible: '=',
                    filing: '=',
                    courtContact: '='
                },
                link: function ($) {
                    $.$watch('isVisible', function (isVisible) {
                        isVisible ? showDialog() : hideDialog();
                    });

                    var ifHearingRemindersEnabled = function () {
                        return UsersResource.settings().$promise
                            .then(function (res) {
                                var result = utils.getOr(function () {
                                    return res.settings.notification
                                        .find(function (n) { return n.code === 'HEARING'; })
                                        .isActive;
                                }, false);

                                if (result === false) {
                                    throw result;
                                }

                                return result;
                            });
                    };

                    var hideDialog = function hideDialog() {
                        $mdDialog.hide();
                    };

                    var showDialog = function () {
                        $mdDialog.show({
                            controller: ['$scope', function ($scope) {
                                $scope.activeTabIndex = 0;
                                $scope.filing = $.filing;
                                $scope.courtContact = $.courtContact;
                                $scope.minDate = new Date();
                                $scope.model = HearingDateModelFactory.create();
                                $scope.getAvailableDatesError = null;
                                $scope.scheduleError = null;

                                $scope.setTabActive = function (index) {
                                    $scope.activeTabIndex = index;
                                };

                                $scope.close = function () {
                                    $.isVisible = false;
                                    hideDialog();
                                    $state.transitionTo('recentFilings', { id: $scope.filing.id });

                                    if ($scope.submitted) {
                                        $rootScope.$broadcast(customEvents.hearingScheduled, {
                                            filingId: $scope.filing.id,
                                            envelopeNumber: $scope.filing.envelope_number
                                        });
                                    }
                                };

                                $scope.changeSelectedDate = function (selectedDate) {
                                    $scope.selectedDate = selectedDate;
                                };

                                $scope.save = function () {
                                    var data;
                                    var step = $scope.activeTabIndex;
                                    var isValid = validate(step);

                                    if (isValid === false) {
                                        return false;
                                    }

                                    data = $scope.selectedDate;

                                    if (step === 1) {
                                        data = $scope.model.toDataObject();
                                    }

                                    data.courtCode = CaseService.getCourtCode($scope.filing);
                                    data.envelope = $scope.filing.envelope_number;
                                    data.caseId = $scope.filing.case_id;
                                    data.filingId = $scope.filing.id;

                                    $scope.scheduleError = false;
                                    CourtResource.reserveDates(data).$promise
                                        .then(function (res) {
                                            if (res && res.error) {
                                                $scope.scheduleError = res.error;
                                            }

                                            if (res && res.dates) {
                                                $scope.submittedDate = {
                                                    start: $filter('amDateFormat')(
                                                        res.dates.start, $rootScope.shortDateTime
                                                    ),
                                                    end: $filter('amDateFormat')(
                                                        res.dates.end, $rootScope.shortDateTime
                                                    )
                                                };
                                            }

                                            if (!$scope.scheduleError) {
                                                $scope.submitted = true;
                                            }

                                            if (!$scope.scheduleError && res && res.dates && res.dates.start) {
                                                ifHearingRemindersEnabled().then(function () {
                                                    ReminderService.create(
                                                        new Reminder({
                                                            type: 'hearing',
                                                            due_date: new Date(res.dates.start),
                                                            description: 'Hearing reminder',
                                                            user_id: EnvironmentService.userId()
                                                        }).setProp('hearing', FirebaseService.purifyObject(data))
                                                    );
                                                }).catch(function () { /* do nothing; */ });
                                            }
                                        });

                                    return true;
                                };

                                var validate = function (step) {
                                    if (step === 0 && !$scope.selectedDate) {
                                        ToastrService.showMessageByKey('available_date_missing');

                                        return false;
                                    }

                                    if (step === 1 && !$scope.model.isValid()) {
                                        $scope.model.errors.map(function (message) {
                                            ToastrService.showError(message);
                                        });

                                        return false;
                                    }

                                    return true;
                                };

                                var data = {
                                    envelope: $scope.filing.envelope_number,
                                    courtCode: CaseService.getCourtCode($scope.filing)
                                };
                                CourtResource.getAvailableDates(data).$promise
                                    .then(function (res) {
                                        $scope.availableDates = [];

                                        if (res && res.dates && res.dates.length) {
                                            $scope.availableDates = res.dates.map(function (date) {
                                                var start = date.start * 1000;
                                                var end = date.end * 1000;
                                                var text = HearingDateModelFactory.formatDate(start);

                                                return {
                                                    startDate: start,
                                                    endDate: end,
                                                    text: text
                                                };
                                            });
                                        }

                                        if (res && res.error) {
                                            $scope.getAvailableDatesError = res.error;
                                        }
                                    });
                            }],
                            templateUrl: '/assets/app/shared/directives/scheduleHearing/template.html' +
                                '?{{onfileApplicationVersion}}',
                            parent: angular.element(document.body),
                            clickOutsideToClose: false
                        });
                    };
                }
            };
        }
    ]);
})(angular.module('onfileApp'));
