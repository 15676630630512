(function (app) {
    app.controller('NotaryRequestsHeaderCtrl', [
        '$scope', '$rootScope', '$timeout',
        '$mdDialog',

        function (
            $, $rootScope, $timeout,
            $mdDialog
        ) {
            $rootScope.showList = true;

            $timeout(function () { $rootScope.customListHeader = true; }, 0);

            var ctrl = function ($scope) {
                $scope.created = false;
                $scope.model = {};
                $scope.files = [];

                $scope.close = function () { $mdDialog.hide(); };
                $scope.submit = function () {
                    $scope.created = true;
                    // $scope.close();
                };

                $scope.filesSelected = function (files) {
                    var newFiles = files.map(function (file) {
                        file.displayedName = file.name;

                        return file;
                    });

                    $scope.files = $scope.files.concat(newFiles);
                };

                $scope.removeFile = function (i) {
                    $scope.files.splice(i, 1);
                };
            };

            $.tags = [
                {
                    name: 'All',
                    value: null,
                    iconColor: 'rgba(0, 0, 0, 0.54)'
                }, {
                    name: 'Pending requests',
                    value: $rootScope.constants.EXTERNAL_SESSION_REQUESTED,
                    iconColor: '#3C5DAD'
                }, {
                    name: 'Scheduled notary',
                    value: $rootScope.constants.INTERNAL_SESSION_CREATED,
                    iconColor: '#FB993D'
                }, {
                    name: 'Notary completed',
                    value: $rootScope.constants.SESSION_FINISHED,
                    iconColor: '#47B460'
                }
            ];
            $.selectedTag = $.tags[0].value;

            $.filterByTag = function (tag) {
                $rootScope.items && $rootScope.items.map(function (r) {
                    if (!tag) {
                        r.isHidden = false;

                        return;
                    }

                    if (!r.tags || !r.tags.length) {
                        r.isHidden = true;

                        return;
                    }

                    r.isHidden = r.tags.indexOf(tag) === -1;
                });
            };

            $.openDocumentPopup = function () {
                $mdDialog.show({
                    controller: 'DocumentRequestCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/DocumentRequest/' +
                        'create-document-request.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.openFilePopup = function () {
                $mdDialog.show({
                    controller: ['$scope', function ($scope) {
                        $scope.model = {};

                        $scope.close = function () { $mdDialog.hide(); };
                        $scope.submit = function () {
                            $scope.close();
                        };
                    }],
                    templateUrl: '/assets/app/shared/controllers/notary/requests/header/popups/' +
                        'create-file-request.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.openFileAndDocumentPopup = function () {
                $mdDialog.show({
                    controller: ['$scope', ctrl],
                    templateUrl: '/assets/app/shared/controllers/notary/requests/header/popups/' +
                        'create-file-and-document-request.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.openSessionPopup = function () {
                $mdDialog.show({
                    controller: 'CreateNotarySessionRequestCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/' +
                        'create-notary-session-request.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.openCreateMatterPopup = function () {
                $mdDialog.show({
                    controller: 'CreateMatterCtrl',
                    templateUrl: '/assets/app/shared/controllers/notary/requests/popups/CreateMatterPopup/' +
                        'create-matter.html?{{onfileApplicationVersion}}',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };

            $.search = function () {
                $rootScope.toggleSearchMattersSidebar();
            };
        }
    ]);
})(angular.module('onfileApp'));