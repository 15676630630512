(function (app) {
    app.constant(
        'mimeTypeMap',
        {
            'application/pdf': 'PDF Document',
            'text/plain': 'Text',
            'image/png': 'Image',
            'image/jpeg': 'Image',
            'image/jpg': 'Image',
            'image/gif': 'Image',
            'application/zip': 'Compressed file',
            'application/x-rar-compressed': 'Compressed file',
            'application/msword': 'Document file',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Document file',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Spreadsheet',
            'application/vnd.ms-excel': 'Spreadsheet'
        }
    );
})(angular.module('onfileApp'));